import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import toastr from 'toastr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Content } from '../../PageLayout';
import MemberClaimsReport from './MemberClaimsReport';
import MemberConditions from './MemberConditions';
import MemberRiskScoreData from './MemberRiskScoreData';
import MemberPopulation from './MemberPopulation';
import MemberPcpReport from './MemberPcpReport';
import MemberRARSDScore from './MemberRARSDScore';
import MemberEnrollmentReport from './MemberEnrollmentReport';
import MemberDetailExportModal from './MemberDetailExportModal';
import { TABS } from './MemberProfileCommon';

export default class MemberDetail extends Component {
	constructor(props) {
		super(props);

		const { search } = props.location;

		let benefitYr = 0, status, urlQuery = '';
		if (search) {
			urlQuery = search;
			let temp = search.split('&');
			temp.forEach((x) => {
				let keyPair = x.split('=');
				if (keyPair[0].indexOf('benefitYr') > -1) {
					benefitYr = parseInt(keyPair[1]);
				} else if (keyPair[0].indexOf('status') > -1) {
					status = keyPair[1];
				} 
			});
		}

		if (benefitYr === 0) {
			benefitYr = new Date().getFullYear()
		}

		const { memberId } = props.match.params;

		this.state = {
			currentTab: TABS.ENROLLMENT.value,
			memberMasterId: memberId,
			member: {},
			showHistory: false,
			benefitYr,
			status,
			collapsed: false,
			show: false,
			urlQuery,
			memberLoaded: false
		};
	}

	componentDidMount() {
		const { memberMasterId } = this.state;

		axios.get('/MemberProfile/' + memberMasterId).then((response) => {
			this.setState({ member: response.data, memberLoaded: true });
		}).catch((err) => {
			console.log(err);
			toastr.error('An error occurred while retrieving member data.');
		});
	}

	getBtnClass = (tab) => {
		if (tab === this.state.currentTab) {
			return 'btn btn-primary active disabled';
		}
		return 'btn btn-default';
	}

	toggleMemberDetail = () => {
		this.setState({ collapsed: !this.state.collapsed });
	}

	selectTab = (tab) => (event) => {
		event.preventDefault();

		if (this.state.currentTab !== tab) {
			this.setState({ currentTab: tab });
		}
	}

	selectShowHistory = (e) => {
		this.setState({ showHistory: e.target.checked });
	}

	formatDate = (date) => {
		date = new Date(date);
		return (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();
	}

	showExportModal = () => {
		this.setState({ show: true });
	};

	onHide = () => {
		this.setState({ show: false });
	}

	render() {
		const { memberMasterId, currentTab, member, showHistory, benefitYr, collapsed, show, urlQuery } = this.state;
		const { getBtnClass, selectTab, formatDate, selectShowHistory, toggleMemberDetail, showExportModal } = this;

		return (
			<Content>
				<Content.Header>
					Member Profile Report
				</Content.Header>
				<Content.Body>
					<Content.Filter>
						{
							collapsed === false &&
							<div className="member-details">
								<div className="row">
									<div className="col-sm-12">
										<label>Member ID: </label>
										<span>{member.MemberId}</span>

										<button className="btn btn-default pull-right" onClick={toggleMemberDetail}>
											<FontAwesomeIcon icon="minus" />
										</button>
									</div>
								</div>
								<div className="row">
									<div className="col-sm-12">
										<label>First Name: </label>
										<span>{member.FirstName}</span>
										<label>MI: </label>
										<span>{member.MiddleName}</span>
										<label>Last Name: </label>
										<span>{member.LastName}</span>
									</div>
								</div>
								<div className="row">
									<div className="col-sm-12">
										<label>DOB: </label>
										<span>{formatDate(member.DateOfBirth)}</span>
									</div>
								</div>
								<div className="row">
									<div className="col-sm-12">
										<label>Age Band: </label>
										<span>{member.Model}</span>
										<label>Sex: </label>
										<span>{member.Gender}</span>
									</div>
								</div>
								<div className="row">
									<div className="col-sm-12">
										<div className="checkbox">
											<label>
												<input type="checkbox" checked={showHistory} onChange={selectShowHistory} /> Show History
											</label>
										</div>
									</div>
								</div>
							</div>
						}
						{
							collapsed === true &&
							<div className="member-details --collapsed">
								<div className="row">
									<div className="col-sm-12">
										<label>Member ID: </label>
										<span>{member.MemberId}</span>

										<button className="btn btn-default pull-right" onClick={toggleMemberDetail}>
											<FontAwesomeIcon icon="plus" />
										</button>
									</div>
								</div>
							</div>
						}
					</Content.Filter>
					<div className="row export-spacing">
						<Link className="btn btn-default" to={'/Reporting/MemberProfile' + urlQuery}>
							Back
						</Link>
						<button className="btn btn-tessellate pull-right" onClick={showExportModal} >
							Export
						</button>
					</div>
					<Content.Result>
						<div className="row" style={{ paddingBottom: '15px' }}>
							<div className="col-sm-12">
								<div className="btn-group tab-group-7" role="group">
									{
										Object.keys(TABS).map((t) => {
											return (
												<button key={'tab_id_' + TABS[t].value}
													className={getBtnClass(TABS[t].value)}
													onClick={selectTab(TABS[t].value)}
												>
													{TABS[t].label}
												</button>
											)
										})
									}
								</div>
							</div>
						</div>
						<div className="row">
							{
								this.state.memberLoaded === true &&
								<div className="col-sm-12">
									{
										currentTab === TABS.ENROLLMENT.value &&
										<MemberEnrollmentReport
											memberMasterId={memberMasterId}
											showHistory={showHistory}
											benefitYr={benefitYr} />
									}
									{
										currentTab === TABS.CLAIMS.value &&
										<MemberClaimsReport
											memberMasterId={memberMasterId}
											showHistory={showHistory}
											benefitYr={benefitYr} />
									}
									{
										currentTab === TABS.CONDITIONS.value &&
										<MemberConditions memberMasterId={memberMasterId} showHistory={showHistory} benefitYr={benefitYr} />
									}
									{
										currentTab === TABS.RISK.value &&
										<MemberRiskScoreData
											memberMasterId={memberMasterId}
											showHistory={showHistory}
											benefitYr={benefitYr} />
									}
									{
										currentTab === TABS.RARSD_RISK.value &&
										<MemberRARSDScore
											memberMasterId={memberMasterId}
											showHistory={showHistory}
											benefitYr={benefitYr} />
									}
									{
										currentTab === TABS.GAPS.value &&
										<MemberPopulation
											memberMasterId={memberMasterId}
											showHistory={showHistory}
											benefitYr={benefitYr} />
									}
									{
										currentTab === TABS.PCP.value &&
										<MemberPcpReport
											memberMasterId={memberMasterId}
											showHistory={showHistory}
											benefitYr={benefitYr} />
									}

								</div>
							}
						</div>
					</Content.Result>
				</Content.Body>
				{
					show === true &&
					<MemberDetailExportModal
						show={show}
						onHide={this.onHide}
						memberMasterId={memberMasterId}
						showHistory={showHistory}
						benefitYr={benefitYr}
					/>
				}
			</Content >
		)
	}
}
