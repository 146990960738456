
import React, { Component } from "react";
import axios from "axios";

import {
  renderDifference,
  renderDecimal,
  renderInteger,
  renderDecimalLink,
  renderMonth
} from "../../../utils/CommonReactTable";

import ReactTable from "react-table";
import MemberRiskScoreDetailModal from "./MemberRiskScoreDetailModal";
import MemberRiskScoreComparisonModal from "./MemberRiskScoreComparisonModal";


export default class MemberRiskScoreData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      memberMasterId: props.memberMasterId,
      results: [],
      columnSorting:[{id: "BenefitYear", desc: true}, {id: "PlanId", desc: false},{id: "MetalLevel", desc: false}],
      BenefitYear: props.benefitYr,
      pageCount: 0,
      pageNumber: 1,
      pageSize: 10,
      loading: false,
      showHistory: props.showHistory,
      showScoreDetailModal: false,
      showComparisonModal: false,
      scoreDetailModalTitle: "",
      calcId: 0,
      calcId2: 0,
      totalItemCount : 0
    };
    this.onPageChange = this.onPageChange.bind(this);
    this.onPageSizeChange = this.onPageSizeChange.bind(this);
    this.onPaging = this.onPaging.bind(this);
    this.onHide = this.onHide.bind(this);
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.showHistory !== nextState.showHistory) {
      this.setState({ ...nextProps }, this.getRiskScoreData);
    }
    return true;
  }
  getRiskScoreData = () => {
    const {
      memberMasterId,
      pageNumber,
      pageSize,
      columnSorting,
      BenefitYear,
      showHistory
    } = this.state;
    let params = {
      pageNumber,
      pageSize,
      columnSorting,
      benefitYear: showHistory === false ? BenefitYear : 9999
    };
    axios
      .get("/MemberProfile/" + memberMasterId + "/RiskScoreData", { params })
      .then(response => {
        this.setState({
          results: response.data.Detail,
          loading: false,
          pageCount: response.data.PageCount,
          totalItemCount : response.data.TotalItemCount
        });
      })
      .catch(error => {
        console.log(error);
      });
  };
  onFecthData = (state, instance) => {
    const { page, pageSize } = instance.state;
    this.setState(
      { loading: true, pageNumber: page + 1, pageSize },
      this.getRiskScoreData
    );
  };

  onPaging = (pageSize, pageIndex) => {
    if (pageSize < 1) pageSize = this.state.results.PageSize;
    else pageIndex = 0;
    let pageNumber = pageIndex + 1;
    this.setState(
      { loading: true, pageNumber: pageNumber, pageSize },
      this.getRiskScoreData
    );
  };
  onPageChange(pageIndex) {
    this.onPaging(-1, pageIndex);
  }
  onPageSizeChange(pageSize, pageIndex) {
    this.onPaging(pageSize, pageIndex);
  }
  onHide() {
    this.setState({ showScoreDetailModal: false, showComparisonModal: false });
  }

  columnSortinghandler = (sorted)=>{
		this.setState({ columnSorting: sorted, pageNumber: 1 } , this.getMemberClaimsData);
  } 
  
  render() {
    const { results, pageNumber, pageSize, pageCount, loading, totalItemCount} = this.state;
    const { onPageChange, onPageSizeChange, onFecthData, columnSortinghandler} = this;
    const columns = [
      {
        Header: "Benefit Year",
        accessor: "BenefitYear",
        width: 110,
        Cell: row => renderInteger(row.original.BenefitYear)
      },
      {
        Header: "Plan ID",
        accessor: "PlanId",
        width: 181
      },
      {
        Header: "Metal Level",
        accessor: "MetalLevel"
      },
      {
        Header: "Calc Claims Score",
        id: "CalcClaimsScore",
        Cell: row => renderDecimalLink(row.original.CalcClaimsScore, 3, "")
      },
      {
        Header: "CMS Data as of Month",
        id: "CMSDataAsofMonth",
        Cell: row => renderMonth(row.original.CMSDataAsofMonth)
      },
      {
        Header: "Calc RACSD Score",
        id: "CalcRACSDScore",
        Cell: row => renderDecimalLink(row.original.CalcRACSDScore, 3, "")
      },
      {
        Header: "CMS RACSD Score",
        id: "CMSRACSDScore",
        Cell: row => renderDecimalLink(row.original.CMSRACSDScore, 3, "")
      },
      {
        Header: "Calc RARSD Score",
        id: "CalcRARSDScore",
        Cell: row => renderDecimalLink(row.original.CalcRARSDScore, 3, "")
      },
      {
        Header: "CMS RARSD Score",
        id: "CMSRARSDScore",
        Cell: row => renderDecimalLink(row.original.CMSRARSDScore, 3, "")
      },
      {
        Header: "RACSD Difference",
        id: "RACSDDiff",
        Cell: row => renderDifference(row.original.RACSDDiff, 3)
      },
      {
        Header: "Calc RACSD vs Calc RARSD Difference",
        id: "CalRACSDvsCalRARSDDiff",
        width: 201,
        Cell: row => renderDecimalLink(row.original.CalRACSDvsCalRARSDDiff, 3)
      },
      {
        Header: "CMS RACSD vs CMS RARSD Difference",
        id: "CMSRACSDvsCMSRARSDDiff",
        width: 204,
        Cell: row => renderDecimalLink(row.original.CMSRACSDvsCMSRARSDDiff, 3)
      },
      {
        Header: "RARSD Difference",
        id: "RARSDDiff",
        Cell: row => renderDifference(row.original.RARSDDiff)
      },
      {
        Header: "Potential Score",
        id: "PotentialScore",
        Cell: row => renderDecimal(row.original.PotentialScore, 3)
      }
    ];

    return (
      <React.Fragment>
        <ReactTable
          className="ts-react-table -striped -highlight"
          columns={columns}
          data={results}
          page={pageNumber - 1}
          loading={loading}
          onFetchData={onFecthData}
          pages={pageCount}
          defaultPageSize={pageSize}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          sortable={true}
          manual
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          onSortedChange = {sorted=>this.columnSortinghandler(sorted)}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              onClick: (e, handleOriginal) => {

                const clickables = [
                  "CalcRACSDScore",
                  "CMSRACSDScore",
                  "CalcRARSDScore",
                  "CMSRARSDScore",
                  "CalcClaimsScore",
                  "CalRACSDvsCalRARSDDiff",
                  "CMSRACSDvsCMSRARSDDiff"
                ];
                
                const titles = [
                  "Calc RACSD",
                  "CMS RACSD",
                  "Calc RARSD",
                  "CMS RARSD",
                  "Calc Claims"
                ];

                const calcIds = [10, 20, 30, 40, 50];
                let foundIndex = clickables.indexOf(column.id);

                if (rowInfo && foundIndex > -1) {
                  let planId = rowInfo.original.PlanId;
                  let CMSDataAsofMonth = rowInfo.original.CMSDataAsofMonth;
                  let scoreDetailScore =
                    rowInfo.original[clickables[foundIndex]];
                  let benefitYear = rowInfo.original.BenefitYear;

                  if (foundIndex < 5) {
                    this.setState({
                      showScoreDetailModal: true,
                      scoreDetailModalTitle: titles[foundIndex],
                      calcId: calcIds[foundIndex],
                      scoreDetailScore,
                      planId,
                      CMSDataAsofMonth,
                      BenefitYear: benefitYear
                    });
                  }
                  if (foundIndex > 4 && foundIndex < 7) {
                    let calcId = 0,
                      calcId2 = 0;
                    if (foundIndex === 5) {
                      calcId = 10;
                      calcId2 = 30;
                    } else {
                      calcId = 20;
                      calcId2 = 40;
                    }

                    this.setState({
                      showComparisonModal: true,
                      scoreDetailModalTitle: titles[foundIndex],
                      calcId,
                      calcId2,
                      scoreDetailScore,
                      planId,
                      CMSDataAsofMonth,
                      BenefitYear: benefitYear
                    });
                  }
                }

                if (handleOriginal) {
                  handleOriginal();
                }
              }
            };
          }}
        />

<div><strong>Total Number of Records: {totalItemCount}</strong></div>

        {this.state.showScoreDetailModal && (
          <MemberRiskScoreDetailModal
            show={this.state.showScoreDetailModal}
            onShow={this.show}
            onHide={this.onHide}
            title={this.state.scoreDetailModalTitle}
            score={this.state.scoreDetailScore}
            memberMasterId={this.state.memberMasterId}
            calcId={this.state.calcId}
            benefitYear={this.state.BenefitYear}
            CMSDataAsofMonth={this.state.CMSDataAsofMonth}
            planId={this.state.planId}
          />
        )}
        {this.state.showComparisonModal && (
          <MemberRiskScoreComparisonModal
            show={this.state.showComparisonModal}
            onShow={this.show}
            onHide={this.onHide}
            score={this.state.scoreDetailScore}
            memberMasterId={this.state.memberMasterId}
            calcId={this.state.calcId}
            calcId2={this.state.calcId2}
            benefitYear={this.state.BenefitYear}
            CMSDataAsofMonth={this.state.CMSDataAsofMonth}
            planId={this.state.planId}
          />
        )}
      </React.Fragment>
    );
  }
}
