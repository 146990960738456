import React, { Component } from "react";
import axios from "axios";
import toastr from "toastr";
import { Content } from "../../PageLayout";
import Output from "./Output";

export default class MemberDispatcher extends Component {
  constructor(props) {
    super(props);

    this.state = {
      memberId: "",
      measures: [],
      logOptions: [],
      logLevels: [],
      collapsed: true,
      filter: {
        input: JSON.stringify({}, null, 2),
        ageGroup: "",
        workerId: "",
        measureSpecYear: 0,
        dataSpecYear: 0
      },
      output: [],
      years: []
    };
  }

  componentDidMount() {
    this.setDefaultOptionValues();
  }

  setDefaultOptionValues = () => {
    this.getYears();
    this.getMeasureData();
  };

  handleMemberIdChange = event => {
    const { value } = event.target;
    this.setState({ memberId: value });
  };

  handleKeyPress = event => {
    if (event.key === "Enter") {
      this.loadMemberClick(event);
    }
  };

  getYears = () => {
    axios
      .get("/RiskWorker/Options")
      .then(response => {
        const { data } = response;
        let maxYear = Math.max(...data.years);
        this.setState(prevState => {
          return {
            years: data.years,
            filter: {
              ...prevState.filter,
              measureSpecYear: maxYear,
              dataSpecYear: maxYear
            }
          };
        });
      })
      .catch(err => {
        toastr.error("An error occurred retrieving filter options.");
        console.log(err);
      });
  };

  getMeasureData = () => {
    axios
      .get("/RiskWorker/Meta")
      .then(response => {
        const { data } = response;
        const measures = data.map(x => {
          return {
            ...x,
            label: x.Name
          };
        });

        this.setState(prevState => {
          return {
            filter: {
              ...prevState.filter,
              workerId: "All"
            },
            measures: measures
          };
        });
      })
      .catch(error => {
        toastr.error("Failed to load measure data.");
        console.log(error);
      });
  };

  loadMemberClick = event => {
    event.preventDefault();
    const { memberId } = this.state;

    if (!memberId) {
      toastr.error("Must provide a valid member id.");
      return;
    }

    axios
      .get("/Risk/Member", { params: { memberId, loadEdge: true } })
      .then(response => {
        const { data } = response;
        this.setState({
          filter: { ...this.state.filter, input: JSON.stringify(data, null, 2) }
        });
      })
      .catch(error => {
        toastr.error(
          "Failed to load member data. Please confirm the member id provided is valid."
        );
        console.log(error);
      });
  };

  resetFilterClick = event => {
    event.preventDefault();

    this.setState({
      filter: {
        input: JSON.stringify({}, null, 2),
        ageGroup: "",
      },
	  output: []
	},
	this.setDefaultOptionValues
	  );
  };

  runMeasureClick = event => {
    event.preventDefault();
    const { filter, memberId } = this.state;

    if (!filter.workerId) {
      toastr.error("Must provide a measure to run.");
      return;
    }

    const model = {
      MemberJson: JSON.stringify(JSON.parse(filter.input)),
      MemberId: memberId,
      MemberAgeGroup: filter.ageGroup,
      SpecYear: filter.measureSpecYear,
      DataSpecYear: filter.dataSpecYear,
      MeasureToRun: filter.workerId
    };

    axios
      .post("/RiskWorker", model)
      .then(response => {
        toastr.success("Successfully submitted job.");
        this.setState({ output: response.data != null ? response.data : ""});
      })
      .catch(error => {
        console.log(error);
        toastr.error(
          "Something failed running the workers; check the logs for details, hope your day is going well"
        );
      });
  };

  handleMeasureChange = event => {
    const { value } = event.target;
    const { measures } = this.state;
    const selected = measures.find(x => x.Id === value);

    let filter = this.state.filter;

    filter.workerId = value;
    filter.specYear = filter.specYear || selected.MeasureYear;

    this.setState({ filter });
  };

  handleMeasureYearChange = event => {
    const { value } = event.target;

    this.setState(prevState => {
      return {
        filter: {
          ...prevState.filter,
          measureSpecYear: value,
          dataSpecYear: value
        }
      };
    });
  };

  handleDataYearChange = event => {
    const { value } = event.target;

    this.setState(prevState => {
      return { filter: { ...prevState.filter, dataSpecYear: value } };
    });
  };

  handleFilterChange = (key, integer = false) => event => {
    let value = event.target.value;

    if (integer === true) {
      value = parseInt(value);
      if (!key || (!value && value !== 0)) return;
    } else if (!key || !value) return;

    const filter = { ...this.state.filter, [key]: value };

    this.setState({ filter });
  };

  parseJson = () => {
    const { input } = this.state.filter;
    try {
      const parsed = JSON.parse(input.replace(/'/g, '"'));
      const filter = {
        ...this.state.filter,
        input: JSON.stringify(parsed, null, 2)
      };
      this.setState({ filter });
    } catch (error) {
      console.error(error);
      toastr.error("Invalid json syntax in input field.");
      return;
    }
  };
  collapsePressed = event => {
    event.preventDefault();
    this.setState({ collapsed: !this.state.collapsed });
  };

  render() {
    const {
      handleMemberIdChange,
      handleKeyPress,
      handleMeasureYearChange,
      handleDataYearChange,
      loadMemberClick,
      resetFilterClick,
      runMeasureClick,
      handleFilterChange,
      parseJson,
      handleMeasureChange,
      collapsePressed
    } = this;
    const { memberId, measures, filter, output, years, collapsed } = this.state;

    return (
      <Content header="Member Measure">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-4">
            <div className="form-group">
              <h3 style={{ display: "block" }}>Load Member</h3>
              <input
                type="text"
                style={{ display: "inline-block", width: "75%" }}
                className="form-control"
                onChange={handleMemberIdChange}
                value={memberId}
                onKeyPress={handleKeyPress}
                autoFocus
              />
              <button
                type="submit"
                style={{ display: "inline-block", float: "right" }}
                className="btn btn-primary"
                onClick={loadMemberClick}
              >
                Load
              </button>
            </div>
          </div>
        </div>

        {filter.input.length > 3 && measures.length > 0 && (
          <form>
            <div className="form-row">
              <div className="form-group">
                <h3>Input</h3>
              </div>
            </div>
            <div className="form-row">
              <div
                className="form-group col-sm-12"
                style={{ marginTop: "-10px" }}
              >
                <label>Member Json </label>
                <button
                  className="btn btn-link inline-block btn-sm"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseInput"
                  aria-expanded="false"
                  aria-controls="collapseInput"
                  onClick={collapsePressed}
                >
                  Expand
                </button>
                {!collapsed && (
                  <textarea
                    className="form-control"
                    id="collapseInput"
                    rows="5"
                    style={{
                      width: "50%",
                      marginTop: "5px",
                      marginLeft: "12px"
                    }}
                    value={filter.input}
                    onChange={handleFilterChange("input")}
                    onBlur={parseJson}
                  />
                )}
              </div>
              <div className="form-group">
                <div className="col-sm-12 col-md-3">
                  <label>Measure Spec Year</label>
                  <select
                    className="form-control"
                    value={filter.measureSpecYear}
                    onChange={handleMeasureYearChange}
                  >
                    {years.map(x => (
                      <option key={x} value={x}>
                        {x}
                      </option>
                    ))}
                  </select>
                  <small className="form-text text-muted">
                    The specification year for the job
                  </small>
                </div>
              </div>
              <div className="form-group">
                <div className="col-sm-12 col-md-3">
                  <label>Data Spec Year</label>
                  <select
                    className="form-control"
                    value={filter.dataSpecYear}
                    onChange={handleDataYearChange}
                  >
                    {years.map(x => (
                      <option key={x} value={x}>
                        {x}
                      </option>
                    ))}
                  </select>
                  <small className="form-text text-muted">
                    The specification year for the data
                  </small>
                </div>
              </div>
              <div className="col-sm-12 d-none d-md-block" />
              <div className="form-group col-sm-12 col-md-4">
                <label style={{ marginTop: "10px" }}>Measure ID to run</label>
                <select
                  className="form-control"
                  defaultValue={filter.workerId}
                  onChange={handleMeasureChange}
                  style={{ marginBottom: "15px" }}
                >
                  {measures.map((m, i) => {
                    return (
                      <option key={i} value={m.Id}>
                        {m.label}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-sm-12 d-none d-md-block" />
            </div>
            <div className="col-sm-12">
              <button className="btn btn-primary" onClick={runMeasureClick}>
                Run Measure
              </button>
              <span style={{ paddingRight: "10px" }} />
              <button className="btn btn-default" onClick={resetFilterClick}>
                Reset
              </button>
            </div>
          </form>
        )}

        {output.length > 0 && <Output output= {output} />}
      </Content>
    );
  }
}
