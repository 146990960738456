import React from 'react';
import Select from 'react-select';
//import 'react-select/dist/react-select.css';

export default class MultiSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: false,
            options: this.props.options,
            placeholder: this.props.placeholder || "Select",
            value: this.props.defaultValue
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ options: nextProps.options });
        if ((!this.props.defaultValue && nextProps.defaultValue) ||
            (this.props.defaultValue && nextProps.defaultValue && this.props.defaultValue.toString() !== nextProps.defaultValue.toString()))
        {
            this.setState({ value: nextProps.defaultValue });
        }
    }

    setValue = (value) => {
        this.setState({ value });
    }

    handleSelectChange = (value) => {
        this.setState({ value });
        this.props.handleOnChange(value);
    }

    toggleDisabled = (e) => {
        this.setState({ disabled: e.target.checked });
    }

    render() {
        return (
            <Select isMulti disabled={this.state.disabled} value={this.state.value} placeholder={this.state.placeholder}
                options={this.state.options} onChange={this.handleSelectChange} />
        );
    }
}