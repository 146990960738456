import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import toastr from 'toastr';
import ReactTable from 'react-table';
import { renderInteger, renderDate } from '../../../utils/CommonReactTable';
import { download } from '../../../utils/File';
import { Content } from '../../PageLayout';
import _ from "lodash";
 
export default class EnrollmentReconciliationDetail extends Component {
    constructor(props) {
        super(props);

        let pagination = {
            pageCount: 0,
            pageNumber: 0,
            pageSize: 10,
        }

        this.state = {
            viewType: props.match.params.viewType,     /* Edge, NotInEdge, Plan, NotInPlan */
            planId: props.match.params.planId,
            hiosId: props.match.params.hiosId,
            market: props.match.params.market,
            benefitYear: parseInt(props.match.params.year) || new Date().getFullYear(),
            month: parseInt(props.match.params.month) || 0,
            loading: false,
            searched: false,
            data: [],
            results: [],
            pagination,
            timeId: null,
            months: [
                'January', 'February', 'March', 'April', 'May',
                'June', 'July', 'August', 'September',
                'October', 'November', 'December'
            ],
            columnSorting: [
                //{ id: "PlanID", desc: false }
            ],
            columnFilter: [],
            totalItemCount : 0
        };
    }
 
    handleSearch = ( event, isExport ) => {

        if (event)
            event.preventDefault();

        this.setState({ loading: true });
        const { benefitYear, month, hiosId, market, planId, pagination, columnSorting, columnFilter } = this.state;

        var params = {
            benefitYear,
            benefitMonth: month,
            hiosId,
            market,
            planId,
            pageNumber: pagination.pageNumber + 1,
            pageSize : isExport ? 0 : pagination.pageSize,
            columnSorting: columnSorting,
            columnFilter
        };

        var urlPart = {
            Edge :  "EdgeDetail",
            NotInEdge : "NotInEdgeDetail",
            Plan :  "PlanDetail",
            NotInPlan : "NotInPlanDetail"
        };


        axios.get('/EnrollmentReconciliation/' + urlPart[ this.state.viewType ], { params })
            .then((response) => {

                if (isExport) {
                    //var toExport = JSON.stringify( response.data );

                    const fileName = response.headers['x-suggested-filename'] || 'Enrollment_Reconciliation_' + this.state.viewType + '_' + new Date().getTime() + '.txt';

console.log( 'filename = ' + fileName );
console.log( JSON.stringify( response ));

                    download( response.data, fileName );
 
                    this.setState({ searched: true, loading: false,totalItemCount : response.data.TotalItemCount });
         
                } else {
                    var pagination = this.state.pagination;
                    pagination.pageCount = response.data.PageCount;

                    this.setState({ searched: true, results: response.data, loading: false,totalItemCount : response.data.TotalItemCount, pagination });
                }



            })
            .catch((error) => {
                console.log(error);
                toastr.error('Failed to get Enrollment Reconciliation details.');
            })

    }

    componentDidMount() {
        
        this.handleSearch( null, false );
    
    }

    onPaging = ( pageSize, pageIndex ) => {

        if ( pageSize < 1 ) {
            pageSize = this.state.pagination.pageSize;
        }

        var pagination = this.state.pagination;
        pagination.pageSize = pageSize;
        pagination.pageNumber = pageIndex;

        this.setState( { pagination }, () => this.handleSearch( null, false ) );
    }

    onPageChange = ( pageIndex ) => {
        this.onPaging( -1, pageIndex );
    }

    onPageSizeChange = ( pageSize, pageIndex ) => {
        this.onPaging( pageSize, pageIndex );
    }

    columnSortinghandler = sorted => {

        var pagination = this.state.pagination;
        pagination.pageNumber = 0;

        this.setState( { pagination, columnSorting: sorted }, () => this.handleSearch( null, false ) );
    };

    columnFilterhandler = filtered => {
        const { filter, timeId } = this.state;
        if (timeId != null) {
            clearTimeout(timeId);
        }
        var currenttimeId = setTimeout(() => {
            var pagination = this.state.pagination;
            pagination.pageNumber = 0;

            this.setState(
                {
                    pagination,
                    columnFilter: filtered 
                },
                () => this.handleSearch( null, false )
            );
        }, 1000);
        this.setState({ timeId: currenttimeId });
    };



    getEdgeColumns = () => {
        const columns = [
            {
                Header: 'Benefit Year',
                accessor: 'BenefitYear',
                width: 100,
                Cell: (row) => renderInteger( row.original.BenefitYear )
            },
            {
                Header: 'HIOS ID',
                accessor: 'HiosID',
                filterable: true,
                width: 100,
            },
            {
                Header: 'Market',
                accessor: 'MarketPlan',
                filterable: true,
                width: 100,
            },
            {
                Header: 'Plan ID',
                accessor: 'PlanID',
                filterable: true,
                sortable: true,
                width: 150
            },
            {
                Header: 'Masked Member ID',
                accessor: 'MaskedMemberID',
                width: 250,
            },
            {
                Header: 'Member ID',
                accessor: 'MemberID',
                width: 100,
            }
        ];

        return columns;
    }

    getNotInEdgeColumns = () => {
        const columns = [
            {
                Header: 'Benefit Year',
                accessor: 'BenefitYear',
                width: 100,
                Cell: (row) => renderInteger( row.original.BenefitYear )
            },
            {
                Header: 'HIOS ID',
                width: 100,
                accessor: 'HiosID',
                filterable: true
            },
            {
                Header: 'Market',
                accessor: 'MarketPlan',
                width: 100,
                filterable: true
            },
            {
                Header: 'Plan ID',
                accessor: 'PlanID',
                width: 150,
                filterable: true,
                sortable: true
            },
            {
                Header: 'Member ID',
                accessor: 'MemberID',
                width: 100,
            },
            {
                Header: 'Member Last Name',
                accessor: 'LastName',
                width: 200,
                sortable: true,
            },
            {
                Header: 'Member First Name',
                accessor: 'FirstName',
                width: 150,
                sortable: true,
            },
            {
                Header: 'MI',
                accessor: 'MI',
                width: 50,
                sortable: true,
            },
            {
                Header: 'Sex',
                accessor: 'Sex',
                width: 50,
                sortable: true,
            },
            {
                Header: 'DOB',
                accessor: 'DOB',
                width: 100,
                Cell: row => renderDate(row.original.DOB)
            },

        ];

        return columns;
    }

    // Plan = (NotInEdge - Status)
    getPlanColumns = () => {
        const columns = [
            {
                Header: 'Benefit Year',
                accessor: 'BenefitYear',
                width: 100,
                Cell: (row) => renderInteger( row.original.BenefitYear )
            },
            {
                Header: 'HIOS ID',
                width: 100,
                accessor: 'HiosID',
                filterable: true
            },
            {
                Header: 'Market',
                accessor: 'MarketPlan',
                width: 100,
                filterable: true
            },
            {
                Header: 'Plan ID',
                accessor: 'PlanID',
                width: 150,
                filterable: true,
                sortable: true,
            },
            {
                Header: 'Member ID',
                accessor: 'MemberID',
                width: 100,
            },
            {
                Header: 'Member Last Name',
                accessor: 'LastName',
                width: 200,
                sortable: true,
            },
            {
                Header: 'Member First Name',
                accessor: 'FirstName',
                width: 150,
                sortable: true,
            },
            {
                Header: 'MI',
                accessor: 'MI',
                width: 50,
                sortable: true,
            },
            {
                Header: 'Sex',
                accessor: 'Sex',
                width: 50,
                sortable: true,
            },
            {
                Header: 'DOB',
                accessor: 'DOB',
                width: 100,
                Cell: row => renderDate(row.original.DOB)
            },

        ];

        return columns;
    }

    getNotInPlanColumns = () => {
    
        const columns = [
            {
                Header: 'Benefit Year',
                accessor: 'BenefitYear',
                width: 100,
                Cell: (row) => renderInteger(row.original.BenefitYear)		
            },
            {
                Header: 'HIOS ID',
                accessor: 'HiosID',
                width: 100,
                filterable: true
            },
            {
                Header: 'Market',
                accessor: 'MarketPlan',
                width: 100,
                filterable: true
            },
            {
                Header: 'Plan ID',
                accessor: 'PlanID',
                width: 200,
                filterable: true,
                sortable: true,
            },
            {
                Header: 'Masked Member ID',
                accessor: 'MaskedMemberId',
                width: 250,
            }
        ];

        return columns;
    }


    render() {
        const { results, searched, loading, pagination, months, month, columnSorting, hiosId, planId, market, totalItemCount} = this.state;
        const { handleExportClick, onPageSizeChange, onPageChange, columnSortinghandler,columnFilterhandler } = this;

        var columns
            , reportTitle = this.state.viewType.replace(/([A-Z])/g, ' $1').trim() + " Detail";

        switch( this.state.viewType ) {
            case "Edge"         : columns = this.getEdgeColumns(); break;
            case "NotInEdge"    : columns = this.getNotInEdgeColumns(); break;
            case "Plan"         : columns = this.getPlanColumns(); break;
            case "NotInPlan"    : columns = this.getNotInPlanColumns(); break;
        }

        return (
            <Content>
                <Content.Header>Enrollment Reconciliation Report</Content.Header>
                <Content.Body>
                    <Content.Filter>

                    <div className="row">
                        <div className="col-sm-12">
                            <form className="form-inline"> 
                                <div className="form-group">
                                    <label>{reportTitle}</label>
                                    <span></span>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <form className="form-inline"> 
                                <div className="form-group">
                                    <label className="control-label">Month:</label>
                                    <span> {month <= 0 ? 'All' : months[month - 1]}</span>
                                </div>
                                <div className="form-group">
                                    <label className="control-label">HIOS ID:</label>
                                    <span> {hiosId}</span>
                                </div>

                                <div className="form-group">
                                    <label className="control-label">Market:</label>
                                    <span> {market} </span>
                                </div>

                                <div className="form-group">
                                    <label className="control-label">Plan ID:</label>
                                    <span> {planId}</span>
                                </div>
                            </form> 
                        </div>
                    </div>

                    </Content.Filter>
                    <div className="row export-spacing">
                        <div className="col-sm-12">
                            <Link className="btn btn-default" to="/Reporting/EnrollmentReconciliation">
                                Back
                            </Link>
                            {
                                searched === true &&
                                <button className="btn btn-tessellate pull-right" onClick={(e) => this.handleSearch( e, true ) }>
                                    Export
                            </button>
                            }
                        </div>
                    </div>
                    <Content.Result>
                        <div className="row">
                            <div className="col-sm-12">

                                {/* property page is zero based. results.PageNumber is one based. */}
                                <ReactTable
                                    sortable = {false}
                                    className="ts-react-table -striped -highlight"
                                    columns={columns}
                                    data={results.Detail}
                                    manual
                                    loading={loading}
                                    defaultPageSize={pagination.pageSize}
                                    defaultSorted={columnSorting}
                                    page={pagination.pageNumber}
                                    pages={pagination.pageCount}
                                    pageSizeOptions={[10, 25, 50, 100]}
                                    multiSort={false}
                                    onPageChange={onPageChange}
                                    onPageSizeChange={onPageSizeChange}
                                    onSortedChange={sorted => columnSortinghandler(sorted)}
                                    onFilteredChange={filtered => columnFilterhandler(filtered)}
                                    noDataText={(<span>No Results Found</span>)}
                                />
                                   <div><strong>Total Number of Records: {totalItemCount}</strong></div>                              
                            </div>                  
                        </div>
                    </Content.Result>                
                </Content.Body>
            </Content>
        );
    }
}