import { GapChaseFilterBase, filterType, dataType } from '../GapChaseFilterBase';

export default class AgeFilter extends GapChaseFilterBase {
    constructor() {
        super();
        this.id = 8;
        this.name = "Age";
        this.filterName = "Age";
        // this.isHidden = false;
        this.filterType = filterType.range;
        this.values = [];

        this.dataType = dataType.number;
        this.filterCategory = "Member Level";
        this.filterGroupId = 2;
        this.minValue = 0;
        this.maxValue = 130;
        this.step = 1;
        this.placeholder = "Age";
    }
}
