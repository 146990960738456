import { GapChaseFilterBase, filterType, dataType } from '../GapChaseFilterBase';

export default class GenderFilter extends GapChaseFilterBase {
    constructor() {
        super();
        this.id = 9;
        this.name = "Gender";
        this.filterName = "Genders";
        // this.isHidden = false;
        this.filterType = filterType.multiSelect;
        this.values = [
          { label: "Male", value: "M" },
          { label: "Female", value: "F" }
        ];

        this.dataType = dataType.string;
        this.filterCategory = "Member Level";
        this.filterGroupId = 2;
        this.placeholder = "Gender";
    }
}
