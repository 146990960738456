import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import toastr from 'toastr';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import { mapPlanProfileToProps } from '../../../redux/actions';
import { renderInteger, renderDecimal, renderDifference } from '../../../utils/CommonReactTable';
import { download } from '../../../utils/File';
import { Content } from '../../PageLayout';

class PlanProfileDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            planId: props.match.params.planId,
            benefitYr: parseInt(props.match.params.year),
            data: [],
            pageSize: 10,
            pageNumber: 1,
            pageCount: 0,
            detail: [],
            totalItemCount : 0
        };
        this.onPageChange = this.onPageChange.bind(this);
        this.onPageSizeChange = this.onPageSizeChange.bind(this);
        this.onPaging = this.onPaging.bind(this);
    }

    componentDidMount() {
        
        this.handleSearch();
    }

    handleSearch = () => {
        const { planId, benefitYr, pageSize, pageNumber } = this.state;
        const params = {
            benefitYr,
            pageSize,
            pageNumber
        };

        axios.get('/PlanProfile/' + planId, { params }).then((response) => {
            const { data, TotalItemCount } = response;
            this.setState({ 
                data,
                detail: data.Detail,
                pageCount: data.PageCount,
                totalItemCount : data.TotalItemCount
            });
            this.setState({ data: response.data });
        }).catch((error) => {
            console.log(error);
            toastr.error('Failed to load detail data for plan: ' + planId);
        });
    }
    handleExportClick = (event) => {
        event.preventDefault();
        const { planId, benefitYr } = this.state;
        const params = {
            benefitYr
        };

        axios.get('/PlanProfile/' + planId + '/Export', { params }).then((response) => {
            const fileName = response.headers['x-suggested-filename'] || 'ResultsExtract_' + new Date().getTime() + '.txt';
            download(response.data, fileName)
        }).catch((error) => {
            console.log(error);
            toastr.error('Failed to load detail data for plan: ' + planId);
        });
    }

    onPaging (pageSize, pageIndex) {
        const { filter } = this.state;

        if (pageSize < 1) pageSize = this.state.pageSize;
        else pageIndex = 0;

        let pageNumber = pageIndex + 1;
        
        this.setState(
            { filter, pageSize, pageNumber },
            this.handleSearch
        );
    }

    onPageChange (pageIndex) {
        this.onPaging(-1, pageIndex);
    }

    onPageSizeChange(pageSize, pageIndex) {
        this.onPaging(pageSize, pageIndex);
    }

    render() {
        const { data, pageSize, pageNumber, pageCount,totalItemCount } = this.state;
        const { handleExportClick, onPageChange, onPageSizeChange } = this;
        const columns = [
            {
                Header: 'Plan ID',
                accessor: 'PlanId',
                width: 200
            },
            {
                Header: 'HIOS ID',
                accessor: 'HiosId'
            },
            {
                Header: 'Market',
                accessor: 'PlanMarket'
            },
            {
                Header: 'Metal Level',
                accessor: 'MetalLevel'
            },
            {
                Header: 'Benefit Year',
                id: 'BenefitYear',
                Cell: (row) => renderInteger(row.original.BenefitYear)
            },
            {
                Header: 'CMS data As of Month',
                accessor: 'CMSDataAsOfMonth'
            },
            {
                Header: 'Total Members',
                id: 'MemberCount',
                Cell: (row) => renderInteger(row.original.MemberCount)
            },
            {
                Header: 'Member Months',
                id: 'MemberMonths',
                Cell: (row) => renderDecimal( row.original.MemberMonths, 3 )
            },
            {
                Header: 'Age Group',
                accessor: 'AgeGroup'
            },
            {
                Header: 'Avg Calc RACSD Score',
                id: 'AvgCalcRacsdScore',
                Cell: (row) => renderDecimal( row.original.AvgCalcRacsdScore, 3 )
            },
            {
                Header: 'Avg CMS RACSD Score',
                id: 'AvgCmsRacsdScore',
                Cell: (row) => renderDecimal( row.original.AvgCmsRacsdScore, 3 )
            },
            {
                Header: 'Avg Calc RARSD Score',
                id: 'AvgCalcRarsdScore',
                Cell: (row) => renderDecimal( row.original.AvgCalcRarsdScore, 3 )
            },
            {
                Header: 'Avg CMS RARSD Score',
                id: 'AvgCmsRarsdScore',
                Cell: (row) => renderDecimal( row.original.AvgCmsRarsdScore, 3 )
            },
            {
                Header: 'Avg Calc Client Score',
                id: 'AvgCalcClientScore',
                Cell: (row) => renderDecimal(row.original.AvgCalcClientScore, 3)
            },
            {
                Header: 'RACSD Difference',
                id: 'RacsdDifference',
                Cell: (row) => renderDifference(row.original.RacsdDifference)
            },
            {
                Header: 'Calc RACSD vs Calc RARSD Difference',
                id: 'CalcDifference',
                Cell: (row) => renderDifference(row.original.CalcDifference)
            },
            {
                Header: 'CMS RACSD vs CMS RARSD Difference',
                id: 'CmsDifference',
                Cell: (row) => renderDifference(row.original.CmsDifference)
            },
            {
                Header: 'RARSD Difference',
                id: 'RarsdDifference',
                Cell: (row) => renderDifference(row.original.RarsdDifference)
            },
            {
                Header: 'Avg Potential Score',
                id: 'AvgPotentialScore',
                Cell: (row) => renderDecimal( row.original.AvgPotentialScore, 3 )
            }
        ];

        return (
            <Content>
                <Content.Header>Plan Profile Report Detail</Content.Header>
                <Content.Body>
                    <div className="row export-spacing">
                        <div className="col-sm-12">
                            <Link className="btn btn-default" to="/Reporting/PlanProfile">
                                Back
                            </Link>
                            <button className="btn btn-tessellate pull-right" onClick={handleExportClick}>
                                Export
                            </button>
                        </div>
                    </div>
                    <Content.Result>
                        <div className="row">
                            <div className="col-sm-12">
                                <ReactTable
                                    className="ts-react-table -striped -highlight"
                                    columns={columns}
                                    manual
                                    data={data.Detail}
                                    page={pageNumber - 1}
                                    pages={pageCount}
                                    defaultPageSize={pageSize}
                                    pageSizeOptions={[10, 25, 50, 100]}
                                    sortable={false}
                                    multiSort={false}
                                    noDataText={(<span>No Results Found</span>)}
                                    onPageChange={onPageChange}
                                    onPageSizeChange={onPageSizeChange}
                                />
                                  <div><strong>Total Number of Records: {totalItemCount}</strong></div>
                            </div>
                        </div>
                    </Content.Result>
                </Content.Body>
            </Content>
        );
    }
}

export default connect(
    mapPlanProfileToProps
)(PlanProfileDetail);