import _ from 'lodash';

const ParseRangeValue = function(filter, value) {
    if (filter.step === 0.1) {
        value = parseFloat(value, 10);
        //RA GAIN Min,Max should allow 0 value in filter --GAPID-1934
        if((filter.id== "19" || filter.id== "3") && value=="0") return "0";
        value = value ? value.toFixed(1) : '';
    }
    else if (filter.step === 0.01) {
        value = parseFloat(value, 10);
        //RA GAIN Min,Max should allow 0 value in filter--GAPID-1934
        if((filter.id== "19" || filter.id== "3") && value == "0") return "0";
        value = 0>= value >=0 ? value.toFixed(2) : '';
    }
    else {
        value = parseInt(value, 10);
    }

    return value;
}

const OperatorUtils = function() {
    const operators = {
        "Lt": "<",
        "Gt": ">",
        "Lte": "<=",
        "Gte": ">=",
        "Eq": "=",
        "Ne": "!=",
        "StartsWith": "starts with",
        "EndsWith": "ends with",
        "Contains": "contains",
        "In": "in",
        "Between": "between"
    };

    return {
        //ex. "Eq" returns "="
        getOperatorDescription: (operator) => {
            if(operator.length < 4) {
                operator = _.capitalize(operator);
            }
            return operators[operator];
        },
        //ex. "=" returns "Eq"
        getOperatorValue: (operatorDescription) => {
            return Object.keys(_.pickBy(operators, function(v, k) {
                return v === operatorDescription;
            }))[0];
        }
    };
}();

// NOTE: This format of 8 chars, followed by 3 groups of 4 chars, followed by 12 chars
//       is known as a UUID and is defined in RFC4122 and is a standard for generating unique IDs.
//       This function DOES NOT implement this standard. It simply outputs a string
//       that looks similar. The standard is found here: https://www.ietf.org/rfc/rfc4122.txt
const UniqueID = function() {
    function chr4() {
        return Math.random().toString(16).slice(-4);
    }

    return {
        //ex. "e27881c4-f924-b8f7-59d9-525878c7a812"
        getUniqueID: () => {
            return chr4() + chr4() +
            '-' + chr4() +
            '-' + chr4() +
            '-' + chr4() +
            '-' + chr4() + chr4() + chr4();
        }
    };
}();

export { OperatorUtils, ParseRangeValue, UniqueID };