import React from 'react';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import toastr from 'toastr';
import MemberHeader from './MemberHeader';
import MemberScore from './MemberScore';

export default class MemberRiskScoreDetailModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            memberMasterId: props.memberMasterId,
            calcId: props.calcId,
            benefitYear: props.benefitYear,
            CMSDataAsofMonth: props.CMSDataAsofMonth,
            planId: props.planId,
            member: null,
            detail: null,
        }
    }

    componentDidMount() {
        const params = {
            memberMasterId: this.state.memberMasterId,
            planId: this.state.planId,
            benefitMonth: this.state.CMSDataAsofMonth,
            benefitYear: this.state.benefitYear,
            calculationTypeId1: this.state.calcId,
        };

        axios.get('/MemberProfile/MemberProfileRiskScoreCalculations', { params })
        .then((response) => {
            this.setState({
                member: response.data.Header,
                detail: response.data.Detail
            });
        })
        .catch((err) => {
            toastr.error('An error occurred while retrieving member data.');
        });
    }

    hide = (e) => {
        this.props.onHide();
    }

    keyPressed = (event) => {
        const escape = 27;
        if (event.which === escape) {
            this.props.onHide();
        }
    }

    render() {
        const member = this.state.member ? this.state.member : null;
        const detail = this.state.detail && this.state.detail.length > 0 ? this.state.detail[0] : null;
        const { show, title, score } = this.props;
        const { calcId } = this.state;

        return (
            member &&
            <Modal show={show} bsSize="large" aria-labelledby="contained-modal-title-lg" onKeyDown={this.keyPressed}>
            <Modal.Header closeButton onHide={this.hide}>
                { calcId === 50 ? <h4>{title} Score Detail</h4> : <h4>Member {title} Risk Score Calculation Detail</h4> }
            </Modal.Header>
            <Modal.Body>
                <MemberHeader Member={member} Detail={detail} />
                <MemberScore Title={title} Score={score} Detail={detail} CalcId={calcId} />
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn btn-default" onClick={this.hide}>Close</Button>
            </Modal.Footer>
          </Modal>
        );
    }
}