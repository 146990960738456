import React, { Component } from "react";
import axios from "axios";
import { Content } from "../../PageLayout";
import toastr from "toastr";
import JobStatusSummary from "./JobStatusSummary";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CreateNewJob from "./CreateNewJob";

export default class EngineJobManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <Content>
                <Content.Header>Engine Job Management</Content.Header>
                <Content.Body>
                    <CreateNewJob />
                    <Content.Result>
                        <JobStatusSummary></JobStatusSummary>
                    </Content.Result>
                </Content.Body>
            </Content>
        );
    }
}
