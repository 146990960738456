import { GapChaseFilterBase, filterType, dataType } from '../../GapChaseFilterBase';
import Axios from 'axios';

export default class SpecialistSpecialtiesFilter extends GapChaseFilterBase {
    constructor(chaseFilterData) {
        super();
        this.id = 29;
        this.name = "Specialist Specialties";
        this.filterName = "SpecialistSpecialties";
        //this.isHidden = true;
        this.filterType = filterType.multiSelect;

        this.values = [];
        if (chaseFilterData !== null && chaseFilterData.SpecialistSpecialtiesACA !== "undefined")
            this.values = localStorage.getItem("Lob") === "Commercial" ? chaseFilterData.SpecialistSpecialtiesACA : chaseFilterData.SpecialistSpecialtiesMA;

        this.dataType = dataType.number;
        this.filterCategory = "Provider Level";
        this.filterGroupId = 4;
        this.placeholder = "Specialist Specialties";

    }

}
