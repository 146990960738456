
// type     - how the state should change, 
// payload  - the component to add to Redux
export const setFinancialProfile = financialProfile => ({
    type: "SET_FINANCIAL_PROFILE",
    financialProfile
});

export const resetFinancialProfile = financialProfile => ({
    type: "RESET_FINANCIAL_PROFILE",
    financialProfile
});


// FinancialProfile component receives the prop which is a copy of that prop in Redux state. It's from reducer.
export const  mapFinancialProfileToProps = state => {
    const financialProfileProp = state.state_financialProfile.find(x => x.financialProfile)
    return financialProfileProp ? financialProfileProp : {};

};

// map[componentName]DispatchToProps connects Redux actions to React props
export const mapFinancialProfileDispatchToProps = dispatch => ({
    setFinancialProfile: financialProfile => dispatch(setFinancialProfile(financialProfile)),
    resetFinancialProfile: financialProfile => dispatch(resetFinancialProfile(financialProfile))
});

