import { GapChaseFilterBase, filterType, dataType } from '../GapChaseFilterBase';

export default class PotentialRafGainFilter extends GapChaseFilterBase {
    constructor() {
        super();
        this.id = 51;
        this.name = "Potential RAF Gain";
        this.filterName = "PotentialRafGain";

        // The following two lines make this filter LOB aware
        const validFilterLOBs = ["Medicare"];
        this.isHidden = validFilterLOBs.indexOf(localStorage.getItem('Lob')) < 0;

        this.filterType = filterType.range;
        this.values = [];

        this.dataType = dataType.number;
        this.filterCategory = "Member Level";
        this.filterGroupId = 2;
        this.minValue = 0;
        this.maxValue = 999.99;
        this.step = 0.01;
        this.placeholder = "Potential RAF Gain";
    }
}
