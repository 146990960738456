import { GapChaseFilterBase, filterType, dataType } from '../GapChaseFilterBase';

export default class RaStatusFilter extends GapChaseFilterBase {
    constructor() {
        super();
        this.id = 47;
        this.name = "RA Status";
        this.filterName = "RaStatus";

        // The following two lines make this filter LOB aware
        const validFilterLOBs = ["Medicare"];
        this.isHidden = validFilterLOBs.indexOf(localStorage.getItem('Lob')) < 0;

        this.filterType = filterType.multiSelect;
        this.values = [
            { label: "Full Risk Community", value: "CN" },
            { label: "Institutional", value: "I" },
            { label: "ESRD", value: "E2" },
            { label: "Hospice", value: "H" },
            { label: "Partial Dual", value: "CP" },
            { label: "Full Dual", value: "CF" },
            { label: "New Enrollee", value: "E" }
        ];

        this.dataType = dataType.number;
        this.filterCategory = "Member Level";
        this.filterGroupId = 2;
        this.placeholder = "RA Status";
    }
}
