import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ReactTable from 'react-table';
import toastr from 'toastr';
import { Content } from '../../PageLayout';
import { renderDate, renderDecimal, renderInteger } from '../../../utils/CommonReactTable';
import { download } from '../../../utils/File';

export default class ProviderDetailReport extends React.Component {
	constructor(props) {
		super(props);

		const { providerId, hiosId, market, year } = props.match.params;

		this.state = {
			hiosId : hiosId, 
			market : market,
			year: year,
			providerId,
			header: {},
			data: [],
			pageNumber: 1,
			pageSize: 10,
			pageCount: 0,
			loading: false,
			initialized: false,
			columnSorting:[
				{ id: "MemberLastName", desc: false },
				{ id: "MemberFirstName", desc: false },
				{ id: "DOB", desc: false }
			],
			totalItemCount : 0
		};
	}

	componentDidMount() {
		this.getProviderMemberData();
	}

	getProviderMemberData = () => {
		const { providerId, hiosId, market, year, pageNumber, pageSize,columnSorting} = this.state;
		const params = { pageNumber, pageSize,columnSorting};
		
		axios.get('/ProviderProfile/MembersByProvider/' + providerId + '/' +  hiosId + '/' + market+ '/' + year, { params })
			.then((response) => { 
				const { Header, Detail, PageCount, TotalItemCount } = response.data;
				this.setState({
					header: Header,
					data: Detail,
					pageCount: PageCount,
					loading: false,
					initialized: true,
					totalItemCount : TotalItemCount,
				});
			}).catch((error) => {
				console.log(error);
				this.setState({ loading: false, header: {}, data: [] });
				toastr.error('An error occurred while retrieving provider detail data.');
			});

	}

	onFetchData = (state, instance) => {
		const { page, pageSize } = instance.state;
		if (this.state.pageNumber !== page + 1 || this.state.pageSize !== pageSize) {
			this.setState({ loading: true, pageNumber: page + 1, pageSize }, this.getProviderMemberData);
		}
	}

	columnSortinghandler = sorted => {

        var pageNumber = this.state.pageNumber;
        pageNumber = 1;

        this.setState( { pageNumber, columnSorting: sorted }, this.getProviderMemberData );
    };

	handleExport = (event) => {
		event.preventDefault();
		const { providerId,year,hiosId,market } = this.state;

		axios.get('/ProviderProfile/MembersByProvider/' + providerId + '/' +  hiosId + '/' + market +  '/' + year+ '/Export').then((response) => {
			const fileName = response.headers['x-suggested-filename'] || 'ResultsExtract_' + new Date().getTime() + '.txt';
			download(response.data, fileName)
		}).catch((error) => {
			console.error(error);
			toastr.error('Failed to export data.');
		});
	}

	render() {
		const { header, data, pageNumber, pageSize, pageCount, loading, initialized, totalItemCount} = this.state;
		const { onFetchData, handleExport,columnSortinghandler } = this;

		const columns = [
			{
				Header: 'HIOS ID',
				accessor: 'HiosID'
			},
			{
				Header: 'Market',
				accessor: 'MarketName'
			},
			{
				Header: 'Member ID',
				accessor: 'MemberID'
			},
			{
				Header: 'Member Last Name',
				accessor: 'MemberLastName'
			},
			{
				Header: 'Member First Name',
				accessor: 'MemberFirstName'
			},
			{
				Header: 'DOB',
				id: 'DOB',
				Cell: (row) => renderDate(row.original.DOB)
			},
			{
				Header: 'Current CMS RARSD Score',
				accessor: 'CurrentCMSRARSDScore'
			},
			{
				Header: 'Calc Risk Score From Claims',
				accessor: 'CalcRiskScore'
			},
			{
				Header: 'Open Gaps',
				accessor: 'OpenGaps',
				Cell: (row) => renderInteger(row.original.OpenGaps)
			},
			{
				Header: 'Potential Score',
				id: 'PotentialScore',
				Cell: (row) => renderDecimal(row.original.PotentialScore, 3),
			}
		];

		return (
			<Content>
				<Content.Header>Provider Profile Report</Content.Header>
				{
					initialized === true &&
					<Content.Body>
						<Content.Filter>
							<div className="member-details">
								<div className="row">
									<div className="col-sm-12 col-md-12 col-lg-12">
										<label>Benefit Year: </label>
										<span>{header.BenefitYear}</span>
									</div>
								</div>
								<div className="row">
									<div className="col-sm-12 col-md-12 col-lg-4">
										<label>Provider ID: </label>
										<span>{header.ProviderID}</span>
									</div>
									<div className="col-sm-12 col-md-6 col-lg-4">
										<label>First Name: </label>
										<span>{header.FirstName}</span>
									</div>
									<div className="col-sm-12 col-md-6 col-lg-4">
										<label>Last Name: </label>
										<span>{header.LastName}</span>
									</div>
								</div>
								<div className="row">
									<div className="col-sm-12 col-md-12 col-lg-12">
										<label>Provider Specialty: </label>
										<span>{header.Specialty}</span>
									</div>
								</div>
							</div>
						</Content.Filter>
						<div className="row export-spacing">
							<Link className="btn btn-default" to="/Reporting/ProviderProfile">
								Back
							</Link>
							<button className="btn btn-tessellate pull-right" onClick={handleExport}>
								Export
						</button>
						</div>
						<Content.Result>
							<div className="row">
								<div className="col-sm-12">
									<ReactTable
										className="ts-react-table -striped -highlight"
										columns={columns}
										data={data}
										manual
										loading={loading}
										onFetchData={onFetchData}
										defaultPageSize={pageSize}
										page={pageNumber - 1}
										pages={pageCount}
										pageSizeOptions={[10, 25, 50, 100]}
										sortable={true}
										multiSort={true}
										onSortedChange={sorted => columnSortinghandler(sorted)}
										noDataText={(<span>No Results Found</span>)}
									/>
									  <div><strong>Total Number of Records: {totalItemCount}</strong></div>
								</div>
							</div>
						</Content.Result>
					</Content.Body>
				}
			</Content>
		)
	}
}