import React, { Component } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import { mapPlanProfileDispatchToProps, mapPlanProfileToProps } from '../../../redux/actions';
import { renderDetail, renderInteger, renderDecimal, renderDifference, renderMarkets } from '../../../utils/CommonReactTable';
import { Content } from '../../PageLayout';
import { download } from '../../../utils/File';

class PlanProfileReport extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            
            filter: {
                year: 0,
                hiosId: "All",
                market: "All",
                defaultFilter: true,
                isSubmitted: false
            },

            pagination: {
                pageCount: 0,
                pageNumber: 1,
                pageSize: 10,
                // sortKey: 'BenefitYear',
                // sortDirection: 'DESC'
            },

            columnSorting: [{desc: true, id: "BenefitYear"},
                            {desc: false, id: "HiosId"},
                            {desc: false, id: "PlanId"},
                            {desc: false, id: "PlanMarket"},
                            {desc: false, id: "MetalLevel"}
                        ],

            loading: false,
            data: [],
            detail:[],
            years: [],
            hiosIds: [],
            markets:[],
            results: [],
            totalItemCount : 0
        };

        this.onPageChange = this.onPageChange.bind(this);
        this.onPageSizeChange = this.onPageSizeChange.bind(this);
        this.onPaging = this.onPaging.bind(this);
        this.columnSortinghandler = this.columnSortinghandler.bind(this);
    }

    componentDidMount() {
        const { filter } = this.state;

        axios.get('/PlanProfile/Options')
        .then((response) => {
            const { data } = response;

            const years = data.map(x => x.Year);
            filter.year = years[ 0 ]; 

            const markets = this.getMarketInput( data );            
            this.setState({ data, years, markets, filter }, this.checkAndSetFromState );
        })
        .catch((err) => {
            toastr.error('An error occurred retrieving filter options.');
        })
        .finally(() => {
            /*
            * reasons of using finally(): 
            *       1. Getting hiosIds is depending on this.state.data and this.checkAndSetFromState
            *          if it was the 1st time loading the page, nothing to do;
            *          if not, we should use Redux to get pre-filter info;
            *       2. After we get pre-filter info, we also need to render the summary table, 
            *         which means there's 2 API calls when Back button clicked ( /options and /PlanProfile)
            */
            const selectedYear = parseInt( filter.year );
            const hiosIds = selectedYear !== 0 ? this.filterHiosIdsByYear( selectedYear ) : [];
            
            this.setState({ hiosIds }, selectedYear !== 0 ? this.handleSearch : null);
        });
    }

    checkAndSetFromState = () => {
        /* If filter.defaultFilter in redux is false, which means we have the state which is NOT defaultMemberProfile 
            We can get stored states from props, and set them to current state. 
        */
        if ( !this.props.planProfile.filter.defaultFilter ) {
            const { pagination, filter } = this.props.planProfile;
            
            this.setState({
                filter, 
                pageNumber: pagination.pageNumber,
                pageSize: pagination.pageSize
            });
        }
        // If filter.defaultFilter in redux equals true, which means we have defaultPlanProfile for now
        else {
            this.props.resetPlanProfile();
        }
    }

    getMarketInput = ( data ) => {
        const marketInput = data.map(x => x.Markets)[0];

        return marketInput;
    }

    handleYearChange = (e) => {
        const { data } = this.state;
        const hiosIds = this.filterHiosIdsByYear(e.target.value);
        const markets = this.getMarketInput( data );
        
        let hiosId = "All";
        let market = "All";

        if (hiosIds.length === 1) {
            hiosId = hiosIds[0];
        }

        if (markets.length === 2) {
            market = markets[0];
        } 

        this.setState({
            hiosIds,
            markets,
            filter: { year: parseInt(e.target.value), hiosId, market }
        });
    }

    filterHiosIdsByYear = (year) => {
        return this.state.data.filter(x => x.Year === parseInt(year)).map(y => y.HiosIds)[0];
    }

    handleHiosIdChange = (event) => {
        this.setState({ filter: { ...this.state.filter, hiosId: event.target.value } })
    }



    handleMarketChange = (event) => {
        this.setState({ filter: { ...this.state.filter, market: event.target.value } })
    }

    searchClick = (event) => {
        event.preventDefault();
        const { filter } = this.state;

        filter.isSubmitted = true;

        // * Do NOT forget to reset pageNumber to 1 when you click "Submit" button which means making a new search
        this.setState({ filter, pageNumber: 1 }, this.handleSearch );
    }

    handleSearch = () => {
        this.setState({ loading: true });

        
        const { filter, pagination, columnSorting } = this.state;

        const params = {
            ...filter,
            pageNumber: pagination.pageNumber,
            pageSize: pagination.pageSize,
            columnSorting: columnSorting

        };
        
        axios.get('/PlanProfile', { params })
        .then((response) => {
            
            const { Detail, PageCount, TotalItemCount} = response.data;
            pagination.pageCount = response.data.PageCount;


            this.setState({
                detail: Detail,
                loading: false,
                totalItemCount : TotalItemCount,
                pageCount: PageCount,
                pagination
            });
        })
        .catch((error) => {
            console.log(error);
        })
    }

    handleExportClick = (event) => {
        event.preventDefault();
        const { filter } = this.state;


        const params = {
            Year: filter.year,
            Market: filter.market,
            HiosId: filter.hiosId
        };

        axios.get('/PlanProfile/Export', { params, responseType: 'blob' }).then((response) => {
            const fileName = response.headers['x-suggested-filename'] || 'ResultsExtract_' + new Date().getTime() + '.txt';
            download(response.data, fileName)
        }).catch((error) => {
            console.error(error);
            toastr.error('Failed to export data.');
        });
    }

    columnSortinghandler = sorted => {

        var pagination = this.state.pagination;
        pagination.pageNumber = 0;

        this.setState( { pagination, columnSorting: sorted }, () => this.onPaging(pagination.pageSize, pagination.pageIndex) );
    };

    onPaging (pageSize, pageIndex) {
        const { filter, pagination, columnSorting } = this.state;

        if (pageSize < 1) pageSize = this.state.results.pageSize;
        else pageIndex = 0;

        pagination.pageNumber = pageIndex + 1;
        pagination.pageSize = pageSize;
        
        this.setState(
            { filter, pagination, columnSorting },
            this.handleSearch
        );
    }

    onPageChange (pageIndex) {
        this.onPaging(-1, pageIndex);
    }

    onPageSizeChange(pageSize, pageIndex) {
        this.onPaging(pageSize, pageIndex);
    }

    setupPlanProfile = () => {
        const { filter, pageNumber, pageSize } = this.state;
        
        filter.defaultFilter = false;

        const planProfile = {
            filter,
            pagination: {
            	pageNumber: pageNumber,
            	pageSize:pageSize
              }
            
        }

        this.props.setPlanProfile( planProfile );
    }

    generateLink = (planID) => {
        // const sortKey = 'PlanID';
        const { filter, } = this.state;
        
        var link = '/Reporting/PlanProfile/' + planID + '/' + filter.year;

        return link;
    }

    render() {
        const { filter, years,  detail, loading, pagination, markets,totalItemCount } = this.state;
        const { handleYearChange, handleHiosIdChange, handleMarketChange, searchClick, handleExportClick,
                columnSortinghandler, onPageSizeChange, onPageChange, setupPlanProfile, generateLink } = this;
        const hiosIds = this.state.hiosIds;

        const columns = [
            {
                Header: 'Detail',
                id: 'detail',
                sortable: false,
                //TODO: In the future, if we want to retrieve filtered plan detail, we might need to update the detail page reuqest link
                Cell: row =>
                    renderDetail(generateLink(row.original.PlanId), setupPlanProfile)
            },
            {
                Header: 'Plan ID',
                accessor: 'PlanId',
                width: 200
            },
            {
                Header: 'HIOS ID',
                accessor: 'HiosId'
            },
            {
                Header: 'Market',
                accessor: 'PlanMarket'
            },
            {
                Header: 'Metal Level',
                accessor: 'MetalLevel'
            },
            {
                Header: 'Benefit Year',
                id: 'BenefitYear',
                Cell: (row) => renderInteger(row.original.BenefitYear)
            },
            {
                Header: 'CMS data As of Month',
                accessor: 'CMSDataAsOfMonth'
            },
            {
                Header: 'Total Members',
                id: 'MemberCount',
                Cell: (row) => renderInteger(row.original.MemberCount)
            },
            {
                Header: 'Member Months',
                id: 'MemberMonths',
                Cell: (row) => renderDecimal(row.original.MemberMonths, 3)
            },
            {
                Header: 'PLRSi',
                id: 'PLRSi',
                Cell: (row) => renderDecimal(row.original.PLRSi, 3)
            },
            {
                Header: 'Avg Calc RACSD Score',
                id: 'AvgCalcRacsdScore',
                Cell: (row) => renderDecimal(row.original.AvgCalcRacsdScore, 3)
            },
            {
                Header: 'Avg CMS RACSD Score',
                id: 'AvgCmsRacsdScore',
                Cell: (row) => renderDecimal(row.original.AvgCmsRacsdScore, 3)
            },
            {
                Header: 'Avg Calc RARSD Score',
                id: 'AvgCalcRarsdScore',
                Cell: (row) => renderDecimal(row.original.AvgCalcRarsdScore, 3)
            },
            {
                Header: 'Avg CMS RARSD Score',
                id: 'AvgCmsRarsdScore',
                Cell: (row) => renderDecimal(row.original.AvgCmsRarsdScore, 3)
            },
            {
                Header: 'Avg Calc Client Score',
                id: 'AvgCalcClientScore',
                Cell: (row) => renderDecimal(row.original.AvgCalcClientScore, 3)
            },
            {
                Header: 'RACSD Difference',
                id: 'RacsdDifference',
                Cell: (row) => renderDifference(row.original.RacsdDifference)
            },
            {
                Header: 'Calc RACSD vs Calc RARSD Difference',
                id: 'CalcDifference',
                Cell: (row) => renderDifference(row.original.CalcDifference)
            },
            {
                Header: 'CMS RACSD vs CMS RARSD Difference',
                id: 'CmsDifference',
                Cell: (row) => renderDifference(row.original.CmsDifference)
            },
            {
                Header: 'RARSD Difference',
                id: 'RarsdDifference',
                Cell: (row) => renderDifference(row.original.RarsdDifference)
            },
            {
                Header: 'Avg Potential Score',
                id: 'AvgPotentialScore',
                Cell: (row) => renderDecimal(row.original.AvgPotentialScore, 3)
            }
        ];

        return (
            <Content>
                <Content.Header>Plan Profile Report</Content.Header>
                <Content.Body>
                    {
                        years.length > 0 &&
                        <Content.Filter>
                            <div className="row">
                                <div className="col-sm-12">
                                    <form className="form-inline">
                                        <div className="form-group">
                                            <label className="control-label">Benefit Year:</label>
                                            <select className="form-control" value={filter.year} onChange={handleYearChange}>
                                                {
                                                    years.map(x => <option key={x} value={x}>{x}</option>)
                                                }
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label className="control-label">HIOS ID:</label>
                                            <select className="form-control"
                                                value={filter.hiosId}
                                                onChange={handleHiosIdChange}
                                            >
                                                {
                                                    hiosIds.map(x => <option key={x} value={x}>{x}</option>)
                                                }
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label className="control-label">Market:</label>
                                            <select className="form-control"
                                                value={filter.market}
                                                onChange={handleMarketChange}
                                                disabled={markets.length < 2}
                                            >
                                                {
                                                    markets.map(x => <option key={x} value={x}>{renderMarkets(x)}</option>)
                                                }
                                            </select>
                                        </div>
                                        <button className="btn btn-tessellate"
                                            onClick={searchClick}
                                        > Submit </button>
                                    </form>
                                </div>
                            </div>
                        </Content.Filter>
                    }
                    {
                        filter.isSubmitted &&
                        <span>
                            <div className="row export-spacing">
                                <div className="col-sm-12">
                                    <button className="btn btn-tessellate pull-right" onClick={handleExportClick}>
                                        Export
                                    </button>
                                </div>
                            </div>
                            <Content.Result>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <ReactTable
                                            className="ts-react-table -striped -highlight"
                                            columns={columns}
                                            data={detail}
                                            manual
                                            loading={loading}
                                            defaultPageSize={pagination.pageSize}
                                            page={pagination.pageNumber - 1}
                                            pages={pagination.pageCount}
                                            pageSizeOptions={[10, 25, 50, 100]}
                                            onSortedChange={sorted => columnSortinghandler(sorted)}
                                            sortable={true}
                                            multiSort={true}

                                            onPageChange={onPageChange}
                                            onPageSizeChange={onPageSizeChange}
                                            noDataText={(<span>No Results Found</span>)}
                                        />
                                          <div><strong>Total Number of Records: {totalItemCount}</strong></div>
                                    </div>
                                </div>
                            </Content.Result>
                        </span>
                    }
                </Content.Body>
            </Content>
        );
    }
}

export default connect(
    mapPlanProfileToProps,
    mapPlanProfileDispatchToProps
)(PlanProfileReport);