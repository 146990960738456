import React from 'react';
import { toDecimalString } from '../../../utils/CommonReactTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class MemberScore extends React.Component {

    constructor(props) {
        super(props);
        let marginLeft = "0px";

        if (this.props.MarginLeft) {
            marginLeft = parseInt( this.props.MarginLeft ) + "px";
        }

        this.state = {
            Title : this.props.Title,
            Score : this.props.Score,
            Detail : this.props.Detail,
            CalcId : this.props.CalcId,
            MarginLeft : marginLeft,
        }
    }

    setEdgeColumnValue = (Edge) => {
        if (Edge === null) {
            return (
                <React.Fragment>
                    <td>-</td>
                    <td>-</td>
                </React.Fragment>
                );
        }
        else if (Edge === 1) {
            return (
                <React.Fragment>
                    <td><FontAwesomeIcon icon="check"/></td>
                    <td> </td>
                </React.Fragment>
            );
        }
        else {
            return (
                <React.Fragment>
                    <td> </td>
                    <td><FontAwesomeIcon icon="check"/></td>
                </React.Fragment>
            );
        }
    }

    renderTableByCalcID = () => {
        const {CalcId, Detail} = this.state;
        
        let score = "";
        if (Detail) {
            score = parseFloat( Detail.CSRAdjustedScore );
            score = isNaN( score ) ? "" : score.toFixed( 3 );
        }

        if (CalcId === 50) {
            return (
                <div>
                    <div className="modalHeader" style={{marginTop: "20px"}}>Claims Data:</div>
                    <table className="modalConditionTable" >
                        <thead >
                            <tr>
                                <th rowSpan = "2">Claim ID</th>
                                <th rowSpan = "2">Source</th>
                                <th rowSpan = "2">Plan</th>
                                <th rowSpan = "1" colSpan = "2">Edge</th>
                            </tr>
                            <tr>
                                <th style={{borderTop: "solid 1px"}}>RA</th>
                                <th style={{borderTop: "solid 1px", borderLeft: "none"}}>Non-RA</th>
                            </tr>
                            
                        </thead>
                        

                        <tbody style={{maxHeight: "150px", overflowY: "scroll"}} >
                        {
                            /* justifyContent : "right"   renderDecimal( f.ConditionFactor, 3 )   */

                            Detail && Detail.Claims &&
                            Detail.Claims.map( ( f, index ) => { 
                            
                            let bgColor = ( index % 2 === 0) ? "rgb(240,240,240)" : "rgb(255,255,255)";

                            return (
                                <React.Fragment>
                                <tr key={index} style={{backgroundColor : bgColor }} >
                                    <td> {f.ClaimID} </td>
                                    <td> { f.Source } </td>
                                    <td> { f.Plan ? <FontAwesomeIcon icon="check" /> :<FontAwesomeIcon icon="times"/>} </td>
                                    {this.setEdgeColumnValue(f.Edge)}
                                </tr>
                                </React.Fragment>
                                )
                            }) 
                        }
                            <tr style={{borderTop:"solid 1px", backgroundColor: "rgb(255,255,255)"}}>
                                <td colSpan="2" >Total </td>
                                <td colSpan="3" style={{borderLeft: "none", textIndent:"-4"}}>{score}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            );
        }
        else {
            return (
                <div>
                    <div className="modalHeader" style={{marginTop: "20px"}}> Risk Score Conditions </div>    
                
                    <table className="modalConditionTable" >
                        
                        {/* widths 20%, 40% does not work.   widths 200px, 40% does work */ }
                        <thead >
                            <tr>
                                <th style={{"width": "60%"}}>Condition</th>
                                <th>Factor </th>
                            </tr>
                        </thead>

                        <tbody style={{maxHeight: "150px", overflowY: "scroll"}} >
                        {
                            /* justifyContent : "right"   renderDecimal( f.ConditionFactor, 3 )   */

                            Detail && Detail.HccFactors &&
                            Detail.HccFactors.map( ( f, index ) => { 
                            
                            let bgColor = ( index % 2 === 0) ? "rgb(240,240,240)" : "rgb(255,255,255)";

                            return <tr key={index} style={{backgroundColor : bgColor }} >
                                <td> {f.Condition} </td>
                                <td> { toDecimalString( f.ConditionFactor, 3 ) } </td>
                                </tr>;
                            }) 
                        }
                        </tbody>

                    </table>
                </div>
            );
        }
    }

    
    render() {
        let detail = this.state.Detail;
        // console.log( ' *** MEMBER SCORE detail = ' + JSON.stringify( detail ) );
        
        let  memberDescription = "", csrCalculation = "", csrDisplayFactor = "", csrFactorApplies = "No";  // monthYear;
        let score = "";

        if (detail) {
            memberDescription = detail.MemberDescription;
            csrCalculation = detail.CSRCalculation;
            csrDisplayFactor = detail.CSRDisplayFactor;

            score = parseFloat( detail.CSRAdjustedScore );

            score = isNaN( score ) ? "" : score.toFixed( 3 );

            if (detail.CSRApplied)
              csrFactorApplies = detail.CSRApplied;
       
        } 

        return (
      
            <div className={this.props.Position} style={{marginLeft: this.state.MarginLeft}} >
                <table className="modalTable" style={{marginTop: "20px"}} >
                    <tbody>
                    <tr>
                            <td className="modalHeader" style={{"width" : "40%"} } colSpan="2" > {this.state.Title} Risk Score:</td>
                            <td style={{"width" : "40%"}} > { score } </td> 
                    </tr>
                    <tr><td style={{"height": "30px"}} colSpan="2"></td></tr> 
                    <tr>
                            <td className="modalHeader" style={{"width" : "40%"} } colSpan="2" > Demographic Factor:</td>
                            <td style={{"width" : "40%"}} > { detail && detail.DemographicScore } </td> 
                    </tr>  
                    </tbody>
                </table>

                <div style = {{"textIndent": "3%","fontStyle": "italic"}}> {memberDescription} </div>
                {this.renderTableByCalcID()}       
                <div className="modalHeader" style={{marginTop: "20px"}}> Enrollment Duration:   
                <table className="modalConditionTable" >
                    
                    <thead >
                    <tr>
                        <th>Enrollment Days</th>
                        <th>Factor</th>
                        <th>Enrollment Months</th>
                        <th>Enrollment Duration Indicator</th>
                        <th>Factor for Risk Score Calculation</th>
                    </tr>
                    </thead>

                    <tbody style={{maxHeight: "150px"}}>
                    {
                    detail &&
                        <tr style={{backgroundColor: "white"}}>
                            <td>{detail.EnrollmentDurationDays}</td>
                            <td>{detail.EnrollmentFactor}</td>
                            <td>{detail.EnrollmentMonth}</td>
                            <td>{detail.EnrollmentDurationIndicator}</td>
                            <td>{detail.EnrollmentFactor}</td>
                        </tr> 
                    }
                    </tbody>

                </table>
                </div>

                <table className="modalTable" style={{marginTop: "10px"}}>
                <tbody>
                <tr>
                    <td className="modalHeader" style={{"width" : "250px"}} >CSR Factor Applies:</td>
                    <td style={{"width" : "150px"}} > {csrFactorApplies} </td>
                    <td className="modalHeader" style={{"width" : "150px"}} >CSR Factor:</td>
                    <td style={{"width" : "150px"}} > {csrDisplayFactor} </td>
                </tr>
                </tbody>
                </table>

                <div className="modalHeader" style={{"marginTop" : "20px"}}>Calculated {this.state.Title} Risk Score</div>
                <div>{csrCalculation}</div>


            </div>

        );

    }

}

