import React from 'react';
import Axios from 'axios';
import { capitalize, clone } from 'lodash';
import { Table, Button, Glyphicon } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { listColumns } from './DefaultColumns';
import GapChaseLoadingStatus from './GapChaseLoadingStatus';
import { Records, Sort } from './TableComponents';
import { Error } from './Messages';

export default class Lists extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lists: null,
            paginationStats: {
                CountPerPage: 10,
                Page: 0,
                RecordCount: 0
            },
            sortOrder: [],
            error: false,
            loading: true
        };

        this.timer = null;
        this.columns = listColumns(this.props.listType);
        const CancelToken = Axios.CancelToken;
        this.source = CancelToken.source();
    }

    componentDidMount() {
        this.loadLists();
    }

    componentWillUnmount() {
        this.source.cancel();
        clearTimeout(this.timer);
    }

    loadLists = (page = this.state.paginationStats.Page, pageSize = this.state.paginationStats.CountPerPage) => {
        clearTimeout(this.timer);
        const sortOrder = [];
        let sortColumns = clone(this.columns);

        sortColumns.sort((a, b) => { return a.sortOrder > b.sortOrder }).forEach((item) => {
            if (item.sortDirection !== undefined && item.sortDirection.length && item.sortOrder > 0) {
                sortOrder.push(`${item.fieldName} ${item.sortDirection}`);
            }
        });

        Axios.get(`/v1/Lists/${this.props.listType}`, {
            params: {
                listType: this.props.listType,
                page: page + 1,
                pageSize: pageSize,
                sortOrder: sortOrder.join(",")
            },
            cancelToken: this.source.token
        })
        .then((response) => {
            if (response.status === 200) {
                this.setState({
                    lists: response.data.Result.results,
                    paginationStats: {
                        CountPerPage: pageSize,
                        Page: response.data.Result.pageNumber - 1,
                        RecordCount: response.data.Result.totalCount
                    },
                    loading: false
                });

                //poll server for changes, remove for now 9/9/2019
                //this.timer = setTimeout(() => { this.loadLists(); }, 10000);
            }
        })
        .catch((error) => {
            if (!Axios.isCancel(error)) {
                // handle error
                this.setState({
                    error: true,
                    loading: false
                });
            }
        });
    }

    handleSort = (sortData, e) => {
        this.columns.forEach((item, index) => {
            if (item.fieldName === sortData.fieldName) {
                this.columns[index].sortOrder = 1;
                this.columns[index].sortDirection = sortData.sortDirection;
            }
            else {
                if (item.sortOrder === undefined) {
                    item.sortOrder = 0;
                }
                this.columns[index].sortOrder = 0;
                this.columns[index].sortDirection = "";
            }
        });
        this.pageChange(0);
    }

    pageChange = (idx) => {
        this.loadLists(idx, this.state.paginationStats.CountPerPage);
    }

    pageCountChange = (countPerPage) => {
        this.loadLists(0, countPerPage);
    }
    
    handleListDelete = (listID) => () => {
        Axios.delete(`/GapChaseLists/${listID}`, {
            cancelToken: this.source.token
        })
        .then(this.loadLists);
    }

    renderLink = (item) => {
        return (
            <Link to={`/GapChase/Lists/${this.props.listType}/${item.id}`}>
                {item.name}
            </Link>
        );
    }

    renderActions = (item) => {
        return (
            <td style={{ whiteSpace: "nowrap" }}>
                <tooltip title={'Export ' + this.props.listType + ' List'} arrow >
                    <Button onClick={() => this.props.handleExportCsv(item.id, item.name)}>
                        <Glyphicon glyph="save" />
                    </Button>
                </tooltip>
                <tooltip title="Export Chase Detail" arrow >
                    <Button style={{
                        display: (item.IsFinal &&
                            this.props.listType === "Chase") ? "auto" : "none"
                    }} tooltip="Export Chase Detail"
                        onClick={() => this.props.handleExportChaseDetail(item.id, item.name)}>
                        <Glyphicon glyph="import" />
                    </Button>
                </tooltip>
                <Button style={{
                    display: (item.status === 'Draft' || item.loadingStatus === 'Error')
                        && this.props.user && (item.createdBy === this.props.user.Email
                            || this.props.user.Roles.includes('Commercial ACA Gap Chase Admin')) ? "auto" : "none"
                }}
                    onClick={this.handleListDelete(item.id)}>
                    <Glyphicon glyph="remove" />
                </Button>
            </td>
        );
    }

    renderResults = () => {
        if (!this.state.loading && !this.state.error) {
            const columns = this.columns.map((item, index) => {
                return (
                    <th key={`${item.fieldName}-th`} className={item.className} style={{ height: "1px" }}>
                        <Sort onSort={this.handleSort} fieldName={item.fieldName} sortDirection={item.sortDirection}
                            text={item.displayName} sortable={item.sortable} />
                    </th>
                );
            });

            return (
                <div style={{ minHeight: "400px" }}>
                    <Table hover bordered condensed striped responsive>
                        <thead>
                            <tr>
                                {columns}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.lists.map((item, index) => {
                                    return (
                                        <tr className="text-left" key={item.id}>
                                            <td>{this.renderLink(item)}</td>
                                            <td>{<b> {item.IsFinal == true ? "Final" : "Draft"} / {GapChaseLoadingStatus[item.Status]} </b>}</td>
                                            <td>{item.createdBy}</td>
                                            <td>{new Date(item.createdDate).toLocaleDateString('en-US')}</td>
                                            {this.renderActions(item)}
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </Table>
                </div>
            );
        }
        else {
            return (
                <div style={{ minHeight: "400px" }}>
                    <Error />
                </div>
            );
        }
    }

    render() {
        let contents = !this.state.loading ? this.renderResults() : <div style={{ height: "400px" }} />;

        return (
            <div>
                <section>
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <h3>{capitalize(this.props.listType)} Lists</h3>
                        </div>
                        <div className="panel-body" style={{ minHeight: "400px" }}>
                            {contents}
                            <Records stats={this.state.paginationStats} onPageChangeSize={this.pageCountChange} onPage={this.pageChange} />
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}