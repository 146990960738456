import {GapChaseFilterBase, filterType, dataType} from '../GapChaseFilterBase';

export default class GapTypesFilter extends GapChaseFilterBase{
    constructor(){
        super();
        this.id = 4;
        this.name = "Gap Type";
        this.filterName = "GapTypes";
        // this.isHidden = false;
        this.filterType = filterType.multiSelect;
        this.values = [
            { label: "Historical", value: "Historical" },
            { label: "Suspect", value: "Suspect" }
          ];

        this.dataType = dataType.number;
        this.filterCategory = "Gap Level";
        this.filterGroupId = 1;
        this.placeholder = "Gap Type";
    }  
}


