import { GapChaseFilterBase, filterType, dataType } from '../GapChaseFilterBase';

export default class RarsdScoreFilter extends GapChaseFilterBase {
    constructor() {
        super();
        this.id = 15;
        this.name = "RARSD Score";
        this.filterName = "RarsdScore";

        // The following two lines make this filter LOB aware
        const validFilterLOBs = ["Commercial"];
        this.isHidden = validFilterLOBs.indexOf(localStorage.getItem('Lob')) < 0;

        this.filterType = filterType.range;
        this.values = [];

        this.dataType = dataType.number;
        this.filterCategory = "Gap Level";
        this.filterGroupId = 1;
        this.minValue = 0;
        this.maxValue = 999;
        this.step = 0.01;
        this.placeholder = "RARSD Score";
    }
}
