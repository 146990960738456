import React from 'react';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import toastr from 'toastr';
import MemberHeader from './MemberHeader';
import MemberScore from './MemberScore';

export default class MemberRiskScoreComparisonModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            score: parseFloat(this.props.score),
            memberMasterId: this.props.memberMasterId,
            benefitYear: this.props.benefitYear,
            CMSDataAsofMonth: props.CMSDataAsofMonth,
            planId: this.props.planId,
            member: null,
            detail: null
        }
    }

    hide = (e) => {
        this.props.onHide();
    }


    keyPressed = (event) => {
        const escape = 27;
        if ( event.which === escape ) {
            this.props.onHide();
        }
    }

    componentDidMount() {
        const params = {
            memberMasterId: this.state.memberMasterId,
            planId: this.state.planId,
            benefitMonth: this.state.CMSDataAsofMonth,
            benefitYear: this.state.benefitYear,
            calculationTypeId1: this.props.calcId,
            calculationTypeId2: this.props.calcId2
        };

        axios.get('/MemberProfile/MemberProfileRiskScoreCalculations', { params })
        .then((response) => {
            this.setState({
                member: response.data.Header,
                detail: response.data.Detail
            });
        })
        .catch((err) => {
            toastr.error('An error occurred while retrieving member data.');
        });
      }


    unifyHccFactors(detail, otherLength) {
        if (detail.HccFactors.length < otherLength) {
            const numToAdd = otherLength - detail.HccFactors.length;
            for (let i = 0; i < numToAdd; i++) {
                detail.HccFactors.push({ Condition: "", ConditionFactor: "" });
            }
        }
    }
 
    unifyDetails(detail1, detail2) {
        if (detail1.AgeBand === "Adult" || detail2.AgeBand === "Adult") {
            detail1.AgeBand = "Adult";
            detail2.AgeBand = "Adult";
        }

        if (detail1.MemberDescription && !detail2.MemberDescription) {
            detail2.MemberDescription = detail1.MemberDescription;
        }
        if (!detail1.MemberDescription && detail2.MemberDescription) {
            detail1.MemberDescription = detail2.MemberDescription;
        }

        this.unifyHccFactors(detail1, detail2.HccFactors.length);
        this.unifyHccFactors(detail2,  detail1.HccFactors.length);
    }

    constructDetail() {
        const nb = "\u00a0"; // "&nbsp;";
        return { AgeBand:"", MemberDescription:"", HccFactors:[], EnrollmentDurationDays: nb, EnrollmentFactor: nb, EnrollmentMonths: nb, EnrollmentDurationIndicator: nb };
    }
  
    getCalcType(typeId) {
        let calcType = "";
        if (typeId === 10) {
            calcType = "Calc RACSD"
        }
        else if (typeId === 20) {
            calcType = "CMS RACSD"
        }
        else if (typeId === 30) {
            calcType = "Calc RARSD"
        }
        else if (typeId === 40) {
            calcType = "CMS RARSD";
        }

        return calcType;
    }

    render() {
        let member = this.state.member ? this.state.member  : null;
        let detail1 = null, detail2 = null;
        let title = "", title1 = "", title2 = "";

        if (this.state.detail) {
          detail1 = this.state.detail.find( d => d.CalculationTypeId === this.props.calcId);
          detail2 = this.state.detail.find( d => d.CalculationTypeId === this.props.calcId2);
        }

        if (!detail1) {
            detail1 = this.constructDetail();
        }
        if (!detail2) {
            detail2 = this.constructDetail();
        }

        this.unifyDetails(detail1, detail2);

        title1 = this.getCalcType(detail1.CalculationTypeId);
        title2 = this.getCalcType(detail2.CalculationTypeId);
        title = title1 + " vs " + title2;

        return (
            member &&
            <Modal show={this.props.show} bsSize="large" aria-labelledby="contained-modal-title-lg" onKeyDown={this.keyPressed}>
                <Modal.Header closeButton onHide={this.hide}>
                    <h4>Member {title} Risk Score Calculation Detail</h4>
                </Modal.Header>
                <Modal.Body>
                    <MemberHeader Member={member} Detail={detail1} />
                    <div className="flexWrapper">
                      <MemberScore Title={title1} Score={this.props.score} Detail={detail1} Position='flexLeft' />
                      <MemberScore Title={title2} Score={this.props.score} Detail={detail2}  Position='flexRight' MarginLeft={20}/>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-default" onClick={this.hide}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}