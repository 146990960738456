import { GapChaseFilterBase, filterType, dataType } from '../GapChaseFilterBase';

export default class AllOrTopChasesFilter extends GapChaseFilterBase {
    constructor() {
        super();
        this.id = 23;
        this.name = "MRR Project";
        this.isHidden = true;
        this.filterType = filterType.select;
        this.values = [];

        this.dataType = dataType.number;
        this.filterCategory = "Chase Level";
        this.filterGroupId = 3;
        this.placeholder = "MRR Project";
    }
}
