import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IntlProvider } from 'react-intl';
import { FormattedDate } from 'react-intl';
import { renderCapitalize } from '../../../utils/CommonReactTable';

export default class MemberHeader extends React.Component {


    constructor( props ) {
        super( props );

        this.state = {
            Member: props.Member,
            Detail: props.Detail,
            collapsed: false
        }
    // console.log( 'this.state = ' + JSON.stringify( this.state ) );
    }
    
    toggleMemberDetail = () => {
		this.setState({ collapsed: !this.state.collapsed });
    }
    
    render() {
        const { collapsed } = this.state;
		const { toggleMemberDetail } = this;
        let collapsedIcon = collapsed ? "plus" : "minus";
        let metalLevel = "", monthYear = "", planType = "",planId;
        let detail = this.state.Detail;
        
        if (detail) {
            metalLevel = detail.MetalLevel;
            planId = detail.PlanId;
            planType = detail.ProductType != null ? renderCapitalize(detail.ProductType) : (detail.Claims && detail.Claims !== null && detail.Claims.length > 0 ? detail.Claims[0].Source : "");
            let date = new Date( detail.Year, detail.Month - 1, 1);
            let month = date.toLocaleString( "en-us", {
              month: "short"
            });

            if ( detail.Year > 0 )
                monthYear = month + '/' + detail.Year;

        }
        
        return (

            <div>
            <table className="modalTable">
                <tbody>
                <tr>
                    <td className="modalHeader" style={{"width" : "100px"}} >Member ID</td>
                    <td style={{"width" : "30%"}} >{this.state.Member.MemberId}</td>
                    <td style={{"width" : "60%"}} >  <button className="btn btn-default pull-right" onClick={toggleMemberDetail}>
                            <FontAwesomeIcon icon={collapsedIcon} />
                            </button> 
                    </td>
                </tr>
                </tbody>
            </table>


            { !collapsed &&
            <table className="modalTable">
              <tbody>
                <tr>  
                      <td className="modalHeader" style={{"width" : "100px"}} >First Name</td><td style={{"width" : "30%"}} >{this.state.Member.FirstName}</td>
                      <td className="modalHeader" style={{"width" : "100px"}} >MI</td><td style={{"width" : "10%"}} >{this.state.Member.MiddleName}</td>
                      <td className="modalHeader" style={{"width" : "100px"}} >Last Name</td><td style={{"width" : "30%"}} >{this.state.Member.LastName}</td> 
                </tr>
                <tr>  
                      <td className="modalHeader" style={{"width" : "100px"}} >DOB</td><td style={{"width" : "30%"}} > 
                          <IntlProvider locale="en"><FormattedDate value={this.state.Member.DateOfBirth} month='2-digit' day='2-digit' year='numeric' /></IntlProvider>
                      </td> 
                      <td className="modalHeader" style={{"width" : "100px"}} > </td><td style={{"width" : "10%"}} >  </td>
                      <td className="modalHeader" style={{"width" : "100px"}} >Sex</td><td style={{"width" : "30%"}} > {this.state.Member.Gender} </td> 
                </tr>
                <tr>  
                      <td className="modalHeader" style={{"width" : "100px"}} >Age Band</td><td style={{"width" : "30%"}} > {this.state.Member.Model} </td> 
                </tr>
                <tr><td colSpan="6" style={{"height":"30px"}}> </td></tr>
                <tr>  
                      <td className="modalHeader" style={{"width" : "100px"}} >Plan ID</td><td style={{"width" : "30%"}} > {planId} </td> 
                      <td> &nbsp; </td><td> &nbsp; </td>
                      <td className="modalHeader" style={{"width" : "100px"}} >Plan Type</td><td style={{"width" : "30%"}} > {planType} </td> 
                </tr>     
                <tr>  
                      <td className="modalHeader" style={{"width" : "100px"}} >Metal Level</td><td style={{"width" : "30%"}} > {metalLevel} </td>
                      <td> &nbsp; </td><td> &nbsp; </td>
                      <td className="modalHeader" style={{"width" : "100px"}} >Month/Year</td><td style={{"width" : "30%"}} > {monthYear} </td> 

                </tr>     
                </tbody>
            </table>
            }

            </div>

        );
    }
}