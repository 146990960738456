import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class Unauthorized extends Component {
    render() {
        return (
            <div className="unauthorized-container">
                <div className="icon">
                    <FontAwesomeIcon icon="user-lock" size="6x" />
                </div>
                <div className="text">
                    <h4>You are not authorized to access this content.</h4>
                </div>
            </div>
        )
    };
}