import { GapChaseFilterBase, filterType, dataType } from '../GapChaseFilterBase';

export default class GapListIdFilter extends GapChaseFilterBase {
    constructor(chaseFilterData) {
        super();
        this.id = 18;
        this.name = "Gap List";
        this.filterName = "GapListId";
        // this.isHidden = false;
        this.filterType = filterType.select;
        this.values = [];
        if (chaseFilterData.GapLists !== null && chaseFilterData.GapLists !== undefined)
            this.values = chaseFilterData.GapLists;
        else
            this.values = chaseFilterData[0].filters[0].values;

        this.dataType = dataType.number;
        this.filterCategory = "Chase Level";
        this.filterGroupId = 3;
        this.placeholder = "Gap List";
    }
}
