
// type     - how the state should change, 
// payload  - the component to add to Redux
export const setEnrollmentRec = enrollmentRec => ({
    type: "SET_ENROLLMENT_REC",
    enrollmentRec
});

export const resetEnrollmentRec = enrollmentRec => ({
    type: "RESET_ENROLLMENT_REC",
    enrollmentRec
});


// EnrollmentRec component receives the prop which is a copy of that prop in Redux state. It's from reducer.
export const  mapEnrollmentRecToProps = state => {
    const enrollmentRecProp = state.state_enrollmentRec.find(x => x.enrollmentRec)
    return enrollmentRecProp ? enrollmentRecProp : {};

};

// map[componentName]DispatchToProps connects Redux actions to React props
export const mapEnrollmentRecDispatchToProps = dispatch => ({
    setEnrollmentRec: enrollmentRec => dispatch(setEnrollmentRec(enrollmentRec)),
    resetEnrollmentRec: enrollmentRec => dispatch(resetEnrollmentRec(enrollmentRec))
});
