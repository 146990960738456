
const defaultEnrollmentRec = {

    filter: {
       benefitYear: 0,
       hiosId: "All",
       market: "All",
       planId: "All",
       timeSliceBy : "Year",
       defaultFilter: true,
       submitted: false
    },
    pagination: {
        pageNumber:1,
        pageSize: 12,
        pageCount: 0,
    }
   
}

const defaultState = {
    type: "SET_ENROLLMENT_REC",
    enrollmentRec: {...defaultEnrollmentRec}
}

const enrollmentRecStates = (state = [{...defaultState}], action) => {
    
    switch (action.type) {
       case "SET_ENROLLMENT_REC":             
           if (state.find(x => x.type === action.type)) {
               return state.map(enrollmentRecStates =>
                   (enrollmentRecStates.type === action.type)
                   ? { ...enrollmentRecStates, enrollmentRec: action.enrollmentRec }
                   : enrollmentRecStates
               );
           }
           else {
               return [
                   ...state,
                   {
                       type: action.type,
                       enrollmentRec: action.enrollmentRec
                   }
               ];
           }
       
       case "RESET_ENROLLMENT_REC":
           if (state.find(x => x.type === action.type)) {
               return state.map(enrollmentRecStates => 
                   (enrollmentRecStates.type === action.type)
                   ? { ...enrollmentRecStates, enrollmentRec: defaultEnrollmentRec }
                   : enrollmentRecStates
               );
           }
           else {
               return [
                   ...state,
                   {
                       type: "SET_ENROLLMENT_REC",
                       enrollmentRec: defaultEnrollmentRec
                   }
               ];
           }

           
       default:
            return state;
    }
};

export default enrollmentRecStates;