import { GapChaseFilterBase, filterType, dataType } from '../GapChaseFilterBase';
import Axios from 'axios';

export default class HccsFilter extends GapChaseFilterBase{
    constructor(){
        super();
        this.id = 2;
        this.name = "HCC";
        this.filterName = "Hccs";
        //this.isHidden = false;
        this.filterType = filterType.multiSelect;

        this.values = [];

        this.dataType = dataType.number;
        this.filterCategory = "Gap Level";
        this.filterGroupId = 1;
        this.placeholder = "HCC";

    }
}
