export const setPlanProfile = planProfile => ({
    type: "SET_PLAN_PROFILE",
    planProfile
});

export const resetPlanProfile = planProfile => ({
    type: "RESET_PLAN_PROFILE",
    planProfile
});

export const mapPlanProfileDispatchToProps = dispatch => ({
    setPlanProfile: planProfile => dispatch(setPlanProfile(planProfile)),
    resetPlanProfile: planProfile => dispatch(resetPlanProfile(planProfile))
});

export const mapPlanProfileToProps = state => {
    const planProfileProp = state.planProfileStates.find(x => x.planProfile);
    return planProfileProp ? planProfileProp : {};
};

