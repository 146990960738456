import { GapChaseFilterBase, filterType, dataType } from '../../GapChaseFilterBase';
import Axios from 'axios';

export default class FacilityChaseTypeFilter extends GapChaseFilterBase {
    constructor() {
        super();
        this.id = 54;
        this.name = "Facility Chase Type";
        this.filterName = "FacilityChaseType";
        //this.isHidden = false;
        this.filterType = filterType.checkbox;
        this.values = [
            {
                "label": "",
                "value": "FacilityChaseType"
            }
        ];

        this.dataType = dataType.boolean;
        this.filterCategory = "Provider Level";
        this.filterGroupId = 4;
        this.placeholder = "Facility Chase Type";

    }

}
