import { GapChaseFilterBase, filterType, dataType } from '../../GapChaseFilterBase';
import Axios from 'axios';

export default class SpecialistRaGainFilter extends GapChaseFilterBase {
    constructor() {
        super();
        this.id = 28;
        this.name = "Specialist RA Gain";
        this.filterName = "SpecialistRaGain";
        //this.isHidden = false;
        this.filterType = filterType.range;
        this.values = [];

        this.dataType = dataType.number;
        this.filterCategory = "Provider Level";
        this.filterGroupId = 4;
        this.minValue = -999.99;
        this.maxValue = 999.99;
        this.step = 0.01;
        this.placeholder = "Specialist RA Gain";

    }

}
