import React from 'react';
import Axios from 'axios';
import FileDownload from 'js-file-download';
import { capitalize, cloneDeep } from 'lodash';
import { Button, Col, Glyphicon, Grid, Modal, Row } from 'react-bootstrap';
import { NavLink, withRouter } from 'react-router-dom';
import FilterSidebar from "./FilterSidebar";
import FilterSummary from "./FilterSummary";
import Lists from "./Lists";
import ListResults from './ListResults';
import toastr from 'toastr';
import ListResultStats from './ListResultStats';
import { ParseRangeValue } from '../../utils/GapChaseUtils';
import GapLevelFilterGroup from '../filtergroups/GapLevelFilterGroup';
import MemberLevelFilterGroup from '../filtergroups/MemberLevelFilterGroup';
import ChaseLevelFilterGroup from '../filtergroups/ChaseLevelFilterGroup';
import ProviderLevelFilterGroup from '../filtergroups/ProviderLevelFilterGroup';
import { filterType } from '../filters/GapChaseFilterBase';
import ListResultStatistics from './ListResultStatistics';
import ChaseListResultStatistics from './ChaseListResultStatistics';

class ListContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showFilterSidebar: props.showFilterSidebar === undefined ? false : props.showFilterSidebar,
            showFilterSummary: false,
            showResults: false,
            showResultStats: false,
            showResultStatistics: false,
            showChaseListResultStatistics: false,
            showLists: props.showLists === undefined ? true : props.showLists,
            showUpdate: props.showUpdate === undefined ? false : props.showUpdate,
            showExports: false,
            showSaveConfirmModal: false,
            showError: false,
            filters: null,
            filters2: null,
            selectedFilters: null,
            savedFilterSelections: null,
            listDefinition: null,
            showChaseCreate: false
        };

        // default gap year filter to the option for the current year;
        // default chase provider type filter to PCP, Specialist, and Facility options
        this.defaultFilterSelections = [
            //{
            //    filterId: 25,
            //    name: "ChaseProviderType",
            //    value: [
            //        { label: "PCP", value: "PCP" },
            //        { label: "Specialist", value: "Specialist" },
            //        { label: "Facility", value: "Facility" }
            //    ],
            //    label: "Chase Provider Type",
            //    operator: "Eq"
            //}
        ];

        this.timer = null;
        this.pollingInterval = 5000;
        const CancelToken = Axios.CancelToken;
        this.source = CancelToken.source();
    }

    componentWillMount() {
        if (this.props.match.params.id) {
            this.handleSelectList(this.props.match.params.id);
        }
        else if (this.props.showFilterSidebar) {
            this.getFilters();
        }
    }

    componentWillReceiveProps(nextProps) {
        // entered on route change via props supplied from withRouter -- we're using a single, shared instance
        // of <ListContainer /> across all gap/chase routes (avoiding unnecessary remounts + requests?)
        if (nextProps.match.params.id) {
            //single list view -- update or results-only
            this.handleSelectList(nextProps.match.params.id);
        }
        else if (nextProps.showFilterSidebar) {
            //single list view -- create
            this.getFilters();
            this.setState({
                showFilterSidebar: nextProps.showFilterSidebar,
                showLists: nextProps.showLists
            });
        }
        else if (this.props.location.pathname !== nextProps.location.pathname) {
            //all lists view (gap or chase)
            this.setState({
                showFilterSidebar: false,
                showFilterSummary: false,
                showResults: false,
                showResultStats: false,
                showResultStatistics: false,
                showChaseListResultStatistics: false,
                showLists: true,
                showUpdate: false,
                showExports: false,
                showSaveConfirmModal: false,
                showError: false,
                filterOptions: null,
                selectedFilters: null,
                savedFilterSelections: null,
                listDefinition: null,
                showChaseCreate: false
            });
        }
    }

    componentWillUnmount() {
        this.source.cancel();
    }

    //todo: export shouldn't be available if loadingStatus !== "Complete"
    //load existing list
    handleSelectList = (listID) => {
        let self = this;
        Promise.all([this.getList(listID), this.getFilters(true)])
        .then((responses) => {
            if (responses[0].status === 200 && responses[1].status === 200) {
                self.setState({
                    selectedFilters: [
                        {
                            "id": 5540,
                            "filterId": 18,
                            "name": "GapYear",
                            "value": 2019,
                            "label": "Gap Year",
                            "operator": "Eq",
                            "createdBy": "support@storan.com",
                            "createdDatetime": "2021-11-18T20:50:56.9906745",
                            "updatedDatetime": null,
                            "updatedBy": null
                        }]
                });
                let filtersEnriched = []
                //Set response[1] to backend list.Configuration data into values
                responses[1].data.forEach(x => { //ForEach filterGroup 
                    x.filters.forEach(y => {
                        
                        //restore data values enriched with dynamic backend data e.g.
                        let listConfigData = responses[0].data;
                        if ((y.filterType != filterType.range && listConfigData[y.filterName] != null) || (y.id == 41 || y.id == 43 || y.id == 45) || y.id == 53 || y.id == 54 || (y.filterType == filterType.range && (listConfigData[y.filterName + "Min"] != null || listConfigData[y.filterName + "Max"] != null))) {
                            let singleFilter = {
                                "id": responses[0].data.id, //This happens to return jobid from v1 api
                                "filterId": y.id,
                                "name": y.name,
                                "value": null, //have to override by type
                                "label": y.name,
                                "operator": "Eq", //override based on type
                                "createdBy": listConfigData.createdBy,
                                "createdDatetime": listConfigData.createdDatetime,
                                "updatedDatetime": listConfigData.updatedDatetime,
                                "updatedBy": listConfigData.updatedBy
                            }
                            //y.values = null;
                            var defaultValue = [];
                            switch (y.filterType) {
                                
                                case filterType.checkbox:
                                    if (y.id == 41) {
                                        singleFilter.value = listConfigData['ChaseTypes'].indexOf(10)>= 0 ?true : null;                                    
                                    }
                                    if (y.id == 53) {
                                        singleFilter.value = listConfigData['ChaseTypes'].indexOf(20) >= 0 ? true : null;
                                    }
                                    if (y.id == 54) {
                                        singleFilter.value = listConfigData['ChaseTypes'].indexOf(30) >= 0 ? true : null;
                                    }
                                    if (y.id == 43) {
                                        singleFilter.value = listConfigData['ChaseTypes'].indexOf(40) >= 0 ? true : null;
                                    }
                                    if (y.id == 45) {
                                        singleFilter.value = listConfigData['ChaseTypes'].indexOf(50) >= 0 ? true : null;
                                    }
                                    break;
                                case filterType.multiSelect:
                                    if (listConfigData[y.filterName] != null) {
                                        listConfigData[y.filterName].forEach(item => {

                                            defaultValue.push({ label: (y.values == undefined || y.values.length == 0) ? item : y.values.find(x => x.value == item).label, value: item });
                                        });
                                        singleFilter.value = defaultValue;
                                    }
                                    break;
                                case filterType.range:
                                   // y.minValue = listConfigData[y.filterName.concat("Min")];
                                   // y.maxValue = listConfigData[y.filterName.concat("Max")];
                                    if (listConfigData[y.filterName.concat("Min")] != null && listConfigData[y.filterName.concat("Max")] == null)
                                        singleFilter.operator = "Gte";
                                    if (listConfigData[y.filterName.concat("Min")] == null && listConfigData[y.filterName.concat("Max")] != null)
                                        singleFilter.operator = "Lte";
                                    if (listConfigData[y.filterName.concat("Min")] != null && listConfigData[y.filterName.concat("Max")] != null)
                                        singleFilter.operator = "Between";
                                    singleFilter.value = [listConfigData[y.filterName.concat("Min")], listConfigData[y.filterName.concat("Max")]];
                                    break;

                                default:
                                    if (y.id == 1) {
                                        // load HCC options
                                        self.getFilter("hcc", listConfigData["Year"], []);
                                        // load PlanID options
                                        self.getFilter("planIds", listConfigData["Year"], []);
                                    }
                                    y.defaultValue = listConfigData[y.filterName];  //Set actual ui control value (NOT WORKING)
                                    singleFilter.value = y.defaultValue;
                                    break;
                            }
                            filtersEnriched.push(singleFilter);
                        }
                    })
                })

                responses[0].data.status = responses[0].data.IsFinal == true ? "Final" : "Draft";
                responses[0].data.loadingStatus = responses[0].data.Status;
                self.setState({
                    listDefinition: responses[0].data,
                    showFilterSidebar: responses[0].data.status !== "Final",
                    showFilterSummary: true,
                    showResults: true,
                    showResultStats: false, //TODO: Create API endpoint and set this to true
                    showResultStatistics: responses[0].data.status !== "Final",
                    showChaseListResultStatistics: responses[0].data.status !== "Final",
                    showLists: false,
                    showExports: true,
                    showUpdate: true,
                    showSaveConfirmModal: false,
                    selectedFilters: filtersEnriched,
                    savedFilterSelections: filtersEnriched, //self.splitSavedValues(self.flattenFilterGroups(responses[1].data), filtersEnriched),
                    // hiding the create chase list button temporarily in the gap list page
                    //showChaseCreate: responses[0].data.loadingStatus === "Complete" && responses[0].data.status === "Final", // todo -- unhide
                    //filters: self.updateObjectInArray( responses[1].data,null)
                    filters: responses[1].data
                });
                //this.setState({ filters: this.updateObjectInArray([...this.state.filters], target) });
            }
        })
        .catch((error) => {
            if (!Axios.isCancel(error)) {
                // handle error
                this.setState({ showError: true });
            }
        });
    }

    //Removed instance, but may reuse in future, 9/10/2019,  This is causing screen flicker effect.
    pollForResultsComplete = (listID) => {
        clearTimeout(this.timer);
        this.getList(listID).then((response) => {
            if (response.data.loadingStatus === "Complete") {
                // ListResults and ListResultsStats will load their data after detecting this listDefinition.loadingStatus change
                // via a comparison of this.props and nextProps in componentWillReceiveProps()
                this.setState({ listDefinition: response.data });
            }
            else {
                this.timer = setTimeout(() => this.pollForResultsComplete(listID), this.pollingInterval);
            }
        })
        .catch((error) => {
            if (!Axios.isCancel(error)) {
                this.setState({ error: true });
            }
        });
    }

    //returns all filters for the current listType
    getList = (listID) => {
        return new Promise((resolve, reject) => {
            let self = this;
            Axios.get(`/v1/Lists/${this.getListTypeViaLocation()}/${listID}`, {
                cancelToken: this.source.token
            })
                .then((response) => {
                    if (response.status === 200) {
                        //response.data = { ...response.data.Result };
                        /* self.setState({
                            selectedFilters: [
                            {
                                    "id": 5540,
                                    "filterId": 18,
                                    "name": "Year",
                                    "value": 2019,
                                    "label": "Gap Year",
                                    "operator": "Eq",
                                    "createdBy": "support@storan.com",
                                    "createdDatetime": "2021-11-18T20:50:56.9906745",
                                    "updatedDatetime": null,
                                    "updatedBy": null
                            }]
                        });
                        response.data = {
                            "id": response.data.id,
                            "name": response.data.name,
                            "createdBy": response.data.createdBy,
                            "createdDate": response.data.createdDate,
                            "updatedBy": response.data.updatedBy,
                            "updatedDate": response.data.updatedDate,
                            "listType": self.getListTypeViaLocation(),
                            "filters": [
                                {
                                    "id": 5540,
                                    "filterId": 18,
                                    "name": "Year",
                                    "value": 2019,
                                    "label": "Gap Year",
                                    "operator": "Eq",
                                    "createdBy": "support@storan.com",
                                    "createdDatetime": "2021-11-18T20:50:56.9906745",
                                    "updatedDatetime": null,
                                    "updatedBy": null
                                }
                            ],
                            "status": response.data.IsFinal == true ? "Final" : "Draft",
                            "listStatus": 0,
                            "loadingStatus": response.data.Status
                        }
                        */
                        //response.data = {
                        //    "id": response.data.id,
                        //    "name": response.data.name,
                        //    "createdBy": response.data.createdBy,
                        //    "createdDate": response.data.createdDate,
                        //    "updatedBy": response.data.updatedBy,
                        //    "updatedDate": response.data.updatedDate,
                        //    "listType": self.getListTypeViaLocation(),
                        //    "filters": [
                        //        {
                        //            "id": 5538,
                        //            "filterId": 25,
                        //            "name": "ChaseProviderType",
                        //            "value": "PCP,Specialist,Facility",
                        //            "label": "Chase Provider Type",
                        //            "operator": "Eq",
                        //            "createdBy": "support@storan.com",
                        //            "createdDatetime": "2021-11-18T20:50:56.9316725",
                        //            "updatedDatetime": null,
                        //            "updatedBy": null
                        //        },
                        //        {
                        //            "id": 5539,
                        //            "filterId": 37,
                        //            "name": "ProviderInclusion",
                        //            "value": "168",
                        //            "label": "Provider Inclusion List",
                        //            "operator": "Eq",
                        //            "createdBy": "support@storan.com",
                        //            "createdDatetime": "2021-11-18T20:50:56.9866748",
                        //            "updatedDatetime": null,
                        //            "updatedBy": null
                        //        },
                        //        {
                        //            "id": 5540,
                        //            "filterId": 18,
                        //            "name": "GapList",
                        //            "value": "1734",
                        //            "label": "Gap List",
                        //            "operator": "Eq",
                        //            "createdBy": "support@storan.com",
                        //            "createdDatetime": "2021-11-18T20:50:56.9906745",
                        //            "updatedDatetime": null,
                        //            "updatedBy": null
                        //        },
                        //        {
                        //            "id": 5541,
                        //            "filterId": 19,
                        //            "name": "ChaseRAGain",
                        //            "value": "5.00,7.00",
                        //            "label": "RA Gain",
                        //            "operator": "Between",
                        //            "createdBy": "support@storan.com",
                        //            "createdDatetime": "2021-11-18T20:50:56.9936748",
                        //            "updatedDatetime": null,
                        //            "updatedBy": null
                        //        },
                        //        {
                        //            "id": 5542,
                        //            "filterId": 20,
                        //            "name": "ChaseRank",
                        //            "value": "5,7",
                        //            "label": "Chase Rank",
                        //            "operator": "Between",
                        //            "createdBy": "support@storan.com",
                        //            "createdDatetime": "2021-11-18T20:50:56.9956738",
                        //            "updatedDatetime": null,
                        //            "updatedBy": null
                        //        },
                        //        {
                        //            "id": 5543,
                        //            "filterId": 40,
                        //            "name": "AllOrTopChases",
                        //            "value": "2",
                        //            "label": "All Or Top Chases",
                        //            "operator": "Eq",
                        //            "createdBy": "support@storan.com",
                        //            "createdDatetime": "2021-11-18T20:50:56.9986737",
                        //            "updatedDatetime": null,
                        //            "updatedBy": null
                        //        },
                        //        {
                        //            "id": 5544,
                        //            "filterId": 41,
                        //            "name": "PCPChaseType",
                        //            "value": "true",
                        //            "label": "PCP Chase Type",
                        //            "operator": "Eq",
                        //            "createdBy": "support@storan.com",
                        //            "createdDatetime": "2021-11-18T20:50:57.0006754",
                        //            "updatedDatetime": null,
                        //            "updatedBy": null
                        //        },
                        //        {
                        //            "id": 5545,
                        //            "filterId": 43,
                        //            "name": "Tier1ChaseType",
                        //            "value": "true",
                        //            "label": "Tier 1 Chase Type",
                        //            "operator": "Eq",
                        //            "createdBy": "support@storan.com",
                        //            "createdDatetime": "2021-11-18T20:50:57.0026745",
                        //            "updatedDatetime": null,
                        //            "updatedBy": null
                        //        },
                        //        {
                        //            "id": 5546,
                        //            "filterId": 26,
                        //            "name": "PCPRAGain",
                        //            "value": "5.00,7.00",
                        //            "label": "PCP RA Gain",
                        //            "operator": "Between",
                        //            "createdBy": "support@storan.com",
                        //            "createdDatetime": "2021-11-18T20:50:57.0056752",
                        //            "updatedDatetime": null,
                        //            "updatedBy": null
                        //        },
                        //        {
                        //            "id": 5547,
                        //            "filterId": 44,
                        //            "name": "Tier1RAGain",
                        //            "value": "5.00,7.00",
                        //            "label": "Tier 1 RA Gain",
                        //            "operator": "Between",
                        //            "createdBy": "support@storan.com",
                        //            "createdDatetime": "2021-11-18T20:50:57.0086757",
                        //            "updatedDatetime": null,
                        //            "updatedBy": null
                        //        },
                        //        {
                        //            "id": 5548,
                        //            "filterId": 45,
                        //            "name": "Tier2ChaseType",
                        //            "value": "true",
                        //            "label": "Tier 2 Chase Type",
                        //            "operator": "Eq",
                        //            "createdBy": "support@storan.com",
                        //            "createdDatetime": "2021-11-18T20:50:57.011675",
                        //            "updatedDatetime": null,
                        //            "updatedBy": null
                        //        },
                        //        {
                        //            "id": 5549,
                        //            "filterId": 46,
                        //            "name": "Tier2RAGain",
                        //            "value": "5.00,7.00",
                        //            "label": "Tier 2 RA Gain",
                        //            "operator": "Between",
                        //            "createdBy": "support@storan.com",
                        //            "createdDatetime": "2021-11-18T20:50:57.0136732",
                        //            "updatedDatetime": null,
                        //            "updatedBy": null
                        //        },
                        //        {
                        //            "id": 5550,
                        //            "filterId": 53,
                        //            "name": "SpecialistChaseType",
                        //            "value": "true",
                        //            "label": "Specialist Chase Type",
                        //            "operator": "Eq",
                        //            "createdBy": "support@storan.com",
                        //            "createdDatetime": "2021-11-18T20:50:57.0166728",
                        //            "updatedDatetime": null,
                        //            "updatedBy": null
                        //        },
                        //        {
                        //            "id": 5551,
                        //            "filterId": 29,
                        //            "name": "Speciality",
                        //            "value": "103T00000X,111N00000X,111NX0800X",
                        //            "label": "Specialist Specialties",
                        //            "operator": "Eq",
                        //            "createdBy": "support@storan.com",
                        //            "createdDatetime": "2021-11-18T20:50:57.0186754",
                        //            "updatedDatetime": null,
                        //            "updatedBy": null
                        //        },
                        //        {
                        //            "id": 5552,
                        //            "filterId": 54,
                        //            "name": "FacilityChaseType",
                        //            "value": "true",
                        //            "label": "Facility Chase Type",
                        //            "operator": "Eq",
                        //            "createdBy": "support@storan.com",
                        //            "createdDatetime": "2021-11-18T20:50:57.0296732",
                        //            "updatedDatetime": null,
                        //            "updatedBy": null
                        //        },
                        //        {
                        //            "id": 5553,
                        //            "filterId": 32,
                        //            "name": "FacilityRAGain",
                        //            "value": "5.00,7.00",
                        //            "label": "Facility RA Gain",
                        //            "operator": "Between",
                        //            "createdBy": "support@storan.com",
                        //            "createdDatetime": "2021-11-18T20:50:57.0336756",
                        //            "updatedDatetime": null,
                        //            "updatedBy": null
                        //        },
                        //        {
                        //            "id": 5554,
                        //            "filterId": 33,
                        //            "name": "Tier1Facility",
                        //            "value": "261QM1300X,261QE0700X",
                        //            "label": "Facility Specialties",
                        //            "operator": "Eq",
                        //            "createdBy": "support@storan.com",
                        //            "createdDatetime": "2021-11-18T20:50:57.0356748",
                        //            "updatedDatetime": null,
                        //            "updatedBy": null
                        //        },
                        //        {
                        //            "id": 5555,
                        //            "filterId": 34,
                        //            "name": "OneFacilityVisit",
                        //            "value": "true",
                        //            "label": "At Least One Inpatient Facility Visit Per Member",
                        //            "operator": "Eq",
                        //            "createdBy": "support@storan.com",
                        //            "createdDatetime": "2021-11-18T20:50:57.0396756",
                        //            "updatedDatetime": null,
                        //            "updatedBy": null
                        //        },
                        //        {
                        //            "id": 5556,
                        //            "filterId": 35,
                        //            "name": "ProviderState",
                        //            "value": "CA",
                        //            "label": "Provider State",
                        //            "operator": "Eq",
                        //            "createdBy": "support@storan.com",
                        //            "createdDatetime": "2021-11-18T20:50:57.0426734",
                        //            "updatedDatetime": null,
                        //            "updatedBy": null
                        //        },
                        //        {
                        //            "id": 5557,
                        //            "filterId": 38,
                        //            "name": "ProviderExclusion",
                        //            "value": "172",
                        //            "label": "Provider Exclusion List",
                        //            "operator": "Eq",
                        //            "createdBy": "support@storan.com",
                        //            "createdDatetime": "2021-11-18T20:50:57.0456728",
                        //            "updatedDatetime": null,
                        //            "updatedBy": null
                        //        }
                        //    ],
                        //    "status":  response.data.IsFinal == true ? "Final" : "Draft",
                        //    "listStatus": 0,
                        //    "loadingStatus": response.data.Status
                        //}
                        resolve(response);
                    }
                });
        });
    }

    //returns all filters for the current listType
    getFilters = (returnPromise = false) => {
        return new Promise((resolve, reject) => {
            let self = this;
            Axios.get('/GapChaseFilterData', {
                params: {
                    listType: self.getListTypeViaLocation(),
                },
                cancelToken: self.source.token
            })
                .then((response) => {
                    let filterData = response.data;
                    if (self.state.filters) {
                        if (!Array.isArray(filterData)) {
                            filterData = self.updateObjectInArray([...self.state.filters], filterData);
                        }
                    }

                    let newFilters = [];
                    if (self.getListTypeViaLocation() == "Gap") {
                        let gapLevelFilterGroup = new GapLevelFilterGroup(filterData);
                        let memberLevelFilterGroup = new MemberLevelFilterGroup(filterData);
                        newFilters.push(gapLevelFilterGroup, memberLevelFilterGroup);
                    }
                    else {
                        let chaseLevelFilterGroup = new ChaseLevelFilterGroup(filterData);
                        let providerLevelFilterGroup = new ProviderLevelFilterGroup(filterData);
                        newFilters.push(chaseLevelFilterGroup, providerLevelFilterGroup);
                    }


                    filterData = newFilters;
                    response.data = filterData;
                    self.setState({
                        filters: filterData,
                        savedFilterSelections: [...self.defaultFilterSelections]
                    });
                    resolve(response);
                })
                .catch((error) => {
                    if (Axios.isCancel(error)) {
                        //request canceled
                    }
                    else {
                        // handle error
                        reject(error);
                        self.setState({ showError: true });
                    }
                });
        });
    }

    //returns a dynamically-populated filter via id + filter selections (param for the associated ValuesQuery)
    getFilter = (name, values, activeInputElements) => {
        let self = this;
        if (values && Array.isArray(values) && values.length > 0) {
            values = values.map(x => x.value);
        }
        Axios.get(`/GapChaseFilterData/${name}`, {
            params: {
                year: values,
                isMA: localStorage.Lob === "Medicare" ? true: false 
            },
            cancelToken: this.source.token
        })
        .then((response) => {
            if (name === "hcc" && response.data) {
                //sort HCC options by value
                response.data.values = [...response.data.HccNumbers];
                self.state.filters[0].filters.find(x => x.name === "HCC").values = response.data.values;
                response.data.id = 2;
                response.data.values.sort((a, b) => parseInt(a.value, 10) - parseInt(b.value, 10));
            }
            if (name === "planIds" && response.data) {
                response.data.values = [...response.data.PlanIds];
                self.state.filters[1].filters.find(x => x.name === "Plan ID").values = response.data.values;
                response.data.id = 7;
                response.data.values.sort((a, b) => parseInt(a.value, 10) - parseInt(b.value, 10));
            }
            let tempSelections = null;
            if (response.data && self.state.selectedFilters.find(x => x.filterId === response.data.id)) {
                tempSelections = [...self.state.selectedFilters];

                //remove any filter selection values that are no longer are available
                const updatedFilter = tempSelections.find(x => x.filterId === response.data.id);
                if (Array.isArray(updatedFilter.value)) updatedFilter.value = updatedFilter.value.filter(x => response.data.values.find(y => y.value === x.value));

                //update uncontrolled input value (via input ref)
                const targetInputElement = activeInputElements.find(x => x.filterId === response.data.id);
                if (targetInputElement) {
                    targetInputElement.element.inputElement.handleSelectChange(updatedFilter.value);
                }
                
                 if (updatedFilter.value.length === 0) {
                    //remove the filter selection if there are no longer any values
                    tempSelections = tempSelections.filter(x => x.filterId !== response.data.id);
                } 
            }

            if (tempSelections) {
                self.setState({
                    filters: self.updateObjectInArray([...self.state.filters], response.data),
                    selectedFilters: tempSelections
                });
            }
            else {
                self.setState({
                    filters: self.updateObjectInArray([...self.state.filters], response.data)
                });
            }
        })
        .catch((error) => {
            if (Axios.isCancel(error)) {
                //request canceled
            }
            else {
                // handle error
                self.setState({ showError: true });
            }
        });
    }

    // converts saved filter selections for ranges from the persisted comma-delimited string
    // into an array of values
    splitSavedValues = (filters, savedFilterSelections) => {
        return savedFilterSelections.map(x => {
            let filter = filters.find(y => y.id === x.filterId);
            if (filter.filterType === "Multiselect") {
                if (x.value) {
                    if (x.value.includes(',')) {
                        x.value = x.value.split(',').map(y => {
                            return {
                                label: filter.values.find(z => z.value === y) ? filter.values.find(z => z.value === y).label : y,
                                value: y
                            };
                        });
                    }
                    else {
                        x.value = [{
                            label: filter.values.find(z => z.value === x.value) ? filter.values.find(z => z.value === x.value).label : x.value,
                            value: x.value
                        }];
                    }
                }
            }
            else if (filter.filterType === "Range") {
                if (x.value) {
                    if (x.value.includes(',')) {
                        x.value = x.value.split(',').map(val => val ? ParseRangeValue(filter, val) : "");
                    }
                    else {
                        if (x.operator === "Gte") {
                            x.value = [ParseRangeValue(filter, x.value), ""]
                        }
                        else if (x.operator === "Lte") {
                            x.value = ["", ParseRangeValue(filter, x.value)]
                        }
                    }
                }
            }

            return x;
        });
    }

    //create new list (draft status)
    handleCreate = (listName) => {
        return new Promise((resolve, reject) => {
            Axios.post(`/v1/Lists/${this.getListTypeViaLocation()}/CreateAndGenerate`, {
                name: listName,
                //listType: this.getListTypeViaLocation(),
                //filters: this.formatSelectedFilters([...this.state.selectedFilters]),
                ...this.formatSelectedFilters([...this.state.selectedFilters]),
                JobId: 0,
                LineOfBusiness: localStorage.getItem('Lob'),
                cancelToken: this.source.token
            }) 
            .then((response) => {
                if (response.status === 201) {
                    toastr.success('This list has been saved in draft status. You may exit at any time.');
                    resolve(response.data.id);
                }
            })
            .catch((error) => {
                if (Axios.isCancel(error)) {
                    //request canceled
                    resolve();
                }
                else if (error.response.status === 409) {
                    reject("Conflict");
                }
                else {
                    // handle error
                    this.setState({ showError: true });
                    reject();
                }
            });
        });
    }

    //modify draft list
    handleUpdate = (listID, listName, isFinalize = false) => {

        return new Promise((resolve, reject) => {
            if (!isFinalize)
                Axios.put(`/v1/Lists/${this.getListTypeViaLocation()}/${listID}/UpdateAndGenerate`, {
                    name: listName,
                    ...this.formatSelectedFilters([...this.state.selectedFilters]),
                    JobId: 0,
                    LineOfBusiness: localStorage.getItem('Lob'),
                    cancelToken: this.source.token
                })
                .then((response) => {
                    toastr.success('This list has been saved in draft status. You may exit at any time.');
                    resolve();
                })
                .catch((error) => {
                    if (Axios.isCancel(error)) {
                        //request canceled
                        resolve();
                    }
                    else if (error.response.status === 409) {
                        reject("Conflict");
                    }
                    else {
                        // handle error
                        this.setState({ showError: true });
                        reject();
                    }
                });
            else
                Axios.post(`/v1/Lists/${this.getListTypeViaLocation()}/${listID}/finalize`, {
                    cancelToken: this.source.token
                })
                .then((response) => {
                    //to-do: use response, remove select list call
                    this.handleSelectList(listID);
                    resolve();
                })
                .catch((error) => {
                    if (Axios.isCancel(error)) {
                        //request canceled
                        resolve();
                    }
                    else if (error.response.status === 409) {
                        reject("Conflict");
                    }
                    else {
                        // handle error
                        this.setState({ showError: true });
                        reject();
                    }
                });
        });
    }

    //export list to mrr
    handleExportMrr = (listID) => {
        Axios.put('/GapChaseLists/' + this.state.listDefinition.id + '/ExportMRR', {
            cancelToken: this.source.token
        })
        .then((response) => {
            this.handleSelectList(this.state.listDefinition.id);
        })
        .catch((error) => {
            if (!Axios.isCancel(error)) {
                // handle error
                this.setState({ showError: true });
            }
        });
    }

    getCurrentTimestamp = () => {
        const date = new Date();
        return `${date.getFullYear()}${String(date.getMonth() + 1).padStart(2, '0')}${''
        }${String(date.getDate()).padStart(2, '0')}${String(date.getHours()).padStart(2, '0')}${''
        }${String(date.getMinutes()).padStart(2, '0')}${String(date.getSeconds()).padStart(2, '0')}`;
    }

    //export list to csv
    handleExportCsv = (listID, listName) => {
        Axios.post(`/v1/Lists/${this.getListTypeViaLocation()}/${listID}/export`, {
            params: {
                listType: this.getListTypeViaLocation().toLowerCase()
            },
            cancelToken: this.source.token
        })
        .then((response) => {
            FileDownload(response.data, `${listName}_${this.getCurrentTimestamp()}.txt`);
        })
        .catch((error) => {
            if (!Axios.isCancel(error)) {
                // handle error
                toastr.error('Oops! Something went wrong.');
                this.setState({ showError: true });
            }
        });
    }

    //export chase detail
    handleExportChaseDetail = (listID, listName) => {
        Axios.post(
            `/v1/Lists/${this.getListTypeViaLocation()}/${listID}/exportChaseDetail`, 
            {
                params: {
                    listType: this.getListTypeViaLocation().toLowerCase()
                },
                cancelToken: this.source.token
            },
            {
                responseType: 'blob'
            }
        )
            .then((response) => {
                FileDownload(response.data, `${listName}_${this.getCurrentTimestamp()}.zip`);
            })
            .catch((error) => {
                if (!Axios.isCancel(error)) {
                    // handle error
                    toastr.error('Oops! Something went wrong.');
                    this.setState({ showError: true });
                }
            });
    }

    //todo: refactor this
    formatSelectedFilters = (selectedFilters) => {
        const filterQueue = [];
        // todo: deep cloning here to prevent the array mutations for range/multiselect from affecting the source,
        // but should this happen upstream instead?
        let filters = cloneDeep([...selectedFilters]);
        filters.map(x => { x.id = x.filterId; x.filterId = undefined; });
        const allFilters = cloneDeep(this.flattenFilterGroups([...this.state.filters]));
        let formattedResults = {};
        let chaseTypes = [];

        for (let i in filters) {
            if (filters.hasOwnProperty(i)) {       
                const option = allFilters.find(x => x.id === filters[i].id);
                let filterName = option.filterName;
                if (option.filterType === "Multiselect" && Array.isArray(filters[i].value)) {
                    filters[i].value = filters[i].value.map(x => x.value)
                    formattedResults = { ...formattedResults, [filterName]: filters[i].value }
                }
                else if (option.filterType === "Range" && Array.isArray(filters[i].value)) {
                    if (filters[i].value[0] && filters[i].value[1]) {
                        formattedResults = { ...formattedResults, [filterName.concat("Min")]: filters[i].value[0] }
                        formattedResults = { ...formattedResults, [filterName.concat("Max")]: filters[i].value[1] }
                    }
                    else if (filters[i].value[0]) {
                        formattedResults = { ...formattedResults, [filterName.concat("Min")]: filters[i].value[0] }
                        formattedResults = { ...formattedResults, [filterName.concat("Max")]: null }
                    }
                    else if (filters[i].value[1]) {
                        formattedResults = { ...formattedResults, [filterName.concat("Min")]: null }
                        formattedResults = { ...formattedResults, [filterName.concat("Max")]: filters[i].value[1] }
                    }
                }
                else if (option.filterType === "Checkbox" && filters[i].value === true) {
                    if (option.filterName === "PcpChaseType") chaseTypes.push(10);
                    else if (option.filterName === "Tier1ChaseType") chaseTypes.push(40);
                    else if (option.filterName === "Tier2ChaseType") chaseTypes.push(50);
                    else if (option.filterName === "SpecialistChaseType") chaseTypes.push(20);
                    else if (option.filterName === "FacilityChaseType") chaseTypes.push(30);
                    else formattedResults = { ...formattedResults, [filterName]: filters[i].value }
                }
                else formattedResults = { ...formattedResults, [filterName]: filters[i].value }
            }
        }

        formattedResults = { ...formattedResults, ChaseTypes: chaseTypes }

        return formattedResults;

        //add queued filters to list
        //for (let key in filterQueue) {
        //    if (filterQueue.hasOwnProperty(key)) {
        //        filters.push(filterQueue[key]);
        //    }
        //}

        //return filters;
    }

    //todo: consolidate with instance in <FilterSidebar />
    //aggregate filter group arrays into a single array
    flattenFilterGroups(filters) {
        return filters.reduce((acc, group) => {
            group.filters.forEach(filter => acc.push(filter));
            return acc;
        }, [])
    }

    getListTypeViaLocation = () => {
        return this.props.match.params.listType !== undefined ? this.props.match.params.listType : "Gap";
    }

    //show filter sidebar
    handleShowFilters = () => {
        this.setState({ showFilterSidebar: true, showLists: false });
    }

    //update filter selections (called on filter change)
    handleSelectFilter = (filters) => {
        this.setState({ selectedFilters: filters, showFilterSummary: true, showLists: false });
    }

    //clear filter selections
    handleClearFilters = () => {
        this.setState({ selectedFilters: null, showFilterSummary: true});
    }

    clearFilterOptions = (id) => {
        //todo: this seems heavy-handed, but will leave for now
        const allFilters = this.flattenFilterGroups(this.state.filters);
        const target = allFilters.find(x => x.id === id);
        if (target) {
            target.values = [];
            this.setState({ filters: this.updateObjectInArray([...this.state.filters], target) });
        }
    }

    updateObjectInArray(array, action) {
        return array.map((item, index) => {
            if(item.categoryName === action.filterCategory) {
                const filter = item.filters.find(x => x.id === action.id);
                if(filter) {
                    filter.values = action.values;
                    filter.name = action.name;
                } else {
                    // for client level filters
                    item.filters.push(action);
                }
            }
            return item;
        });
    };

    toggleSaveConfirmModal = () => {
        this.setState({ showSaveConfirmModal: !this.state.showSaveConfirmModal });
    }

    handleModalKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.handleSave();
        }
    }

    saveAsFinalDisabled = () => {
        return !(this.props.user ?
            (this.state.listDefinition)
            || this.props.user.Roles.includes('Commercial ACA Gap Chase Admin')
            : false);
    }

    renderSaveConfirmModal = (listType) => {
        return (
            <Modal show={this.state.showSaveConfirmModal} onHide={this.toggleSaveConfirmModal} onKeyPress={this.handleModalKeyPress}>
                <Modal.Header closeButton>
                    <Modal.Title>Save as Final?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>After saving, this list can't be modified.</h4>
                </Modal.Body>
                <Modal.Footer>
                    <Button bsStyle="success" key="save" 
                        onClick={() => this.handleUpdate(this.state.listDefinition.id, this.state.listDefinition.name, true)}
                        style={{ float: "right" }}>OK
                        <Glyphicon glyph="ok" style={{ marginLeft: "0.66rem", lineHeight: "14px", float: "right" }} />
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    renderActions = (listType) => {
        const actions = [];
        if (!this.state.showFilterSidebar && !this.state.showResults) {
            actions.push(
                <NavLink key="set" to={`/GapChase/Lists/${listType}/New`}>
                    <Button style={{ paddingLeft: "10px", paddingRight: "10px", marginTop : "3px" }}>Create New {capitalize(listType)} List
                        <Glyphicon glyph="plus-sign" style={{ marginLeft: "0.66rem", top: "2.4px", lineHeight: "14px", color: "#1e821e" }} />
                    </Button>
                </NavLink>
            );
        }
        if (this.state.showChaseCreate) {
            actions.push(
                <NavLink key="chaseCreateLink" to={`/GapChase/Lists/Chase/New/${this.state.listDefinition.id}`}>
                    <Button key="chaseCreate" style={{ float: "right", marginLeft: "5px" }}>Create Chase List
                        <Glyphicon glyph="circle-arrow-right" style={{ marginLeft: "0.66rem", top: "2.8px", lineHeight: "14px", color: "#6c716c" }} />
                    </Button>
                </NavLink>
            );
        }
        if (this.state.listDefinition && this.state.listDefinition.loadingStatus === "Complete"
            && this.state.listDefinition.status !== "Final" && !this.saveAsFinalDisabled()) {
            actions.push(
                <Button key="save" onClick={this.toggleSaveConfirmModal} style={{ float: "right" }}>
                    Save as Final
                    <Glyphicon glyph="floppy-disk" style={{ marginLeft: "0.66rem", top: "2.8px", lineHeight: "14px", color: "#6c716c" }} />
                </Button>
            );
        }
        if (this.state.showExports && !listType === "Chase") {
            if(!this.state.listDefinition.mrrExportRequestedDate) {
                actions.push(
                    <Button key="export-mrr" onClick={this.handleExportMrr} style={{ float: "right", "marginLeft": "5px" }}>
                        Export to MRR
                        <Glyphicon glyph="export" style={{marginLeft: "0.66rem", top: "2.8px", lineHeight: "14px", color: "#6c716c"}} />
                    </Button>
                );
            }
            else if(!this.state.listDefinition.mrrExportCompletedDate) {
                actions.push(
                    <Button key="export-mrr" disabled={true} onClick={this.handleExportMrr} style={{ float: "right", "marginLeft": "5px" }}>
                        Export to MRR Requested
                        <Glyphicon glyph="export" style={{ marginLeft: "0.66rem", top: "2.8px", lineHeight: "14px", color: "#6c716c" }} />
                    </Button>
                );
            }
            else {
                actions.push(
                    <Button key="export-mrr" disabled={true} onClick={this.handleExportMrr} style={{ float: "right", "marginLeft": "5px" }}>
                        Export to MRR Complete
                        <Glyphicon glyph="export" style={{ marginLeft: "0.66rem", top: "2.8px", lineHeight: "14px", color: "#6c716c" }} />
                    </Button>
                );
            }
        }
        if (this.state.showExports) {
            actions.push(
                <Button key="export-csv" onClick={() => this.handleExportCsv(this.state.listDefinition.id, this.state.listDefinition.name)}
                    style={{float: 'right'}}>
                    Export for Review
                    <Glyphicon glyph="th" style={{ marginLeft: "0.66rem", top: "2.8px", lineHeight: "14px", color: "#6c716c" }} />
                </Button>
            );
        }
        if (this.state.showExports && listType === "Chase" && this.state.listDefinition.status === "Final") {
            actions.push(
                <Button key="export-csv" onClick={() => this.handleExportChaseDetail(this.state.listDefinition.id, this.state.listDefinition.name)}
                    style={{ float: 'right' }}>
                    Export Chase Detail
                    <Glyphicon glyph="th" style={{ marginLeft: "0.66rem", top: "2.8px", lineHeight: "14px", color: "#6c716c" }} />
                </Button>
            );
        }

        if (this.state.showResults) {
            if (!this.state.showFilterSidebar) {
                actions.push(
                    <NavLink key="return" to={`/GapChase/Lists/${listType}`}>
                        <Button style={{ paddingLeft: "10px", paddingRight: "10px" }}>Return to {capitalize(listType)} Lists
                            <Glyphicon glyph="hand-left" style={{ marginLeft: "0.66rem", top: "1.6px", lineHeight: "14px", color: "#6c716c" }} />
                        </Button>
                    </NavLink>
                );
            }
        }
       
        return <div style={{ height: "3.6rem", marginTop: "2rem", marginBottom: "1rem" }}>{actions}</div>;
    }

    render() {
        let contents, sidebar, summary, lists, results, resultstats = null;
        const listName = this.state.listDefinition ? this.state.listDefinition.name : "";
        const listID = this.state.listDefinition ? this.state.listDefinition.id : "";
        const createdBy = this.state.listDefinition ? this.state.listDefinition.createdBy : "";
        const listType = this.getListTypeViaLocation();

        if (this.state.showFilterSidebar) {
            sidebar = <FilterSidebar filters={this.state.filters} savedFilterSelections={this.state.savedFilterSelections}
                onSelectFilter={this.handleSelectFilter} selectList={this.handleSelectList} onClear={this.handleClearFilters}
                onCreate={this.handleCreate} onUpdate={this.handleUpdate} listType={listType} showUpdate={this.state.showUpdate}
                listName={listName} listID={listID} selectedGapId={this.props.match.params.gapId} getFilter={this.getFilter}
                clearFilterOptions={this.clearFilterOptions} history={this.props.history} user={this.props.user} createdBy={createdBy} />;
        }
        if (this.state.showFilterSummary) {
            if (this.state.listDefinition && this.state.listDefinition.status === "Final") {
                summary = <FilterSummary filters={this.state.filters} selectedFilters={this.state.savedFilterSelections}
                flattenFilterGroups={this.flattenFilterGroups} isFinal={true} />;
            }
            else {
                summary = <FilterSummary filters={this.state.filters} selectedFilters={this.state.selectedFilters}
                    flattenFilterGroups={this.flattenFilterGroups} isFinal={false} />;
            }
        }
        if (this.state.showLists) {
            lists = <Lists listType={listType} selectList={this.handleSelectList} user={this.props.user} handleExportCsv={this.handleExportCsv} handleExportChaseDetail={this.handleExportChaseDetail} />;
        }
        if (this.state.showResults) {
            results = <ListResults listType={listType} listDefinition={this.state.listDefinition} showError={this.state.showError}
                getList={this.getList} />;
        }
        if (this.state.showResultStats) {
            resultstats = <ListResultStats listType={listType} listDefinition={this.state.listDefinition} showError={this.state.showError} />;
        }
        if (this.state.showResultStatistics && listType === "Gap" && localStorage.Lob === "Medicare") {
            resultstats = <ListResultStatistics listType={listType} listDefinition={this.state.listDefinition} showError={this.state.showError} />;
        }
        if (this.state.showResultStatistics && listType === "Chase" && localStorage.Lob === "Medicare") {
            resultstats = <ChaseListResultStatistics listType={listType} listDefinition={this.state.listDefinition} showError={this.state.showError} />;
        }
     
        if (!this.state.showFilterSidebar && this.state.showExports) {
            //finalized results view
            contents = (<Row>
                <Col lg={12} lgOffset={0}>
                    {summary}
                    {this.renderActions(listType)}
                    {lists}
                    {resultstats}
                    {results}
                </Col>
            </Row>);
        }
        else {
            //view with sidebar or default list view
            contents = (<Row>
                <Col lg={4}>
                    {sidebar}
                </Col>
                <Col lg={this.state.showFilterSidebar ? 8 : 10} lgOffset={this.state.showFilterSidebar ? 0 : 1}>
                    {summary}
                    {this.renderActions(listType)}
                    {lists}
                    {resultstats}
                    {results}
                    {this.renderSaveConfirmModal(listType)}
                </Col>
            </Row>);
        }

        return (
            /* todo: negative margin here is to counter the 50px of padding added by .content-wrapper.
               consider refactoring .content-wrapper so its styling can be applied more selectively */
            <div style={{ marginTop: '-10px' }}>
                <Grid fluid style={{ maxWidth: window.innerWidth }}>
                    {contents}
                </Grid>
            </div>
        )
    }
}

export default withRouter(ListContainer);