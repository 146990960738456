import { capitalize } from 'lodash';

const listColumns = function(listType) {
    return [{
            displayName: `${capitalize(listType)} List Name`,
            fieldName: "name",
            className: "text-center col-lg-5",
            customComponent: "",
            sortDirection: "",
            order: 0,
        },
        {
            displayName: "Status",
            fieldName: "status",
            className: "text-center col-lg-2",
            order: 1
        },
        {
            displayName: "Created By",
            fieldName: "createdBy",
            className: "text-center col-lg-3",
            order: 2
        },
        {
            displayName: "Created On",
            fieldName: "createdDate",
            className: "text-center col-lg-1",
            order: 3,
            sortDirection: "desc",
            sortOrder: 1
        },
        {
            displayName: "",
            fieldName: "action",
            className: "text-center col-lg-1",
            order: 4,
            sortable: false
        }
    ];
};

const defaultGapListResultColumns = function() {
    return [{
            displayName: "DOS Year",
            fieldName: "GapYear",
            className: "text-center",
            customComponent: "",
            sortDirection: "",
            order: 0,
        },
        {
            displayName: "Member ID",
            fieldName: "MemberId",
            className: "text-center",
            sortDirection: "asc",
            sortOrder: 1,
            order: 1
        },
        {
            displayName: "Member Name",
            fieldName: "MemberName",
            className: "text-center",
            order: 2
        },
        {
            displayName: "Age",
            fieldName: "MemberAge",
            className: "text-center",
            order: 3
        },
        {
            displayName: "Gender",
            fieldName: "MemberGender",
            className: "text-center",
            order: 4
        },
        {
            displayName: "Metal Level",
            fieldName: "MetalLevel",
            className: "text-center",
            order: 5
        },
        {
            displayName: "OREC",
            fieldName: "OREC",
            className: "text-center",
            order: 6
        },
        {
            displayName: "RAStatus",
            fieldName: "RaStatus",
            className: "text-center",
            order: 7
        },
        {
            displayName: "HCC Model",
            fieldName: "HccType",
            className: "text-center",
            order: 8
        },
        {
            displayName: "HCC",
            fieldName: "Hcc",
            className: "text-center",
            order: 9
        },
        {
            displayName: "Chronic / Acute",
            fieldName: "isChronic",
            className: "text-center",
            order: 10
        },
        {
            displayName: "Gap Type",
            fieldName: "gapType",
            className: "text-center",
            order: 11
        },
        {
            displayName: "Suspect Type",
            fieldName: "suspectType",
            className: "text-center",
            order: 12
        },
        {
            displayName: "Risk Score",
            fieldName: "RiskScore",
            className: "text-center",
            order: 13
        },
        {
            displayName: "RA Gain",
            fieldName: "raGain",
            className: "text-center",
            order: 14
        }
    ];
};

const defaultChaseListResultColumns = function() {
    return [
        {
            displayName: "Ranking",
            fieldName: "Ranking",
            className: "text-center",
            customComponent: "",
            sortDirection: "asc",
            sortOrder: 1,
            order: 0
        },
        {
            displayName: "Provider NPI",
            fieldName: "ProviderNPI",
            className: "text-center",
            sortDirection: "",
            order: 1
        },
        {
            displayName: "Provider Name",
            fieldName: "ProviderName",
            className: "text-center",
            order: 2
        },
        {
            displayName: "Provider Specialty",
            fieldName: "ProviderSpecialty",
            className: "text-center",
            order: 3
        },
        {
            displayName: "Provider Address 1",
            fieldName: "ProviderAddress1",
            className: "text-center",
            order: 4
        },
        {
            displayName: "Provider Address 2",
            fieldName: "ProviderAddress2",
            className: "text-center",
            order: 5
        },
        {
            displayName: "Provider City",
            fieldName: "ProviderCity",
            className: "text-center",
            order: 6
        },
        {
            displayName: "Provider State",
            fieldName: "ProviderState",
            className: "text-center",
            order: 7
        },
        {
            displayName: "Chase Type",
            fieldName: "ChaseType",
            className: "text-center",
            order: 8
        },
        {
            displayName: "Member ID",
            fieldName: "MemberId",
            className: "text-center",
            order: 9
        },
        {
            displayName: "Member Name",
            fieldName: "MemberName",
            className: "text-center",
            order: 10
        },
        {
            displayName: "HCC",
            fieldName: "Hcc",
            className: "text-center",
            order: 11
        },
        {
            displayName: "Risk Score",
            fieldName: "RiskScore",
            className: "text-center",
            order: 12
        },
        {
            displayName: "Max RA Gain",
            fieldName: "MaxRaGain",
            className: "text-center",
            order: 13
        }
    ];
};

const defaultChaseListResultStatsColumns = function() {
    return [{
            displayName: "Provider Type",
            fieldName: "ProviderType",
            className: "text-center",
            customComponent: "",
            sortDirection: "",
            order: 0,
        },
        {
            displayName: "Distinct Chases",
            fieldName: "DistinctChaseCount",
            className: "text-center",
            order: 1
        },
        {
            displayName: "Average RA Gain",
            fieldName: "AverageRaGain",
            className: "text-center",
            order: 2
        }
    ];
};

const defaultGapChaseListResultStatsMemberCountByMetalLevelColumns = function() {
    return [{
            displayName: "Metal Level",
            fieldName: "MetalLevel",
            className: "text-center",
            customComponent: "",
            sortDirection: "",
            sortable: false,
            order: 0,
        },
        {
            displayName: "Distinct Members",
            fieldName: "MemberCount",
            className: "text-center",
            sortable: false,
            order: 1
        }
    ];
};

const defaultGapChaseListResultStatsMemberCountByGenderColumns = function() {
    return [{
            displayName: "Gender",
            fieldName: "Gender",
            className: "text-center",
            customComponent: "",
            sortDirection: "",
            sortable: false,
            order: 0,
        },
        {
            displayName: "Distinct Members",
            fieldName: "MemberCount",
            className: "text-center",
            sortable: false,
            order: 1
        }
    ];
};

const defaultGapChaseListResultStatsMemberCountByPlanIDColumns = function() {
    return [{
            displayName: "Plan ID",
            fieldName: "PlanID",
            className: "text-center",
            customComponent: "",
            sortDirection: "",
            sortable: false,
            order: 0,
        },
        {
            displayName: "Distinct Members",
            fieldName: "MemberCount",
            className: "text-center",
            sortable: false,
            order: 1
        }
    ];
};

const defaultGapChaseListResultStatsChaseCountByProviderTypeColumns = function() {
    return [{
            displayName: "Provider Type",
            fieldName: "ProviderType",
            className: "text-center",
            customComponent: "",
            sortDirection: "",
            sortable: false,
            order: 0,
        },
        {
            displayName: "Chases",
            fieldName: "ChaseCount",
            className: "text-center",
            sortable: false,
            order: 1
        }
    ];
};

const inclusionExclusionListColumns = function() {
    return [{
            displayName: `List Name`,
            fieldName: "listName",
            className: "text-center col-lg-3",
            customComponent: "",
            sortDirection: "",
            order: 0,
        },
        {
            displayName: `List Type`,
            fieldName: "listType",
            className: "text-center col-lg-2",
            customComponent: "",
            sortDirection: "",
            order: 1,
        },
        {
            displayName: "Status",
            fieldName: "raListStatusID",
            className: "text-center col-lg-2",
            order: 2
        },
        {
            displayName: "Created By",
            fieldName: "createdBy",
            className: "text-center col-lg-3",
            order: 3
        },
        {
            displayName: "Created On",
            fieldName: "createdDatetime",
            className: "text-center col-lg-1",
            order: 4,
            sortDirection: "desc",
            sortOrder: 1
        },
        {
            displayName: "",
            fieldName: "action",
            className: "text-center col-lg-1",
            order: 5,
            sortable: false
        }
    ];
};

const defaultGapListResultStatsMemberCountByPlanColumns = function () {
    return [{
        displayName: "Plan Name",
        fieldName: "PlanName",
        className: "text-center",
        customComponent: "",
        sortDirection: "asc",
        sortOrder: 1,
        order: 0,
    },
    {
        displayName: "Historical Gaps",
        fieldName: "HistoricalGaps",
        className: "text-center",
        order: 1
    },
    {
        displayName: "Members W/ Historical Gaps",
        fieldName: "MembersHistoricalGaps",
        className: "text-center",
        order: 2
    },
    {
        displayName: "Suspect Gaps",
        fieldName: "SuspectGaps",
        className: "text-center",
        order: 3
    },
    {
        displayName: "Members W/ Suspect Gaps",
        fieldName: "MembersSuspectGaps",
        className: "text-center",
        order: 4
    },
    {
        displayName: "Total Members",
        fieldName: "TotalMembers",
        className: "text-center",
        order: 5
    },
    {
        displayName: "Average Gaps Per Member",
        fieldName: "AverageGapsPerMember",
        className: "text-center",
        order: 6
    }

    ];
};

const defaultGapListResultStatsMemberCountByRAStatus = function () {
    return [{
        displayName: "RA Status",
        fieldName: "RAStatus",
        className: "text-center",
        customComponent: "",
        sortDirection: "asc",
        sortOrder: 1,
        order: 0,
    },
    {
        displayName: "Historical Gaps",
        fieldName: "HistoricalGap",
        className: "text-center",
        order: 1
    },
    {
        displayName: "Members W/ Historical Gaps",
        fieldName: "MembersHistoricalGaps",
        className: "text-center",
        order: 2
    },
    {
        displayName: "Suspect Gaps",
        fieldName: "SuspectGap",
        className: "text-center",
        order: 3
    },
    {
        displayName: "Members W/ Suspect Gaps",
        fieldName: "MembersSuspectGaps",
        className: "text-center",
        order: 4
    },
    {
        displayName: "Total Members",
        fieldName: "TotalMemberCount",
        className: "text-center",
        order: 5
    },
    {
        displayName: "Average Gaps Per Member",
        fieldName: "AverageGapsPerMember",
        className: "text-center",
        order: 6
    }
    ];
};

const defaultChaseListResultStatsMemberCountByPlanColumns = function () {
    return [{
        displayName: "Plan ID",
        fieldName: "PlanID",
        className: "text-center",
        customComponent: "",
        sortDirection: "asc",
        sortOrder: 1,
        order: 0,
    },
    {
        displayName: "Distinct Members",
        fieldName: "DistinctMembers",
        className: "text-center",
        order: 1
    },
    {
        displayName: "PCP Chases",
        fieldName: "PCPChases",
        className: "text-center",
        order: 2
    },
    {
        displayName: "PCP Like Chases",
        fieldName: "PCPLikeChases",
        className: "text-center",
        order: 3
    },
    {
        displayName: "Specialist Chases",
        fieldName: "SpecialistChases",
        className: "text-center",
        order: 4
    },
    {
        displayName: "Facility Chases",
        fieldName: "FacilityChases",
        className: "text-center",
        order: 5
    },
    {
        displayName: "Avg RA Gain Per Chase",
        fieldName: "AverageRAGainPerChase",
        className: "text-center",
        order: 6
    },
    {
        displayName: "Avg Chases Per Member",
        fieldName: "AverageChasesPerMember",
        className: "text-center",
        order: 7
    } 
    ];
};

export { listColumns, 
        defaultGapListResultColumns, 
        defaultChaseListResultColumns, 
        defaultChaseListResultStatsColumns, 
        defaultGapChaseListResultStatsMemberCountByMetalLevelColumns, 
        defaultGapChaseListResultStatsMemberCountByGenderColumns, 
        defaultGapChaseListResultStatsMemberCountByPlanIDColumns,
        defaultGapChaseListResultStatsChaseCountByProviderTypeColumns,
        inclusionExclusionListColumns,
        defaultGapListResultStatsMemberCountByPlanColumns,
        defaultGapListResultStatsMemberCountByRAStatus,
        defaultChaseListResultStatsMemberCountByPlanColumns         
    };
