
import React, { Component } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import { Content } from '../../PageLayout';
import { download } from '../../../utils/File';
import { renderMarkets } from '../../../utils/CommonReactTable';
import GapMemberSummaryTable from './GapMemberSummaryTable';
import GapMemberDetailTable from './GapMemberDetailTable';
import GapPCPSummaryTable from './GapPCPSummaryTable';
import GapPCPDetailTable from './GapPCPDetailTable';
import * as Constants from './Constants';

import { connect } from "react-redux";
import {
	mapGapProfileDispatchToProps,
	mapGapProfileToProps
} from "../../../redux/actions";


/*export default*/

class GapProfileReport extends Component {
	constructor(props) {
		super(props);

		let filter = {
			year: new Date().getFullYear(),
			hiosId: "All",
			market: "All",
			defaultFilter: true,
		};

		this.state = {
			filter,
			years: [],
			hiosIds: [],
			markets: [],
			viewBy: Constants.VIEW_BY.Member,
			viewType: Constants.VIEW_TYPE.Master,
			goBackViewBy: Constants.VIEW_BY.Member,
			searched: false,
			data: [],
			results: [],
			memberMasterID: 0,
			providerMasterID: 0,
			pageCount: 0,
			columnSorting:[],
			totalItemCount:0  
		}

		this.checkAndSetFromState = this.checkAndSetFromState.bind(this);

	}

	componentDidMount() {

		axios.get('/GapProfile/Options').then((response) => {
			const { data } = response;
			const { filter } = this.state;
			const years = data.map(x => x.Year);

			this.setState({ data, years }, () => this.yearChange(filter.year));
		}).catch((err) => {
			console.warn(err);
			toastr.error('An error occurred retrieving filter options.');
		});

	}

	shouldComponentUpdate(nextProps, nextState) {
		return true;
	}

	checkAndSetFromState = () => {
		console.log('checkAndSetFromState');
		const { filter, pagination } = this.props.gapProfile;

		console.log('checkAndSet ' + JSON.stringify(this.props.gapProfile));

		// If filter.defaultFilter in redux is true, we have defaultGapProfile
		if (filter.defaultFilter) {
			this.props.resetGapProfile();
		}

		// If !filter.defaultFilter, which means we have the state which is NOT defaultGapProfile 
		// We can get stored states from props, and set them to current state.

		else {

			this.setState({ filter }, () => this.processSearch( pagination ));

		}
	};

	setupGapProfile = ( event ) => {

		// get pagination from event from master component
		const { filter } = this.state;
		const { pagination } = event;

		filter.defaultFilter = false;

		const gapProfile = {
			filter,
			pagination
		};


		console.log(' *** setupGapProfile gepProfile = ' + JSON.stringify(gapProfile));
		console.log(' *** setupGapProfile event = ' + JSON.stringify(event));

		this.props.setGapProfile(gapProfile);


	};

	handleYearChange = (event) => {
		const { value } = event.target;
		this.yearChange(value);
	}

	yearChange = (year) => {
		const { data } = this.state;

		const hiosIds = this.getHiosIdInput(year);
		const markets = this.getMarketInput(data);


		let hiosId = 'All';
		let market = 'All';

		if (hiosIds.length === 1) {
			hiosId = hiosIds[0];
		}

		if (markets.length === 1) {
			market = markets[0];
		}


		const filter = this.state.filter;

		filter.year = parseInt(year);
		filter.hiosId = hiosId;
		filter.market = market;

		console.log('yearChange filter = ' + JSON.stringify(filter));
		this.setState({ filter, hiosIds, markets });


	}

	getMarketInput = (data) => {
		const markets = data.map(x => x.Markets)[0];

		return markets;
	}

	getHiosIdInput = (year) => {
		const { data } = this.state;
		const hiosIds = data.filter(x => x.Year === parseInt(year)).map(y => y.HiosIds)[0];

		return hiosIds;
	}

	handleHiosIdChange = (event) => {
		const filter = this.state.filter;

		filter.hiosId = event.target.value;

		this.setState({ filter });
	}

	handleMarketChange = (event) => {
		const filter = this.state.filter;

		filter.market = event.target.value;

		this.setState({ filter });
	}

	handleViewByChange = (event) => {

		const viewBy = parseInt(event.target.value);
		const viewByDescrip = Object.keys(Constants.VIEW_BY).find(key => Constants.VIEW_BY[key] === viewBy);

		//const sortKey = viewByDescrip === "PCP" ? "ProviderID" : "MemberID";

		//this.setState({ viewBy, sortKey, searched: false });
		this.setState({ viewBy, searched: false })
	}

	processSearch = ( pagination ) => {
		const { filter, columnSorting } = this.state;
		const	viewBy = this.state.viewBy;

		this.setState({ searched: false });


		const params = {
			Year: filter.year,
			hiosID: filter.hiosId,
			market: filter.market,

			pageNumber: pagination.pageNumber,
			pageSize: pagination.pageSize,
			columnSorting
		};

		let viewByText = Object.keys(Constants.VIEW_BY).find(key => Constants.VIEW_BY[key] === viewBy);

		// filters need unique url for user activity
		axios.get('/GapProfile/' + viewByText, { params }).then((response) => {
			pagination.pageCount = response.data.PageCount;

			this.setState({ searched: true, results: response.data, viewBy, masterPagination: pagination, totalItemCount: response.data.TotalItemCount });
		}).catch((error) => {
			console.log(error);
		})


	}

	searchClick = (event) => {
		event.preventDefault();

		// Every click of submit, with possible filter change, should set pageNumber = 1
		var pagination = {
			pageNumber: 1,
			pageSize: 10,
			pageCount: 0
		}
		
		
		this.processSearch( pagination );

	}

	handleExportClick = (event) => {
		// event.preventDefault();

		// !!! here, get pagination from event (from master), not state from shell
		const { filter, viewBy } = this.state;
		const { pagination } = event;


		// note: state sortKey and sortDirection maintained by handleSortChange
		const params = {
			Year: filter.year,
			hiosID: filter.hiosId,
			market: filter.market,
			ViewBy: Object.keys(Constants.VIEW_BY).find(key => Constants.VIEW_BY[key] === viewBy),
		};

		axios.get('/GapProfile/ExportSummary', { params, responseType: 'blob' }).then((response) => {

			const fileName = response.headers['x-suggested-filename'] || 'ResultsExtract_' + new Date().getTime() + '.txt';
			download( response.data, fileName );

		}).catch((error) => {
			console.error(error);
			toastr.error('Failed to export data.');
		});

	}

	handleMemberChange = (event) => {
		this.setState({ searched: false });

		const { memberMasterID, goBackViewBy } = event;
		// here, get pagination from event (from master), not state from shell
		// since pagination is stored with the component 
		const { pagination } = event;
		const { filter } = this.state;


		const params = {
			memberMasterID,
			specYearStart: filter.year,
			specYearEnd: filter.year,
			hiosID: filter.hiosId,
			market: filter.market,

			pageNumber: pagination.pageNumber,	// called when clicking + for detail
			pageSize: pagination.pageSize,
			sortKey: pagination.sortKey,
			sortDirection: pagination.sortDirection
		};

		// run member query to get member data
		axios.get('/GapProfile/MemberGapDetail', { params }).then((response) => {
			this.setState({
				searched: true, results: response.data, memberMasterID
				, viewBy: Constants.VIEW_BY.Member, viewType: Constants.VIEW_TYPE.Detail, goBackViewBy
			});
		}).catch((error) => {
			console.log(error);
		})
	}

	handleProviderChange = (event) => {
		this.setState({ searched: false });

		const { providerMasterID, goBackViewBy } = event;
		// here, get pagination from event (from master), not state from shell 
		// since pagination is stored with the component 
		const { pagination } = event;
		const { filter } = this.state;

		// pagination.sortKey = 'MEMBERID';
		// pagination.sortDirection = 'ASC';

		const params = {
			providerMasterID,
			specYearStart: filter.year,
			specYearEnd: filter.year,

			pageNumber: pagination.pageNumber,	// called when clicking + for detail
			pageSize: pagination.pageSize,
			sortKey: pagination.sortKey,
			sortDirection: pagination.sortDirection
		};

		// run member query to get member data
		axios.get('/GapProfile/PcpGapDetail', { params }).then((response) => {
			this.setState({
				searched: true, results: response.data, providerMasterID
				, viewBy: Constants.VIEW_BY.PCP, viewType: Constants.VIEW_TYPE.Detail, goBackViewBy
			});
		}).catch((error) => {
			console.log(error);
		})
	}

	// handleBack = (event) => {
	// 	this.setState({ searched: false, viewType: Constants.VIEW_TYPE.Master }, this.processSearch(event.viewBy));
	// }

	handleBack = (event) => {

		if (event.viewBy == Constants.VIEW_BY.PCP && event.MemberDetail) {
			const { providerMasterID } = this.state;

			const providerArgs = {
				providerMasterID, pagination:event.pagination
				, goBackViewBy: Constants.VIEW_BY.PCP
			};

			this.setupGapProfile(providerArgs);
			this.handleProviderChange(providerArgs);
		}
		else {
			this.setState({ searched: false, viewType: Constants.VIEW_TYPE.Master, viewBy: event.viewBy }, () => this.checkAndSetFromState());
		}

		//this.setState({ searched: false, viewType: Constants.VIEW_TYPE.Master, viewBy: event.viewBy }, () => this.checkAndSetFromState());


	}

	handleSortChange = (event) => {

		// No longer set state.pagination here. It's stored in GapMemberSummaryTable and GapPCPSummaryTable
		// console.log( JSON.stringify( event ));
		
	}

	render() {


		const { hiosIds, markets: markets, viewBy, viewType, goBackViewBy, years, searched, results, memberMasterID, providerMasterID
			, filter, masterPagination, totalItemCount } = this.state;

		const { handleYearChange, handleHiosIdChange, handleMarketChange, handleViewByChange, searchClick, handleExportClick
			, handleSortChange, handleMemberChange, handleProviderChange, setupGapProfile } = this;


		return (
			<React.Fragment>
				{
					viewType === Constants.VIEW_TYPE.Master &&
					<Content>
						<Content.Header>Gap Profile Report</Content.Header>
						<Content.Body>
							{
								years.length > 0 && viewType === Constants.VIEW_TYPE.Master &&
								<Content.Filter>
									<div className="row">
										<div className="col-sm-12">
											<form className="form-inline">
												<div className="form-group">
													<label className="control-label">Benefit Year:</label>
													<select className="form-control" value={filter.year} onChange={handleYearChange}>
														{
															years.map(x => <option key={x} value={x}>{x}</option>)
														}
													</select>
												</div>
												<div className="form-group">
													<label className="control-label">HIOS ID:</label>
													<select className="form-control"
														value={filter.hiosId}
														onChange={handleHiosIdChange}
													>
														{
															hiosIds.map(x => <option key={x} value={x}>{x}</option>)
														}
													</select>
												</div>
												<div className="form-group">
													<label className="control-label">Market:</label>
													<select className="form-control"
														value={filter.market}
														onChange={handleMarketChange}
													>
														{
															markets.map(x => <option key={x} value={x}>{renderMarkets(x)}</option>)
														}
													</select>
												</div>
											</form>
										</div>
									</div>
									<div className="row">
										<div className="col-sm-12">
											<form className="form-inline">
												<div className="form-group">
													<label className="control-label radio-label">View By:</label>
													{
														Object.keys(Constants.VIEW_BY).map((y, i) => {
															return (
																<label className="radio-inline" key={'view-by-' + i}>
																	<input type="radio"
																		value={Constants.VIEW_BY[y]}
																		checked={viewBy === Constants.VIEW_BY[y]}
																		onChange={handleViewByChange} />
																	{y}
																</label>
															)
														})
													}
												</div>
												<button className="btn btn-tessellate"
													onClick={searchClick}
												>Submit</button>
											</form>
										</div>
									</div>
								</Content.Filter>
							}
							{
								searched &&
								<span>
									<Content.Result>
										<div className="row">
											<div className="col-sm-12">
												{
													viewBy === Constants.VIEW_BY.Member &&
													<GapMemberSummaryTable results={results} onMemberChange={handleMemberChange} onSortChange={handleSortChange}
														filter={filter} onDetail={setupGapProfile} onExport={handleExportClick} masterPagination={masterPagination}  noDataText={(<span>No Results Found</span>)}/>
												}
												{
													viewBy === Constants.VIEW_BY.PCP &&
													<GapPCPSummaryTable results={results} onProviderChange={handleProviderChange} onSortChange={handleSortChange}
														filter={filter} onDetail={setupGapProfile} onExport={handleExportClick}  masterPagination={masterPagination}  noDataText={(<span>No Results Found</span>)}/>
												}
												<div><strong>Total Number of Records: {totalItemCount}</strong></div>
											</div>
											
										</div>
									</Content.Result>
								</span>
							}
						</Content.Body>
					</Content>
				}
				{
					searched &&
					viewBy === Constants.VIEW_BY.Member &&
					viewType === Constants.VIEW_TYPE.Detail &&
					<GapMemberDetailTable results={results} memberMasterID={memberMasterID}
						filter={filter} onBack={this.handleBack} goBackViewBy={goBackViewBy} />
				}
				{
					searched &&
					viewBy === Constants.VIEW_BY.PCP &&
					viewType === Constants.VIEW_TYPE.Detail &&
					<GapPCPDetailTable results={results} providerMasterID={providerMasterID}
						filter={filter} onBack={this.handleBack} onMemberChange={handleMemberChange} />
				}
			</React.Fragment>
		);
	}
}

export default connect(

	mapGapProfileToProps,
	mapGapProfileDispatchToProps

)(GapProfileReport);