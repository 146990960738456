import React, { Component } from "react";
import axios from "axios";
import toastr from "toastr";
import { Content } from "../../PageLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ClientList } from "../../../utils/ClientList";
import Toggle from "react-bootstrap-toggle";

export default class CreateNewJob extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filter: {
                specYear: 0,
                jobTypeId: 0,
                triggerUser: "",
                RARSDForMmIdActive: false,
                refreshMRRDataActive: true
            },
            years: [],
            clientName: "",
            jobTypeIds: [],
            errors: {
                year: "",
                jobType: "",
                triggerUser: ""
            },
            errorCount: 0
        };
    }

    componentDidMount() {
        const { filter } = this.state;
        const year = new Date().getFullYear();

        axios
            .get("/v1/job/options")
            .then(response => {
                const { data } = response;
                this.setState({
                    years: data.Years,
                    clientId: data.ClientId,
                    clientName: ClientList[data.ClientId],
                    jobTypeIds: data.JobTypeIds,
                    filter: {
                        ...filter,
                        specYear: year,
                        jobTypeId: data.JobTypeIds["RiskJob"]
                    }
                });
            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false, data: [] });
                toastr.error("An error occurred retrieving job options.");
            });
    }

    handleFilterChange = key => event => {
        event.preventDefault();

        this.validateAndSetFilter(key, event.target);
    };

    handleToggleChange = key => event => {
        const { filter } = this.state;
        let toggled = !filter[key];
        this.setState({ filter: { ...filter, [key]: toggled } });
    };

    handleSubmitClicked = event => {
        event.preventDefault();
        const { filter, clientId, errors } = this.state;

        const params = {
            year: filter.specYear,
            clientId,
            jobTypeId: filter.jobTypeId,
            triggerUser: filter.triggerUser,
            useRARSD: filter.RARSDForMmIdActive,
            RefreshMRRData: filter.refreshMRRDataActive
        };

        if (this.countErrors(errors) === 0) {
            axios
                .post("/v1/Job/CreateNewJob", params)
                .then(response => {
                    if (response.status === 200) {
                        toastr.success("New Job Request Created!");
                    }
                })
                .catch(() => {
                    toastr.error("Error Submit");
                });
        }
    };

    validateAndSetFilter = (key, target) => {
        const { filter } = this.state;
        const { value } = target;
        let errors = this.state.errors;

        switch (key) {
            case "specYear":
                const currentYear = new Date().getFullYear();
                if (currentYear < value || currentYear - 2 > value) {
                    errors.year = "Year is not valid";
                }
                break;
            case "triggerUser":
                errors.triggerUser =
                    value === null || value.trim().length === 0
                        ? "TriggerUser cannot be empty."
                        : "";

                break;
            case "jobTypeId":
                if (value.length < 1) {
                    errors.jobType = "JobType is not Valid";
                }
                break;
            default:
                break;
        }

        this.setState({ errors, filter: { ...filter, [key]: value } });
    };

    countErrors = errors => {
        let count = 0;
        Object.values(errors).forEach(
            val => val.length > 0 && (count = count + 1)
        );
        return count;
    };

    render() {
        const { filter, years, clientName, jobTypeIds, errors } = this.state;
        const {
            handleSubmitClicked,
            handleFilterChange,
            handleToggleChange
        } = this;

        return (
            <React.Fragment>
                <div className="row createJob-spacing">
                    <button
                        className="btn btn-success  pull-right"
                        type="button"
                        data-toggle="collapse"
                        data-target="#createNewJob"
                        aria-expanded="false"
                        aria-controls="createNewJob"
                    >
                        <FontAwesomeIcon icon="plus-circle" fixedWidth />
                        <span>Create New Job</span>
                    </button>
                </div>
                <div className="collapse" id="createNewJob">
                    <Content.Filter>
                        <div className="card card-body">
                            <form className="needs-validation" noValidate>
                                <div className="form-row" id="createNewJob">
                                    <div
                                        className="col-sm-12 col-md-2"
                                        style={{ textAlign: "center" }}
                                    >
                                        <div className="row">
                                            <label>Client Name:</label>
                                        </div>
                                        <div className="row">
                                            <span className="clientName">
                                                {clientName}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-2">
                                        <label>Year:</label>
                                        <select
                                            className="form-control"
                                            value={filter.specYear}
                                            onChange={handleFilterChange(
                                                "specYear"
                                            )}
                                        >
                                            <option value={0} disabled>
                                                Select
                                            </option>
                                            {years.map(x => (
                                                <option key={x} value={x}>
                                                    {x}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-sm-12 col-md-2">
                                        <label>Job Type:</label>
                                        <select
                                            className="form-control"
                                            value={filter.jobTypeId}
                                            onChange={handleFilterChange(
                                                "jobTypeId"
                                            )}
                                        >
                                            <option value={0} disabled>
                                                Select
                                            </option>
                                            {Object.keys(jobTypeIds).map(
                                                (key, value) => {
                                                    return (
                                                        <option
                                                            key={key}
                                                            value={
                                                                jobTypeIds[key]
                                                            }
                                                        >
                                                            {key}
                                                        </option>
                                                    );
                                                }
                                            )}
                                        </select>
                                    </div>
                                    <div className="col-sm-12 col-md-2">
                                        <label>Trigger User* :</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Trigger User"
                                            value={filter.triggerUser}
                                            onChange={handleFilterChange(
                                                "triggerUser"
                                            )}
                                            onBlur={handleFilterChange(
                                                "triggerUser"
                                            )}
                                            required
                                        />
                                        {errors.triggerUser.length > 0 && (
                                            <span className="error">
                                                {errors.triggerUser}
                                            </span>
                                        )}
                                    </div>
                                    <div className="col-sm-12 col-md-4">
                                        <div className="row">
                                            <label className="col-md-8">
                                                Use RARSD for MemberMasterIds?
                                            </label>
                                            <div className="col-md-4">
                                                <Toggle
                                                    onClick={handleToggleChange(
                                                        "RARSDForMmIdActive"
                                                    )}
                                                    on="Yes"
                                                    off="No"
                                                    size="sm"
                                                    width="48px"
                                                    height="24px"
                                                    onstyle="primary"
                                                    offstyle="default"
                                                    active={
                                                        filter.RARSDForMmIdActive
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <label className="col-md-8">
                                                Refresh MRR Data?
                                            </label>
                                            <div className="col-md-4">
                                                <Toggle
                                                    onClick={handleToggleChange(
                                                        "refreshMRRDataActive"
                                                    )}
                                                    on="Yes"
                                                    off="No"
                                                    size="sm"
                                                    width="48px"
                                                    height="24px"
                                                    onstyle="primary"
                                                    offstyle="default"
                                                    active={
                                                        filter.refreshMRRDataActive
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="form-row pull-right"
                                    id="createNewJob"
                                >
                                    <div className="col-sm-12">
                                        <button
                                            disabled={
                                                filter.triggerUser.length < 1
                                            }
                                            type="submit"
                                            className="btn btn-primary"
                                            onClick={handleSubmitClicked}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </Content.Filter>
                </div>
            </React.Fragment>
        );
    }
}
