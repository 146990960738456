import { GapChaseFilterBase, filterType, dataType } from '../GapChaseFilterBase';
import Axios from 'axios';

export default class MetalLevelIdsFilter extends GapChaseFilterBase {
    constructor(metalLevels) {
        super();
        this.id = 10;
        this.name = "Metal Level";
        this.filterName = "MetalLevelIds";

        // The following two lines make this filter LOB aware
        const validFilterLOBs = ["Commercial"];
        this.isHidden = validFilterLOBs.indexOf(localStorage.getItem('Lob')) < 0;

        this.filterType = filterType.multiSelect;
        this.values = metalLevels;

        this.dataType = dataType.number;
        this.filterCategory = "Member Level";
        this.filterGroupId = 2;
        this.placeholder = "Metal Level";

    }
  
}
