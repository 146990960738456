import YearFilter from '../filters/gap/YearFilter';
import HccsFilter from '../filters/gap/HccsFilter';
import RaGainFilter from '../filters/gap/RaGainFilter';
import GapTypesFilter from '../filters/gap/GapTypesFilter';
import HccTypesFilter from '../filters/gap/HccTypesFilter';
import SuspectTypeIdsFilter from '../filters/gap/SuspectTypeIdsFilter';
// import React, { Component } from 'react'


export default class GapLevelFilterGroup {
    constructor(gapLevelData){
        // super(props);
        this.categoryName = "Gap Level";
        this.isHidden = false;

        this.filters = [];
        let gapYearFilter = new YearFilter();
        let hccsFilter = new HccsFilter();
        let raGainFilter = new RaGainFilter();
        let gapTypesFilter = new GapTypesFilter();
        let hccTypesFilter = new HccTypesFilter();
        let suspectTypeFilter = new SuspectTypeIdsFilter(gapLevelData.SuspectTypes);

        this.filters.push(gapYearFilter, hccsFilter, raGainFilter, gapTypesFilter, hccTypesFilter, suspectTypeFilter);

    }


/*    render() {
        return (
            // <div class="panel panel-default">
            // <div className="panel-heading" style="cursor: pointer;">
            //     <div className="panel-title">
            //         <div>
            //             Gap Level
            //             <span class="glyphicon glyphicon-menu-down" style="padding-right: 0.4rem; color: rgb(108, 113, 108); float: right;"></span>
            //         </div>
            //         <div class="panel-body" style="display: inherit;">
            //             <div class="col-lg-12" style="min-height: 128px;">
            <>
                            <GapYearFilter />
                            <HCCFilter />
                            <RAGainFilter />
                            <GapTypeFilter />
                            <HCCTypeFilter />
            </>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
        )
    }
    */
}

