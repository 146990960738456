import React from 'react';
import Axios from 'axios';
import { capitalize } from 'lodash';
import { Button, ButtonGroup, Modal, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import FilterPanel from './FilterPanel';
import { Error } from './Messages';

export default class FilterSidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listName: this.props.listName ? this.props.listName : "",
            showSaveModal: false,
            showSuspectType: null,
            showPCP: null,
            showTier1: null,
            showTier2: null,
            showFacility: null,
            showSpecialist: null,
            showChasesPerMember: null,
            savedFilterCategories: null,
            error: false,
            selectedFilters: []
        };
        this.inputRefs = [];
        this.activeInputElements = [];
        this.excludedOrIncludedFilterValues = [];
        //this.selectedFilters =[];

        //set after initial filter load & setting of any saved filter values
        this.initialSet = false;

        const CancelToken = Axios.CancelToken;
        this.source = CancelToken.source();
    }
    static getDerivedStateFromProps(props, state) {
        if (props.savedFilterSelections && state.selectedFilters.length  === 0) {
            state.selectedFilters = props.savedFilterSelections;
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.listName !== this.props.listName && this.props.listName === "") {
            this.setState({ listName: nextProps.listName });
        }
        if (!this.initialSet && nextProps.filters && !nextProps.savedFilterSelections) {
            //set saved selections after getting filters on initial load (create / sidebar-only view)
            this.setSavedFilterSelections(nextProps.filters, this.defaultFilterSelections);
            this.initialSet = true;
        }
        if (!this.initialSet && nextProps.filters && nextProps.savedFilterSelections) {
            //set saved selections after getting filters on initial load (on select of existing list)
            this.setSavedFilterSelections(nextProps.filters, nextProps.savedFilterSelections);
            this.initialSet = true;
        }
        else if (!this.initialSet && nextProps.filters && nextProps.selectedGapId) {
            //sets selected gap list on initial load when "Create Chase List" button is selected from final gap list
            this.setSelectedGapID(nextProps.filters, nextProps.selectedGapId);
            this.initialSet = true;
        }
    }

    componentWillUnmount() {

        this.source.cancel();
        //this.handleClearFilters();
    }

    setSelectedGapID = (filters, selectedGapID) => {
        const initialFilters = filters !== null ? filters.find(x => x.categoryName === "Chase Level") : [];
        const gapListFilter = initialFilters.filters.find((filter) => { return filter.id === 18 });
        if (gapListFilter) {
            this.handleFilterChange(gapListFilter, "eq", selectedGapID, null);
        }
    }

    //add saved filter selections to current selection lists
    setSavedFilterSelections = (filters, savedFilterSelections) => {
        const savedCategories = [];
        let foundFilter;

        //todo: refactor this
        if (savedFilterSelections) {
            //merge filter groups
            const allFilters = this.flattenFilterGroups(filters);

            //search filters by id; find filter group name; update current selections
            for (let savedSelection of savedFilterSelections) {
                foundFilter = allFilters.find(x => x.id === savedSelection.filterId);
                if (foundFilter) {
                    const category = foundFilter.filterCategory;
                    if (!savedCategories.includes(category)) {
                        savedCategories.push(category);
                    }

                    this.handleFilterChange(foundFilter, savedSelection.operator, savedSelection.value, null, savedSelection.id);
                }
            }

            this.setState({ savedFilterCategories: savedCategories });
        }
    }

    //todo: consolidate with instance in <ListContainer />
    //aggregate filter group arrays into a single array
    flattenFilterGroups(filters) {
        return filters.reduce((acc, group) => {
            group.filters.forEach(filter => acc.push(filter));
            return acc;
        }, [])
    }

    //toggle dependent filters (suspect type, pcp, specialist, facility)
    toggleFilterDisplay = (stateProp, bool) => {
        this.setState({ [stateProp]: bool });
    }

    //clear input elements and filter selections via an array of filter ids
    clearInputFieldsAndFilterSelections = (filterIDs) => {
        let self = this;
        //todo: is this needed?
        filterIDs.forEach(id => {
            const targetInput = self.inputRefs.find(ref => ref.filterId === id);
            if (targetInput && targetInput.input) {
                targetInput.input.clear();
            }
        });

        return this.state.selectedFilters.filter(x => !filterIDs.includes(x.filterId));
    }

    //set filter selection
    handleFilterChange = (filter, operator, value, input, id = 0) => {
        let _this = this; //JSON.parse(JSON.stringify(this));
        let indexToUpdate;
        if (_this.props.listType === "Gap") {
            if (filter.id === 1) {
                if (value.length > 0) {
                    // load HCC options
                    _this.props.getFilter("hcc", value, this.activeInputElements);
                    // load PlanID options
                    _this.props.getFilter("planIds", value, this.activeInputElements);
                }
                else {
                    _this.state.selectedFilters = this.clearInputFieldsAndFilterSelections([2]);
                    _this.state.selectedFilters = this.clearInputFieldsAndFilterSelections([7]);
                    _this.props.clearFilterOptions(2);
                    _this.props.clearFilterOptions(7);
                }
            }
            // suspect type is displayed when a gap type of "Suspect" is selected
            if (filter.id === 4) {
                if (value && typeof (value) === 'object' && value.find(x => x.value === "Suspect")) {
                    //display suspect type
                    _this.toggleFilterDisplay('showSuspectType', true);
                }
                else {
                    //hide suspect type
                    _this.toggleFilterDisplay('showSuspectType', false);

                    //clear suspect type input field
                    //clear suspect type value from selectedFilters
                    _this.state.selectedFilters = _this.clearInputFieldsAndFilterSelections([6]);
                }
            }
            else if (filter.id === 12) {
                if (value != null && value.length > 0) {
                    // load county options when the state selection is updated
                    //this.props.getFilter(13, value, this.activeInputElements); //CountyFilter is currently hidden and getFilter() function has been changed. Revise this call if enabling.
                }
                else {
                    _this.state.selectedFilters = _this.clearInputFieldsAndFilterSelections([13]);

                    // clear county options when the state selection is empty
                    _this.props.clearFilterOptions(13);
                }
            }
        }
        else if (_this.props.listType === "Chase") {
            if (filter.id === 18) {
                if (value.length > 0) {
                    // load mrr project options when the gap list selection is updated
                    //this.props.getFilter(23, value, this.activeInputElements); //MrrProjectFilter is hidden and getFilter() changed.  Revise this call if enabling filter.
                }
                else {
                    _this.state.selectedFilters = _this.clearInputFieldsAndFilterSelections([23]);
                    _this.props.clearFilterOptions(23);
                }
            }

            //chase provider type selections enable pcp, specialist, and facility filters
            if (filter.id === 41 || filter.id === 43 || filter.id === 45 || filter.id === 53 || filter.id === 54) {
                switch (filter.id) {
                    case 41:
                        _this.toggleFilterDisplay('showPCP', value);
                        if (!value) _this.state.selectedFilters = _this.clearInputFieldsAndFilterSelections([26]);
                        break;
                    case 43:
                        _this.toggleFilterDisplay('showTier1', value);
                        if (!value) _this.state.selectedFilters = _this.clearInputFieldsAndFilterSelections([44]);
                        break;
                    case 45:
                        _this.toggleFilterDisplay('showTier2', value);
                        if (!value) _this.state.selectedFilters = _this.clearInputFieldsAndFilterSelections([46]);
                        break;
                    case 53:
                        _this.toggleFilterDisplay('showSpecialist', value);
                        if (!value) _this.state.selectedFilters = _this.clearInputFieldsAndFilterSelections([28, 29]);
                        break;
                    case 54:
                        this.toggleFilterDisplay('showFacility', value);
                        if (!value) _this.state.selectedFilters = this.clearInputFieldsAndFilterSelections([32, 33, 34]);
                        break;
                    default:
                }
            }

            //All Or Top Chases
            if (filter.id === 40) {
                this.toggleFilterDisplay('showChasesPerMember', (value !== "1" && value.length > 0));
            }
        }

        // remove empty/false checkbox selections -- no need to track these
        if (filter.filterType === "Checkbox" && !value) {
            _this.state.selectedFilters = _this.state.selectedFilters.filter(x => x.filterId !== filter.id);
        }
        else {
            //update or insert filter
            indexToUpdate = _this.state.selectedFilters.findIndex(x => x.filterId === filter.id);
            let raGains = [26, 44, 46, 28, 32];
            if (indexToUpdate !== -1) {
                if (value === "" || value === null || (Array.isArray(value) && value.length > 0 && raGains.indexOf(filter.id) >= 0)) {
                    if ((value !== "" && value != null) && value[0] === "" && value[1] === "")
                        _this.state.selectedFilters.splice(indexToUpdate, 1);
                    else
                        _this.state.selectedFilters[indexToUpdate].value = value;


                }
                else {
                    _this.state.selectedFilters[indexToUpdate].value = value;
                    _this.state.selectedFilters[indexToUpdate].name = filter.name;
                    if (_this.state.selectedFilters[indexToUpdate].operator !== operator) {
                        _this.state.selectedFilters[indexToUpdate].operator = operator;
                    }
                }
            }
            else {
                if (value !== "") {
                    if (!this.state.selectedFilters) this.state.selectedFilters = this.props.savedFilterSelections;
                    this.state.selectedFilters.push({
                        "id": id,
                        "filterId": filter.id,
                        "name": filter.name,
                        "operator": operator,
                        "value": value
                    });
                }
            }
        }

        //build filter selection string (for summary)
        _this.props.onSelectFilter(_this.state.selectedFilters);

        //store a ref to the input (used to clear values)
        if (input) {
            trackInput(input, filter);
        }

        function trackInput(input, filter) {
            if (_this.activeInputElements.length > 0) {
                //if the element hasn't been previously encountered, add it to the list of active elements
                if (!_this.activeInputElements.find(item => item.element.state.id === input.state.id)) {
                    updateElements();
                }
            }
            else {
                updateElements();
            }

            function updateElements() {
                const updatedElements = [..._this.activeInputElements];
                updatedElements.push({
                    'element': input,
                    'filterId': filter.id,
                });
                _this.activeInputElements = updatedElements;
            }
        }
    }

    //clear filters, set saved selections
    handleClearFilters = () => {
        if (this.props.filters) {
            //reset filter inputs
            this.activeInputElements.forEach((item) => {
                if (item.element) {
                    item.element.reset();
                }
            });

            let tempFilters = [...this.props.filters];
            if (this.props.listType === "Gap") {
                // reset county (dynamic filter)
                const countyFilter = tempFilters.find(x => x.categoryName === "Member Level").filters.find(obj => obj.id === 13);
                countyFilter.values = [];
            }
            else {
                // reset mrr project (dynamic filter)
                const mrrProjectFilter = tempFilters.find(x => x.categoryName === "Chase Level").filters.find(obj => obj.id === 23);
                mrrProjectFilter.values = [];
            }

            //reset local state
            this.setState({
                filters: tempFilters,
                showSuspectType: false,
                showPCP: null,
                showTier1: null,
                showTier2: null,
                showSpecialist: null,
                showFacility: null,
                selectedFilters:[]
            });
            this.activeInputElements = [];
            this.excludedOrIncludedFilterValues = [];
            //this.selectedFilters = [];

            //reset filter selections in parent state
            this.props.onClear();

            //set saved selections
            if (this.props.savedFilterSelections) {
                this.setSavedFilterSelections(this.props.filters, this.props.savedFilterSelections);
            }
        }
    }

    openSaveModal = () => {
        this.setState({ showSaveModal: true });
    }

    closeSaveModal = () => {
        this.setState({ showSaveModal: false });
        setTimeout(() => { this.setState({ saveModalValidationMessage: null }) }, 200);
    }

    //bind 'enter' to create/update button
    handleModalKeyPress = (event) => {
        if (event.key === 'Enter') {
            if (this.state.listName) {
                this.handleCreateOrUpdate();
            }
        }
    }

    //set list name
    handleListNameChange = (event) => {
        this.setState({ listName: event.target.value });
    }

    //display create/update validation message
    showSaveModalValidationMessage = (message) => {
        this.setState({ saveModalValidationMessage: message });
    }

    //compares saved filter selections to current filter selections.
    //returns an array containing any new filter selections
    getFilterChanges = () => {
        if (this.props.savedFilterSelections && this.props.savedFilterSelections.length > 0) {
            //join items missing from each set (selections in A not in B + selections in B not in A)
            return getDiff(this.state.selectedFilters, this.props.savedFilterSelections).concat(getDiff(this.props.savedFilterSelections, this.state.selectedFilters));
        }
        else {
            return this.state.selectedFilters;
        }

        function getDiff(firstArr, secondArr) {
            return firstArr.filter((item) => {
                const target = secondArr.filter(x => x.filterId === item.filterId);

                if (target.length > 0) {
                    if (typeof item.value === 'string') {
                        return target[0].value !== item.value.replace(' ', '');
                    }
                    else if (Array.isArray(item.value)) {
                        let arrayDiff = false;
                        target.forEach((target) => {
                            if (!item.value.includes(target.value)) {
                                arrayDiff = true;
                            }
                        })
                        return arrayDiff;
                    }
                    else {
                        return true;
                    }
                }
                else {
                    return true;
                }
            });
        }
    }

    //check if the list name has already been used
    checkUsedListName = (listType, listName) => {
        return new Promise((resolve, reject) => {
            Axios.get(`/v1/Lists/${listType}`, {
                cancelToken: this.source.token
            })
                .then((response) => {
                    if (response.status === 200) {
                        response.data.forEach((item) => {
                            if (listName !== this.props.listName && item.name.toLowerCase() === listName.toLowerCase()) {
                                return resolve(`Please enter an unused list name. "${listName}" has already been used by another ${listType} List.`);
                            }
                        })
                        return resolve();
                    }
                })
                .catch((error) => {
                    if (!Axios.isCancel(error)) {
                        // handle error
                        this.setState({ showError: true });
                    }
                    return reject("Error");
                });
        });
    }

    validateCreate = () => {
        if (this.state.listName != "") {
            return this.validateInitialSelections();
        }
        else {
            return "Filter selections and a list name must be entered.";
        }
    }

    validateUpdate = () => {
        if (this.getFilterChanges().length > 0 || this.props.listName != "") {
            this.state.listName = this.props.listName;
            return this.validateInitialSelections();
        }
        else {
            return "The current selections are the same as the existing selections. No changes were made.";
        }
    }

    validateInitialSelections = () => {
        let hasRequiredFilters = true;
        let hasRangeFilters = true;
        let errorMessages = "";

        if (this.props.listType === "Gap") {
            // gap year is required for gap lists
            const requiredFilterIDs = [1];
            let rangeFilterIDs = [];
            if (localStorage.Lob === "Commercial") {
                rangeFilterIDs = [3, 8, 15];
            } else {
                rangeFilterIDs = [3, 8, 49]
            }

            requiredFilterIDs.forEach((filter) => {
                if (!this.state.selectedFilters.find(x => x.filterId === filter)) {
                    hasRequiredFilters = false;
                }
            });
            rangeFilterIDs.forEach((filter) => {
                var min = document.getElementById(filter + '-1').value;
                var max = document.getElementById(filter + '-2').value;
                if (min !== "" && max !== "" && parseFloat(min) > parseFloat(max)) {
                    hasRangeFilters = false;
                }
            });

            errorMessages += hasRequiredFilters ? "" : "Gap Year is required.\n";
            errorMessages += hasRangeFilters ? "" : "Min Value must be less than Max Value.";
            return errorMessages;
        }
        else {
            // gap list is required for chase lists
            const requiredFilterIDs = [18];
            const requiredOneOfChaseTypesIDs = [41, 43, 45, 53, 54]; // [PCP Chase, Tier 1 Chase, Tier 2 Chase, Specialist Chase, Facility Chase]
            const rangeFilterIDs = [3, 19, 26, 28, 32, 44, 46];

            let hasChaseType = false;
            let hasChaseRankValid = true;

            requiredFilterIDs.forEach((filter) => {
                if (!this.state.selectedFilters.find(x => x.filterId === filter)) {
                    hasRequiredFilters = false;
                }
            });

            let self = this;
            requiredOneOfChaseTypesIDs.forEach((filter) => {
                if (!hasChaseType && this.state.selectedFilters.find(x => x.filterId === filter)) {
                    hasChaseType = true;
                }
            });
            rangeFilterIDs.forEach((filter) => {
                var min = document.getElementById(filter + '-1') === undefined || document.getElementById(filter + '-1') === null ? "" :document.getElementById(filter + '-1').value ;
                var max = document.getElementById(filter + '-2') === undefined || document.getElementById(filter + '-2') === null ? "" :document.getElementById(filter + '-2').value ;
                if (min !== "" && max !== "" && parseFloat(min) > parseFloat(max)) {
                    hasRangeFilters = false;
                }
                if (filter === 3 && (min === "0" || max === "0")) {
                    hasChaseRankValid = false;
                }
            });

            errorMessages += hasRequiredFilters ? "" : "Gap List is required.\n";
            errorMessages += hasChaseType ? "" : "Please select at least one Chase Type.\n"
            errorMessages += hasRangeFilters ? "" : "Min Value must be less than Max Value.\n";
            errorMessages += hasChaseRankValid ? "" : "The entered value is not a valid Chase Rank. Please input whole numbers equal to or greater than 1.";
            return errorMessages;

        }
    }

    handleCreateOrUpdate = () => {
        const uniquenessError = `Please enter an unused list name. "${this.state.listName}" has already been used by another ${this.props.listType} List.`;
        const validationMessage = !this.props.showUpdate ? this.validateCreate() : this.validateUpdate();

        if (!validationMessage) {
            if (!this.props.showUpdate) {
                this.props.onCreate(this.state.listName)
                    .then((response) => {
                        //set url to freshly-created list
                        this.props.history.push(`/GapChase/Lists/${this.props.listType}/${response}`);
                    })
                    .catch((error) => {
                        if (error === "Conflict") {
                            this.showSaveModalValidationMessage(uniquenessError);
                        }
                    });
            }
            else {
                this.props.onUpdate(this.props.listID, this.state.listName)
                    .then(this.closeSaveModal)
                    .catch((error) => {
                        if (error === "Conflict") {
                            this.showSaveModalValidationMessage(uniquenessError);
                        }
                    });
            }
        }
        else {
            this.showSaveModalValidationMessage(validationMessage);
        }
    }

    //sets a reference to a filter input (needed for reset on initiative type change & HCC panel hide
    //as well as setting client filters as action on load of saved chase list)
    setInputRef = (filterId, input) => {
        const ref = this.inputRefs.find(x => x.filterId === filterId);
        if (ref) {
            ref.input = input;
        }
        else {
            this.inputRefs.push({ filterId, input });
        }
    }

    getExpandedPanels = (listType) => {
        const filterGroups = this.props.filters.map(x => x.categoryName).reduce((acc, cur) => {
            if (!acc.includes(cur)) {
                acc.push(cur);
            }
            return acc;
        }, []);

        return this.state.savedFilterCategories.filter(x => filterGroups.includes(x));
    }

    renderSaveModal = () => {
        const actionLabel = !this.props.showUpdate ? "Create" : "Update";
        const createOrUpdateButton = (
            <Button bsStyle="success" disabled={!this.state.listName} onClick={this.handleCreateOrUpdate} style={{ float: "right" }}>
                {actionLabel}
            </Button>
        );

        const inputLabel = (
            !this.state.saveModalValidationMessage ? <p>Please specify a name for this {capitalize(this.props.listType)} List:</p>
                : this.state.saveModalValidationMessage.split('\n').map((item, i) => <p className="text-danger" key={i}>{item}</p>)
            // : <p className="text-danger">{this.state.saveModalValidationMessage}</p>
        );

        return (
            <Modal show={this.state.showSaveModal} onHide={this.closeSaveModal} onKeyPress={this.handleModalKeyPress}>
                <Modal.Header closeButton>
                    <Modal.Title>{actionLabel} {capitalize(this.props.listType)} List</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {inputLabel}
                    <span style={{ color: 'red', paddingRight: '0.69rem' }}>*</span>
                    <input type="text" name="listName" value={this.state.listName} onChange={this.handleListNameChange} style={{ width: "23.5rem" }} />
                </Modal.Body>
                <Modal.Footer>
                    {createOrUpdateButton}
                </Modal.Footer>
            </Modal>
        );
    }

    updateDisabled = () => {
        //return !(this.props.user ?
        //    this.props.createdBy === this.props.user.Email || this.props.user.Roles.includes('Commercial ACA Gap Chase Admin')
        //    : false);
        return false;
    }

    renderSidebarContents = () => {
        const createOrUpdateButton = (!this.props.showUpdate ?
            <Button onClick={this.openSaveModal} bsStyle="success" style={{ float: "right" }}>Create</Button>
            : <Button onClick={this.handleCreateOrUpdate} bsStyle="success" disabled={this.updateDisabled()} style={{ float: "right" }}>Update</Button>);

        const filterActions = this.state.error ?
            (<div>
                <Error />
                <ButtonGroup style={{ marginBottom: "4rem", float: "left" }}>
                    <NavLink to={'/GapChase/Lists/' + this.props.listType} onClick={this.handleClearFilters}>
                        <Button>Cancel</Button>
                    </NavLink>
                </ButtonGroup>
            </div>)
            :
            (<div style={{ marginBottom: "4rem" }}>
                <NavLink to={'/GapChase/Lists/' + this.props.listType}>
                    <Button style={{ float: "left" }}>Cancel</Button>
                </NavLink>
                <Button onClick={this.handleClearFilters} style={{ marginLeft: "5px" }}>Clear</Button>
                {createOrUpdateButton}
            </div>);

        const savedFilters = this.props.savedFilterSelections !== null ? this.props.savedFilterSelections : [];
        const expanded = this.state.savedFilterCategories !== null ? this.getExpandedPanels() : [];
        var showSuspectType = (this.state.showSuspectType == null && this.props.listType === 'Gap' && savedFilters.length > 0) ? (savedFilters.find(x => x.filterId == 6) !== undefined) ? true : false : this.state.showSuspectType ? this.state.showSuspectType : false;
        var showChasesPerMember = (this.state.showChasesPerMember == null && this.props.listType === 'Chase' && savedFilters.length > 0) ? (savedFilters.find(x => x.filterId == 24) !== undefined) ? true : false : this.state.showChasesPerMember ? this.state.showChasesPerMember : false;
        var showPCP = (this.state.showPCP == null && this.props.listType === 'Chase' && savedFilters.length > 0) ? (savedFilters.find(x => x.filterId == 41) !== undefined) ? savedFilters.find(x => x.filterId == 41).value : false : this.state.showPCP ? this.state.showPCP : false;
        var showTier1 = (this.state.showTier1 == null && this.props.listType === 'Chase' && savedFilters.length > 0) ? (savedFilters.find(x => x.filterId == 43) !== undefined) ? savedFilters.find(x => x.filterId == 43).value : false : this.state.showTier1 ? this.state.showTier1 : false;
        var showTier2 = (this.state.showTier2 == null && this.props.listType === 'Chase' && savedFilters.length > 0) ? (savedFilters.find(x => x.filterId == 45) !== undefined) ? savedFilters.find(x => x.filterId == 45).value : false : this.state.showTier2 ? this.state.showTier2 : false;
        var showSpecialist = (this.state.showSpecialist == null && this.props.listType === 'Chase' && savedFilters.length > 0) ? (savedFilters.find(x => x.filterId == 53) !== undefined) ? savedFilters.find(x => x.filterId == 53).value : false : this.state.showSpecialist ? this.state.showSpecialist : false;
        var showFacility = (this.state.showFacility == null && this.props.listType === 'Chase' && savedFilters.length > 0) ? (savedFilters.find(x => x.filterId == 54) !== undefined) ? savedFilters.find(x => x.filterId == 54).value : false : this.state.showFacility ? this.state.showFacility : false;

        const renderedFilterGroups = this.props.filters ? this.props.filters.map((filterGroup, index) => {
            const isExpanded = index === 0 || expanded.includes(filterGroup.categoryName);
            return (
                <Row key={filterGroup.categoryName}>
                    <div className="col-md-12">
                        <FilterPanel filterList={filterGroup.filters} savedFilterSelections={savedFilters} title={filterGroup.categoryName}
                            hidden={filterGroup.isHidden} expanded={isExpanded} setDefault={true} onUpdateFilter={this.handleFilterChange}
                            onMemberPopulationChange={this.handleMemberPopulationChange} setInputRef={this.setInputRef}
                            onInitiativeTypeChange={this.handleInitiativeTypeChange} showSuspectType={showSuspectType}
                            selectedGapId={this.props.selectedGapId} showPCP={showPCP} showTier1={showTier1}
                            showTier2={showTier2} showFacility={showFacility} showSpecialist={showSpecialist} showUpdate={this.props.showUpdate}
                            setChaseFilterInputRefs={this.setChaseFilterInputRefs} showChasesPerMember={showChasesPerMember} />
                    </div>
                </Row>
            );
        }) : null;

        return this.state.error || (this.props.filters !== null && this.props.filters.length > 0) ? (
            <div>
                {renderedFilterGroups}
                {filterActions}
                {this.renderSaveModal()}
            </div>
        ) : null;
    }

    render() {
        return (
            <div>
                <div className="panel-content">
                    {this.renderSidebarContents()}
                </div>
            </div>
        );
    }
}
