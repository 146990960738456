import { GapChaseFilterBase, filterType, dataType } from '../../GapChaseFilterBase';
import Axios from 'axios';

export default class FacilitySpecialtiesFilter extends GapChaseFilterBase {
    constructor(chaseFilterData) {
        super();
        this.id = 33;
        this.name = "Facility Specialties";
        this.filterName = "FacilitySpecialties";
        //this.isHidden = true;
        this.filterType = filterType.multiSelect;

        this.values = [];
        if (chaseFilterData !== null && chaseFilterData.FacilitySpecialtiesACA !== "undefined")
            this.values = localStorage.getItem("Lob") === "Commercial" ? chaseFilterData.FacilitySpecialtiesACA : chaseFilterData.FacilitySpecialtiesMA;


        this.dataType = dataType.number;
        this.filterCategory = "Provider Level";
        this.filterGroupId = 4;
        this.placeholder = "Facility Specialties";

    }

}
