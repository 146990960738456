import React, { Component } from "react";
import ReactTable from "react-table";
import axios from "axios";
import toastr from "toastr";
import {
    renderInteger,
    renderDecimal,
    renderDifference,
    renderDetail
} from "../../../utils/CommonReactTable";
import { Redirect } from 'react-router-dom'

export default class SummaryTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Skip: 0,
            Take: 10,
            data: [],
            hiosId: props.hiosId,
            market: props.market,
            results: props.results,
            loading: props.loading,
            pageCount: props.pageCount,
            pageSize: props.pageSize,
            pageNumber: props.pageNumber,
            totalItemCount: props.totalItemCount,
            redirect: false,
            detailUrl: null
        };
    }

    generateLink = (ProviderMasterId, HiosID, MarketName, BenefitYear) => {
        const link =
            "/Reporting/ProviderProfile/" +
            ProviderMasterId +
            "/" +
            (HiosID && HiosID != "-" ? HiosID : this.state.hiosId) +
            "/" +
            (MarketName && MarketName != "-" ? MarketName : this.state.market) +
            "/" +
            BenefitYear;

        return link;
    };

    setRedirect = (providerMasterId, hiosID, marketName, benefitYear) => {
        var url = this.generateLink(
            providerMasterId,
            hiosID,
            marketName,
            benefitYear
        );
        this.setState(
            {
                redirect: true,
                detailUrl: url
            },
            () => this.props.onDetail()
        );
    };
    render() {
        const { setRedirect } = this;
        const { detailUrl, redirect } = this.state;

        const columns = [
            {
                Header: "Detail",
                id: "detail",
                sortable: false,
                Cell: row =>
                    renderDetail(
                        "/Reporting/ProviderProfile/" +
                            row.original.ProviderMasterId +
                            "/" +
                            (row.original.HiosID && row.original.HiosID != "-"
                                ? row.original.HiosID
                                : this.state.hiosId) +
                            "/" +
                            (row.original.MarketName &&
                            row.original.MarketName != "-"
                                ? row.original.MarketName
                                : this.state.market) +
                            "/" +
                            row.original.BenefitYear,
                        this.props.onDetail
                    )
            },
            {
                Header: "Benefit Year",
                accessor: "BenefitYear"
            },
            {
                Header: "Provider ID",
                accessor: "ProviderId"
            },
            {
                Header: "Provider Last Name",
                accessor: "LastName"
            },
            {
                Header: "Provider First Name",
                accessor: "FirstName"
            },
            {
                Header: "Provider Specialty",
                accessor: "Specialty",
                width: 140
            },
            {
                Header: "Member Count",
                id: "MemberCount",
                Cell: row => renderInteger(row.original.MemberCount)
            },
            {
                Header: "HIOS ID",
                accessor: "HiosID"
            },
            {
                Header: "Market",
                accessor: "MarketName"
            },
            {
                Header: "Avg Calc Claims Score",
                id: "AvgClientScore",
                Cell: row => renderDecimal(row.original.AvgClientScore, 3)
            },
            {
                Header: "Avg Calc RACSD Score",
                id: "AvgCalcRacsdScore",
                Cell: row => renderDecimal(row.original.AvgCalcRacsdScore, 3)
            },
            {
                Header: "Avg CMS RACSD Score",
                id: "AvgCmsRacsdScore",
                Cell: row => renderDecimal(row.original.AvgCmsRacsdScore, 3)
            },
            {
                Header: "Avg Calc RARSD Score",
                id: "AvgCalcRarsdScore",
                Cell: row => renderDecimal(row.original.AvgCalcRarsdScore, 3)
            },
            {
                Header: "Avg CMS RARSD Score",
                id: "AvgCmsRarsdScore",
                Cell: row => renderDecimal(row.original.AvgCmsRarsdScore, 3)
            },
            {
                Header: "RACSD Difference",
                id: "RacsdDifference",
                Cell: row => renderDifference(row.original.RacsdDifference)
            },
            {
                Header: "Calc RACSD vs Calc RARSD Difference",
                id: "CalcDifference",
                Cell: row => renderDifference(row.original.CalcDifference)
            },
            {
                Header: "CMS RACSD vs CMS RARSD Difference",
                id: "CmsDifference",
                Cell: row => renderDifference(row.original.CmsDifference)
            },
            {
                Header: "RARSD Difference",
                id: "RarsdDifference",
                Cell: row => renderDifference(row.original.RarsdDifference)
            },
            {
                Header: "Avg Potential Score",
                id: "AvgPotentialScore",
                Cell: row => renderDecimal(row.original.AvgPotentialScore, 3)
            }
        ];

        if (redirect) {
            return <Redirect to = {detailUrl} />
          }

        return (
            <ReactTable
                className="ts-react-table -striped -highlight table-row-cursor"
                columns={columns}
                data={this.props.results}
                manual
                loading={this.props.loading}
                defaultPageSize={this.props.pageSize}
                page={this.props.pageNumber - 1}
                pages={this.props.pageCount}
                pageSizeOptions={[10, 25, 50, 100]}
                sortable={true}
                multiSort={true}
                onPageChange={this.props.onPageChange}
                onPageSizeChange={this.props.onPageSizeChange}
                onSortedChange={sorted =>
                    this.props.columnSortinghandler(sorted)
                }
                noDataText={<span>No Results Found</span>}
                getTrProps={(state, rowInfo, column) => {
                    return {
                        onClick: event =>
                            setRedirect(
                                rowInfo.row._original.ProviderMasterId,
                                rowInfo.row._original.HiosID,
                                rowInfo.row._original.MarketName,
                                rowInfo.row._original.BenefitYear
                            )
                    };
                }}
            />
        );
    }
}
