import axios from 'axios';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Header, Footer, Sidebar } from './components/PageLayout';
import FullPageLoader from './components/Shared/FullPageLoader';
import { MapUserAccess, MapAllPages } from './config/pages';
import { mapUserDispatchToProps, mapUserStateToProps } from './redux/actions';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initialized: false
        }
    }

    componentWillMount = () => {
        this.checkAuthorization()
            .then(new Promise((resolve, reject) => {
                return this.getLob(resolve, reject)
            }))
            .then(this.initializationCompleted)

            .catch(this.initializationCompleted);
    }

    //todo: this doesn't seem to handle token refresh -- is expiry not implemented?
    checkAuthorization = () => {
        return new Promise((resolve, reject) => {
            if (process.env.NODE_ENV === 'development') {
                // generates a token for debug builds --
                // assign the apiKey value (available in the api's web.config) either below or in .env,
                // but please ensure that it never gets checked in; this is for local use *only*
                axios.post('/api/token', {
                    userId: 0,      // << replace with a valid userId
                    username: '',   // << replace with a valid user name
                    email: '',      // << replace with a valid user e-mail
                    roles: [
                        'Commercial ACA Gap Chase Admin',
                        'EnrollmentReconciliationReport',
                        'FinancialProfileReport',
                        'GapProfileReport',
                        'MemberProfileReport',
                        'PlanProfileReport',
                        'ProviderProfileReport',
                        'User Activity',
                    ],
                    apiKey: process.env.REACT_APP_API_KEY
                })
                .then((response) => {
                    axios.defaults.headers.common = {
                        'Authorization': 'bearer ' + response.data
                    };
                    localStorage.setItem('ACAToken', response.data);
                    return this.getUserData(resolve, reject);
                })
                .catch((error) => {
                    this.redirectToLogin();
                    return reject("Error");
                });
            }
            else {
                const storedToken = localStorage.getItem('ACAToken');
                let query;
                if (window.location.search) {
                    query = window.location.search;
                }
                else {
                    query = window.location.href.indexOf('?') > -1 ?
                        window.location.href.split('?')[1] : '';
                }
                const params = new URLSearchParams(query);
                let jwt = params.get('jwt');

                if (jwt) {
                    jwt = jwt.replace(/"/g,"");
                    axios.defaults.headers.common = {
                        'Authorization': 'bearer ' + jwt
                    };

                    localStorage.setItem('ACAToken', jwt);
                    return this.getUserData(resolve, reject);
                }
                else if (storedToken) {
                    axios.defaults.headers.common = {
                        'Authorization' : 'bearer ' + storedToken
                    };

                    return this.getUserData(resolve, reject);
                }
                else {
                    this.redirectToLogin();
                    return resolve("Complete");
                }
            }
        });
    }

    getUserData = (resolve, reject) => {
        axios.get('/authenticate/GetUser')
        .then((response) => {
            const user = response.data;
            if (user.Roles.indexOf('Administrators') > -1) {
                user.AuthorizedPages = MapAllPages();
            }
            else {
                user.AuthorizedPages = MapUserAccess(user.Roles);
            }

            //persist user information in "session" redux store
            this.props.setUser(user);

            // The code below appears to be redundant, but raise 
            // Veracode issue "URL Redirection to Untrusted Site ('Open Redirect')"
            // window.location = window.location.href.split("?")[0];
            
            return resolve("Complete");
        })
        .catch((error) => {
            this.redirectToLogin();
            return reject("Error");
        });
    }

    getLob = (resolve, reject) => {
        axios.get('/v1/settings')
            .then((response) => {

                localStorage.setItem('Lob', response.data.LineOfBusiness);

                return resolve("Complete");
            })
            .catch((error) => {
                this.redirectToLogin();
                return reject("Error");
            });
    }

    redirectToLogin = () => {
        if (process.env.NODE_ENV === 'development') {
            window.location = `${process.env.REACT_APP_CURRENT_URL_DEV}/login`;
        }
        else {
            window.location = `${process.env.REACT_APP_CURRENT_URL_PROD}/login`;
        }
    }

    initializationCompleted = () => {
        this.setState({ initialized: true });
    }

    render() {
        return (
            <div className='wrapper'>
                <Header />
                <Sidebar />
                <div className='content-wrapper' style={{ overflowY: 'scroll' }}>
                    {
                        this.state.initialized &&
                        this.props.children
                    }
                </div>
                <Footer />
                <FullPageLoader />
            </div>
        )
    }
}

export default connect(
  mapUserStateToProps,
  mapUserDispatchToProps
)(App);
