import React, { Component } from "react";
import toastr from "toastr";
import axios from "axios";
import {
    renderInteger,
    renderDateTime,
    renderStr,
    renderClientName,
    renderStatus,
    renderDetail
} from "../../../utils/CommonReactTable";
import ReactTable from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import JobDetail from "./JobDetail";
export default class JobStatusSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dayRange: 14,
            data: [],
            loading: false,
            expanded: {}
        };
    }

    componentDidMount() {
        this.getJobStatusSummary();
    }

    getJobStatusSummary = () => {
        const { dayRange } = this.state;

        const params = { dayRange };

        axios
            .get("/v1/Job", { params })
            .then(response => {
                this.setState({
                    loading: false,
                    data: response.data
                });
            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false, data: [] });
                toastr.error("An error occurred retrieving job summary.");
            });
    };

    render() {
        const { data, loading, isExpanded } = this.state;

        const columns = [
            {
                expander: true,
                Header: "Job Detail",
                id: "Detail",
                width: 80,

                Expander: ({ isExpanded, ...rest }) => {
                    return (
                        <div
                            style={{
                                width: "100%",
                                textAlign: "center",
                                color: "#3c8dbc"
                            }}
                        >
                            {isExpanded ? (
                                <FontAwesomeIcon icon="minus" />
                            ) : (
                                <FontAwesomeIcon icon="plus" />
                            )}
                        </div>
                    );
                }
            },

            {
                Header: "Job ID",
                id: "JobId",
                // width: 50,
                Cell: row => renderInteger(row.original.JobId)
            },
            {
                Header: "Created Time",
                id: "CreateTimestamp",
                width: 150,
                Cell: row => renderDateTime(row.original.CreateTimestamp)
            },
            {
                Header: "Job Type",
                id: "JobTypeID",
                Cell: row => renderStr(row.original.JobTypeID)
            },
            {
                Header: "Client",
                id: "ClientId",
                Cell: row => renderClientName(row.original.ClientId)
            },
            {
                Header: "Year",
                id: "SpecYear",
                accessor: "SpecYear",
                Cell: row => renderInteger(row.original.SpecYear)
                // width: 70
            },

            {
                Header: "Dispatch Status",
                id: "DispatchStatus",
                Cell: row => renderStr(row.original.DispatchStatus)
                //  width: 50
            },
            {
                Header: "Member Request Total Count",
                id: "MemberRequestTotalCount",
                width: 120,
                Cell: row => renderInteger(row.original.MemberRequestTotalCount)
            },
            {
                Header: "Trigger User",
                id: "TriggerUser",
                width: 160,
                Cell: row => renderStr(row.original.TriggerUser)

                //  width: 50
            },
            {
                Header: "Status",
                id: "Status",
                Cell: row => renderStatus(row.original.Status),
                width: 140
            }
        ];

        return (
            <React.Fragment>
                <div className="row jobHeader col-sm-12">
                    <div>
                        <h4>Job Status Summary</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <ReactTable
                            className="ts-react-table -striped -highlight"
                            columns={columns}
                            data={data}
                            loading={loading}
                            defaultPageSize={10}
                            pageSizeOptions={[10, 25, 50, 100]}
                            sortable={false}
                            multiSort={false}
                            expanded={isExpanded}
                            onExpandedChange={(isExpanded, index, event) => {
                                this.setState({ isExpanded });
                            }}
                            SubComponent={row => {
                                return <JobDetail jobId={row.original.JobId} />;
                            }}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
