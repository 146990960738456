
import React, { Component } from 'react';
import ReactTable from 'react-table';
import axios from 'axios';
import toastr from 'toastr';
import { renderDetail, renderDate, renderDecimal, renderInteger } from '../../../utils/CommonReactTable';
import { download } from '../../../utils/File';
import { Content } from '../../PageLayout';
import * as Constants from './Constants';


export default class GapPCPDetailTable extends Component {
	constructor(props) {
		super(props);

		let pagination = {
			pageNumber: 1,
			pageSize: 10,
			pageCount: 0,
			sortKey: "MEMBERID",
			sortDirection: "ASC"
		}

		// pagination, filter
		this.state = {
			results: props.results,
			filter: props.filter,
			showHeader: true,
			pagination,
			sortKey: "MEMBERID",
			sortDirection: "ASC",
			totalItemCount:0  
		};

		this.onPageChange = this.onPageChange.bind(this);
		this.onPageSizeChange = this.onPageSizeChange.bind(this);
		this.onPaging = this.onPaging.bind(this);
		this.onSortedChange = this.onSortedChange.bind(this);

		this.onBack = this.onBack.bind(this);
	}


	static getDerivedStateFromProps(nextProps, prevState) {

		return { results: nextProps.results, providerMasterID: nextProps.providerMasterID
			, filter: nextProps.filter, totalItemCount: nextProps.results.TotalItemCount };

	}

	onPaging = (pageSize, pageIndex, sortKey, sortDirection) => {

		const { providerMasterID, filter, pagination } = this.state;

		// onPageChange: pageSize from state, onPageSizeChange: pageSize passed in
		if (pageSize < 1)
			pageSize = this.state.results.PageSize;
		else
			pageIndex = 0;

		if (!sortKey) {
			sortKey = pagination.sortKey;
			sortDirection = pagination.sortDirection;
		}

		pagination.pageNumber = pageIndex + 1;
		pagination.pageSize = pageSize;
		pagination.sortKey = sortKey;
		pagination.sortDirection = sortDirection;

		const paramsDetail = {
			ProviderMasterID: providerMasterID,
			specYearStart: filter.year,
			specYearEnd: filter.year,
			pageNumber: pagination.pageNumber,
			pageSize: pagination.pageSize,
			sortKey: pagination.sortKey,
			sortDirection : pagination.sortDirection
		};

		// run member query to get member data
		axios.get('/GapProfile/PcpGapDetail', { params: paramsDetail }).then((response) => {
			// console.log('onFetchData response = ' + JSON.stringify( response.data )) ;

			let results = this.state.results;
			results.Detail = response.data.Detail;
			pagination.pageCount = response.data.PageCount;

			this.setState({ results, providerMasterID, pageCount: response.data.PageCount, pagination});

		}).catch((error) => {
			console.log(error);
		})


	}

	onPageChange(pageIndex) {

		this.onPaging(-1, pageIndex, "", "");
	}

	onPageSizeChange(pageSize, pageIndex) {

		this.onPaging(pageSize, pageIndex, "", "");
	}

	onSortedChange(newSorted, column, shiftKey) {

		const sort = newSorted[0];
		const sortDirection = sort.desc ? "DESC" : "ASC";

		console.log(JSON.stringify(newSorted[0]));

		this.onPaging(-1, 0, sort.id, sortDirection);

	}

	handleExportDetailClick = (event) => {
		event.preventDefault();
		const { filter, providerMasterID, sortKey, sortDirection } = this.state;
		const params = {
			providerMasterID,
			specYearStart: filter.year,
			specYearEnd: filter.year,
			hiosID: "",
			Market: "",
			ViewBy: "PCP",
			sortKey,
			sortDirection
		};

		axios.get('/GapProfile/ExportDetail', { params, responseType: 'blob' }).then((response) => {
			const fileName = response.headers['x-suggested-filename'] || 'ResultsExtract_' + new Date().getTime() + '.txt';
			download(response.data, fileName);
		}).catch((error) => {
			console.error(error);
			toastr.error('Failed to export data.');
		});
	}

	handleShowHeader = (event) => {
		this.setState({ showHeader: !this.state.showHeader })
	}

	onBack() {
		const args = { viewBy: Constants.VIEW_BY.PCP };	 
		this.props.onBack(args);
	}

	render() {

		const { results, pagination,totalItemCount } = this.state;

		const columns = [

			{
				Header: 'Detail',
				id: 'Detail',
				Cell: (row) => renderDetail('')
			},
			{
				Header: 'Member ID',
				id: 'MemberID',
				accessor: 'MemberID',
				sortable: true,
			},
			{
				Header: 'Member First Name',
				accessor: 'FirstName',
				sortable: true,
			},
			{
				Header: 'Member Last Name',
				accessor: 'LastName',
				sortable: true,
			},
			{
				Header: 'MI',
				accessor: 'MiddleName',
			},
			{
				Header: 'DOB',
				id: 'DOB',
				accessor: 'DateOfBirth',
				Cell: (row) => renderDate(row.original.DateOfBirth),
				sortable: true,
			},
			{
				Header: 'Sex',
				accessor: 'Gender',
				sortable: true,
			},
			{
				Header: 'As Of Month',
				accessor: 'AsOfMonth',
			},
			{
				Header: 'RARSD Score',
				id: 'RARSDScore',
				Cell: (row) => renderDecimal(row.original.RARSDScore, 3),
				sortable: true
			},
			{
				Header: 'Known HCCs',
				id: 'KnownHCCs',
				Cell: (row) => renderInteger(row.original.KnownHCCs),
				sortable: true
			},
			{
				Header: 'HCC Gaps',
				id: 'HCCGaps',
				Cell: (row) => renderInteger(row.original.HCCGaps),
				sortable: true
			},
			{
				Header: 'HCC Suspects',
				id: 'HCCSuspects',
				Cell: (row) => renderInteger(row.original.HCCSuspects),
				sortable: true
			},
			{
				Header: 'Potential Gaps',
				id: 'PotentialGaps',
				Cell: (row) => renderDecimal(row.original.PotentialGaps, 3),
				sortable: true
			},
			{
				Header: 'Potential Suspects',
				id: 'PotentialSuspects',
				Cell: (row) => renderDecimal(row.original.PotentialSuspects, 3),
				sortable: true
			},

		];

		let provider = results.Header;

		return (
			<Content>
				<Content.Header>Gap Profile Detail</Content.Header>
				<Content.Body>
					<Content.Filter>
						<div className="member-details">
							<div className="row">
								<div className="col-sm-12 col-md-12 col-lg-4">
									<label>Provider ID: </label>
									<span>{provider.ProviderID}</span>
								</div>
								<div className="col-sm-12 col-md-6 col-lg-4">
									<label>First Name: </label>
									<span>{provider.FirstName}</span>
								</div>
								<div className="col-sm-12 col-md-6 col-lg-4">
									<label>Last Name: </label>
									<span>{provider.LastName}</span>
								</div>
							</div>
						</div>
					</Content.Filter>
					<div className="row export-spacing">
						<div className="col-sm-12">
							<button className="btn btn-default" onClick={this.onBack}>
								Back
							</button>
							<button className="btn btn-tessellate pull-right" onClick={this.handleExportDetailClick}>
								Export
							</button>
						</div>
					</div>
					<Content.Result>
						{/* property page is zero based. results.PageNumber is one based. */}
						<ReactTable
							className='ts-react-table -striped -highlight table-row-cursor'
							columns={columns}
							data={results.Detail}
							page={pagination.pageNumber - 1}
							pages={pagination.pageCount}
							defaultPageSize={10}
							pageSizeOptions={[5, 10, 25, 50, 100]}
							sortable={false}
							defaultSorted={[{
								id: 'MemberID',
								desc: false
							}]}
							multiSort={false}
							manual

							onPageChange={this.onPageChange}
							onPageSizeChange={this.onPageSizeChange}
							onSortedChange={this.onSortedChange}
							noDataText={(<span>No Results Found</span>)}

							getTdProps={(state, rowInfo, column, instance) => {
								return {
									onClick: (e, handleOriginal) => {
										const memberMasterID = rowInfo.original.MemberMasterID;
										const memberArgs = { memberMasterID, pagination, goBackViewBy: Constants.VIEW_BY.PCP };
										this.props.onMemberChange(memberArgs);
										
										if (handleOriginal) {
											handleOriginal();
										}
									}
								};
							}}
						/>
						<div><strong>Total Number of Records: {totalItemCount}</strong></div>
					</Content.Result>
				</Content.Body>
			</Content>

		);
	}

};
