import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { isNil } from 'lodash';
import { ParseRangeValue } from '../../utils/GapChaseUtils';
import toastr from "toastr";

export default class Range extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultValue: this.props.defaultValue ? this.formatValue(this.props.defaultValue) : ['', ''],
            value: this.props.defaultValue ? this.formatValue(this.props.defaultValue) : ['', ''],
            handleOnUpdate: false
        };
    }

    static getDerivedStateFromProps(props, state) {
        if ( !state.handleOnUpdate) {
            state.defaultValue = props.defaultValue && props.defaultValue !== undefined ? props.defaultValue : ['', ''];
            state.value = props.defaultValue && props.defaultValue !== undefined ? props.defaultValue : ['', ''];
        }
    }

    getValuePart = (rangePart) => {
        const value = this.state.value ? this.state.value : ['', ''];
        if (rangePart === 'min') {
            return value[0];
        }
        else if (rangePart === 'max') {
            return value[1];
        }
        else {
            return '';
        }
    }

    formatValue = (value) => {
        if (!value || value.length !== 2) {
            return ['', ''];
        }
        else {
            return value;
        }
    }

    //set value externally (from parent, etc.)
    setValue = (value) => {
        this.setState({ value: this.formatValue(value) });
    }

    handleOnChange = (rangePart, e) => {
        this.state.handleOnUpdate = true;
        let value = e.target.value ? e.target.value : '';

        //build combined value
        if (rangePart === 'min') {
            value = [value, this.state.value[1]];
        }
        else if (rangePart === 'max') {
            value = [this.state.value[0], value];
        }

        this.setState({ value });
        this.props.handleOnChange(value);
    }

    //enforce min, max, decimal formatting, & non-negatives when onBlur is triggered
    validateOnBlur = (rangePart, e) => {
        let value = '';

        //decimal precision
        if (e.target.value) {
            value = ParseRangeValue(this.props.filter, e.target.value);
        }
        
        if(value === ''){
            e.target.value = value;
        }

        //min
        if (value < this.props.filter.minValue) {
            value = this.props.filter.minValue;
        }

        //max
        if (value > this.props.filter.maxValue) {
            value = this.props.filter.maxValue;
        }

        //build combined value
        if (rangePart === 'min') {
            value = [value, this.state.value[1]];
        }
        else if (rangePart === 'max') {
            value = [this.state.value[0], value];
        }

        //min,max Validation
        var minObj = document.getElementById(this.props.filter.id + '-1');
        var maxObj = document.getElementById(this.props.filter.id + '-2');
        var min = this.state.value[0];
        var max = this.state.value[1];
        let isminlessthanmax = true;
        let isChaseRankZero = false;

        minObj.style.borderColor = "#ccc";
        maxObj.style.borderColor = "#ccc";        
        if(min !== "" && max !== "" && parseFloat(min) > parseFloat(max)){
            isminlessthanmax = false;
            minObj.style.borderColor = "red";
            toastr.error("Min Value must be less than Max Value");
        }
        
        //Rank validation
        if(this.props.filter.filterName === 'ChaseRank'){
            min = ParseRangeValue(this.props.filter, min);
            max = ParseRangeValue(this.props.filter, max);            
            if(isminlessthanmax && min === 0){
                minObj.style.borderColor = "red";
                isChaseRankZero = true;
            } 
            if(max === 0){
                maxObj.style.borderColor = "red";
                isChaseRankZero = true;
             }  
        }
        if(isChaseRankZero){
            toastr.error("The entered value is not a valid Chase Rank. Please input whole numbers equal to or greater than 1");
        }


        //only update if validation has made changes
        if (value !== this.state.value) {
            this.setState({ value });
            this.props.handleOnChange(value);
        }
    }

    handleKeyUp(e){
        var charCode = (e.which) ? e.which : e.keyCode;
        const allowNegativeValFilters = ['RaGain','Tier1RaGain','Tier2RaGain','FacilityRaGain','PcpRaGain','SpecialistRaGain'];
        const numberOnlyFilters = ['Age','ChaseRank']
        if(allowNegativeValFilters.includes(this.props.filter.filterName)){   
            if(charCode !== 189 && e.target.value === ''){
                e.target.value = '';
            }
        }
        else{
            if(numberOnlyFilters.includes(this.props.filter.filterName) && charCode === 190  && e.target.value !== ''){
                e.target.value = e.target.value;
            }
            else{
               if(e.target.value === ''){
                e.target.value = '';
               }                
            } 
        }       
    }

    render() {

        var minValue = (this.state.value[0] === undefined || this.state.value[0] === null) ? '' : this.state.value[0];
        var maxValue = (this.state.value[1] === undefined || this.state.value[1] === null) ? '' : this.state.value[1];
        if (isNil(this.props.filter.minValue) || isNil(this.props.filter.maxValue) || isNil(this.props.filter.step)) {
                return (
                    <Row>
                        <Col xs={6}>
                            <input className="form-control" id={`${this.props.filter.id}-1`} type="number" min="0" step="any"
                                placeholder={`Min ${this.props.filter.placeholder}`} value={minValue}
                                onChange={(e) => this.handleOnChange('min', e)} onKeyUp={this.handleKeyUp.bind(this)} onBlur={(e) => this.validateOnBlur('min', e)} />
                        </Col>
                        <Col xs={6}>
                            <input className="form-control" id={`$${this.props.filter.id}-2`} type="number" min="0" step="any"
                                placeholder={`Max ${this.props.filter.placeholder}`} value={maxValue}
                                onChange={(e) => this.handleOnChange('max', e)} onKeyUp={this.handleKeyUp.bind(this)} onBlur={(e) => this.validateOnBlur('max', e)} />
                        </Col>
                    </Row>
                );
        }
        else {
            // Disable on render if Tier1, tier2, pcp chase, specialilty  and Facility
        //    if (this.props.filter.id === 44 || this.props.filter.id === 46 || this.props.filter.id === 26 || this.props.filter.id === 28 || this.props.filter.id === 32)
          //  {
            return (
                <Row>
                    <Col xs={6}>
                        <input className="form-control" id={`${this.props.filter.id}-1`} type="number" min={this.props.filter.minValue}
                            max={this.props.filter.maxValue} step={this.props.filter.step} placeholder={`Min`} 
                            value={minValue} onKeyUp={this.handleKeyUp.bind(this)} onChange={(e) => this.handleOnChange('min', e)} onBlur={(e) => this.validateOnBlur('min', e)} />
                    </Col>
                    <Col xs={6}>
                        <input className="form-control" id={`${this.props.filter.id}-2`} type="number" min={this.props.filter.minValue}
                            max={this.props.filter.maxValue} step={this.props.filter.step} placeholder={`Max`} 
                            value={maxValue} onKeyUp={this.handleKeyUp.bind(this)} onChange={(e) => this.handleOnChange('max', e)} onBlur={(e) => this.validateOnBlur('max', e)} />
                    </Col>
                </Row>
            );
         //   }
            //else {

            //    return (
            //        <Row>
            //            <Col xs={6}>
            //                <input className="form-control" id={`${this.props.filter.id}-1`} type="number" min={this.props.filter.minValue}
            //                    max={this.props.filter.maxValue} step={this.props.filter.step} placeholder={`Min`}  
            //                    value={minValue} onKeyUp={this.handleKeyUp.bind(this)} onChange={(e) => this.handleOnChange('min', e)} onBlur={(e) => this.validateOnBlur('min', e)} />
            //            </Col>
            //            <Col xs={6}>
            //                <input className="form-control" id={`${this.props.filter.id}-2`} type="number" min={this.props.filter.minValue}
            //                    max={this.props.filter.maxValue} step={this.props.filter.step} placeholder={`Max`}
            //                    value={maxValue} onKeyUp={this.handleKeyUp.bind(this)} onChange={(e) => this.handleOnChange('max', e)} onBlur={(e) => this.validateOnBlur('max', e)} />
            //            </Col>
            //        </Row>
            //    );
            //}
        }
    }
}