import { GapChaseFilterBase, filterType, dataType } from '../GapChaseFilterBase';

export default class MemberInclusionListIdsFilter extends GapChaseFilterBase {
    constructor(incExList) {
        super();
        this.id = 16;
        this.name = "Member Inclusion List";
        this.filterName = "MemberInclusionListIds";
        // this.isHidden = false;
        this.filterType = filterType.multiSelect;
        this.values = incExList;

        this.dataType = dataType.number;
        this.filterCategory = "Member Level";
        this.filterGroupId = 2;
        this.placeholder = "Member Inclusion List";
    }
}
