import React from 'react';
import axios from 'axios';
import { Col, ControlLabel, FormControl, FormGroup, Modal, Row } from 'react-bootstrap';
import Select from 'react-select';
import toastr from 'toastr';
import InclusionExclusionFileUpload from './InclusionExclusionFileUpload';

export default class InclusionExclusionUploadModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listName: '',
            listType: { value: 'Member', label: 'Member' },
            templatesLoaded: false
        };

        //todo: pull these from ref table once created; pass to this component as a prop
        this.listTypes = [
            { value: 'Member', label: 'Member' },
            { value: 'Provider', label: 'Provider' },
            { value: 'MemberProvider', label: 'MemberProvider' }
        ];

        this.memberTemplateData = null;
        this.providerTemplateData = null;
        this.memberProviderTemplateData = null;
    }

    componentWillMount() {
        this.getFileFormatPdfs();
    }

    getFileFormatPdfs = () => {
        Promise.all([
            axios.get('/StaticContent?filename=Advantasure_Member_List_Layout.pdf', { responseType: 'arraybuffer' }),
            axios.get('/StaticContent?filename=Advantasure_Provider_List_Layout.pdf', { responseType: 'arraybuffer' }),
            axios.get('/StaticContent?filename=Advantasure_MemberProvider_List_Layout.pdf', { responseType: 'arraybuffer' })
        ])
        .then((responses) => {
            this.memberTemplateData = responses[0].data;
            this.providerTemplateData =  responses[1].data;
            this.memberProviderTemplateData = responses[2].data;
            this.setState({
                templatesLoaded: true
            });
        })
        .catch((error) => {
             toastr.error('Failed to download file templates.');
        });
    }

    downloadTemplate = (filename) => {
        if (this.state.templatesLoaded) {
            let blobURL;
            if (filename === "Member") {
                blobURL = URL.createObjectURL(new Blob([this.memberTemplateData], { type: "application/pdf" }));
            }
            else if (filename === "Provider") {
                blobURL = URL.createObjectURL(new Blob([this.providerTemplateData], { type: "application/pdf" }));
            }
            else {
                blobURL = URL.createObjectURL(new Blob([this.memberProviderTemplateData], { type: "application/pdf" }));
            }

            const tempLink = document.createElement('a');
            tempLink.style.display = 'none';
            tempLink.href = blobURL;
            tempLink.setAttribute('download', filename);
            tempLink.setAttribute('target', '_blank');
            document.body.appendChild(tempLink);
            tempLink.click();
            document.body.removeChild(tempLink);
            window.URL.revokeObjectURL(blobURL);
        }
        else {
             toastr.error('File templates have not been loaded.');
        }
    }

    //todo
    handleListNameChange = (e) => {
        this.setState({ listName: e.target.value });
    }

    //todo
    handleListTypeChange = (e) => {
        this.setState({ listType: e });
    }

    //todo
    uploadList = () => {
        return;
    }

    render() {
        return (
            <Modal show={this.props.visible} onHide={this.props.handleModalToggle} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Save as Final?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ minHeight: "400px" }}>
                        <Row style={{ marginBottom: "1rem" }}>
                            <Col lg={7}>
                                <FormGroup>
                                    <ControlLabel style={{ marginRight: "1rem" }} className="requiredField">List Name</ControlLabel>
                                    <FormControl type="text" name="inclusionListName" value={this.state.listName} maxLength="50"
                                        onChange={this.handleListNameChange} />
                                </FormGroup>
                                <FormGroup>
                                    <ControlLabel style={{ marginRight: "1rem" }} className="requiredField">List Type</ControlLabel>
                                    <Select value={this.state.listType} name="inclusionListType" options={this.listTypes} onChange={this.handleListTypeChange} />
                                </FormGroup>
                            </Col>
                            <Col lg={5}>
                                <FormGroup style={{ paddingTop: "0.1rem" }}>
                                    <ControlLabel>Upload Templates</ControlLabel>
                                    <ul>
                                        <li><a onClick={() => this.downloadTemplate("Member")} style={{ cursor: 'pointer' }}>Member</a></li>
                                        <li><a onClick={() => this.downloadTemplate("Provider")} style={{ cursor: 'pointer' }}>Provider</a></li>
                                        <li><a onClick={() => this.downloadTemplate("Member-Provider")} style={{ cursor: 'pointer' }}>Member-Provider</a></li>
                                    </ul>
                                </FormGroup>
                            </Col>
                        </Row>
                        <InclusionExclusionFileUpload listName={this.state.listName} listType={this.state.listType.value}
                            handleModalToggle={this.props.handleModalToggle} />
                    </div>
                </Modal.Body>
                <Modal.Footer />
            </Modal>
        );
    }
}