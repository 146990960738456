import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class Login extends React.Component {
    render() {
        return (
            <div className="login-container">
                <div>
                    <FontAwesomeIcon icon="hand-paper" size="5x" />
                </div>
                <div>
                    <h3>You are not currently logged in.</h3>
                </div>
                <div>
                    Please <a href={process.env.PORTAL_URL}>click here</a> to login to the portal.
                </div>
            </div>
        )
    }
}