import React from 'react';
import axios from 'axios';
import ReactTable from 'react-table';
import { renderDate, renderYesNo, renderInteger } from '../../../utils/CommonReactTable';
import toastr from 'toastr';

export default class MemberClaimsReport extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			...props,
			data: [],
			columnSorting:[{id: "ThroughDos", desc: true}, {id: "FromDos", desc: true},{id: "ClaimID", desc: false}],
			pageCount: 0,
			pageNumber: 0,		// zero based
			pageSize: 10,
			loading: false,
			totalItemCount:0
		};
		
		this.onPageChange = this.onPageChange.bind(this);
		this.onPageSizeChange = this.onPageSizeChange.bind(this);
		this.onPaging = this.onPaging.bind(this);

		this.getMemberClaimsData();

	}


	shouldComponentUpdate(nextProps, nextState) {

console.log( 'sCU np = ' + JSON.stringify(nextProps));
console.log( 'sCU ns = ' + JSON.stringify(nextState));

		if (nextProps.showHistory !== nextState.showHistory) {
			this.setState( { ...nextProps }, this.getMemberClaimsData );
		}

		return true;
	}

	getMemberClaimsData = () => {
		const { memberMasterId, pageNumber, pageSize, showHistory, benefitYr, columnSorting,TotalItemCount } = this.state;
		const params = {
			pageNumber, pageSize, columnSorting,
			benefitYear: showHistory === true ? 9999 : benefitYr
		};

		axios.get('/MemberProfile/' + memberMasterId + '/Claims', { params }).then((response) => {
			this.setState({ data: response.data.Detail, loading: false, pageCount: response.data.PageCount, totalItemCount: response.data.TotalItemCount });
		}).catch((error) => {
			console.log(error);
			this.setState({ loading: false });
			toastr.error('An error occurred retrieving member claims data');
		});
	}

	onPaging = (pageSize, pageIndex) => {
		
		if (pageSize < 0) pageSize = this.state.pageSize;

		this.setState( { pageSize, pageNumber: pageIndex}, this.getMemberClaimsData );
	}

	onPageChange(pageIndex) {
console.log('oPC pageIndex = ' + pageIndex );
		this.onPaging(-1, pageIndex);
	}

	onPageSizeChange(pageSize, pageIndex) {

		this.onPaging(pageSize, pageIndex);
	}

	columnSortinghandler = (sorted)=>{
		this.setState({ columnSorting: sorted, pageNumber: 0 } , this.getMemberClaimsData);
	} 


	render() {
		const { data, pageNumber, pageSize, pageCount, loading,totalItemCount} = this.state;

		const columns = [
			{
				Header: 'Claim ID',
				accessor: 'ClaimID'
			},
			{
				Header: 'EDGE Status',
				accessor: 'EDGEStatus'
			},
			{
				Header: 'RA',
				id: 'RA',
				Cell: (row) => renderYesNo(row.original.RA)
			},
			{
				Header: 'From DOS',
				id: 'FromDos',
				Cell: (row) => renderDate(row.original.FromDos)
			},
			{
				Header: 'Through DOS',
				id: 'ThroughDos',
				Cell: (row) => renderDate(row.original.ThroughDos)
			},
			{
				Header: 'Source',
				accessor: 'Source'
			},
			{
				Header: 'Add/Del',
				accessor: 'AddDel'
			},
			{
				Header: 'ICD',
				accessor: 'ICDCode'
			},
			{
				Header: 'ICD A/C',
				accessor: 'ICDAC'
			},
			{
				Header: 'Service Code',
				accessor: "ServiceCode"
			},
			{
				Header: 'NDC',
				accessor: 'NDC'
			},
			{
				Header: 'HCC Type',
				accessor: 'CommercialHCCType'
			},
			{
				Header: 'HCC',
				accessor: 'CommercialHCCNumber',
				Cell: (row) => renderInteger(row.original.CommercialHCCNumber)
			},
			{
				Header: 'HCC A/C',
				accessor: 'HCCAC',
			},
			{
				Header: 'Provider ID',
				accessor: 'ProviderID'
			},
			{
				Header: 'Provider Last Name',
				accessor: 'ProviderLastName'
			},
			{
				Header: 'Provider First Name',
				accessor: 'ProviderFirstName'
			}
			
		];

		// 	ReactTable and API have zero based pageNumber

		return (
			<div className="col-sm-12">
			<ReactTable
			    sortable = {true}
				className="ts-react-table -striped -highlight"
				columns={columns}
				data={data}
				manual
				loading={loading}
				
				onPageChange={this.onPageChange}
				onPageSizeChange={this.onPageSizeChange}

				defaultPageSize={pageSize}
				page={pageNumber}
				pages={pageCount}
				pageSizeOptions={[ 5, 10, 25, 50, 100 ]}
				onSortedChange = {sorted=>this.columnSortinghandler(sorted)}
				noDataText={(<span>No Results Found</span>)}
			/>
			<div><strong>Total Number of Records: {totalItemCount}</strong></div>
			</div>
		);
	}
}
