export const setUser = user => ({
    type: 'SET_USER',
    user
});

export const mapUserStateToProps = state => ({
    session: state.session
});

export const mapUserDispatchToProps = dispatch => ({
    setUser: user => dispatch(setUser(user))
});

export const mapUserToProps = state => {
    const userProp = state.session.find(x => x.user);
    return userProp ? userProp : {};
};