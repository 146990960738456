import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class FullPageLoader extends React.Component {
    render() {
        return (
            <div id="loader" className="loader hidden">
                <FontAwesomeIcon icon="spinner" pulse size="6x" />
            </div>
        );
    }
}