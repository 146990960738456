
import React from 'react';
import { Route, Redirect, Switch } from 'react-router';
import App from '../App';
import AuthWrapper from '../components/Shared/AuthWrapper';
import ErrorPages from '../components/Error';
import AdminApp from '../components/AdminApp';
import AdminLogin from '../components/Admin/Login';
import { MemberDispatcher } from '../components/Admin/MemberDispatcher';
import { CreateNewJob, EngineJobManagement} from '../components/Admin/EngineJobManagement';
import InclusionExclusionContainer from '../components/GapChaseList/InclusionExclusion/InclusionExclusionContainer';
import ListContainer from '../components/GapChaseList/ListContainer';
import { PAGES } from './pages';
import { logout } from '../utils/Window';

//  EnrollmentReconciliationDetail
import { EnrollmentReconciliationReport } from '../components/Reporting/EnrollmentReconciliation';
import { EnrollmentReconciliationDetail } from '../components/Reporting/EnrollmentReconciliation';

import { FinancialProfileReport, FinancialProfileDetail } from '../components/Reporting/FinancialProfile';
import { GapProfileReport } from '../components/Reporting/GapProfile';
import { MemberProfileReport, MemberDetail } from '../components/Reporting/MemberProfile';
import { PlanProfileReport, PlanProfileDetail } from '../components/Reporting/PlanProfile';
import { ProviderProfileReport, ProviderDetailReport } from '../components/Reporting/ProviderProfile';
import { UserActivityReport } from '../components/Reporting/UserActivity';
import { UserManual } from '../components/Help';


export default (
    <Switch>
        {/* Admin Routes */}
        <Route exact path='/Admin'
            render={() => <Redirect to="/Admin/MemberDispatcher" />} />
        <Route exact path='/Admin/Login'
            render={(props) => <AdminApp {...props}><AdminLogin /></AdminApp>} />
        <Route exact path='/Admin/MemberDispatcher'
            render={(props) => <AdminApp {...props}><MemberDispatcher /></AdminApp>} />
        <Route exact path='/Admin/EngineJobManagement'
            render={(props) => <AdminApp {...props}><EngineJobManagement /></AdminApp>}/>
        {/* Main App Routes */}
        <App>
            <Switch>
                {/* Root */}
                <Route 
                    exact 
                    path='/'
                    render={() => {
                        const redirectPath = localStorage.getItem('Lob') === 'Medicare' ? '/GapChase/Lists/Gap' : '/Reporting/MemberProfile';
                        return <Redirect to={redirectPath} />;
                    }} 
                />

                {/* Reporting */}


                <Route exact path='/Reporting/UserActivity'
                    render={(props) => <AuthWrapper {...props} component={UserActivityReport} page={PAGES.UserActivity} />} />

                <Route exact path='/Reporting/ProviderProfile'
                    render={(props) => <AuthWrapper {...props} component={ProviderProfileReport} page={PAGES.ProviderProfileReport} />} />

                {/* todo: test route :providerId access */}
                <Route exact path='/Reporting/ProviderProfile/:providerId/:hiosId/:market/:year'
                    render={(props) => <AuthWrapper {...props} component={ProviderDetailReport} page={PAGES.ProviderProfileReport} />} />

                <Route exact path='/Reporting/MemberProfile'
                    render={(props) => <AuthWrapper {...props} component={MemberProfileReport} page={PAGES.MemberProfileReport} />} />

                <Route exact path='/Reporting/MemberProfile/:memberId'
                    render={(props) => <AuthWrapper {...props} component={MemberDetail} page={PAGES.MemberProfileReport} />} />

                <Route exact path='/Reporting/PlanProfile'
                    render={(props) => <AuthWrapper {...props} component={PlanProfileReport} page={PAGES.PlanProfileReport} />} />

                <Route exact path='/Reporting/EnrollmentReconciliation'
                    render={(props) => <AuthWrapper {...props} component={EnrollmentReconciliationReport} page={PAGES.EnrollmentReconciliationReport} />} />

                <Route exact path='/Reporting/EnrollmentReconciliation/:viewType/:market/:planId/:hiosId/:year/:month'
                    render={(props) => <AuthWrapper {...props} component={EnrollmentReconciliationDetail} page={PAGES.EnrollmentReconciliationReport} />} />

                <Route exact path='/Reporting/PlanProfile/:planId/:year'
                    render={(props) => <AuthWrapper {...props} component={PlanProfileDetail} page={PAGES.PlanProfileReport} />} />

                <Route exact path='/Reporting/GapProfile'
                    render={(props) => <AuthWrapper {...props} component={GapProfileReport} page={PAGES.GapProfileReport} />} />

                <Route exact path='/Reporting/FinancialProfile'
                    render={(props) => <AuthWrapper {...props} component={FinancialProfileReport} page={PAGES.FinancialProfileReport} />} />

                <Route exact path='/Reporting/FinancialProfile/:year/:hiosId/:market/:planId'
                    render={(props) => <AuthWrapper {...props} component={FinancialProfileDetail} page={PAGES.FinancialProfileReport} />} />

                {/* Gap + Chase List -- note: key props are set on these routes to force a remount on route change */}
                <Route exact path='/GapChase/Lists/:listType/New'
                    render={(props) => <AuthWrapper {...props} component={ListContainer} key={window.location.href} showFilterSidebar={true} showLists={false} />} />

                <Route exact path='/GapChase/Lists/:listType/New/:gapId'
                    render={(props) => <AuthWrapper {...props} component={ListContainer} key={window.location.href} showFilterSidebar={true} showLists={false} />} />

                <Route exact path='/GapChase/Lists/:listType'
                    render={(props) => <AuthWrapper {...props} component={ListContainer} key={window.location.href} />} />

                <Route exact path='/GapChase/Lists/:listType/:id'
                    render={(props) => <AuthWrapper {...props} component={ListContainer} key={window.location.href} showFilterSidebar={true} showLists={false}
                        showUpdate={true} />} />

                <Route exact path='/GapChase/InclusionExclusion'
                    render={(props) => <AuthWrapper {...props} component={InclusionExclusionContainer} key={window.location.href} />} />

                <Route exact path='/Help/UserManual'
                    render={(props) => <UserManual {...props} />} />

                {/* Misc. */}
                <Route path='/Unauthorized' component={ErrorPages.Unauthorized} />
                <Route path='/Login' component={ErrorPages.Login} />
                <Route path='/Logout' render={logout} />
                <Route component={ErrorPages.NotFound} />
            </Switch>
        </App>
    </Switch>
)
