export const filterType = {
    select: "Select",
    multiSelect: "Multiselect",
    range: "Range",
    text: "Text",
    checkbox: "Checkbox"
}

export const dataType = {
    number: "Number",
    boolean: "Boolean",
    string: "String"
}

export class  GapChaseFilterBase {
    constructor(){
        this.id = 1;
        this.name = "undefined";
        this.filterName = "undefined";
        this.isHidden = false;
        this.filterType = filterType.select;
        this.values = [];

        this.dataType = dataType.number;
        this.filterCategory = "undefined";
        this.filterGroupId = 1;
        this.minValue = null;
        this.maxValue = null;
        this.step = null;
        this.placeholder = "Select";
        // this.links = [
        //     {
        //         rel: "self",
        //         href: "http://localhost:50961/GapChaseFilters/1"
        //     }
        // ]
    }
}