import { GapChaseFilterBase, filterType, dataType } from '../../GapChaseFilterBase';
import Axios from 'axios';

export default class Tier1ChaseTypeFilter extends GapChaseFilterBase {
    constructor() {
        super();
        this.id = 43;
        this.name = "Tier 1 Chase Type";
        this.filterName = "Tier1ChaseType";
        //this.isHidden = false;
        this.filterType = filterType.checkbox;
        this.values = [
            {
                "label": "",
                "value": "Tier1ChaseType"
            }
        ];

        this.dataType = dataType.boolean;
        this.filterCategory = "Provider Level";
        this.filterGroupId = 4;
        this.placeholder = "Tier 1 Chase Type";

    }

}
