import React, { Component } from 'react';
//import { CheckAuthorization } from '../../config/auth';

class ContentHeader extends Component {
    render() {
        const { children } = this.props;
        return (
            <section className="content-header">
                <h1>{children}</h1>
            </section>
        )
    }
}

class ContentBody extends Component {
    render() {
        const { children } = this.props;
        return (
            <section className="panel-content">
                {children}
            </section>
        );
    }
}

class ContentFilter extends Component {
    render() {
        const { children } = this.props;
        return (
            <div className="panel panel-default">
                <div className="panel-body">
                    {children}
                </div>
            </div>
        );

    }
}

class ContentResult extends Component {
    render() {
        const { children, header } = this.props;
        return (
            <div className="panel panel-default">
                {
                    header &&
                    <div className="panel-heading" style={{ padding: '5px 15px' }}>
                        <h4 style={{ fontWeight: 'bold' }}>{this.props.header}</h4>
                    </div>
                }
                <div className="panel-body">
                    {children}
                </div>
            </div>
        );
    }
}

class Content extends Component {
    render() {
        const { children } = this.props;
        return (
            <div style={{ height: 'inherit', overflow: 'auto' }}>
                {children}
            </div>
        );
    }
}

Content.Header = ContentHeader;
Content.Body = ContentBody;
Content.Filter = ContentFilter;
Content.Result = ContentResult;
export default Content;