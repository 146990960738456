
import React, { Component } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import { download } from '../../../utils/File';
import { Content } from '../../PageLayout';
import ReactTable from 'react-table';

import {
    renderIntegerLink
} from "../../../utils/CommonReactTable";

import { connect } from "react-redux";
import {
    mapEnrollmentRecDispatchToProps,
    mapEnrollmentRecToProps
} from "../../../redux/actions";


export class EnrollmentReconciliationReport extends Component {

    constructor(props) {
        super(props);

        let filter = {
            year: new Date().getFullYear(),
            hiosId: "All",
            market: "All",
            planId: "All",
            timeSliceBy : "Year",
            defaultFilter: true,
            submitted: false,
        };

        let pagination = {
            pageCount: 0,
            pageNumber: 0,
            pageSize: 12,
        }

        this.state = {
            years: [],
            hiosIds: [],
            markets: [],
            plans: [],

            filter,
            pagination,
            data: [],

            loading: false,
        };

    }

    componentDidMount() {

            this.getOptions();

    }

    getOptions = () => {

        const filter = this.state.filter;
        const url = '/EnrollmentReconciliation/Options?BenefitYear=' + filter.year 
            + '&HiosId=' + filter.hiosId;


        axios
            .get( url )
            .then(response => {

                const filterData  = response.data[0];
                const year = new Date().getFullYear();

                const years = [ year, year - 1, year -2 ];
                const markets = filterData.Markets;
                const hiosIds = filterData.HiosIds;
                const plans = filterData.PlanIDs;

                this.setState({ years, markets, hiosIds, plans }, this.checkAndSetFromState );

            })
            .catch(error => {
                console.log( JSON.stringify(error));

                toastr.error("An error occurred retrieving filter options2.");
            });

    } 

    checkAndSetFromState = () => {
        const {filter, pagination} = this.props.enrollmentRec;
        
        // console.log('checkAndSet ' + JSON.stringify( this.props.enrollmentRec ));

        // If filter.defaultFilter in redux is true, we have defaultEnrollmentRec
        if (filter.defaultFilter) {
            this.props.resetEnrollmentRec();
        } 
        
        // If !filter.defaultFilter, which means we have the state which is NOT defaultEnrollmentRec 
        // We can get stored states from props, and set them to current state.
         
        else {

            this.setState( { filter, pagination }, () => this.searchByFilter( false ) );

        }
    };
 
    // static getDerivedStateFromProps(nextProps, prevState) {
    //     console.log('getDerivedStateFromProps nextProps = ' + JSON.stringify(nextProps) );
    // }

    modifyOptions = (key) => {

        if (key !== "timeSliceBy")
            this.getOptions();


        this.setupEnrollmentRec( true );
    }
    
    handleFilterChange = key => event => {
        const { filter } = this.state;

        if (key === "year") {
            filter.hiosId = "All";
            filter.market = "All";
            filter.planId = "All";
        }

        filter.submitted = false;
        filter[key] = event.target.value;
        this.setState( {filter}, () => this.modifyOptions( key ) );

    };

    handleSearchByFilter = ( event, isExport ) => {

        event.preventDefault();

        var filter = this.state.filter;
        filter.submitted = true;

        this.setState( { filter }, () => this.searchByFilter( isExport ) );
    }

    searchByFilter = ( isExport ) => {

        const filter = this.state.filter;

        const url = '/EnrollmentReconciliation/EnrollmentSummary' 
            + '?BenefitYear=' + filter.year 
            + '&HiosId=' + filter.hiosId
            + '&Market=' + filter.market
            + '&PlanId=' + filter.planId
            + '&TimeSliceBy=' + filter.timeSliceBy
            + '&PageSize=' + (isExport ? 0 : 10)
            ;

            console.log( 'export = ' + isExport );
            console.log( 'searchByFilter url = ' + url);

        axios
            .get( url )
            .then(response => {

                const data = response.data;
                filter.submitted = true;

                if(data == null)
                {
                    return;
                }

                else if ( isExport ) {

                    const fileName = response.headers['x-suggested-filename'] || 'Enrollment_Reconciliation_Summary_' + new Date().getTime() + '.txt';

    console.log( 'filename = ' + fileName );
    console.log( JSON.stringify( response ));

                    download( data, fileName );

                    this.setState( { filter } );

                } else {

                    this.setState( { filter, data } );
                }

            })
            .catch(error => {

                console.log( 'searchByFilter url = ' + url);
                console.log( JSON.stringify(error));

                toastr.error("An error occurred retrieving Enrollment Summary.");
            });

    };
    
    generateLink = ( urlSection, monYear ) => {
        var month = '0';

        var d = Date.parse(monYear.substring( 0, 3 ) + "1, 2000");
        if(!isNaN(d)){
           month = (new Date(d).getMonth()) + 1;
        }

        const filter = this.state.filter;
        var link = '#/Reporting/EnrollmentReconciliation/' + urlSection + '/' + filter.market + '/' + filter.planId + '/' + filter.hiosId 
            + '/' + filter.year + '/' + month;

        return link;
    }

    setupEnrollmentRec = (defaultFilter) => {
		const { filter, pagination } = this.state;

//         filter.defaultFilter = false;
// filter.defaultFilter = true;
        filter.defaultFilter = defaultFilter;

        const enrollmentRec = {
            filter,
            pagination
        };


        this.props.setEnrollmentRec( enrollmentRec );


    };

    getColumns = () => {

        var cb = () => this.setupEnrollmentRec( false );

        const columns = [
            {
                Header: "Year",
                accessor: "MonYear",
                width: 100,
            },
            {
                Header: "Plan",
                id: "MembersInPlan",
                width: 100,
                Cell: (row) => renderIntegerLink( row.original.MembersInPlan, this.generateLink( 'Plan', row.original.MonYear ), cb )
            },
            {

                Header: 'EDGE',
                id: 'MembersInEdge',
                width: 100,
                Cell: (row) => renderIntegerLink( row.original.MembersInEdge, this.generateLink( 'Edge', row.original.MonYear ), cb )
            },
            {
                Header: "Not In Plan",
                id: "MembersNotInPlan",
                width: 100,
                Cell: (row) => renderIntegerLink( row.original.MembersNotInPlan, this.generateLink( 'NotInPlan', row.original.MonYear ), cb )
            },
            {
                Header: "Not In EDGE",
                accessor: "MembersNotInEdge",
                width: 100,
                Cell: (row) => renderIntegerLink( row.original.MembersNotInEdge, this.generateLink( 'NotInEdge', row.original.MonYear ), cb )
            }
  
        ];

        return columns;
    }

    render() {

        const {
            years,
            markets,
            hiosIds,
            plans,

            filter,
            pagination,
            data,
            loading,

        } = this.state;

        const {
            handleFilterChange,
            handleSearchByFilter
        } = this;
    
        const columns = this.getColumns();

        
        return (
            <Content>
                <Content.Header>Enrollment Reconciliation Report</Content.Header>
                <Content.Body>
                    <Content.Filter>
                        {years.length > 0 && markets.length > 0 && (
                        <div className="row">
                            <div className="col-sm-12">
                            <form className="form-inline">
                                <div className="form-group">
                                    <label className="control-label">Benefit Year:</label>
                                    <select
                                        className="form-control"
                                        value={filter.year}
                                        onChange={handleFilterChange("year")}
                                    >
                                        {years.map(x => (
                                        <option key={x} value={x}>
                                            {x}
                                        </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label className="control-label">HIOS ID:</label>
                                    <select
                                        className="form-control"
                                        value={filter.hiosId}
                                        onChange={handleFilterChange("hiosId")}
                                    >

                                        {hiosIds.map(x => (
                                        <option key={x} value={x}>
                                            {x}
                                        </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label className="control-label">Market:</label>
                                    <select
                                        className="form-control"
                                        value={filter.market}
                                        onChange={handleFilterChange("market")}
                                    >
                                        {markets.map(x => (
                                        <option key={x} value={x}>
                                            {x}
                                        </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label className="control-label">Plan ID:</label>
                                    <select
                                        className="form-control"
                                        value={filter.planId}
                                        onChange={handleFilterChange("planId")}
                                    >
                                        {plans.map( (x, index) => (
                                            <option key={x} value={x}>
                                                 {x}
                                            </option>
                                            ))} 
                                    </select>
                                </div>
 
                                <label className="control-label" style={{ paddingRight:10 }}>View By:</label>
                                <div className="radio paddingRight">
                                    <label>
                                        <input type="radio" value="Month" checked={filter.timeSliceBy==="Month"} onChange={handleFilterChange("timeSliceBy")} />
                                        Month
                                    </label>
                                </div>
                                <div className="radio paddingRight">
                                    <label>
                                        <input type="radio" value="Year"  checked={filter.timeSliceBy==="Year"} onChange={handleFilterChange("timeSliceBy")} />
                                        Year
                                    </label>
                                </div>

                                <button
                                    className="btn btn-tessellate"
                                    style={{ marginRight: "15px" }}
                                    onClick={(e) => this.handleSearchByFilter( e, false ) }
                                    disabled={ filter.year === 0}
                                >
                                    Submit
                                </button>


                            </form>
                            </div>
 
                        </div>
                        )}
                    </Content.Filter>

                    { filter.submitted && (
                        <React.Fragment>
                            <div className="row export-spacing">
                                <div className="col-sm-12">

                                    <button className="btn btn-tessellate pull-right" onClick={(e) => this.handleSearchByFilter( e, true ) }>
                                            Export
                                    </button>

                                </div>
                            </div>
                            
                            <Content.Result>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <ReactTable

                                            className="ts-react-table -striped -highlight"
                                            columns={columns}
                                            data={data}
                                            manual
                                            loading={loading}
                                            defaultPageSize={pagination.pageSize}
                                            showPagination={false}
                                            noDataText='No Enrollments'
    />
                                    </div>
                                </div>
                            </Content.Result>
                        </React.Fragment>
                    )}

                </Content.Body>
            </Content>
        );
        
    }
}


export default connect(

    mapEnrollmentRecToProps,
    mapEnrollmentRecDispatchToProps

)( EnrollmentReconciliationReport );

