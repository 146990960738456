export const JobStatus = Object.freeze({
    "Unknown": "bg-light text-dark",
    "Requested": "bg-primary text-white",
    "WorkersRunning": "bg-info text-white",
    "WorkersFinished": "bg-success text-white",
    "SummaryRunning": "bg-info text-white",
    "Completed": "bg-success text-white",
    "Canceled": "bg-secondary text-white",
    "Exception": "bg-danger text-white",
    "MRRRunning": "bg-info text-white",
    "MRRFinished": "bg-success text-white",
    "Deleted": "bg-secondary text-white"
});
