// import React from 'react';
import {GapChaseFilterBase, filterType, dataType} from '../GapChaseFilterBase';

export default class YearFilter extends GapChaseFilterBase{
    constructor(numYears=4){
        super();
        this.id = 1;
        this.name = "Gap Year";
        this.filterName = "Year";
        // this.isHidden = false;
        this.filterType = filterType.select;
        this.values = [];

        let currentYear  = (new Date()).getFullYear();
        for (let x=0; x < numYears; x++){
            let yearToAdd = (currentYear - x).toString();
            this.values.push ({
                label: yearToAdd,
                value: yearToAdd
            });
        }

        this.dataType = dataType.number;
        this.filterCategory = "Gap Level";
        this.filterGroupId = 1;
        this.minValue = null;
        this.maxValue = null;
        this.step = null;
        this.placeholder = "Gap Year";
        // this.links = [
        //     {
        //         rel: "self",
        //         href: "http://localhost:50961/GapChaseFilters/1"
        //     }
        // ]
    }
    
    // return (
    //     <div style="padding-bottom: 10px;">
    //         <span>
	// 			<span style="color: red; padding-right: 0.2rem;">*</span>Gap Year
	// 		</span>
	// 		<select class="select-placeholder form-control" id="01125639-2911-950d-7482-88e24d4b98f6">
	// 			<option value="">Select</option>
	// 			<option value=yearToAdd>2019</option>
	// 			<option value="2018">2018</option>
	// 			<option value="2020">2020</option>
	// 			<option value="2021">2021</option>
	// 		</select>
    //     </div>
    // )
}

// GapYearFilter.defaultProps = {
//     numYears: 4,
//     placeHolderText = "Select"
// }

// GapYearFilter.PropTypes = {
//     numYears: PropTypes.number,
//     placeHolderText: PropTypes.string
// }

