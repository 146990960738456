
import React, { Component } from 'react';
import ReactTable from 'react-table';
import axios from 'axios';
import toastr from 'toastr';
import { renderBoolAsCheck, renderInteger, renderDecimal } from '../../../utils/CommonReactTable';
import { download } from '../../../utils/File';
import { Content } from '../../PageLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Constants from './Constants';


export default class GapMemberDetailTable extends Component {
	constructor(props) {
		super(props);

		let pagination = {
			pageNumber: 1,
			pageSize: props.results.PageSize,
			pageCount: props.results.PageCount,
			sortKey: "HCC",
			sortDirection: "ASC",
			totalItemCount:0  
		}

		this.state = {
			results: props.results,
			filter: props.filter,
			pagination,
			showHeader: true,
		};

		this.onPageChange = this.onPageChange.bind(this);
		this.onPageSizeChange = this.onPageSizeChange.bind(this);
		this.onPaging = this.onPaging.bind(this);
		this.onSortedChange = this.onSortedChange.bind(this);
	}


	static getDerivedStateFromProps(nextProps, prevState) {
		return {
			results: nextProps.results, memberMasterID: nextProps.memberMasterID
			, filter: nextProps.filter, totalItemCount: nextProps.results.TotalItemCount 
		};

	}

	onPaging = (pageSize, pageIndex, sortKey, sortDirection) => {
		const { memberMasterID, filter, pagination } = this.state;

		if (pageSize < 1)
			pageSize = this.state.pagination.pageSize;
		else
			pageIndex = 0;

		if (!sortKey) {
			sortKey = pagination.sortKey;
			sortDirection = pagination.sortDirection;
		}

		pagination.pageNumber = pageIndex + 1;
		pagination.pageSize = pageSize;
		pagination.sortKey = sortKey;
		pagination.sortDirection = sortDirection;

		const paramsDetail = {
			MemberMasterID: memberMasterID,
			specYearStart: filter.year,
			specYearEnd: filter.year,
			hiosId: filter.hiosId,
			market: filter.market,
			pageNumber: pagination.pageNumber,
			pageSize: pagination.pageSize,
			sortKey: pagination.sortKey,
			sortDirection: pagination.sortDirection
		};

		// run member query to get member data
		axios.get('/GapProfile/MemberGapDetail', { params: paramsDetail }).then((response) => {

			let results = this.state.results;
			results.Detail = response.data.Detail;
			pagination.pageCount = response.data.PageCount;

			this.setState({ results, memberMasterID, pagination });

		}).catch((error) => {
			console.log(error);
		})


	}

	onPageChange(pageIndex) {
		this.onPaging(-1, pageIndex, "", "");
	}

	onPageSizeChange(pageSize, pageIndex) {

		this.onPaging(pageSize, pageIndex, "", "");
	}

	onSortedChange(newSorted, column, shiftKey) {
		const sort = newSorted[0];
		const sortDirection = sort.desc ? "DESC" : "ASC";
		this.onPaging(-1, 0, sort.id, sortDirection);
	}


	handleExportDetailClick = (event) => {
		event.preventDefault();
		const { filter, memberMasterID, pagination } = this.state;
		const params = {
			memberMasterID,
			specYearStart: filter.year,
			specYearEnd: filter.year,
			hiosID: filter.hiosId,
			market: filter.market,
			ViewBy: "Member",
			sortKey: pagination.sortKey,
			sortDirection: pagination.sortDirection
		};

		axios.get('/GapProfile/ExportDetail', { params, responseType: 'blob' }).then((response) => {
			const fileName = response.headers['x-suggested-filename'] || 'ResultsExtract_' + new Date().getTime() + '.txt';
			download(response.data, fileName);
		}).catch((error) => {
			console.error(error);
			toastr.error('Failed to export data.');
		});
	}

	handleShowHeader = (event) => {
		this.setState({ showHeader: !this.state.showHeader })
	}

	onBack() {

		// viewBy: Constants.VIEW_BY.Member in normal case. However, there is another case.
		const { pagination } = this.state;
		const args = { MemberDetail: true, pagination, viewBy: this.props.goBackViewBy };
		this.props.onBack(args);
	}

	render() {
		const { results, pagination, totalItemCount } = this.state;

		const columns = [
			{
				Header: 'HIOS ID',
				accessor: 'HiosID'
			},
			{
				Header: 'Market',
				accessor: 'MarketName'
			},
			{
				Header: 'HCC Type',
				accessor: 'HCCType',
				sortable: true,
			},
			{
				Header: 'HCC',
				id: 'HCC',
				Cell: (row) => renderInteger(row.original.HCC),
				sortable: true,
			},
			{
				Header: 'HCC Description',
				accessor: 'HCCDescription',
				sortable: true
			},
			{
				Header: 'Risk Factor',
				id: 'RiskFactor',
				Cell: (row) => renderDecimal(row.original.RiskFactor, 3),
				sortable: true,
			},
			{
				Header: 'HCC Source',
				accessor: 'HCCSource',
				sortable: true
			},
			{
				Header: 'Last ICD Code',
				accessor: 'LastICDCode',
				sortable: true
			},
			{
				Header: 'Last Through DOS',
				accessor: 'LastThroughDOS',
				sortable: true
			},
			{
				Header: 'Last Provider ID',
				accessor: 'LastProviderID',
				sortable: true,
			},
			{
				Header: 'Last Provider Last Name',
				accessor: 'LastProviderLastName',
				sortable: true,
			},
			{
				Header: 'Last Provider First Name',
				accessor: 'LastProviderFirstName',
				sortable: true,
			},
			{
				id: 'IsPCP',
				Header: 'Is PCP',
				Cell: (row) => renderBoolAsCheck(row.original.IsPCP),
				width: 40
			}
		];

		let member = results.Header;
		let dobDate = new Date(member.DateOfBirth);
		let dob = dobDate.toLocaleDateString();
		const showHeaderIcon = this.state.showHeader ? "minus" : "plus";

		return (
			<Content>
				<Content.Header>Gap Profile Detail</Content.Header>
				<Content.Body>
					<Content.Filter>
						<div className="header-details">
							<div className="row">
								<div className="col-sm-12">
									<label>Member ID: </label>
									<span>{member.MemberId}</span>

									<button className="btn btn-default pull-right" onClick={this.handleShowHeader}>
										<FontAwesomeIcon icon={showHeaderIcon} />
									</button>

								</div>
							</div>
						</div>
						{
							this.state.showHeader &&
							<div className="header-details" >

								<div className="row">
									<div className="col-sm-12">
										<label>First Name: </label>
										<span>{member.FirstName}</span>
										<label>Last Name: </label>
										<span>{member.LastName}</span>
										<label>MI: </label>
										<span>{member.MiddleName}</span>
									</div>
								</div>
								<div className="row" style={{ paddingTop: '15px' }}>
									<div className="col-sm-12">
										<label>DOB: </label>
										<span>{dob}</span>
										<label>Sex: </label>
										<span>{member.Gender}</span>
									</div>
								</div>
							</div>
						}
					</Content.Filter>
					<div className="row export-spacing">
						<div className="col-sm-12">
							<button className="btn btn-default" onClick={() => this.onBack()}  >
								Back
							</button>

							<button className="btn btn-tessellate pull-right" onClick={this.handleExportDetailClick} >
								Export
							</button>
						</div>
					</div>
					<Content.Result>
						{/* property page is zero based. results.PageNumber is one based. */}

						<ReactTable
							className='ts-react-table -striped -highlight'
							columns={columns}
							data={results.Detail}
							page={pagination.pageNumber - 1}
							pages={pagination.pageCount}
							defaultPageSize={10}
							pageSizeOptions={[5, 10, 25, 50, 100]}
							sortable={false}
							defaultSorted={[{
								id: 'HCC',
								desc: false
							}]}
							multiSort={false}
							manual
							noDataText={(<span>No Results Found</span>)}
							onPageChange={this.onPageChange}
							onPageSizeChange={this.onPageSizeChange}
							onSortedChange={this.onSortedChange}

						/>
					<div><strong>Total Number of Records: {totalItemCount}</strong></div>
					</Content.Result>
				</Content.Body>
			</Content>
		);
	}

};
