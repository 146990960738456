import { GapChaseFilterBase, filterType, dataType } from '../GapChaseFilterBase';

export default class ORECListFilter extends GapChaseFilterBase {
    constructor() {
        super();
        this.id = 48;
        this.name = "Original Reason for Entitlement";
        this.filterName = "ORECList";

        // The following two lines make this filter LOB aware
        const validFilterLOBs = ["Medicare"];
        this.isHidden = validFilterLOBs.indexOf(localStorage.getItem('Lob')) < 0;

        this.filterType = filterType.multiSelect;
        this.values = [
            { label: "Age-Insured", value: "0" },
            { label: "Age Disabled", value: "1" },
            { label: "ESRD", value: "2" },
            { label: "Age Disabled-ESRD", value: "3" },
            { label: "Other", value: "9" },
            { label: "Status Unknown", value: "10"}
        ];

        this.dataType = dataType.number;
        this.filterCategory = "Member Level";
        this.filterGroupId = 2;
        this.placeholder = "Original Reason for Entitlement";
    }
}
