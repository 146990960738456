import { GapChaseFilterBase, filterType, dataType } from '../GapChaseFilterBase';

export default class MarketsFilter extends GapChaseFilterBase {
    constructor() {
        super();
        this.id = 11;
        this.name = "Market";
        this.filterName = "Markets";

        // The following two lines make this filter LOB aware
        const validFilterLOBs = ["Commercial"];
        this.isHidden = validFilterLOBs.indexOf(localStorage.getItem('Lob')) < 0;

        this.filterType = filterType.multiSelect;
        this.values = [
            { label: "Individual", value: "Individual" },
            { label: "Small Group", value: "Small Group" },
            { label: "Other", value: "Other" }
        ];

        this.dataType = dataType.number;
        this.filterCategory = "Member Level";
        this.filterGroupId = 2;
        this.placeholder = "Market";
    }
}
