import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink, withRouter } from 'react-router-dom';

import { toggleClass } from '../../utils/Document';

export default class Sidebar extends Component {
	constructor(props) {
		super(props);

		this.state = {
			sidebarOpen: true,
			expanded: [1, .2, 3, 4]
		};
	}

	expand = (num) => (evt) => {
		evt.preventDefault();
		if (this.state.expanded.indexOf(num) > -1) {
			this.setState({ expanded: this.state.expanded.filter(x => { return x !== num }) });
		} else {
			this.setState({
				expanded: [
					...this.state.expanded,
					num
				]
			});
		}
	}

	minimize = (e) => {
		toggleClass('main', 'sidebar-collapse');
		this.setState({ sidebarOpen: !this.state.sidebarOpen });
	}

	renderReports = () => {
		const { __administrative__token__ } = window.sessionStorage; 

		return (
			<ul className="treeview-menu">
				{
					typeof(__administrative__token__) !== 'undefined' &&
					<React.Fragment>
						<li>						
							<NavLink to="/Admin/MemberDispatcher">
								<span>Member Dispatcher</span>
							</NavLink>
						</li>
						<li>
							<NavLink to="/Admin/EngineJobManagement">
								<span>Engine Job Management</span>
							</NavLink>
						</li>	
					</React.Fragment>
				}
			</ul>
		);
	}

	render() {
		return (
			<aside className="main-sidebar">
				<section className="sidebar">
					<ul className="sidebar-menu">
						<li className={this.state.expanded.indexOf(1) > -1 ? "treeview active" : "treeview"}>
							<a href="#" onClick={this.expand(1)}>
								<FontAwesomeIcon icon="tools" fixedWidth />
								<span>Admin Tools</span>
								<span className="pull-right-container">
									<FontAwesomeIcon icon={(this.state.expanded.indexOf(1) > -1) ? "angle-down" : "angle-left"} fixedWidth />
								</span>
							</a>
							{this.renderReports()}
						</li>
					</ul>
				</section>
				<div className="sidebar-collapse">
					{
						this.state.sidebarOpen === true &&
						<span onClick={this.minimize} role="button">
							<FontAwesomeIcon icon="angle-double-left" size="lg" fixedWidth />
						</span>
					}
					{
						this.state.sidebarOpen === false &&
						<span onClick={this.minimize} role="button">
							<FontAwesomeIcon icon="angle-double-right" size="lg" fixedWidth />
						</span>
					}
				</div>
			</aside>
		);
	}
}
