import React from 'react';
import { Glyphicon, Grid } from 'react-bootstrap';

export function Error(props) {
    return(
        <div className="row">
            <div className="col-lg-12">
                <div className="panel panel-default">
                    <div className="panel-body">
                        <div>
                            <h4><Glyphicon glyph="exclamation-sign" style={{color: "#a94442", marginRight: "1rem"}} />An error occurred while processing your request.</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function Wait(props) {
    return(
        <div className="row">
            <div className="col-lg-12">
                <div className="panel panel-default">
                    <div className="panel-body">
                        <h4 style={{textAlign: 'center'}}>...(spinner) Loading filters...</h4>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function NoRecordsFound(props) {
    return(
        <div className="row">
            <div className="col-lg-12">
                <div className="maxDisplay">
                    <div className="panel panel-default">
                        <div className="panel-body">
                            <h4>The applied filter did not return any records</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function NotFound() {
    return(
        <Grid fluid style={{maxWidth: window.innerWidth}}>
            <div className="row">
                <div className="col-lg-10 col-lg-offset-1">
                    <div className="panel panel-default">
                        <div className="panel-body">
                            <h4><Glyphicon glyph="exclamation-sign" style={{color: "#a94442", marginRight: "1rem"}} />The page you are looking for cannot be found.</h4>
                        </div>
                    </div>
                </div>
            </div>
        </Grid>
    )
}