import React, { Component } from 'react';
import { Redirect } from 'react-router';
import '../assets/css/admin.css';
import { Header, Footer, AdminSidebar } from './PageLayout';
import FullPageLoader from './Shared/FullPageLoader';

export default class AdminApp extends Component {
	constructor(props) {
		super(props);

		this.state = {
			shouldRedirect: false
		}
	}

	componentWillMount() {
		const { pathname } = this.props.location;
		const { __administrative__token__ } = window.sessionStorage;

		if (pathname !== '/Admin/Login' && typeof (__administrative__token__) === 'undefined') {
			this.setState({ shouldRedirect: true });
		}
	}

	componentWillReceiveProps(nextProps) {
		const { pathname } = nextProps.location;
		const { __administrative__token__ } = window.sessionStorage;

		if (pathname !== '/Admin/Login' && typeof (__administrative__token__) === 'undefined') {
			this.setState({ shouldRedirect: true });
		} else {
			this.setState({ shouldRedirect: false });
		}
	}

	render() {
		const { shouldRedirect } = this.state;

		if (shouldRedirect)
			return (<Redirect to="/Admin/Login" />);

		return (
			<div className='wrapper'>
				<Header />
				<AdminSidebar />
				<div className='content-wrapper'>
					{this.props.children}
				</div>
				<Footer />
				<FullPageLoader />
			</div>
		)
	}
}
