import React, { Component } from "react";
import toastr from "toastr";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class JobDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            jobId: props.jobId,
            data: []
        };
    }

    componentDidMount() {
        this.getJobDetail(this.state.jobId);
    }

    getJobDetail = jobId => {
        const params = { jobId };

        axios
            .get("/v1/job/jobDetail/", { params })
            .then(response => {

                this.setState({
                    loading: false,
                    data: response.data
                });
            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false, data: [] });
                toastr.error("An error occurred retrieving job summary.");
            });
    };

    render() {
        const { jobId, data } = this.state;
        return (
            <div>
                <h5>JobDetail {jobId}</h5>
                <table className="modalTable">
                    <tbody>
                        <tr>
                            <td className="jdLabel">Member Requested</td>
                            <td className="jdRes">{data.Requested}</td>
                            <td className="jdLabel">Risk Cnt</td>
                            <td className="jdRes">{data.RiskCnt}</td>
                            <td className="jdLabel">Executed</td>
                            <td className="jdRes">{data.Executed}</td>
                            <td className="jdLabel">Exception Cnt</td>
                            <td className="jdRes">{data.ExceptionCnt}</td>

                            <td className="jdLabel">Gap Cnt</td>
                            <td className="jdRes">{data.GapCnt}</td>

                            <td className="jdLabel">Status</td>
                            <td className="jdRes">{data.Status}</td>

                            <td className="jdLabel">Visit Cnt</td>
                            <td className="jdRes">{data.VisitCnt}</td>

                            <td className="jdLabel">GapSummary Cnt</td>
                            <td className="jdRes">{data.GapSummaryCnt}</td>

                            <td className="jdLabel">ProductSummary Cnt</td>
                            <td className="jdRes">{data.ProductSummaryCnt}</td>

                            <td className="jdLabel">PcpSummary Cnt</td>
                            <td className="jdRes">{data.PcpSummaryCnt}</td>

                            <td className="jdLabel">RiskSummary Cnt</td>
                            <td className="jdRes">{data.RiskSummaryCnt}</td>

                            <td className="jdLabel">FinancialSummary Cnt</td>
                            <td className="jdRes">{data.FinancialSummaryCnt}</td>

                            <td className="jdLabel">PlanSummary Cnt</td>
                            <td className="jdRes">{data.PlanSummaryCnt}</td>
                            <td className="jdLabel">ProviderSummary Cnt</td>
                            <td className="jdRes">{data.ProviderSummaryCnt}</td>
                            <td className="jdLabel">PlanDetailSummary Cnt</td>
                            <td className="jdRes">{data.PlanDetailSummaryCnt}</td>

                        </tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                    </tbody>
                </table>
                {/* <label className="tab">Member Requested:</label>
                <span>{data.Requested}</span> */}
            </div>
        );
    }
}
