import React, { Component } from "react";
import axios from "axios";

import {
  renderInteger,
  renderDecimalLink
} from "../../../utils/CommonReactTable";

import ReactTable from "react-table";
import MemberRiskScoreDetailModal from "./MemberRiskScoreDetailModal";

export default class MemberRARSDScore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      results: [],
      columnSorting:[{id: "BenefitYear", desc: true}, {id: "PlanId", desc: false},{id: "MetalLevel", desc: false}],
      memberMasterId: props.memberMasterId,
      BenefitYear: props.benefitYr,
      pageCount: 0,
      pageNumber: 1,
      pageSize: 10,
      loading: false,
      showHistory: props.showHistory,
      showScoreDetailModal: false,
      totalItemCount:0
      
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.showHistory !== nextState.showHistory) {
      this.setState({ ...nextProps }, this.getRARSDScore);
    }

    return true;
  }

  onPaging = () => {
    let pageSize, pageIndex;
    if (this.state.pageSize < 1) {
      pageSize = this.state.results.PageSize;
    } else {
      pageIndex = 0;
    }

    let pageNumber = pageIndex + 1;
    this.setState(
      {
        loading: true,
        pageNumber: pageNumber,
        pageSize
      },
      this.getRARSDScore
    );
  };

  onPageChange = pageIndex => {
    this.onPaging(-1, pageIndex);
  };

  onPageSizeChange = (pageSize, pageIndex) => {
    this.onPaging(pageSize, pageIndex);
  };

  onHide = () => {
    this.setState({ showScoreDetailModal: false });
  };

  handleOnFetchData = (state, instance) => {
    const { page, pageSize } = instance.state;
    this.setState(
      { loading: true, pageNumber: page + 1, pageSize },
      this.getRARSDScore
    );
  };

  getRARSDScore = () => {
    const {
      memberMasterId,
      pageNumber,
      pageSize,
      BenefitYear,
      showHistory,
      columnSorting,
      totalItemCount
    } = this.state;

    let params = {
      pageNumber,
      pageSize,
      columnSorting,
      benefitYear: showHistory === false ? BenefitYear : 9999
    };

    axios
      .get(`/MemberProfile/${memberMasterId}/RARSDScore`, { params })
      .then(response => {
        this.setState({
          results: response.data.Detail,
          loading: false,
          pageCount: response.data.PageCount,
          totalItemCount :response.data.TotalItemCount
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  columnSortinghandler = (sorted)=>{
		this.setState({ columnSorting: sorted, pageNumber: 1 } , this.getRARSDScore);
	}

  render() {
    const { results, loading, pageSize, pageNumber, pageCount,totalItemCount } = this.state;
    const { handleOnFetchData, onPageChange, onPageSizeChange, columnSortinghandler } = this;
    const columns = [
      {
        Header: "Benefit Year",
        id: "BenefitYear",
        width: 110,
        Cell: row => renderInteger(row.original.BenefitYear)
      },
      {
        Header: "Plan ID",
        width: 185,
        accessor: "PlanID"
      },
      {
        Header: "Metal Level",
        width: 110,
        accessor: "Metal"
      },
      {
        Header: "Jan",
        id: "Jan",
        width: 55,
        Cell: row => renderDecimalLink(row.original.Jan, 3, "")
      },
      {
        Header: "Feb",
        accessor: "Feb",
        width: 55,
        Cell: row => renderDecimalLink(row.original.Feb, 3, "")
      },
      {
        Header: "Mar",
        id: "Mar",
        width: 55,
        Cell: row => renderDecimalLink(row.original.Mar, 3, "")
      },
      {
        Header: "Apr",
        id: "Apr",
        width: 55,
        Cell: row => renderDecimalLink(row.original.Apr, 3, "")
      },
      {
        Header: "May",
        id: "May",
        width: 55,
        Cell: row => renderDecimalLink(row.original.May, 3, "")
      },
      {
        Header: "Jun",
        id: "Jun",
        width: 55,
        Cell: row => renderDecimalLink(row.original.Jun, 3, "")
      },
      {
        Header: "Jul",
        id: "Jul",
        width: 55,
        Cell: row => renderDecimalLink(row.original.Jul, 3, "")
      },
      {
        Header: "Aug",
        id: "Aug",
        width: 55,
        Cell: row => renderDecimalLink(row.original.Aug, 3, "")
      },
      {
        Header: "Sep",
        id: "Sep",
        width: 55,
        Cell: row => renderDecimalLink(row.original.Sep, 3, "")
      },
      {
        Header: "Oct",
        id: "Oct",
        width: 55,
        Cell: row => renderDecimalLink(row.original.Oct, 3, "")
      },
      {
        Header: "Nov",
        id: "Nov",
        width: 55,
        Cell: row => renderDecimalLink(row.original.Nov, 3, "")
      },
      {
        Header: "Dec",
        id: "Dec",
        width: 55,
        Cell: row => renderDecimalLink(row.original.Dec, 3, "")
      }
    ];

    return (
      <React.Fragment>
        <ReactTable
          className="ts-react-table -striped -highlight"
          columns={columns}
          data={results}
          page={pageNumber - 1}
          loading={loading}
          onFetchData={handleOnFetchData}
          pages={pageCount}
          defaultPageSize={pageSize}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          sortable={true}
          manual
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          onSortedChange = {sorted=>columnSortinghandler(sorted)}
          noDataText={(<span>No Results Found</span>)}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              onClick: (e, handleOriginal) => {
                if (rowInfo) {
                  const dt = Date.parse(
                    column.id + " 1, " + rowInfo.original.BenefitYear
                  );
                  if (!isNaN(dt)) {
                    let scoreDetailScore = rowInfo.original[column.id];
                    let asOfMonth = new Date(dt).getMonth() + 1;

                    // do not process dashes
                    if (!isNaN(scoreDetailScore)) {
                      this.setState({
                        showScoreDetailModal: true,
                        scoreDetailModalTitle: column.id,
                        planId: rowInfo.original.PlanID,
                        scoreDetailScore,
                        asOfMonth
                      });
                    }
                  }
                }
                if (handleOriginal) {
                  handleOriginal();
                }
              }
            };
          }}
        />
          <div><strong>Total Number of Records: {totalItemCount}</strong></div>
        {/* {
                    this.state.showScoreDetailModal && <MemberRiskScoreComparisonModal show={this.state.showScoreDetailModal}
                        onShow={this.show} onHide={this.onHide} score={this.state.scoreDetailScore}
                        memberMasterId={this.state.memberMasterId} calcId={30} calcId2={40} benefitYear={this.state.BenefitYear}
                        asOfMonth={this.state.asOfMonth} planId={this.state.planId}
                        />
                }  */}
        {this.state.showScoreDetailModal && (
          <MemberRiskScoreDetailModal
            show={this.state.showScoreDetailModal}
            onShow={this.show}
            onHide={this.onHide}
            title={"CMS RARSD"}
            score={this.state.scoreDetailScore}
            memberMasterId={this.state.memberMasterId}
            calcId={40}
            benefitYear={this.state.BenefitYear}
            CMSDataAsofMonth={this.state.asOfMonth}
            planId={this.state.planId}
          />
        )}
      </React.Fragment>
    );
  }
}
