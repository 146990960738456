import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import { HashRouter } from 'react-router-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import 'toastr';
import './config/icons';
import './config/interceptor';
import Routes from './config/routes';
import rootReducer from './redux/reducers';

/*  External CSS    */
import 'bootstrap/dist/css/bootstrap.min.css';
//import 'bootstrap/dist/css/bootstrap-theme.min.css';
import 'toastr/build/toastr.css';
import 'react-bootstrap-multiselect/css/bootstrap-multiselect.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-table/react-table.css';
import 'react-bootstrap-toggle/dist/bootstrap2-toggle.css';

/*  Internal CSS    */
import './assets/css/base.css';
import './assets/css/commercial.css';
import './assets/css/gap-chase.css';

const store = createStore(rootReducer);
render(
    <Provider store={store}>
        <HashRouter>{Routes}</HashRouter>
    </Provider>,
    document.getElementById('app')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();