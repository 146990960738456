import React, { Component } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import { Content } from '../../PageLayout';
import ReactTable from 'react-table';
import { renderMarkets, renderDetail, renderInteger, renderDecimal, renderCapitalize } from '../../../utils/CommonReactTable';
import { download } from '../../../utils/File';

import { connect } from "react-redux";
import {
    mapFinancialProfileDispatchToProps,
    mapFinancialProfileToProps
} from "../../../redux/actions";
import { Redirect } from "react-router-dom";


class FinancialProfileReport extends Component {
    constructor(props) {
        super(props);

        this.state = {

            filter: {
                benefitYear: 0,
                hiosId: 'All',
                market: 'All',
                defaultFilter: true,
                submitted: false
            },

            pagination: {
                pageCount: 0,
                pageNumber: 1,
                pageSize: 10,
                // sortKey: 'PlanID',
                // sortDirection: 'ASC'
            },

            data: [],
            results: [],
            years: [],
            hiosIds: [],
            marketInput: [],
            loading: false,
            totalItemCount: 0,
            detailUrl: null,
            redirect: false
        };

        this.onPageChange = this.onPageChange.bind(this);
        this.onPageSizeChange = this.onPageSizeChange.bind(this);
        this.onPaging = this.onPaging.bind(this);
        this.onSortedChange = this.onSortedChange.bind(this);
    }

    componentDidMount() {
        axios.get('/FinancialProfile/Options').then((response) => {
            const { data } = response;
            const { filter } = this.state;
            const years = data.map(x => x.Year);
            filter.benefitYear = years[0];
            const hiosIds = this.getHiosIds(filter.benefitYear, data);
            const marketInput = this.getMarketInput(data);

            this.setState({ data, years, marketInput, filter, hiosIds });
        }).catch((err) => {
            console.warn(err);
            toastr.error('An error occurred retrieving filter options.');
        }).finally(this.displayMasterPage);
    }

    displayMasterPage = () => {
        const { search } = this.props.location;

        console.log('FPR dmp props.location = ' + JSON.stringify(this.props.location));
        console.log('FPR dmp props = ' + JSON.stringify(this.props));

        let filter = {
            benefitYear: 0,
            hiosId: 'All',
            market: 'All'
        };

        let pagination = {
            pageNumber: 1,
            pageSize: 10,
            sortKey: '',
            sortDirection: ''
        };

        if (search) {
            let temp = search.split('&');
            temp.forEach((x) => {
                let keyPair = x.split('=');
                if (keyPair[0].indexOf('benefitYear') > -1) {
                    filter.benefitYear = parseInt(keyPair[1]);
                } else if (keyPair[0].indexOf('hiosId') > -1) {
                    filter.hiosId = keyPair[1];
                } else if (keyPair[0].indexOf('market') > -1) {
                    filter.market = keyPair[1].replace("%20", " ");
                } else if (keyPair[0].indexOf('pageNumber') > -1) {
                    pagination.pageNumber = parseInt(keyPair[1]);
                } else if (keyPair[0].indexOf('pageSize') > -1) {
                    pagination.pageSize = parseInt(keyPair[1]);
                } else if (keyPair[0].indexOf('sortKey') > -1) {
                    pagination.sortKey = keyPair[1];
                } else if (keyPair[0].indexOf('sortDirection') > -1) {
                    pagination.sortDirection = keyPair[1];
                }
            });

            if (pagination.sortKey === '') pagination.sortKey = 'PlanID';

            const hiosIds = this.getHiosIds(filter.benefitYear, this.state.data);
            this.setState({ filter, hiosIds, pagination }, this.checkAndSetFromState)

        }
    }


    checkAndSetFromState = () => {
        const { filter, pagination } = this.props.financialProfile;

        // console.log( 'checkAndSetFromState filter = ' + JSON.stringify( filter ) );
        // console.log( 'checkAndSetFromState pagination = ' + JSON.stringify( pagination ) );


        // If filter.defaultFilter in redux is true, we have default state
        if (filter.defaultFilter) {
            this.props.resetFinancialProfile();
        }

        // If !filter.defaultFilter, which means we have the state which is NOT default state
        // We can get stored states from props, and set them to current state.

        else {

            this.setState({ filter, pagination }, () => this.handleSearch());

        }
    };

    getMarketInput = (data) => {
        const marketInput = data.map(x => x.Markets)[0].sort();

        if (marketInput.indexOf('All') < 0) {
            marketInput.unshift('All');
        }

        return marketInput;
    }

    handleYearChange = (event) => {
        const { value } = event.target;
        const { data } = this.state;

        const hiosIds = this.getHiosIds(value, data);
        const marketInput = this.getMarketInput(data);

        let hiosId = 'All';
        let market = 'All';

        if (marketInput.length === 2) {
            market = marketInput[0];
        }

        if (hiosIds.length === 2) {
            hiosId = hiosIds[0];
        }

        this.setState({ hiosIds, marketInput, filter: { benefitYear: parseInt(value), hiosId, market } });
    }

    handleHiosIdChange = (event) => {
        this.setState({ filter: { ...this.state.filter, hiosId: event.target.value } })
    }

    handleMarketChange = (event) => {
        this.setState({ filter: { ...this.state.filter, market: event.target.value } })
    }

    getHiosIds = (year, data) => {
        const hiosIds = data.filter(x => x.Year === parseInt(year)).map(y => y.HiosIds)[0].sort();

        var hiosIdIndex = hiosIds.indexOf('All');

        if (hiosIdIndex > 0) {
            hiosIds.splice(hiosIdIndex, 1);
            hiosIdIndex = -1;
        }

        if (hiosIdIndex < 0) {
            hiosIds.unshift('All');
        }

        return hiosIds;
    }


    searchClick = (event) => {
        event.preventDefault();

        var filter = this.state.filter;
        filter.submitted = true;

        this.setState({ filter }, this.handleSearch);
    }

    handleSearch = () => {
        const { filter, pagination } = this.state;

        filter.submitted = true;

        const params = {
            ...filter,
            ...pagination
        };

        axios.get('/FinancialProfile', { params }).then((response) => {

            var pagination = this.state.pagination;
            pagination.pageCount = response.data.PageCount;

            this.setState({
                filter,
                loading: false,
                pagination,
                results: response.data.Detail,
                totalItemCount : response.data.TotalItemCount
            });
        }).catch((error) => {
            console.log(error);
            this.setState({ results: [] });
            toastr.error('An error occurred retrieving financial profile data.');
        })
    }

    handleExportClick = (event) => {
        event.preventDefault();
        const { filter, pagination } = this.state;
        const sortKey = pagination.sortKey;
        const sortDirection = pagination.sortDirection;

        const params = {
            ...filter,
            sortKey,
            sortDirection
        }

        axios.get('/FinancialProfile/Export', { params, responseType: 'blob' }).then((response) => {
            const fileName = response.headers['x-suggested-filename'] || 'ResultsExtract_' + new Date().getTime() + '.txt';
            download(response.data, fileName)
        }).catch((error) => {
            console.error(error);
            toastr.error('Failed to export data.');
        });
    }

    onSortedChange = (newSorted, column) => {
        const sort = newSorted[0];
        const sortDirection = sort.desc ? "DESC" : "ASC";
        this.onPaging(-1, 0, sort.id, sortDirection);
    }

    onPaging = (pageSize, pageIndex, sortKey, sortDirection) => {
        const { pagination } = this.state;


        if (pageSize < 1)
            pageSize = pagination.pageSize;
        else
            pageIndex = 0;

        if (!sortKey) {
            sortKey = pagination.sortKey;
            sortDirection = pagination.sortDirection;
        }

        pagination.pageSize = pageSize;
        pagination.pageNumber = pageIndex + 1;
        pagination.sortKey = sortKey;
        pagination.sortDirection = sortDirection;

        this.setState({ pagination }, this.handleSearch)
    }

    onPageChange(pageIndex) {
        this.onPaging(-1, pageIndex, "", "");
    }

    onPageSizeChange(pageSize, pageIndex) {

        this.onPaging(pageSize, pageIndex, "", "");
    }

    generateLink = (benefitYear, hiosID, market, planID) => {
        // const sortKey = 'PlanID';
        const { pagination } = this.state;

        var link = '/Reporting/FinancialProfile/' + benefitYear + '/' + hiosID + '/' + market + '/' + planID;
        link += '?pageNumber=' + pagination.pageNumber + '&pageSize=' + pagination.pageSize
            + '&sortKey=' + pagination.sortKey + '&sortDirection=' + pagination.sortDirection + '&market=' + this.state.filter.market;


        return link;
    }

    setupFinancialProfile = () => {
        const { filter, pagination } = this.state;

        filter.defaultFilter = false;

        const financialProfile = {
            filter,
            pagination
        };


        this.props.setFinancialProfile(financialProfile);


    };

    setRedirect = (benefitYear, hiosID, market, planID) => {
        var url = this.generateLink(
            benefitYear,
            hiosID,
            market,
            planID
        );
        this.setState({
          redirect: true,
          detailUrl: url
        }, ()=>this.setupFinancialProfile())
    }

    render() {
        const { filter, results, years, hiosIds, marketInput, pagination, loading, totalItemCount, redirect, detailUrl } = this.state;
        const { handleYearChange, handleHiosIdChange, handleMarketChange, searchClick, handleExportClick
            , onSortedChange, onPageChange, onPageSizeChange, generateLink, setRedirect } = this;

        var cb = this.setupFinancialProfile;

        const columns = [
            {
                Header: 'Detail',
                id: 'PlanId',
                Cell: (row) => renderDetail(generateLink(row.original.BenefitYear, row.original.HiosID, row.original.Market, row.original.PlanID), cb)

            },
            {
                Header: 'Plan ID',
                accessor: 'PlanID',
                width: 200,
                sortable: true
            },
            {
                Header: 'HIOS ID',
                accessor: "HiosID"
            },
            {
                Header: 'Market',
                accessor: 'Market',
                sortable: true,
                Cell: (row) => renderCapitalize(row.original.Market)
            },
            {
                Header: 'Benefit Year',
                id: 'BenefitYear',
                Cell: (row) => renderInteger(row.original.BenefitYear)
            },
            {
                Header: 'As of Month/Year',
                accessor: 'AsOfMonYr',
                sortable: true
            },
            {
                Header: 'Total Members',
                id: 'TotalMembers',
                Cell: (row) => renderInteger(row.original.TotalMembers),
                sortable: true
            },
            {
                Header: 'Member Months',
                id: 'MemberMonths',
                Cell: (row) => renderDecimal(row.original.MemberMonths, 3),
                sortable: true
            },
            {
                Header: 'Medical Claims',
                id: 'MedicalClaims',
                Cell: (row) => renderDecimal(row.original.MedicalClaims, 2, "$"),
                sortable: true
            },
            {
                Header: 'Pharmacy Claims',
                id: 'PharmacyClaims',
                Cell: (row) => renderDecimal(row.original.PharmacyClaims, 2, "$"),
                sortable: true
            },
            {
                Header: 'Avg PMPM Expense',
                id: 'AvgPmpmExpense',
                Cell: (row) => renderDecimal(row.original.AvgPmpmExpense, 2, "$"),
                sortable: true
            }
        ];

        
        if (redirect) {
            return <Redirect to = {detailUrl} />
          }

        return (
            <Content>
                <Content.Header>Financial Profile Report</Content.Header>
                <Content.Body>
                    {
                        years.length > 0 &&
                        <Content.Filter>
                            <div className="row">
                                <div className="col-sm-12">
                                    <form className="form-inline">
                                        <div className="form-group">
                                            <label className="control-label">Benefit Year:</label>
                                            <select className="form-control" value={filter.benefitYear} onChange={handleYearChange}>
                                                {
                                                    years.map(x => <option key={x} value={x}>{x}</option>)
                                                }
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label className="control-label">HIOS ID:</label>
                                            <select className="form-control"
                                                value={filter.hiosId}
                                                onChange={handleHiosIdChange}
                                                disabled={hiosIds.length < 2}
                                            >
                                                {
                                                    hiosIds.map(x => <option key={x} value={x}>{x}</option>)
                                                }
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label className="control-label">Market:</label>
                                            <select className="form-control"
                                                value={filter.market}
                                                onChange={handleMarketChange}
                                                disabled={marketInput.length < 2}
                                            >
                                                {
                                                    marketInput.map(x => <option key={x} value={x}>{renderMarkets(x)}</option>)
                                                }
                                            </select>
                                        </div>
                                        <button className="btn btn-tessellate"
                                            onClick={searchClick}
                                        >Submit</button>
                                    </form>
                                </div>
                            </div>
                        </Content.Filter>
                    }
                    {filter.submitted &&
                        <span>
                            <div className="row export-spacing">
                                <div className="col-sm-12">
                                    <button className="btn btn-tessellate pull-right" onClick={handleExportClick}>
                                        Export
                            </button>
                                </div>
                            </div>
                            <Content.Result>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <ReactTable
                                            className="ts-react-table -striped -highlight table-row-cursor"
                                            columns={columns}
                                            data={results}
                                            manual
                                            loading={loading}
                                            defaultPageSize={pagination.pageSize}
                                            page={pagination.pageNumber - 1}
                                            pages={pagination.pageCount}
                                            pageSizeOptions={[10, 25, 50, 100]}
                                            sortable={false}
                                            multiSort={false}

                                            onSortedChange={onSortedChange}
                                            onPageChange={onPageChange}
                                            onPageSizeChange={onPageSizeChange}

                                            noDataText={(<span>No Results Found</span>)}
                                            getTrProps={(state, rowInfo, column) => {
                                                return {
                                                  onClick: (event)=>setRedirect(rowInfo.row._original.BenefitYear, rowInfo.row._original.HiosID, rowInfo.row._original.Market, rowInfo.row._original.PlanID)}
                                                  }                                    
                                          }
                                        />
                                        <div><strong>Total Number of Records: {totalItemCount}</strong></div>
                                    </div>
                                </div>
                            </Content.Result>
                        </span>
                    }
                </Content.Body>
            </Content>
        );
    }
}

export default connect(

    mapFinancialProfileToProps,
    mapFinancialProfileDispatchToProps

)(FinancialProfileReport);

