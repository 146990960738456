import React from 'react';
import axios from 'axios';
import toastr from 'toastr';
import ReactTable from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { renderInteger } from '../../../utils/CommonReactTable';

export default class MemberEnrollmentReport extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			...props,
			data: [],
			columnSorting:[{id: "BenefitYear", desc: true}, {id: "PlanId", desc: false},{id: "Market", desc: false}],
			totalItemCount:0
		}
	}

	componentDidMount() {
		this.getMemberEnrollmentData();
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (nextProps.showHistory !== nextState.showHistory) {
			this.setState({ ...nextProps }, this.getMemberEnrollmentData);
		}

		return true;
	}

	getMemberEnrollmentData = () => {
		const { memberMasterId, showHistory, benefitYr,columnSorting} = this.state;

		let params = {columnSorting};
		if (showHistory === false) {
			params = {
				columnSorting,
				benefitYear: benefitYr
			};
		}

		axios.get('/MemberProfile/' + memberMasterId + '/Enrollment', { params }).then((response) => {
			this.setState({ data: response.data,  totalItemCount: response.data.length });
		}).catch((error) => {
			console.log(error);
			toastr.error('An error occurred retrieving member pcp data');
		});
	}

	renderMonthEnrollment = (year, month, value) => {
		const today = new Date();

		if (today.getFullYear() === year && today.getMonth()+1 < month) {
			return (
				<div style={{ width: '100%', textAlign: 'center' }}>n/a</div>
			)
		} else if (value === true) {
			return (
				<div style={{ width: '100%', textAlign: 'center' }}>
					<FontAwesomeIcon icon="check" />
				</div>
			)
		} else {
			return (
				<div style={{ width: '100%', textAlign: 'center' }}>
					<FontAwesomeIcon icon="minus" />
				</div>
			)
		}
	}

	columnSortinghandler = (sorted)=>{
		this.setState({ columnSorting:  sorted } , this.getMemberEnrollmentData);
	}

	render() {
		const { data,totalItemCount } = this.state;
		const { renderMonthEnrollment,columnSortinghandler } = this;
		const columns = [
			{
				Header: 'Benefit Year',
				id: 'BenefitYear',
				Cell: (row) => renderInteger(row.original.BenefitYear)
			},
			{
				Header: 'Plan ID',
				accessor: 'PlanId',
				width: 200
			},
			{
				Header: 'HIOS ID',
				accessor: 'HiosId'
			},
			{
				Header: 'Market',
				accessor: 'Market'
			},
			{
				Header: 'As of Mon/Yr',
				accessor: 'AsOfMonYr'
			},
			{
				Header: 'Metal Level',
				accessor: 'MetalLevel'
			},
			{
				Header: 'Jan',
				id: 'Jan',
				width: 50,
				Cell: (row) => renderMonthEnrollment(row.original.BenefitYear, 1, row.original.Jan)
			},
			{
				Header: 'Feb',
				id: 'Feb',
				width: 50,
				Cell: (row) => renderMonthEnrollment(row.original.BenefitYear, 2, row.original.Feb)
			},
			{
				Header: 'Mar',
				id: 'Mar',
				width: 50,
				Cell: (row) => renderMonthEnrollment(row.original.BenefitYear, 3, row.original.Mar)
			},
			{
				Header: 'Apr',
				id: 'Apr',
				width: 50,
				Cell: (row) => renderMonthEnrollment(row.original.BenefitYear, 4, row.original.Apr)
			},
			{
				Header: 'May',
				id: 'May',
				width: 50,
				Cell: (row) => renderMonthEnrollment(row.original.BenefitYear, 5, row.original.May)
			},
			{
				Header: 'Jun',
				id: 'Jun',
				width: 50,
				Cell: (row) => renderMonthEnrollment(row.original.BenefitYear, 6, row.original.Jun)
			},
			{
				Header: 'Jul',
				id: 'Jul',
				width: 50,
				Cell: (row) => renderMonthEnrollment(row.original.BenefitYear, 7, row.original.Jul)
			},
			{
				Header: 'Aug',
				id: 'Aug',
				width: 50,
				Cell: (row) => renderMonthEnrollment(row.original.BenefitYear, 8, row.original.Aug)
			},
			{
				Header: 'Sep',
				id: 'Sep',
				width: 50,
				Cell: (row) => renderMonthEnrollment(row.original.BenefitYear, 9, row.original.Sep)
			},
			{
				Header: 'Oct',
				id: 'Oct',
				width: 50,
				Cell: (row) => renderMonthEnrollment(row.original.BenefitYear, 10, row.original.Oct)
			},
			{
				Header: 'Nov',
				id: 'Nov',
				width: 50,
				Cell: (row) => renderMonthEnrollment(row.original.BenefitYear, 11, row.original.Nov)
			},
			{
				Header: 'Dec',
				id: 'Dec',
				width: 50,
				Cell: (row) => renderMonthEnrollment(row.original.BenefitYear, 12, row.original.Dec)
			}
		];

		return (
			<div className="col-sm-12">
			<ReactTable
				className="ts-react-table -striped -highlight"
				columns={columns}
				data={data}
				defaultPageSize={10}
				pageSizeOptions={[10, 25, 50, 100]}
				sortable={true}
				onSortedChange = {sorted=>columnSortinghandler(sorted)}
				noDataText={(<span>No Results Found</span>)}
			/>
			<div><strong>Total Number of Records: {totalItemCount}</strong></div>
			</div>
		)
	}
}