import { GapChaseFilterBase, filterType, dataType } from '../GapChaseFilterBase';

export default class MemberPopulationFilter extends GapChaseFilterBase {
    constructor() {
        super();
        this.id = 14;
        this.name = "Member Population";
        this.filterName = "MemberPopulation";
        this.isHidden = true; // Hiding per request from Christian 11/09/2021
        this.filterType = filterType.multiSelect;
        this.values = [
            { label: "Members With Gaps", value: "MembersWithGaps" },
            { label: "Members Without Gaps", value: "MembersWithoutGaps" }
        ];

        this.dataType = dataType.number;
        this.filterCategory = "Member Level";
        this.filterGroupId = 2;
        this.placeholder = "Member Population";
    }
}
