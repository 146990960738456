import React from 'react';
import Axios from 'axios';
import { Glyphicon, Table } from 'react-bootstrap';
import { Col, Collapse, Panel, Row } from 'react-bootstrap';
import { Sort } from './TableComponents';
import {
    defaultGapListResultStatsMemberCountByPlanColumns,
    defaultGapListResultStatsMemberCountByRAStatus
} from './DefaultColumns.js';
import { Error } from './Messages';
import { clone } from 'lodash';

export default class ListResultStats extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listResultStats: null,
            sortOrder: [],
            listResultRAStatusStats: null,
            sortOrderRAStatus: [],
            error: this.props.showError ? this.props.showError : false
        };

        this.columnsMemberCountByPlan = defaultGapListResultStatsMemberCountByPlanColumns();
        this.columnsMemberCountByRAStatus = defaultGapListResultStatsMemberCountByRAStatus();

        const CancelToken = Axios.CancelToken;
        this.source = CancelToken.source();
    }

    componentDidMount() {
        //load stats on create
        if (this.props.listDefinition && this.props.listDefinition.loadingStatus === "Complete") {
            this.loadListResultStats(this.props.listDefinition.id);
            this.loadListResultStatsRAStatus(this.props.listDefinition.id);
        }
    }

    componentWillUpdate(nextProps, nextState) {
        //load stats on update
        if (nextProps.listDefinition && this.props.listDefinition && nextProps.listDefinition.loadingStatus !== this.props.listDefinition.loadingStatus) {
            this.loadListResultStats(nextProps.listDefinition.id);
            this.loadListResultStatsRAStatus(nextProps.listDefinition.id);
        }

        if (nextProps.showError === true && this.props.showError !== nextProps.showError) {
            this.setState({ error: true });
        }
    }

    componentWillUnmount() {
        this.source.cancel();
    }
        handleSort = (sortData, e) => {
            this.columnsMemberCountByPlan.forEach((item, index) => {
            if (item.fieldName === sortData.fieldName) {
                this.columnsMemberCountByPlan[index].sortOrder = 1;
                this.columnsMemberCountByPlan[index].sortDirection = sortData.sortDirection;
            }
            else {
                if (item.sortOrder === undefined) {
                    item.sortOrder = 0;
                }
                this.columnsMemberCountByPlan[index].sortOrder = 0;
                this.columnsMemberCountByPlan[index].sortDirection = "";
            }
        });
        this.pageChange(0);
    }

    handleRAStatusSort = (sortData, e) => {
        this.columnsMemberCountByRAStatus.forEach((item, index) => {
            if (item.fieldName === sortData.fieldName) {
                this.columnsMemberCountByRAStatus[index].sortOrder = 1;
                this.columnsMemberCountByRAStatus[index].sortDirection = sortData.sortDirection;
            }
            else {
                if (item.sortOrder === undefined) {
                    item.sortOrder = 0;
                }
                this.columnsMemberCountByRAStatus[index].sortOrder = 0;
                this.columnsMemberCountByRAStatus[index].sortDirection = "";
            }
        });
        this.pageChange(0);
    }

    pageChange = (idx) => {
        this.loadListResultStats(this.props.listDefinition.id);
        this.loadListResultStatsRAStatus(this.props.listDefinition.id);
    }

    loadListResultStats = (listID) => {
        this.getListResultStats(listID)
        .then((response) => {
            if (response.status === 200) {
                this.setState({
                    listResultStats: response.data,
                    expanded: true
                });
            }
        })
        .catch((error) => {
            if (!Axios.isCancel(error)) {
                // handle error
                this.setState({ error: true });
            }
        });
    }

    loadListResultStatsRAStatus = (listID) => {
        this.getListResultRAStatusStats(listID)
            .then((response) => {
                if (response.status === 200) {
                    this.setState({
                        listResultRAStatusStats: response.data,
                        expanded: true
                    });
                }
            })
            .catch((error) => {
                if (!Axios.isCancel(error)) {
                    // handle error
                    this.setState({ error: true });
                }
            });
    }

    getListResultStats = (listID) => {
        const sortOrder = [];
        let localColumns = clone(this.columnsMemberCountByPlan);
        localColumns.sort((a, b) => { return a.sortOrder > b.sortOrder }).forEach((item) => {
            if (item.sortDirection !== undefined && item.sortDirection.length && item.sortOrder > 0) {
                sortOrder.push(item.fieldName + " " + item.sortDirection);
            }
        });
        return Axios.get(`/v1/Lists/Gap/${listID}/stats`, {
            params: {
                sortOrder: sortOrder.join(',')
            },
            cancelToken: this.source.token
        });
    }

    getListResultRAStatusStats = (listID) => {
        const sortOrder = [];
        let localColumns = clone(this.columnsMemberCountByRAStatus);
        localColumns.sort((a, b) => { return a.sortOrder > b.sortOrder }).forEach((item) => {
            if (item.sortDirection !== undefined && item.sortDirection.length && item.sortOrder > 0) {
                sortOrder.push(item.fieldName + " " + item.sortDirection);
            }
        });
        return Axios.get(`/v1/Lists/Gap/${listID}/RA/stats`, {
            params: {
                sortOrder: sortOrder.join(',')
            },
            cancelToken: this.source.token
        });
    }

    expandPanel = () => {
        this.setState({ expanded: !this.state.expanded });
    }
    
    listStatsGroupByTable = (columns, count, paddingRight = '0px', paddingLeft = '0px', isPlanStats=1) => {
        let header, rows;
        if (isPlanStats === 1) {
            header = columns.map((item, index) => {
                return (
                    <th key={index} className={item.className} style={{ height: "1px" }}>
                        <Sort fieldName={item.fieldName} text={item.displayName} onSort={this.handleSort}
                            sortDirection={item.sortDirection} sortable={item.sortable} />
                    </th>
                );
            });
            rows = count.map((item, index) => {
                return (
                    <tr className="text-left" key={index}>
                        {
                            Object.values(item).map((value, idx) => {
                                return (<td key={idx}>{value}</td>);
                            })
                        }
                    </tr>
                );
            });
        }
        else {
            header = columns.map((item, index) => {
                return (
                    <th key={index} className={item.className} style={{ height: "1px" }}>
                        <Sort fieldName={item.fieldName} text={item.displayName} onSort={this.handleRAStatusSort}
                            sortDirection={item.sortDirection} sortable={item.sortable} />
                    </th>
                );
            });
            rows = count.map((item, index) => {
                return (
                    <tr className="text-left" key={index}>
                        {
                            Object.values(item).map((value, idx) => {
                                return (<td key={idx}>{value}</td>);
                            })
                        }
                    </tr>
                );
            });
             
        }
            return (
                <div style={{ verticalAlign: "top", maxHeight: '220px', overflowY: 'auto', paddingRight: paddingRight, paddingLeft: paddingLeft }}>
                    <Table hover bordered condensed striped responsive>
                        <thead>
                            <tr>
                                {header}
                            </tr>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                    </Table>
                </div> 
            );
        }

    renderGapStats = (tableMemberCountByPlan, tableMemberCountByRAStatus) => {
        return (
            <div>
                <Row>
                    <h4 style={{ marginLeft: '1.4rem' }}>{this.props.listType}</h4>
                    <hr style={{ marginTop: 0, marginRight: '1rem', marginLeft: '1rem' }} />
                    <Col lg={11}>
                        <Row>
                            {tableMemberCountByPlan}
                        </Row>
                    </Col>
                </Row>
                <Row>
                    {/*<h4 style={{ marginLeft: '1.4rem' }}>{this.props.listType}</h4>*/}
                    <hr style={{ marginTop: 0, marginRight: '1rem', marginLeft: '1rem' }} />
                    <Col lg={11}>
                        <Row>
                            {tableMemberCountByRAStatus}
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }

    render() {
        let contents, panelTitle, table, tableMemberCountByPlan, tableMemberCountByRAStatus;
     
        if (this.props.listDefinition) {
            let listName = this.props.listDefinition.name + " Summary Statistics";
            panelTitle = <div style={{ display: "inline-block" }}>{listName}</div>;

            if (this.props.listDefinition.loadingStatus === "Complete" && this.state.listResultStats && this.state.listResultRAStatusStats) {
                
                tableMemberCountByPlan = this.listStatsGroupByTable(
                    this.columnsMemberCountByPlan,
                    this.state.listResultStats.GapListPlanStatistics, '0px', '15px');

                tableMemberCountByRAStatus = this.listStatsGroupByTable(
                    this.columnsMemberCountByRAStatus,
                    this.state.listResultRAStatusStats.GapListRAStatistics, '0px', '15px', 0);

                if (this.props.listType === "Gap") {
                    contents = this.renderGapStats(tableMemberCountByPlan, tableMemberCountByRAStatus);
                }
               
            }
            
            else if (this.props.listDefinition.loadingStatus === "Error" ) {
                //Error on generation
                table = (
                    <div style={{ textAlign: "center", marginTop: "13rem", marginBottom: "8rem" }}>
                        <Glyphicon  glyph="remove-circle" style={{ fontSize: "48px", lineHeight: "14px", color: "#6c716c" }} />
                        <h3 style={{ textAlign: "center", marginTop: "2rem" }}>An error has occurred during result generation.</h3>
                    </div>
                );
                contents = <Col lg={12}>{table}</Col>;
            }

            else {
                //list loaded, results unavailable
                table = (
                    <div style={{ textAlign: "center", marginTop: "13rem", marginBottom: "8rem" }}>
                        <Glyphicon className="spinning" glyph="cog" style={{ fontSize: "48px", lineHeight: "14px", color: "#6c716c" }} />
                        <h3 style={{ textAlign: "center", marginTop: "2rem" }}>List generation in progress</h3>
                    </div>
                );
                contents = (<div>{table}</div>)
            }
        }

        const title = (
            <div onClick={this.expandPanel} style={{ cursor: 'pointer' }}>
                <h4>
                    {panelTitle}
                    <Glyphicon glyph="menu-down" style={{ color: "#6c716c", float: "right", paddingRight: "0.4rem" }} />
                </h4>
            </div>
        );

            return (
                 <Panel>
                    <Panel.Heading>
                        <Panel.Title>
                            {title}
                        </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body>
                        <Collapse in={this.state.expanded}>
                            {contents}
                        </Collapse>
                    </Panel.Body>
                </Panel>
             );
    }
}