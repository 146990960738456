import { GapChaseFilterBase, filterType, dataType } from '../../GapChaseFilterBase';
import Axios from 'axios';

export default class Tier2ChaseTypeFilter extends GapChaseFilterBase {
    constructor() {
        super();
        this.id = 45;
        this.name = "Tier 2 Chase Type";
        this.filterName = "Tier2ChaseType";
        //this.isHidden = true;
        this.filterType = filterType.checkbox;
        this.values = [
            {
                "label": "",
                "value": "Tier2ChaseType"
            }
        ];

        this.dataType = dataType.boolean;
        this.filterCategory = "Provider Level";
        this.filterGroupId = 4;
        this.placeholder = "Tier 2 Chase Type";

    }

}
