import React from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import InclusionExclusionLists from "./InclusionExclusionLists";

//todo
export default class InclusionExclusionContainer extends React.Component {
    render() {
        return (
            <Grid fluid style={{ maxWidth: window.innerWidth }}>
                <Row>
                    <Col lg={10} lgOffset={1}>
                        <InclusionExclusionLists user={this.props.user}/>
                    </Col>
                </Row>
            </Grid>
        );
    }
}