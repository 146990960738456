import { GapChaseFilterBase, filterType, dataType } from '../GapChaseFilterBase';
import Axios from 'axios';

export default class PlanIdsFilter extends GapChaseFilterBase {
    constructor() {
        super();
        this.id = 7;
        this.name = "Plan ID";
        this.filterName = "PlanIds";

        // this.isHidden = false;
        this.filterType = filterType.multiSelect;
        this.values = [];
        
        this.dataType = dataType.number;
        this.filterCategory = "Member Level";
        this.filterGroupId = 2;
        this.placeholder = "Plan ID";
    }
}
