const defaultPlanProfile = {
    filter: {
        year: 0,
        hiosId: "",
        market: "",
        defaultFilter: true,
        isSubmitted: false
    },

    pagination: {
        pageNumber: 1,
        pageSize: 10
    }

}

const defaultState = {
    type: "SET_PLAN_PROFILE",
    planProfile: { ...defaultPlanProfile }
}

const planProfileStates = (state = [{...defaultState}], action) => {
    switch (action.type) {
        case 'SET_PLAN_PROFILE':
            if (state.find(x => x.type === action.type)) {
                return state.map(planProfileStates =>
                    (planProfileStates.type === action.type)
                    ? { ...planProfileStates, planProfile: action.planProfile }
                    : planProfileStates
                );
            }
            else {
                return [
                    ...state,
                    {
                        type: action.type,
                        planProfile: action.planProfile
                    }
                ];
            }
        case 'RESET_PLAN_PROFILE':
            if (state.find(x => x.planProfile)) {
                return state.map(planProfileStates =>
                    (planProfileStates.type === "SET_PLAN_PROFILE")
                    ? { ...planProfileStates, planProfile: defaultPlanProfile }
                    : planProfileStates
                );
            }
            else {
                return [
                    ...state,
                    {
                        type: "SET_PLAN_PROFILE",
                        planProfile: defaultPlanProfile
                    }
                ];
            }
        default:
            return state;
    }
};

export default planProfileStates;