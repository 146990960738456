import { GapChaseFilterBase, filterType, dataType } from '../../GapChaseFilterBase';
import Axios from 'axios';

export default class FacilityRaGainFilter extends GapChaseFilterBase {
    constructor() {
        super();
        this.id = 32;
        this.name = "Facility RA Gain";
        this.filterName = "FacilityRaGain";
        //this.isHidden = true;
        this.filterType = filterType.range;
        this.values = [];

        this.dataType = dataType.number;
        this.filterCategory = "Provider Level";
        this.filterGroupId = 4;
        this.minValue = -999.99;
        this.maxValue = 999.99;
        this.step = 0.01;
        this.placeholder = "Min Facility RA Gain";

    }

}
