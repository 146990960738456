import React from 'react';
import { Modal } from 'react-bootstrap'
import axios from 'axios';
import { find } from 'lodash';
import toastr from 'toastr';
import { download } from '../../../utils/File';
import { TABS } from './MemberProfileCommon';

export default class MemberDetailExportModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: [],
            showHistory: props.showHistory,
            memberMasterId: props.memberMasterId,
            benefitYr: props.benefitYr
        };
    }

    hide = (e) => {
        this.props.onHide();
    }

    keyPressed = (event) => {
        if (event.which === 27) {
            this.props.onHide();
        }
    }

    toggleTab = (event) => {
        const { value, checked } = event.target;
        let selected = this.state.selected;

        if (checked === true) {
            selected.push(parseInt(value));
        }
        else {
            selected = selected.filter(x => x !== parseInt(value));
        }

        this.setState({ selected });
    }

    toggleHistory = (event) => {
        const { checked } = event.target;
        this.setState({ showHistory: checked });
    }

    performExport = () => {
        const { selected, benefitYr, memberMasterId, showHistory } = this.state;
        let views = [];
        const benefitYear = showHistory === true ? 9999 : benefitYr;

        selected.forEach(x => {
            const t = find(TABS, y => y.value === x);
            views.push(t.export);
        });

        const config = {
            responseType: 'blob'
        };

        axios.post(`/MemberProfile/MemberProfileExport/${memberMasterId}/${benefitYear}`, views, config)
            .then((response) => {
                const now = new Date();
                const fileName = response.headers['x-suggested-filename'] ||
                     `${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_CLIENT_NAME_DEV : process.env.REACT_APP_CLIENT_NAME_PROD}_MemberProfile__MemberDetailExtract_${this.dateFormatterForFilename(now)}.zip`;

                download(response.data, fileName, 'blob');
            }).catch(error => {
                toastr.error('An error occurred generating member detail export files.');
            });
    }

    dateFormatterForFilename = (dd) => {
        return '' + (dd.getMonth() + 1) + (dd.getDate() + 1) + dd.getFullYear() + dd.getHours() + dd.getMinutes() + dd.getSeconds();
    }

    toggleSelectAll = () => {
        let selected = [];

        if (this.state.selected.length !== Object.keys(TABS).length) {
            Object.keys(TABS).forEach((x) => {
                selected.push(parseInt(TABS[x].value));
            });
        }

        this.setState({ selected });
    }

    render() {
        const { selected, showHistory } = this.state;
        const { props, keyPressed, hide, toggleTab, toggleHistory, performExport,
             toggleSelectAll } = this;

        return (
            <Modal show={props.show} onKeyDown={keyPressed} >
                <Modal.Header closeButton onHide={hide}>
                    <h4>Member Detail Export</h4>
                </Modal.Header>
                <Modal.Body className="export-modal">
                    <div className="row">
                        <div className="col-sm-12">
                            Select tab(s) to export:
                        </div>
                        <hr />
                        <div className="col-sm-12">
                            <form>
                                <div className="checkbox">
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={selected.length === Object.keys(TABS).length}
                                            onChange={toggleSelectAll}
                                            value={0}
                                        />
                                        (Select All)
                                    </label>
                                </div>
                                {
                                    Object.keys(TABS).map((t) => {
                                        return (
                                            <div className="checkbox" key={t}>
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        checked={selected.indexOf(TABS[t].value) > -1}
                                                        onChange={toggleTab}
                                                        value={TABS[t].value}
                                                    />
                                                    {TABS[t].label}
                                                </label>
                                            </div>
                                        )
                                    })
                                }
                            </form>
                        </div>
                        <div className="col-sm-12">
                            <form>
                                <div className="checkbox">
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={showHistory}
                                            onChange={toggleHistory}
                                        />
                                        Include historical data in export
                                    </label>
                                </div>
                            </form>
                        </div>
                        <div className="col-sm-12">
                            Note: This export will create a zip file with one file for each tab.
                        </div>
                        <div className="col-sm-12">
                            <button className="btn btn-tessellate pull-right"
                                onClick={performExport} disabled={selected.length < 1}>
                                Submit
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}