import FacilityChaseTypeFilter from "../filters/chase/providerfilters/FacilityChaseTypeFilter.js"
import ProviderExclusionListFilter from "../filters/chase/providerfilters/ProviderExclusionListFilter.js"
import SpecialistSpecialtiesFilter from "../filters/chase/providerfilters/SpecialistSpecialtiesFilter.js"
import FacilityRaGainFilter from "../filters/chase/providerfilters/FacilityRaGainFilter.js"
import ProviderInclusionListFilter from "../filters/chase/providerfilters/ProviderInclusionListFilter.js"
import Tier1ChaseTypeFilter from "../filters/chase/providerfilters/Tier1ChaseTypeFilter.js"
import FacilitySpecialtiesFilter from "../filters/chase/providerfilters/FacilitySpecialtiesFilter.js"
import ProviderStateFilter from "../filters/chase/providerfilters/ProviderStateFilter.js"
import Tier1RaGainFilter from "../filters/chase/providerfilters/Tier1RaGainFilter.js"
import OneFacilityVisitFilter from "../filters/chase/providerfilters/OneFacilityVisitFilter.js"
import ProviderTypesFilter from "../filters/chase/providerfilters/ProviderTypesFilter.js"
import Tier2ChaseTypeFilter from "../filters/chase/providerfilters/Tier2ChaseTypeFilter.js"
import PcpChaseTypeFilter from "../filters/chase/providerfilters/PcpChaseTypeFilter.js"
import SpecialistChaseTypeFilter from "../filters/chase/providerfilters/SpecialistChaseTypeFilter.js"
import Tier2RaGainFilter from "../filters/chase/providerfilters/Tier2RaGainFilter.js"
import PcpRaGainFilter from "../filters/chase/providerfilters/PcpRaGainFilter.js"
import SpecialistRaGainFilter from "../filters/chase/providerfilters/SpecialistRaGainFilter.js"

export default class ProviderLevelFilterGroup {
    constructor(chaseFilterData=null) {
        // super(props);
        this.categoryName = "Provider Level";
        this.isHidden = false;

        this.filters = [];
        let facilityChaseTypeFilter = new FacilityChaseTypeFilter();
        let providerExclusionListFilter = new ProviderExclusionListFilter(chaseFilterData);
        let specialistSpecialtiesFilter = new SpecialistSpecialtiesFilter(chaseFilterData);
        let facilityRaGainFilter = new FacilityRaGainFilter();
        let providerInclusionListFilter = new ProviderInclusionListFilter(chaseFilterData);
        let tier1ChaseTypeFilter = new Tier1ChaseTypeFilter();
        let facilitySpecialtiesFilter = new FacilitySpecialtiesFilter(chaseFilterData);
        let providerStateFilter = new ProviderStateFilter();
        let tier1RaGainFilter = new Tier1RaGainFilter();
        let oneFacilityVisitFilter = new OneFacilityVisitFilter();
        let providerTypesFilter = new ProviderTypesFilter();
        let tier2ChaseTypeFilter = new Tier2ChaseTypeFilter();
        let pcpChaseTypeFilter = new PcpChaseTypeFilter();
        let specialistChaseTypeFilter = new SpecialistChaseTypeFilter();
        let tier2RaGainFilter = new Tier2RaGainFilter();
        let pcpRaGainFilter = new PcpRaGainFilter();
        let specialistRaGainFilter = new SpecialistRaGainFilter();


        this.filters.push(
            pcpChaseTypeFilter,
            pcpRaGainFilter,
            tier1ChaseTypeFilter,
            tier1RaGainFilter,
            tier2ChaseTypeFilter,
            tier2RaGainFilter,
            specialistChaseTypeFilter,
            specialistRaGainFilter,
            specialistSpecialtiesFilter,
            facilityChaseTypeFilter,
            facilityRaGainFilter,
            facilitySpecialtiesFilter,
            providerStateFilter,
            providerInclusionListFilter,
            providerExclusionListFilter,

            oneFacilityVisitFilter,
            providerTypesFilter
   
            
            
        );

    }
}