import React from 'react';
import axios from 'axios';
import ReactTable from 'react-table';
import toastr from 'toastr';
import { renderDate } from '../../../utils/CommonReactTable';

export default class MemberPcpReport extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			...props,
			columnSorting:[{id: "EndDate", desc: true}, {id: "StartDate", desc: false}],
			data: [],
			totalItemCount : 0
		}
	}

	componentDidMount() {
		this.getPcpData();
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (nextProps.showHistory !== nextState.showHistory) {
			this.setState({ ...nextProps }, this.getPcpData);
		}

		return true;
	}

	getPcpData = () => {
		const { memberMasterId, showHistory, benefitYr,columnSorting } = this.state;

		let params = {columnSorting};
		if (showHistory === false) {
			params = {
				benefitYr,
				columnSorting
			};
		}

		axios.get('/MemberProfile/' + memberMasterId + '/PCP', { params }).then((response) => {
			this.setState({ data: response.data, totalItemCount : response.data.length });
		}).catch((error) => {
			console.log(error);
			toastr.error('An error occurred retrieving member pcp data');
		});
	}

	columnSortinghandler = (sorted)=>{
		this.setState({ columnSorting:  sorted } , this.getPcpData);
	}


	render() {
		const { data, totalItemCount } = this.state;
		const columns = [
			{
				Header: 'Provider ID',
				accessor: 'ProviderID'
			},
			{
				Header: 'Provider Last Name',
				accessor: 'ProviderLastName'
			},
			{
				Header: 'Provider First Name',
				accessor: 'ProviderFirstName'
			},
			{
				Header: 'Date From',
				id: 'StartDate',
				Cell: (row) => renderDate(row.original.StartDate)
			},
			{
				Header: 'Date To',
				id: 'EndDate',
				Cell: (row) => renderDate(row.original.EndDate)
			}
		];

		return (
			<div className="col-sm-12">
			<ReactTable
				className="ts-react-table -striped -highlight"
				columns={columns}
				manual
				data={data}
				defaultPageSize={10}
				pageSizeOptions={[10, 25, 50, 100]}
				sortable={true}
				onSortedChange = {sorted=>this.columnSortinghandler(sorted)}
				noDataText={(<span>No Results Found</span>)}
			/>
			<div><strong>Total Number of Records: {totalItemCount}</strong></div>
			</div>
		)
	}
}