import { GapChaseFilterBase, filterType, dataType } from '../GapChaseFilterBase';

export default class MemberExclusionListIdsFilter extends GapChaseFilterBase {
    constructor(incExList) {
        super();
        this.id = 17;
        this.name = "Member Exclusion List";
        this.filterName = "MemberExclusionListIds";
        // this.isHidden = false;
        this.filterType = filterType.multiSelect;
        this.values = incExList;

        this.dataType = dataType.number;
        this.filterCategory = "Member Level";
        this.filterGroupId = 2;
        this.placeholder = "Member Exclusion List";
    }
}
