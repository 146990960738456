
const defaultFinancialProfile = {

    filter: {
       benefitYear: 0,
       hiosId: "All",
       market: "All",
       defaultFilter: true,
       submitted: false
    },

    pagination: {
        pageCount: 0,
        pageNumber:1,
        pageSize: 10,
        sortKey: 'PlanID',
        sortDirection: 'ASC'
    }
   
}

const defaultState = {
    type: "SET_FINANCIAL_PROFILE",
    financialProfile: {...defaultFinancialProfile}
}

const financialProfileStates = (state = [{...defaultState}], action) => {
    
    switch (action.type) {
       case "SET_FINANCIAL_PROFILE":             
           if (state.find(x => x.type === action.type)) {
               return state.map(financialProfileStates =>
                   (financialProfileStates.type === action.type)
                   ? { ...financialProfileStates, financialProfile: action.financialProfile }
                   : financialProfileStates
               );
           }
           else {
               return [
                   ...state,
                   {
                       type: action.type,
                       financialProfile: action.financialProfile
                   }
               ];
           }
       
       case "RESET_FINANCIAL_PROFILE":
           if (state.find(x => x.type === action.type)) {
               return state.map(financialProfileStates => 
                   (financialProfileStates.type === action.type)
                   ? { ...financialProfileStates, financialProfile: defaultFinancialProfile }
                   : financialProfileStates
               );
           }
           else {
               return [
                   ...state,
                   {
                       type: "SET_FINANCIAL_PROFILE",
                       financialProfile: defaultFinancialProfile
                   }
               ];
           }

           
       default:
            return state;
    }
};

export default financialProfileStates;
