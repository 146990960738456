import React from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import { mapUserToProps } from '../../redux/actions/index';

class AuthWrapper extends React.Component {
    render() {
        const token = localStorage.getItem('ACAToken');
        if (this.props.page && this.props.user && this.props.user.authorizedPages && this.props.user.authorizedPages.indexOf(this.props.page) < 0) {
            return (<Redirect to="/Unauthorized" />);
        }
        else if (!this.props.user || !token) {
            return (<Redirect to="/Login" />);
        }
        return (<this.props.component {...this.props} />);
    }
}

export default connect(
    mapUserToProps
)(AuthWrapper);