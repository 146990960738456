import {GapChaseFilterBase, filterType, dataType} from '../GapChaseFilterBase';

export default class SuspectTypeIdsFilter extends GapChaseFilterBase{
    constructor(suspectTypes){
        super();
        this.id = 6;
        this.name = "Suspect Type";
        this.filterName = "SuspectTypeIds";
        this.isHidden = true;
        this.filterType = filterType.multiSelect;
        this.values = suspectTypes;

        this.dataType = dataType.number;
        this.filterCategory = "Gap Level";
        this.filterGroupId = 1;
        this.placeholder = "Suspect Type";
    }  

}


