import { GapChaseFilterBase, filterType, dataType } from '../GapChaseFilterBase';

export default class ChaseRankFilter extends GapChaseFilterBase {
    constructor() {
        super();
        this.id = 3;
        this.name = "Chase Rank";
        this.filterName = "ChaseRank";
        // this.isHidden = false;
        this.filterType = filterType.range;
        this.values = [];

        this.dataType = dataType.number;
        this.filterCategory = "Chase Level";
        this.filterGroupId = 3;
        this.minValue = 0;
        this.maxValue = 999999999;
        this.step = 1.00;
        this.placeholder = "Chase Rank";
    }
}
