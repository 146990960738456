import {GapChaseFilterBase, filterType, dataType} from '../GapChaseFilterBase';

export default class RaGainFilter extends GapChaseFilterBase{
    constructor(){
        super();
        this.id = 19;
        this.name = "RA Gain";
        this.filterName = "RaGain";
        // this.isHidden = false;
        this.filterType = filterType.range;
        this.values = [];

        this.dataType = dataType.number;
        this.filterCategory = "Chase Level";
        this.filterGroupId = 3;
        this.minValue = -999.99;
        this.maxValue = 999.99;
        this.step = 0.01;
        this.placeholder = "RA Gain";
    }  
}
