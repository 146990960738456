import React from 'react'
import { Button, Glyphicon, OverlayTrigger, Tooltip } from 'react-bootstrap';

//todo: review/refactor this; make pagination styling less ugly
export class Records extends React.Component {
    changePageSize = (e) => {
        this.props.onPageChangeSize(parseInt(e.target.value, 10));
    }

    collate(end) {
        var list = [];
        for (var i = 1; i <= end; i++) {
            list.push(i);
        }
        return list;
    }

    pages(stats) {
        if (stats.CountPerPage >= stats.RecordCount) {
            return stats.Page + 1;
        }
        else {
            let test = stats.RecordCount / stats.CountPerPage;
            let round = Math.floor(stats.RecordCount / stats.CountPerPage);
            if (test === round) {
                return test;
            }
            else {
                return (round + 1);
            }
        }
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-lg-6">
                        <RecordType Page={this.props.stats.Page + 1} CountPerPage={this.props.stats.CountPerPage}
                            RecordCount={this.props.stats.RecordCount} />
                    </div>
                    <div className="col-lg-6">
                        <div className=" pull-right">
                            <h4>Rows per Page:&nbsp;
                            <select id="rows-per-page" value={parseInt(this.props.stats.CountPerPage, 10)} onChange={this.changePageSize}>
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                </select>
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div>
                            <h4>
                                <Pagination onPage={this.props.onPage} current={this.props.stats.Page + 1} end={this.pages(this.props.stats)}
                                    pages={this.collate(this.pages(this.props.stats))} />
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export function RecordType(props) {
    const firstRow = ((props.Page - 1) * props.CountPerPage) + 1;
    let lastRow = firstRow + props.CountPerPage - 1;

    if (lastRow > props.RecordCount) {
        lastRow = props.RecordCount;
    }

    return (
        <div className="row">
            <div className="col-lg-12">
                <h4>Rows: {firstRow}-{lastRow} of <span id="record-count">{props.RecordCount ? props.RecordCount : ''}</span></h4>
            </div>
        </div>
    )
}

export class Pagination extends React.Component {
    page = (event) => {
        this.props.onPage(event);
    }

    previous = () => {
        this.props.onPage(this.props.current - 2);
    }

    next = () => {
        this.props.onPage(this.props.current);
    }

    render() {
        let previous, next, allPages;
        const min = this.props.current - 1 < 3 ? 0 : this.props.current - 3;
        const max = this.props.current + 2;
        const selectedPages = this.props.pages.slice(min, max);

        if (this.props.current === 1) {
            previous = (
                <Button disabled id="previous-btn">
                    <Glyphicon glyph="arrow-left" style={{ marginRight: "0.66rem", top: "1.6px", lineHeight: "14px", color: "#6c716c" }} />
                    Previous Page
                </Button>
            );
        }
        else {
            previous = (
                <Button onClick={this.previous} className="btn btn-default" id="previous-btn">
                    <Glyphicon glyph="arrow-left" style={{ marginRight: "0.66rem", top: "1.6px", lineHeight: "14px", color: "#6c716c" }} />
                    Previous Page
                </Button>
            );
        }

        if (this.props.current === this.props.end) {
            next = (
                <Button disabled id="next-btn">
                    Next Page
                    <Glyphicon glyph="arrow-right" style={{ marginLeft: "0.66rem", top: "1.6px", lineHeight: "14px", color: "#6c716c" }} />
                </Button>
            );
        }
        else {
            next = (
                <Button onClick={this.next} className="btn btn-default" id="next-btn">
                    Next Page
                    <Glyphicon glyph="arrow-right" style={{ marginLeft: "0.66rem", top: "1.6px", lineHeight: "14px", color: "#6c716c" }} />
                </Button>
            );
        }


        allPages = selectedPages.map((p, index) => {
            return <Page key={p} page={p} onPage={this.page} current={this.props.current} />;
        });

        return (
            <div className="row">
                <div className="col-lg-3">{previous}</div>
                <div className="col-lg-6 text-center form-inline">
                    Pages:
                    {
                        max > 3 && min !== 0 &&
                        <span>
                            <Page page={1} onPage={this.page} current={this.props.current} />
                            <p style={{display: 'inline-block', marginLeft: '0.8rem'}}>...</p>
                        </span>
                    }
                    {allPages}
                    {
                        this.props.pages.length > max &&
                        <span>
                            <p style={{ display: "inline-block", marginLeft: "0.8rem" }}>...</p>
                            <Page page={this.props.pages.length} onPage={this.page} current={this.props.current} />
                        </span>
                    }
                </div>
                <div className="col-lg-3">
                    <div className="pull-right">
                        {next}
                    </div>
                </div>
            </div>
        );
    }
}

export class Page extends React.Component {
    page = (e) => {
        this.props.onPage(this.props.page - 1);
    }

    render() {
        let div = null;
        if (this.props.page === this.props.current) {
            div = <div id={'page-' + this.props.page} className="form-group disabled" key={this.props.page}
                style={{ marginLeft: "0.8rem", verticalAlign: "baseline" }}>
                <h4 style={{ color: "#666", fontWeight: "600" }}>{this.props.page}</h4>
            </div>;
        }
        else {
            div = <div id={'page-' + this.props.page} onClick={this.page} className="form-group" key={this.props.page}
                style={{ cursor: "pointer", marginLeft: "0.8rem", verticalAlign: "baseline" }}>
                    <h4><small>{this.props.page}</small></h4>
                </div>;
        }
        return (div);
    }
}

export class Sort extends React.Component {
    onSort(event) {
        let direction = null;
        if (this.props.sortable !== false) {
            if (this.props.sortDirection === "asc" || this.props.sortDirection === undefined) {
                direction = "desc";
            }
            else {
                direction = "asc"
            }
            this.props.onSort({ fieldName: this.props.fieldName, sortDirection: direction }, event);
        }
    } 

    render() {
        let link = null;
        let cursorStyle = "default";
        if (this.props.sortable !== false) { cursorStyle = "pointer" }

        if (this.props.sortDirection === "asc") {
            link = <Glyphicon glyph="arrow-up" style={{ paddingRight: "0.4rem", top: "1.6px", lineHeight: "14px", color: "#6c716c" }} />
        }
        else if (this.props.sortDirection === "desc") {
            link = <Glyphicon glyph="arrow-down" style={{ paddingRight: "0.4rem", top: "1.6px", lineHeight: "14px", color: "#6c716c" }} />
        }

        const tooltip = (<Tooltip id="tooltip-top">Click to sort</Tooltip>);

        return (
            <OverlayTrigger delay={1600} delayHide={600} placement="top" overlay={tooltip}>
                <div style={{ top: "0px", bottom: "0px", display: "flex", justifyContent: "center", cursor: cursorStyle }} onClick={this.onSort.bind(this)}>
                    <div style={{ alignSelf: "flex-end" }}>
                        <span className="sort-field">{this.props.text}</span>
                        <span style={{ display:"block" }}>{link}</span>
                    </div>
                </div>
            </OverlayTrigger>
        );
    }
}