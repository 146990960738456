import React from 'react';
import { sortBy } from 'lodash';
import { Col, Panel } from 'react-bootstrap';
import { OperatorUtils } from '../../utils/GapChaseUtils';

export default class FilterSummary extends React.Component {
    getLabel = (filters, filterId, value) => {
        let filter, selections, label;
        label = "";

        filter = filters.find(x => x.id === filterId);
        if (filter) {
            if (filter.filterType === 'Multiselect') {
                if (Array.isArray(value)) {
                    return value.reduce((acc, cur, index, src) => {
                        return index !== src.length - 1 ? acc += `${cur.label}, ` : acc += `${cur.label}`;
                    }, '');
                }
                else {
                    const foundVal = filter.values.find(x => x.value === value);
                    return foundVal ? foundVal.label : '';
                }
            }
            else if (filter.filterType === 'Select') {
                selections = value.toString().split(',');
                for (let val of selections) {
                    const foundVal = filter.values!= undefined ? filter.values.find(x => x.value.toString() === val.toString()): null;

                    if (foundVal) {
                      if (label === "") {
                          label += foundVal.label;
                      }
                      else {
                          label += ',' + foundVal.label;
                      }
                    }
                    else {
                        if (label === "") {
                            label += "No label (invalid value)";
                        }
                        else {
                            label += ", No label (invalid value)";
                        }
                    }
                }
            }
        }

        return label;
    }

    formatFilters = (length, index, value) => {
        if (typeof(value) === 'string') {
            value = value.replace(',', ', ');
        }
        else if (Array.isArray(value)) {
            if (value[0] && value[1]) {
                value = ' ' + value.join('-');
            }
            else if (value[0]) {
                value = ' ' + value[0];
            }
            else if (value[1]) {
                value = ' ' + value[1];
            }
        }

        if (length - 1 === index) {
            return ' ' + value;
        }
        else {
            return ' ' + value + ',';
        }
    }

    render() {
        let summary = null;
        let filterSelections = this.props.selectedFilters ? sortBy(this.props.selectedFilters, ['name', 'value']) : null;

        if (this.props.filters && filterSelections && filterSelections.length > 0) {
            let label = "";
            let filterCount = filterSelections.length;
        
            const allFilters = this.props.flattenFilterGroups(this.props.filters);
            summary = filterSelections.map((filter, index) => {
                if ((filter.value !== null && !Array.isArray(filter.value)) || (Array.isArray(filter.value) && filter.value.length !== 0)) {
                    label = this.getLabel(allFilters, filter.filterId, filter.value);
                    return (
                        <span key={index} style={{ marginLeft: index !== 0 ? '0.5em' : '0' }}>
                            <b>{ !this.props.isFinal ? filter.name : filter.label }</b>&nbsp;
                            {OperatorUtils.getOperatorDescription(filter.operator)}
                            {this.formatFilters(filterCount, index, label || filter.value)}
                        </span>
                    );
                }
                else {
                    return '';
                }
            });

            return (
                <div className="panel-content" style={{ marginBottom: "0rem", paddingLeft: "0rem", paddingRight: "0rem"}}>
                    <Panel>
                        <Panel.Body>
                            <Col lg={2}>
                                Selected Filters
                            </Col>
                            <Col sm={10}>
                                <div>
                                    {summary}
                                </div>
                            </Col>
                        </Panel.Body>
                    </Panel>
                </div>
            );
        }
        else {
            return null;
        }
    }
}