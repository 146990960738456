import { GapChaseFilterBase, filterType, dataType } from '../../GapChaseFilterBase';
import Axios from 'axios';

export default class ProviderExclusionListFilter extends GapChaseFilterBase {
    constructor(chaseFilterData) {
        super();
        this.id = 38;
        this.name = "Provider Exclusion List";
        this.filterName = "ProviderExclusionLists";
        //this.isHidden = true;
        this.filterType = filterType.multiSelect;

        this.values = [];
        if (chaseFilterData.ProviderIncExNames !== null && chaseFilterData.ProviderIncExNames !== "undefined")
            this.values = chaseFilterData.ProviderIncExNames;

        this.dataType = dataType.number;
        this.filterCategory = "Provider Level";
        this.filterGroupId = 4;
        this.placeholder = "Provider Exclusion List";

    }

}
