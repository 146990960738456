import React, { Component } from 'react';
import axios from 'axios';
import ReactTable from 'react-table';
import { renderDecimal, renderInteger, renderStrNum } from '../../../utils/CommonReactTable';
export default class MemberConditions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            
            results: [],
            columnSorting:[{id: "BenefitYear", desc: true}, {id: "VariableName", desc: false}],
            memberMasterId: props.memberMasterId,
            showHistory: props.showHistory,
            benefitYr: props.benefitYr,
            pageCount: 0,
            pageNumber: 1,
            pageSize: 10,
            loading: false,
            totalItemCount:0  
        };

        this.onPageChange = this.onPageChange.bind(this);
        this.onPageSizeChange = this.onPageSizeChange.bind(this);
        this.onPaging = this.onPaging.bind(this);

    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.showHistory !== nextState.showHistory) {
            this.setState({ ...nextProps }, this.getMemberConditions);
        }

        return true;
    }
    getMemberConditions = () => {
        const { memberMasterId, pageNumber, pageSize, showHistory, benefitYr,columnSorting } = this.state;
        const params = { pageNumber, 
                        pageSize, 
                        columnSorting,
                        benefitYear: showHistory === false? benefitYr : 9999 };

        axios.get('/MemberProfile/' + memberMasterId + '/Conditions', { params }).then((response) => {
            
            this.setState({ results: response.data.Detail, loading: false, pageCount: response.data.PageCount, totalItemCount: response.data.TotalItemCount});
        }).catch((error) => {
            console.log(error);
        })
    }

    onFetchData = (state, instance) => {
        const { page, pageSize } = instance.state;
        
        this.setState({ loading: true, pageNumber: page + 1, pageSize }, this.getMemberConditions);
    }

    onPaging = (pageSize, pageIndex) => {
        if (pageSize < 1)
			pageSize = this.state.results.PageSize;
		else
			pageIndex = 0;

        let pageNumber = pageIndex + 1;
        this.setState({ loading: true, pageNumber:pageNumber, pageSize }, this.getMemberConditions);
    }

    onPageChange(pageIndex) {
        this.onPaging(-1, pageIndex);
    }

    onPageSizeChange(pageSize, pageIndex) {
        this.onPaging(pageSize, pageIndex);
    }

    columnSortinghandler = (sorted)=>{
		this.setState({ columnSorting: sorted, pageNumber: 1  } , this.getMemberConditions);
	}


    render() {
        const { results, pageCount, pageNumber, pageSize, loading, totalItemCount} = this.state;
        const { onFetchData, onPageChange, onPageSizeChange,columnSortinghandler } = this;
        const columns = [
            {
                Header: 'Benefit Year',
                accessor: 'BenefitYear',
                Cell: (row) => renderInteger(row.original.BenefitYear),
                width: 150
            },
            {
                Header: 'HCC Type',
                accessor: 'CommercialHCCType',
                width: 150
            },
            {
                Header: 'HCC',
                accessor: 'VariableName',
                Cell: (row) => renderStrNum(row.original.VariableName),
                width: 150
            },
            {
                Header: 'HCC A/C',
                accessor: 'IsChronic',
                width: 150
            },
            {
                Header: 'HCC Description',
                accessor: 'CommercialHCCDescription',
            },
            {
                Header: 'Risk Factor',
                id: 'RiskFactor',
                Cell: (row) => renderDecimal(row.original.RiskFactor, 3),
                width: 120
            }
        ];
        return (
            <div className="col-sm-12">
            <ReactTable
                className="ts-react-table -striped -highlight"
                columns={columns}
				data={results}
                page={pageNumber - 1}
                pages={pageCount}
                onFetchData={onFetchData}
                loading={loading}
				defaultPageSize={pageSize}
				pageSizeOptions={[5, 10, 25, 50, 100]}
				sortable={true}
				manual

                onPageChange={onPageChange}
                onPageSizeChange={onPageSizeChange}
                onSortedChange = {sorted=>columnSortinghandler(sorted)}
                noDataText={(<span>No Results Found</span>)}
            />
            	<div><strong>Total Number of Records: {totalItemCount}</strong></div>
            </div>
        );
    }
}
