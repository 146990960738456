import React, { Component } from "react";
import Select from "react-select";
import axios from "axios";
import toastr from "toastr";
import { Content } from "../../PageLayout";
import { renderMarkets } from "../../../utils/CommonReactTable";
import SummaryTable from "./SummaryTable";
import { connect } from "react-redux";
import {
    mapProviderProfileDispatchToProps,
    mapProviderProfileToProps
} from "../../../redux/actions";
import { download } from "../../../utils/File";
import Autosuggest from "react-autosuggest";

class ProviderProfileReport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filter: {
                hiosId: "All",
                market: "All",
                benefitYear: 0,
                searchObj: {
                    suggestionLabel: "",
                    providerMasterId: 0
                },
                defaultFilter: true
            },
            years:[],
            hiosIds: [],
            marketInput: [],
            searchInput: "",
            options: [],
            data: [],
            pageNumber: 1,
            pageSize: 10,
            pageCount: 0,
            optionLoaded: false,
            submitClicked: false,
            initState: true,
            summResults: [],
            loading: true,
            suggestions: [],
            timeId: null,
            columnSorting: [
                { id: "LastName", desc: false },
                { id: "FirstName", desc: false }
            ],
            totalItemCount : 0
        };

        this.onPageChange = this.onPageChange.bind(this);
        this.onPageSizeChange = this.onPageSizeChange.bind(this);
        this.onPaging = this.onPaging.bind(this);
        this.columnSortinghandler = this.columnSortinghandler.bind(this);
    }

    componentDidMount() {
        this.checkAndSetFromState();
        this.getOptions();
    }

    getOptions() {
        axios
            .get("/providerProfile/ProviderProfileFilters")
            .then(response => {
                const { data } = response;
                const { filter } = this.state;
                const years = data.map(x => x.Year);

                filter.benefitYear = filter.defaultFilter? years[0] : filter.benefitYear;

                const marketInput = this.getMarketInput(data);
                const hiosIds = this.getHiosIdInput(filter.benefitYear, data);


                this.setState(
                    {
                        filter,
                        data,
                        marketInput,
                        hiosIds,
                        years,
                        optionLoaded: true
                    }
                );
            })
            .catch(error => {
                console.error(error);
                toastr.error(
                    "Failed to load provider profile filters and options."
                );
            })
            .finally(() => {
                this.getSummaryResults();
            });
    }

    checkAndSetFromState = () => {
        /* If flag defaultFilter value in redux is False, which means we have the state which is NOT defaultProviderProfile 
            We can get stored states from props, and set them to current state. 
        */
        const { pagination, filter, searchInput } = this.props.providerProfile;
        
        if (!filter.defaultFilter) {
            this.setState({
                filter,
                pageNumber: pagination.pageNumber,
                pageSize: pagination.pageSize,
                searchInput,
                optionLoaded: true
            });
        }
        // If flag defaultFilter value in redux is True, which means we have defaultProviderProfile for now
        else {
            this.props.resetProviderProfile();
        }
    };

    setupProviderProfile = () => {
        const { filter, pageNumber, pageSize, searchInput } = this.state;
        filter.defaultFilter = false;
        
        const providerProfile = {
            filter,
            pagination: {
                pageNumber,
                pageSize
            },
            searchInput
        };

        this.props.setProviderProfile(providerProfile);
    };

    getSummaryResults = () => {
        const { pageNumber, pageSize, filter, columnSorting} = this.state;

        let params = { pageSize, pageNumber,columnSorting };

        if (filter.searchObj) params.ProviderMasterID = filter.searchObj.providerMasterId;
        if (filter.hiosId) params.hiosId = filter.hiosId;
        if (filter.market) params.market = filter.market;
        if (filter.benefitYear) params.benefitYear = filter.benefitYear;

        axios
            .get("/ProviderProfile", { params })
            .then(response => {
                this.setState({
                    loading: false,
                    summResults: response.data.Detail,
                    pageCount: response.data.PageCount,
                    pageSize: response.data.PageSize,
                    pageNumber: response.data.PageNumber,
                    totalItemCount : response.data.TotalItemCount

                });
            })
            .catch(error => {
                console.log(error); // add toastr message
                this.setState({ loading: false });
            });
    };

    getMarketInput = data => {
        const marketInput = data.map(x => x.Markets)[0];

        if (marketInput.indexOf("All") < 0) {
            marketInput.unshift("All");
        }

        return marketInput;
    };

    getHiosIdInput = (year, data) => {
        const hiosIds = data.filter(x => x.Year === parseInt(year)).map(x => x.HiosIds)[0].sort();

        if (hiosIds.indexOf("All") < 0) {
            hiosIds.unshift("All");
        }

        return hiosIds;
    };

    handleYearChange = (event) => {
        const { value } = event.target;
        const { data } = this.state;

        const hiosIds = this.getHiosIdInput(value, data);
        const marketInput = this.getMarketInput(data);

        let hiosId = 'All';
        let market = 'All';

        if (marketInput.length === 2) {
            market = marketInput[0];
        }

        if (hiosIds.length === 2) {
            hiosId = hiosIds[0];
        }

        this.setState({ hiosIds, marketInput, filter: { benefitYear: parseInt(value), hiosId, market } });
    }


    handleHiosIdChange = event => {
        // When user modify the prefilter, the search bar will be reset
        this.setState({
            filter: {
                ...this.state.filter,
                hiosId: event.target.value,
                searchObj: null
            }
        });
    };

    handleMarketChange = event => {
        // When user modify the prefilter, the search bar will be reset
        this.setState({
            filter: {
                ...this.state.filter,
                market: event.target.value,
                searchObj: null
            }
        });
    };

    isSubmitDisabled = () => {
        const { filter, hiosIds, marketInput } = this.state;

        if (hiosIds.length && filter.hiosId === 0) return true;

        if (marketInput.length && filter.market === 0) return true;

        return false;
    };

    submitClick = event => {
        event.preventDefault();

        const { filter } = this.state;

        this.setState(
            {
                searchInput: "",
                ProviderMasterId: 0,
                pageNumber: 1,
                filter: { ...filter, searchObj: null }
            },
            this.getSummaryResults
        );
    };

    handleExport = event => {
        event.preventDefault();

        const { filter } = this.state;

        let params = {};
        if (filter.searchObj) params.ProviderMasterID = filter.searchObj.ProviderMasterID;
        if (filter.hiosId) params.hiosId = filter.hiosId;
        if (filter.market) params.market = filter.market;
        if (filter.benefitYear) params.benefitYear = filter.benefitYear;
        
        axios
            .get("/ProviderProfile/Export", { params })
            .then(response => {
                const fileName =
                    response.headers["x-suggested-filename"] ||
                    "ResultsExtract_" + new Date().getTime() + ".txt";
                download(response.data, fileName);
            })
            .catch(error => {
                console.error(error);
                toastr.error("Failed to export data.");
            });
    };

    getProviderSuggestions = value => {
        const { timeId } = this.state;
        const { hiosId, market } = this.state.filter;

        // params.Search is one of the parameter for the API
        const params = { Search: value, HiosId: hiosId, Market: market };
        
        if (timeId != null) clearTimeout(timeId);

        var currentTimeId = setTimeout(() => {
            axios
                .get("/ProviderProfile/Options", { params })
                .then(response => {
                    this.setState({ suggestions: response.data });
                })
                .catch(error => {
                    console.log(error);
                    toastr.error(
                        "An error occurred retrieving provider suggestions for autofill."
                    );
                });
        }, 1000);

        this.setState({ timeId: currentTimeId });
    };

    onSuggestionsClearRequested = () => {
        this.setState({ suggestions: [] });
    };

    onSuggestionsFetchRequested = ({ value, reason }) => {
        if (
            (reason === "input-changed" && value.length > 3) ||
            reason === "input-focused"
        ) {
            this.getProviderSuggestions(value);
        }
    };

    renderSuggestion = (suggestion, { query, isHighlighted }) => {
        return <span>{suggestion.Label}</span>;
    };

    providerSearchChange = (event, { newValue, method }) => {
        const { filter } = this.state;
        event.preventDefault();

        if (method == "type") {
            this.setState({ searchInput: newValue });

            //When search box is cleared, the whole summary will be back
            if (newValue === null || newValue.length === 0) {
                this.setState(
                    {
                        filter: {
                            ...filter,
                            searchObj: { providerMasterId: 0 }
                        }
                    },
                    this.getSummaryResults
                );
            }
        } 
        };

    getSuggestionValue = suggestion => {
        const { filter } = this.state;
        
        const suggProviderMasterId =
            suggestion === null ? 0 : suggestion.ProviderMasterID;

        this.setState({
            filter: {
                ...filter,
                searchObj: { providerMasterId: suggProviderMasterId }
            },
            pageNumber: 1
        },this.getSummaryResults);
        
        return suggestion.Label;
    };

    shouldRenderSuggestions = value => {
        return value.trim().length > 3;
    }

    onPaging = (pageSize, pageIndex) => {
        const { filter } = this.state;

        if (pageSize < 1) pageSize = this.state.pageSize;
        else pageIndex = 0;

        let pageNumber = pageIndex + 1;

        this.setState(
            // pageCount has default value
            { filter, pageSize, pageNumber },
            this.getSummaryResults
        );
    };

    onPageChange = pageIndex => {
        this.onPaging(-1, pageIndex);
    };

    onPageSizeChange = (pageSize, pageIndex) => {
        this.onPaging(pageSize, pageIndex);
    };

    columnSortinghandler = sorted => {

        var pageNumber = this.state.pageNumber;
        pageNumber = 1;

        this.setState( { pageNumber, columnSorting: sorted }, this.getSummaryResults );
    };

    render() {
        const {
            filter,
            summResults,
            years,
            hiosIds,
            marketInput,
            optionLoaded,
            loading,
            pageSize,
            pageNumber,
            pageCount,
            suggestions,
            searchInput,
            totalItemCount
        } = this.state;
        const {
            handleHiosIdChange,
            handleMarketChange,
            handleYearChange,
            isSubmitDisabled,
            setupProviderProfile,
            handleExport,
            onPageChange,
            onPageSizeChange,
            submitClick,
            onSuggestionsClearRequested,
            onSuggestionsFetchRequested,
            getSuggestionValue,
            renderSuggestion,
            providerSearchChange,
            shouldRenderSuggestions,
            columnSortinghandler
        } = this;

        const inputProps = {
            value: searchInput,
            onChange: providerSearchChange,
            placeholder: "Search...."
        };

        return (
            <Content>
                <Content.Header>Provider Profile Report</Content.Header>
                <Content.Body>
                    <Content.Filter>
                        <div className="row">
                            <div className="col-sm-12">
                                <form className="form-inline">
                                    <div className="form-group">
                                            <label className="control-label">Benefit Year:</label>
                                            <select className="form-control" value={filter.benefitYear} onChange={handleYearChange}>
                                                {
                                                    years.map(x => <option key={x} value={x}>{x}</option>)
                                                }
                                            </select>
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label">
                                            HIOS ID:
                                        </label>
                                        <select
                                            className="form-control"
                                            value={filter.hiosId}
                                            onChange={handleHiosIdChange}
                                            disabled={hiosIds.length < 2}
                                        >
                                            <option value={0} disabled>
                                                Select
                                            </option>
                                            {hiosIds.map(x => (
                                                <option key={x} value={x}>
                                                    {x}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label">
                                            Market:
                                        </label>
                                        <select
                                            className="form-control"
                                            value={filter.market}
                                            onChange={handleMarketChange}
                                            disabled={marketInput.length < 2}
                                        >
                                            <option value={0} disabled>
                                                Select
                                            </option>
                                            {marketInput.map(x => (
                                                <option key={x} value={x}>
                                                    {renderMarkets(x)}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <button
                                        className="btn btn-tessellate"
                                        disabled={isSubmitDisabled()}
                                        onClick={submitClick}
                                    >
                                        Submit
                                    </button>
                                </form>
                            </div>
                            <div className="col-sm-12 select">
                                <label className="control-label">
                                    Search by PCP Provider (Provider ID, Last Name):
                                </label>
                                <Autosuggest
                                    className="form-control"
                                    suggestions={suggestions}
                                    onSuggestionsFetchRequested={
                                        onSuggestionsFetchRequested
                                    }
                                    onSuggestionsClearRequested={
                                        onSuggestionsClearRequested
                                    }
                                    shouldRenderSuggestions={shouldRenderSuggestions}
                                    getSuggestionValue={getSuggestionValue}
                                    renderSuggestion={renderSuggestion}
                                    inputProps={inputProps}
                                />
                            </div>
                        </div>
                    </Content.Filter>

                    {optionLoaded && (
                        <span>
                            <div className="row export-spacing">
                                <div className="col-sm-12">
                                    <button
                                        className="btn btn-tessellate pull-right"
                                        onClick={handleExport}
                                    >
                                        Export
                                    </button>
                                </div>
                            </div>
                            <Content.Result>
                                {summResults.length >= 0 && (
                                    <SummaryTable
                                        results={summResults}
                                        hiosId={filter.hiosId}
                                        market={filter.market}
                                        onDetail={setupProviderProfile}
                                        loading={loading}
                                        pageCount={pageCount}
                                        pageNumber={pageNumber}
                                        pageSize={pageSize}
                                        onPageChange={onPageChange}
                                        onPageSizeChange={onPageSizeChange}
                                        columnSortinghandler = {columnSortinghandler}
                                        totalItemCount = {totalItemCount}
                                        noDataText={(<span>No Results Found</span>)}
                                    />
                                )}
                                  <div><strong>Total Number of Records: {totalItemCount}</strong></div>
                            </Content.Result>
                        </span>
                    )}
                </Content.Body>
            </Content>
        );
    }
}

export default connect(
    mapProviderProfileToProps,
    mapProviderProfileDispatchToProps
)(ProviderProfileReport);
