const defaultProviderProfile = {
    filter: {
        benefitYear: 0,
        hiosId: "All",
        market: "All",
        searchObj: null,
        defaultFilter: true
    },
    pagination: {
        pageNumber: 1,
        pageSize: 10
    },
    searchInput: ""
};

const defaultState = {
    type: "SET_PROVIDER_PROFILE",
    providerProfile: { ...defaultProviderProfile }
};

const providerProfileStates = (state = [{ ...defaultState }], action) => {
    switch (action.type) {
        case "SET_PROVIDER_PROFILE":
            if (state.find(x => x.type === action.type)) {
                return state.map(providerProfileStates =>
                    providerProfileStates.type === action.type
                        ? {
                              ...providerProfileStates,
                              providerProfile: action.providerProfile
                          }
                        : providerProfileStates
                );
            } else {
                return [
                    ...state,
                    {
                        type: action.type,
                        providerProfile: action.providerProfile
                    }
                ];
            }

        case "RESET_PROVIDER_PROFILE":
            if (state.find(x => x.type === action.type)) {
                return state.map(providerProfileStates =>
                    providerProfileStates.type === action.type
                        ? {
                              ...providerProfileStates,
                              providerProfile: defaultProviderProfile
                          }
                        : providerProfileStates
                );
            } else {
                return [
                    ...state,
                    {
                        type: "SET_PROVIDER_PROFILE",
                        providerProfile: defaultProviderProfile
                    }
                ];
            }

        default:
            return state;
    }
};

export default providerProfileStates;
