
// type     - how the state should change, 
// payload  - the component to add to Redux
export const setGapProfile = gapProfile => ({
    type: "SET_GAP_PROFILE",
    gapProfile
});

export const resetGapProfile = gapProfile => ({
    type: "RESET_GAP_PROFILE",
    gapProfile
});


// GapProfile component receives the prop which is a copy of that prop in Redux state. It's from reducer.
export const  mapGapProfileToProps = state => {
    const gapProfileProp = state.state_gapProfile.find(x => x.gapProfile)
    return gapProfileProp ? gapProfileProp : {};

};

// map[componentName]DispatchToProps connects Redux actions to React props
export const mapGapProfileDispatchToProps = dispatch => ({
    setGapProfile: gapProfile => dispatch(setGapProfile(gapProfile)),
    resetGapProfile: gapProfile => dispatch(resetGapProfile(gapProfile))
});
