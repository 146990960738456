export const setProviderProfile = providerProfile => ({
    type: "SET_PROVIDER_PROFILE",
    providerProfile
});

export const resetProviderProfile = providerProfile => ({
    type: "RESET_PROVIDER_PROFILE",
    providerProfile
});

export const mapProviderProfileToProps = state => {
    const providerProfileProp = state.state_providerProfile.find(x => x.providerProfile)
    return providerProfileProp ? providerProfileProp : {};
};

export const mapProviderProfileDispatchToProps = dispatch => ({
    setProviderProfile: providerProfile => dispatch(setProviderProfile(providerProfile)),
    resetProviderProfile: providerProfile => dispatch(resetProviderProfile(providerProfile))
});