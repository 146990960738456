
import { combineReducers } from 'redux';
import session from './session';
import planProfileStates from './planProfileStates';
import memberProfileStates from './memberProfileStates';
import state_enrollmentRec from './state_enrollmentRec';
import state_financialProfile from './state_financialProfile';
import state_gapProfile from './state_gapProfile';
import state_providerProfile from './state_providerProfile';


export default combineReducers({
    session 
    , planProfileStates
    , memberProfileStates
    , state_enrollmentRec
    , state_financialProfile
    , state_gapProfile
    , state_providerProfile
});

