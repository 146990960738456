import React from 'react';
import { Col, Glyphicon, Panel } from 'react-bootstrap';
import FilterInput from './FilterInput';

export default class FilterPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: this.props.expanded,
            hidden: this.props.hidden
        };
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.expanded !== nextProps.expanded) {
            this.setState({ expanded: nextProps.expanded });
        }
    }

    expandPanel = () => {
        this.setState({ expanded: !this.state.expanded });
    }

    handleFilterChange = (filter, operator, value, input) => {
        this.props.onUpdateFilter(filter, operator, value, input);
    }

    setDefaultValues = () => {
        if (this.props.filterList && this.props.savedFilterSelections) {
            const updatedFilterList = [...this.props.filterList];
            for (let filter of updatedFilterList) {
                //group filters by filterID; assign default values
                let savedFilters = this.props.savedFilterSelections.filter((val) => {
                    return val.filterId === filter.id;
                });

                if (savedFilters) {
                    for (let savedFilter of savedFilters) {
                        filter.defaultValue = savedFilter.value;
                    }
                }
            }

            //if gap list id was selected for chase list, then default the gap list select to the value with that id
            if (this.props.selectedGapId) {
                const gapListFilter = updatedFilterList.find((filter) => { return filter.name === "Gap List"; });
                if (gapListFilter) {
                    gapListFilter.defaultValue = this.props.selectedGapId;
                }
            }
            return updatedFilterList;
        }
        else {
            return this.props.filterList;
        }
    }

    getFilterInput = (filter, index) => {
        //toggle "Suspect Type" gap filter
        if (filter.id === 6) {
            filter.isHidden = !this.props.showSuspectType;
        }
        else if (localStorage.Lob === "Medicare" &&
            (filter.id === 10 // Metal Level 
                || filter.id === 11 // Market
                || filter.id === 15 // RARSD Score
            )) {
            filter.isHidden = true;
        }
        else if (localStorage.Lob === "Commercial" &&
            (filter.id ===  47 // RA Status
                || filter.id === 48 // Original Reason for Entitlement
                || filter.id === 49 // RAF (Min/Max)
                || filter.id === 50 // RAF Opportunity Increase
                || filter.id === 51 // Potential RAF Gain
            )) {
            filter.isHidden = true;
        }
        //toggle "County" gap filter
        else if (filter.id === 13 && filter.values.length > 0) {
            filter.isHidden = false;
        }
        //toggle "MRR Project" chase filter
        else if (filter.id === 23 && filter.values.length > 0) {
            filter.isHidden = false;
        }
        else if (filter.id === 26) {
            filter.isHidden = !this.props.showPCP;
        }
        else if (filter.id === 44) {
            filter.isHidden = !this.props.showTier1;
        }
        else if (filter.id === 46) {
            filter.isHidden = !this.props.showTier2;
        }
        else if (filter.id === 27 || filter.id === 28 || filter.id === 29) { //filter.id == 27 and 30 dropped
            filter.isHidden = !this.props.showSpecialist;
        }
        else if (filter.id === 32 || filter.id === 33) { //filter id==31 dropped
            filter.isHidden = !this.props.showFacility;
        }
        //else if (filter.id === 33) { //filter id==31 dropped
        //    filter.isHidden = !this.props.showFacSpecialities;
        //}
        //filter.isHidden = !this.props.showFacSpecialities;
        else if(filter.id === 24){
            filter.isHidden = !this.props.showChasesPerMember;
        }
        //All Or Top Chases
        //else if (filter.id === 40) {
        //    filter.defaultValue = 1;
        //}
        else if(filter.id === 5){
            filter.isHidden = false;
        }
 
        //set "required" styling on the filter labels for gap year and gap list
        const isRequired = [1, 18].includes(filter.id);

        return filter && !filter.isHidden ?
            <FilterInput key={filter.filterName} filter={filter} inputType={filter.filterType} dataType={filter.dataType} defaultValue={filter.defaultValue}
                showUpdate={this.props.showUpdate} onChange={this.handleFilterChange} setDefault={this.props.setDefault} isRequired={isRequired} />
            : null;
    }

    render() {
        let filterListWithDefaults = this.setDefaultValues();
        let mappedFilters = () => {
            return filterListWithDefaults.map((filter, index) => this.getFilterInput(filter, index));
        }

        const title = (
            <div>
                {this.props.title}
                <Glyphicon glyph="menu-down" style={{ paddingRight: "0.4rem", color: "#6c716c", float: "right" }} />
            </div>
        );

        return filterListWithDefaults && !this.state.hidden ? (
            <Panel>
                <Panel.Heading onClick={this.expandPanel} style={{ cursor: "pointer" }}>
                    <Panel.Title>
                        {title}
                    </Panel.Title>
                </Panel.Heading>
                <Panel.Body style={{ display: this.state.expanded ? "inherit" : "none" }}>
                    <Col lg={12} style={{ minHeight: "128px" }}>
                        {mappedFilters()}
                    </Col>
                </Panel.Body>
            </Panel>
        ) : null;
    }
}