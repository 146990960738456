export const redirectToPortal = () => {
    //Determine environment
    var currentHostname = window.location.host.slice(0, window.location.host.indexOf('.', 1));
    var clientId = currentHostname.substring(0, currentHostname.indexOf('-'));
    var domainName = window.location.host.substring(window.location.host.indexOf('.') + 1);

    switch (clientId) {
        case "qacomm" || "qama":
            window.location = "https://qama-portal.qa.advantasure.com";
            break;
        case "premera":
            window.location = "https://" + clientId + "-ma." + domainName;
            break;
        default:
            window.location = "https://" + clientId + "-portal." + domainName;
            break;
    }
};

export const redirectToLanding = () => {
    if (process.env.NODE_ENV === 'development') {
        window.location = process.env.REACT_APP_CURRENT_URL_DEV;
    }
    else {
        window.location = process.env.REACT_APP_CURRENT_URL_PROD;
    }
};

export const redirect = (url) => {
    if (url) {
        window.location = url;
    }
};

export const logout = () => {
    localStorage.removeItem('ACAToken');
    redirectToPortal();
}
