 const defaultMemberProfile = {
     filter: {
        benefitYear: 0,
        hiosId: "All",
        market: "All",
        status: "",
        columnFilter:[],
        columnSorting:[]
     },
     pagination: {
         pageNumber:1,
         pageSize: 10,
         
     }
    
 }
 
 const defaultState = {
     type: "SET_MEMBER_PROFILE",
     memberProfile: {...defaultMemberProfile}
 }
 
 const memberProfileStates = (state = [{...defaultState}], action) => {
     switch (action.type) {
        case "SET_MEMBER_PROFILE":             
            if (state.find(x => x.type === action.type)) {
                return state.map(memberProfileStates =>
                    (memberProfileStates.type === action.type)
                    ? { ...memberProfileStates, memberProfile: action.memberProfile }
                    : memberProfileStates
                );
            }
            else {
                return [
                    ...state,
                    {
                        type: action.type,
                        memberProfile: action.memberProfile
                    }
                ];
            }
        
        case "RESET_MEMBER_PROFILE":
            if (state.find(x => x.type === action.type)) {
                return state.map(memberProfileStates => 
                    (memberProfileStates.type === action.type)
                    ? { ...memberProfileStates, memberProfile: defaultMemberProfile }
                    : memberProfileStates
                );
            }
            else {
                return [
                    ...state,
                    {
                        type: "SET_MEMBER_PROFILE",
                        memberProfile: defaultMemberProfile
                    }
                ];
            }

            
        default:
             return state;
     }
 };
 
 export default memberProfileStates;