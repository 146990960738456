import { GapChaseFilterBase, filterType, dataType } from '../../GapChaseFilterBase';
import Axios from 'axios';

export default class ProviderTypesFilter extends GapChaseFilterBase {
    constructor() {
        super();
        this.id = 25;
        this.name = "Chase Provider Type";
        this.filterName = "ProviderTypes";
        this.isHidden = true;
        this.filterType = filterType.multiSelect;

        this.values = [
            {
                "label": "PCP",
                "value": "PCP"
            },
            {
                "label": "Specialist",
                "value": "Specialist"
            },
            {
                "label": "Facility",
                "value": "Facility"
            }
        ];

        this.dataType = dataType.string;
        this.filterCategory = "Provider Level";
        this.filterGroupId = 4;
        this.placeholder = "Chase Provider Type";

    }

}
