import React, { Component } from "react";

export default class Output extends Component {
  constructor(props) {
    super(props);

    this.state = {
      output: props.output,

      measureResultShow: true,
      logErrorShow: false,
      logTraceShow: false,
      logWarningShow: false,
      measureResult: props.output,
      logErrorOutput: [],
      logTraceOutput: [],
      logWarningOutput: []
    };
  }

  componentDidMount() {
    this.getLogOutput();
  }
  componentWillReceiveProps(nextProps) {
    this.setState(
      {
        ...this.state,
        output: nextProps.output,
        measureResult: nextProps.output
      },
      this.getLogOutput
    );
  }

  getLogOutput = () => {
    var rawOutput = this.state.output[0];
    if (
      rawOutput.hasOwnProperty("Results") &&
      rawOutput.Results[0].hasOwnProperty("Log")
    ) {
      var log = this.extractLogInfo(rawOutput.Results[0].Log);
      this.setState({
        logTraceOutput: log.trace,
        logErrorOutput: log.error,
        logWarningOutput: log.warning
      });
    }
  };

  extractLogInfo = input => {
    const noLog = "This worker's Log is unavailable ";
    var output = {
      trace: [],
      error: [],
      warning: []
    };

    if (input == null) {
      output.trace.push(noLog);
      output.error.push(noLog);
      output.warning.push(noLog);
    } else {
      for (let i = 0; i < input.length; i++) {
        if (input[i].includes("TRACE")) {
          output.trace.push(input[i]);
        } else if (input[i].includes("ERROR")) {
          output.error.push(input[i]);
        } else if (input[i].includes("WARN")) {
          output.warning.push(input[i]);
        }
      }
    }

    return output;
  };

  handleButtonClick = event => {
    var buttonId = event.target.id;

    switch (buttonId) {
      
      case "1":
        this.setState({
          measureResultShow: !this.state.measureResultShow,
          logErrorShow: false,
          logTraceShow: false,
          logWarningShow: false
        });
        break;

      case "2":
        this.setState({
          logErrorShow: !this.state.logError,
          measureResultShow: false,
          logTraceShow: false,
          logWarningShow: false
        });
        break;

      case "3":
        this.setState({
          logWarningShow: !this.state.logWarningShow,
          measureResultShow: false,
          logErrorShow: false,
          logTraceShow: false
        });
        break;

      case "4":
        this.setState({
          logTraceShow: !this.state.logTraceShow,
          measureResultShow: false,
          logErrorShow: false,
          logWarningShow: false
        });
        break;

      default:
    }

  };

  render() {
    const { handleButtonClick } = this;
    const {
      measureResult,
      measureResultShow,
      logErrorShow,
      logTraceShow,
      logWarningShow,
      logTraceOutput,
      logErrorOutput,
      logWarningOutput
    } = this.state;

    return (
      <div className="col-sm-12">
        <div className="row">
          <h3 style={{ marginTop: "25px" }}>Output</h3>
        </div>
        {/* TODO: Should optimize this button group incase adding more buttons in the future */}

        <div className="row">
          <nav className="col-sm-2 col-md-2">
            <ul className="btn-group-vertical">
              <button
                type="button"
                className="btn btn-primary"
                id="1"
                onClick={handleButtonClick}
              >
                Measure Output
              </button>
              <button
                type="button"
                className="btn btn-danger"
                id="2"
                onClick={handleButtonClick}
              >
                Log Error
              </button>
              <button
                type="button"
                className="btn btn-warning"
                id="3"
                onClick={handleButtonClick}
              >
                Log Warning
              </button>
              <button
                type="button"
                className="btn btn-info"
                id="4"
                onClick={handleButtonClick}
              >
                Log Trace
              </button>
            </ul>
          </nav>
          {measureResultShow && (
            <div className="col-sm-9 col-md-9" id="measureOutput">
              <pre>{JSON.stringify(measureResult, null, 2)}</pre>
            </div>
          )}
          {logErrorShow && (
            <div className="col-sm-9 col-md-9" id="logError">
              <pre>{JSON.stringify(logErrorOutput, null, 2)}</pre>
            </div>
          )}
          {logWarningShow && (
            <div className="col-sm-9 col-md-9" id="logWarning">
              <pre>{JSON.stringify(logWarningOutput, null, 2)}</pre>
            </div>
          )}
          {logTraceShow && (
            <div className="col-sm-9 col-md-9" id="logTrace">
              <pre>{JSON.stringify(logTraceOutput, null, 2)}</pre>
            </div>
          )}
        </div>
      </div>
    );
  }
}
