import {GapChaseFilterBase, filterType, dataType} from '../GapChaseFilterBase';

export default class HccTypesFilter extends GapChaseFilterBase{
    constructor(){
        super();
        this.id = 5;
        this.name = "HCC Type";
        this.filterName = "HccTypes";
        this.isHidden = false;
        this.filterType = filterType.select;
        this.values = [
            { label: "All", value: "5" },
            { label: "Chronic", value: "1" },
            { label: "Acute", value: "0" }
          ];

        this.dataType = dataType.number;
        this.filterCategory = "Gap Level";
        this.filterGroupId = 1;
        this.placeholder = "HCC Type";
    }  
}


