import { GapChaseFilterBase, filterType, dataType } from '../GapChaseFilterBase';

export default class CountyFilter extends GapChaseFilterBase {
    constructor() {
        super();
        this.id = 13;
        this.name = "County";
        this.filterName = "County";
        this.isHidden = true;
        this.filterType = filterType.multiSelect;
        this.values = [
        ];

        this.dataType = dataType.number;
        this.filterCategory = "Member Level";
        this.filterGroupId = 2;
        this.placeholder = "County";
    }
}
