import React, { Component } from 'react';
import { Content } from "../PageLayout";
import UserManualPdf from './UserManual.pdf';

export default class UserManual extends Component {
    render() {
        return (
            <Content>
                <Content.Header>User Manual</Content.Header>
                    <Content.Body>
                        <div><a href={UserManualPdf} target="_blank">Open User Manual </a></div>
                    </Content.Body>
            </Content>
        )
    }
}