import React, { Component } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import { Redirect } from 'react-router';

export default class AdminLogin extends Component {
	constructor(props) {
		super(props);

		this.state = {
			passcode: '',
			goToMainPage: false
		}
	}

	requestAccess = (event) => {
		event.preventDefault();
		const model = {
			PassCode: this.state.passcode
		};

		axios.post('/Authenticate/Admin', model).then((response) => {
			window.sessionStorage.setItem('__administrative__token__', response.data);
			this.setState({ goToMainPage: true });
		}).catch(() => {
			toastr.error('Incorrect Access Code.');
		});
	}

	render() {
		const { passcode, goToMainPage } = this.state;
		const { requestAccess } = this;

		if (goToMainPage === true)
			return (<Redirect to="/Admin" />);

		return (
			<section className="panel-content panel-admin-login">
				<div className="panel panel-default">
					<div className="panel-body">
						<div className="admin-login">
							<div>
								<h3>Enter Code to Access Admin Area</h3>
							</div>
							<form className="form-inline">
								<div className="form-group">
									<input type="password"
										className="form-control"
										placeholder="Passcode"
										onChange={(e) => this.setState({ passcode: e.target.value })}
										value={passcode} />
								</div>
								<button type="submit"
									className="btn btn-default btn-tessellate"
									onClick={requestAccess}
								>
									Access
								</button>
							</form>
						</div>
					</div>
				</div>
			</section>
		);
	}
}