import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ClientList } from "./ClientList";
import { JobStatus } from './JobStatus';

function toFixed(num, precision) {
    return (+(Math.round(+(num + "e" + precision)) + "e" + -precision)).toFixed(
        precision
    );
}

export const renderInteger = num => {
    if (isNaN(parseInt(num)) === false)
        return <div className="rt-num">{parseInt(num)}</div>;
    else return <div></div>;
};

export const renderCapitalize = (val) => {
    if (val === undefined || val === null || val.length == 0) return "";
    return (
        <div style={{ textTransform: 'capitalize' }}>
            {val.toLowerCase()}
        </div>
    )
}
export const renderIntegerLink = ( num, url, func ) => {
    var value = parseInt( num );

    if (isNaN(value) === false) {
        if (value === 0) return <div className="rt-num">{value}</div>;

        return (
            <a href={url} onClick={func} className="rt-num underline">
                {value}
            </a>
        );
    } else return <div className="rt-num">-</div>;
};

export const renderDecimal = (num, digits, prefix = "") => {
    let floatValue = parseFloat(num);

    if (isNaN(floatValue) === false)
        return (
            <div className="rt-num">
                {prefix}
                {toFixed(floatValue, digits)}
            </div>
        );
    else return <div></div>;
};

export const renderDecimalLink = (num, digits, url) => {
    let floatValue = parseFloat(num);

    if (isNaN(floatValue) === false) {
        return (
            <a to={url} className="rt-num underline">
                {toFixed(floatValue, digits)}
            </a>
        );
    } else {
        return <div className="rt-num">-</div>;
    }
};

export const renderDifference = num => {
    let floatValue = parseFloat(num);

    if (isNaN(floatValue) === false) {
        let rounded = toFixed(floatValue, 3);

        if (num < 0) {
            return (
                <div className="rt-num" style={{ color: "#ab001d" }}>
                    {rounded}
                </div>
            );
        } else {
            return <div className="rt-num">{rounded}</div>;
        }
    } else {
        return <div></div>;
    }
};

export const renderDetail = (url, func) => {
    // added func as an input parameter for Link's onClick();
    if (url)
        return (
            <div style={{ width: "100%", textAlign: "center" }}>
                <Link to={url} onClick={func}>
                    <FontAwesomeIcon icon="plus" />
                </Link>
            </div>
        );
    return (
        <div style={{ width: "100%", textAlign: "center", color: "#3c8dbc"}}>
            <FontAwesomeIcon icon="plus" />
        </div>
    );
};

export const renderCheckBox = checked => {
    return (
        <div className="rt-check">
            <input
                type="checkbox"
                className="checkbox"
                disabled
                readOnly
                checked={checked}
            />
        </div>
    );
};

export const renderBoolAsCheck = checked => {
    if (checked)
        return (
            <div style={{ width: "100%", textAlign: "center" }}>
                <FontAwesomeIcon icon="check" />
            </div>
        );

    return <div style={{ width: "100%", textAlign: "center" }}></div>;
};

export const renderDate = date => {
    if (date) {
        date = new Date(date);
        return (
            <div className="rt-num">
                {("0" +( date.getMonth() + 1)).slice(-2) +
                    "/" +
                    ("0" + date.getDate()).slice(-2) +
                    "/" +
                    date.getFullYear()}
            </div>
        );
    } else {
        return <div></div>;
    }
};

export const renderDateTime = date => {
    if (date) {
        date = new Date(date)
            .toISOString()
            .substr(0, 19)
            .replace("T", " ");

        return <div className="rt-num">{date}</div>;
    } else {
        return <div></div>;
    }
};

export const formatDate = date => {
    date = new Date(date);
    return (
        date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear()
    );
};

export const renderYesNo = (value, abbrev = true) => {
    if (abbrev === true) {
        return <div>{value ? "Y" : "N"}</div>;
    } else {
        return <div>{value ? "Yes" : "No"}</div>;
    }
};

export const renderStrNum = value => {
    if (value !== null) {
        return <div className="rt-num">{value.toString()}</div>;
    }
};

export const renderMonthInfo = value => {
    if (value) {
        if (isNaN(parseFloat(value)) === true) {
            // when the value is a string instead of a number
            return (
                <div style={{ width: "100%", textAlign: "center" }}>
                    {value.toLowerCase()}
                </div>
            );
        }

        // When the value is a number
        else {
            return (
                <div style={{ width: "100%", textAlign: "center" }}>
                    {renderDecimal(parseFloat(value), 3)}
                </div>
            );
        }
    }
    return <div></div>;
};

export const renderMonth = value => {
    if (value && isNaN(parseInt(value)) === false) {
        let val = parseInt(value);
        let date = new Date();
        date.setMonth(val - 1);
        let month = date.toLocaleString("en-us", { month: "short" });
        return (
            <div style={{ width: "100%", textAlign: "center" }}>{month}</div>
        );
    } else {
        return <div></div>;
    }
};

export const toDecimalString = (num, digits) => {
    let floatValue = parseFloat(num);
    let result = "";

    if (isNaN(floatValue) === false) result = floatValue.toFixed(digits);

    return result;
};

export const renderMarkets = market => {
    if (market === null || market.length === 0) {
        return market;
    }

    let result = market.split(" ");
    return result
        .map(x => x.charAt(0).toUpperCase() + x.slice(1).toLowerCase())
        .join(" ");
};

export const renderStr = str => {
    if (str === null || str.length === 0) {
        return str;
    }
    return <div style={{ textAlign: "center" }}>{str}</div>;
};

export const renderClientName = id => {
    if (id === null || isNaN(parseInt(id)) === true) {
        return <div></div>;
    }
    if (id in ClientList) {
        return <div style={{ textAlign: "center" }}>{ClientList[id]}</div>;
    } else {
        return <div style={{ textAlign: "center" }}> {id} </div>;
    }
};

export const renderStatus = status => {
    if (status === null || status.length === 0)
    {
        return <div></div>;
    }
    
    if (status in JobStatus)
    {
        return (<div className={JobStatus[status]} style={{textAlign: "center"}}>{status}</div>);
    }
    else {
        return <div style={{textAlign: "center"}}>{status}</div>
    }
};
