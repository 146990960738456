import React, { Component } from "react";
import ReactTable from "react-table";
import {
    renderDetail,
    renderDecimal,
    renderInteger
} from "../../../utils/CommonReactTable";
import * as Constants from "./Constants";
import axios from "axios";

export default class GapPCPSummaryTable extends Component {
    constructor(props) {
        super(props);

        var pagination = {
            pageNumber: props.masterPagination.pageNumber,
            pageSize: props.masterPagination.pageSize,
            pageCount: props.masterPagination.pageCount
        };

        // pagination, filter
        this.state = {
            results: props.results,
            filter: props.filter,
            pagination,
            columnSorting: [],
            totalItemCount: 0
        };

        this.onPageChange = this.onPageChange.bind(this);
        this.onPageSizeChange = this.onPageSizeChange.bind(this);
        this.onPaging = this.onPaging.bind(this);
        this.columnSortinghandler = this.columnSortinghandler.bind(this);
    }

    // static getDerivedStateFromProps(nextProps, prevState) {
    // 	console.log( 'GPST gDSFP nextProps = ' + JSON.stringify( nextProps ));

    // 	return { results: nextProps.results, filter: nextProps.filter, pagination };

    // }

    onPaging = (pageSize, pageIndex) => {
        const { filter, pagination, columnSorting } = this.state;

        // onPageChange: pageSize from state, onPageSizeChange: pageSize passed in
        if (pageSize < 1) pageSize = this.state.results.PageSize;
        else pageIndex = 0;

        pagination.pageNumber = pageIndex + 1;
        pagination.pageSize = pageSize;

        const params = {
            Year: filter.year,
            hiosId: filter.hiosId,
            market: filter.market,
            //ViewBy: 'PCP',

            pageNumber: pagination.pageNumber,
            pageSize: pagination.pageSize,
            columnSorting: columnSorting
        };

        // run member query to get member data
        axios
            .get("/GapProfile/PCP", { params })
            .then(response => {
                pagination.pageCount = response.data.PageCount;
                // should not need to set this.state.pageCount - its in pagination
                this.setState({
                    searched: true,
                    results: response.data,
                    pageCount: response.data.PageCount,
                    pagination,
                    totalItemCount: response.data.TotalItemCount
                });
            })
            .catch(error => {
                console.log(error);
            });
    };

    onPageChange(pageIndex) {
        this.onPaging(-1, pageIndex);
    }

    onPageSizeChange(pageSize, pageIndex) {
        this.onPaging(pageSize, pageIndex);
    }

    columnSortinghandler = sorted => {
        var pagination = this.state.pagination;
        pagination.pageNumber = 0;

        this.setState({ pagination, columnSorting: sorted }, () =>
            this.onPaging(pagination.pageSize, pagination.pageIndex)
        );
    };

    onExport() {
        const pagination = this.state;

        const args = { pagination };
        this.props.onExport(args);
    }

    render() {
        const { results, pagination, totalItemCount } = this.state;
        const { onPageChange, onPageSizeChange, columnSortinghandler } = this;
        const columns = [
            {
                Header: "Detail",
                id: "Detail",
                sortable: false,
                Cell: row => renderDetail("")
            },
            {
                Header: "Benefit Year",
                id: "BenefitYear",
                Cell: row => renderInteger(row.original.BenefitYear)
            },
            {
                Header: "ProviderID",
                accessor: "ProviderID"
            },
            {
                Header: "Provider First Name",
                accessor: "ProviderFirstName"
            },
            {
                Header: "Provider Last Name",
                accessor: "ProviderLastName"
            },
            {
                Header: "Member Count",
                id: "MemberCount",
                Cell: row => renderInteger(row.original.MemberCount)
            },
            {
                Header: "Payment HCCs",
                id: "PaymentHCCs",
                Cell: row => renderInteger(row.original.PaymentHCCs)
            },
            {
                Header: "Historical Gaps",
                id: "HistoricalGaps",
                Cell: row => renderInteger(row.original.HistoricalGaps)
            },
            {
                Header: "Suspect Gaps",
                id: "SuspectGaps",
                Cell: row => renderInteger(row.original.SuspectGaps)
            },
            {
                Header: "Historical Potential",
                id: "HistoricalPotential",
                Cell: row => renderDecimal(row.original.HistoricalPotential, 3)
            },
            {
                Header: "Suspect Potential",
                id: "SuspectPotential",
                Cell: row => renderDecimal(row.original.SuspectPotential, 3)
            },
            {
                Header: "Total Gaps",
                id: "TotalGaps",
                Cell: row => renderInteger(row.original.TotalGaps)
            },
            {
                Header: "Total Potential",
                id: "TotalPotential",
                Cell: row => renderDecimal(row.original.TotalPotential, 3)
            }
        ];

        return (
            <React.Fragment>
                <div className="row export-spacing">
                    <div className="col-sm-12">
                        <button
                            className="btn btn-tessellate pull-right"
                            onClick={() => this.onExport()}
                        >
                            Export
                        </button>
                    </div>
                </div>

                <ReactTable
                    className="ts-react-table -striped -highlight table-row-cursor"
                    columns={columns}
                    data={results.Detail}
                    page={pagination.pageNumber - 1}
                    pages={pagination.pageCount}
                    defaultPageSize={10}
                    pageSizeOptions={[5, 10, 25, 50, 100]}
                    multiSort={true}
                    sortable={true}
                    manual
                    onPageChange={onPageChange}
                    onPageSizeChange={onPageSizeChange}
                    onSortedChange={sorted => columnSortinghandler(sorted)}
                    noDataText={<span>No Results Found</span>}
                    getTdProps={(state, rowInfo, column, instance) => {
                        return {
                            onClick: (e, handleOriginal) => {
                                var detailPagination = Object.assign(
                                    {},
                                    pagination
                                );
                                detailPagination.pageNumber = 1;

                                const providerMasterID =
                                    rowInfo.original.ProviderMasterID;
                                const providerArgs = {
                                    providerMasterID,
                                    pagination: detailPagination,
                                    goBackViewBy: Constants.VIEW_BY.PCP
                                };

                                this.props.onDetail({ pagination });
                                this.props.onProviderChange(providerArgs);

                                if (handleOriginal) {
                                    handleOriginal();
                                }
                            }
                        };
                    }}
                />
            </React.Fragment>
        );
    }
}
