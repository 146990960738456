import React, { Component } from 'react';
import axios from 'axios';
import ReactTable from 'react-table';
import { renderDecimal } from '../../../utils/CommonReactTable';


export default class MemberPopulation extends Component {
    
    constructor( props ) {
        
        super( props );

        this.state = {
            results: [],
            memberMasterID: props.memberMasterId,
            benefitYr: props.benefitYr,
            //pageCount: 0,
            pageNumber: 1,
            pageSize: 10,
            sortKey: 'HCC',
            sortDirection: 'ASC',
            loading: false,
            showHistory: props.showHistory,
            totalItemCount:0  
        };

		this.onPageChange = this.onPageChange.bind(this);
		this.onPageSizeChange = this.onPageSizeChange.bind(this);
        this.onPaging = this.onPaging.bind(this);
        this.onSortedChange = this.onSortedChange.bind( this );
        
    }

    componentDidMount() {
		this.getMemberPopulation();
	}
    
    componentWillReceiveProps( props ) {

		if ( props.showHistory !== this.state.showHistory) {
            this.setState({ showHistory: props.showHistory }, this.getMemberPopulation );
		}

		return true;
    }
    
    getMemberPopulation = ( pageSize, pageIndex, sortKey, sortDirection ) => {

        const { memberMasterID } = this.state;
        

		// onPageChange: pageSize from state, onPageSizeChange: pageSize passed in
		if ( pageSize < 1 ) {
            pageSize = this.state.results.PageSize;
            // pageSize = 10;
        }    
		else
			pageIndex = 0;

		let pageNumber = pageIndex + 1;

		if ( !sortKey ) {
			sortKey = this.state.sortKey;
			sortDirection = this.state.sortDirection;
		}

        var specYearStart = this.state.showHistory ? this.state.benefitYr - 2 : this.state.benefitYr;

		const paramsDetail = {
			MemberMasterID: memberMasterID, 
			specYearStart, 
            specYearEnd: this.state.benefitYr,
            hiosID: "All",
            Market: "All",            
			pageSize,
			pageNumber: pageNumber,		
			sortKey,
			sortDirection
        };
        

		// run member query to get member data
		axios.get('/GapProfile/MemberGapDetail', { params: paramsDetail }).then( (response) => {

			let results = this.state.results;
			results.Detail = response.data.Detail;
			results.PageNumber = response.data.PageNumber;
			results.PageSize = response.data.PageSize;
			results.PageCount = response.data.PageCount;

			this.setState( { results, memberMasterID, sortKey, sortDirection,totalItemCount: response.data.Detail.length } );
	
		}).catch(( error ) => {
			console.log( error );
		})

    }

	
	onPaging = ( pageSize, pageIndex, sortKey, sortDirection ) => {

        this.getMemberPopulation( pageSize, pageIndex, sortKey, sortDirection );

	}

	onPageChange(pageIndex) {

		this.onPaging( -1, pageIndex, "", "" );
	}

	onPageSizeChange(pageSize, pageIndex) {

		this.onPaging( pageSize, pageIndex, "", "" );
	}

	onSortedChange( newSorted, column, shiftKey ) {

		const sort = newSorted[ 0 ];
		const sortDirection = sort.desc ? "DESC" : "ASC";

		// console.log( JSON.stringify( newSorted[ 0 ] ) );

		this.onPaging( -1, 0, sort.id, sortDirection );

	}

    shouldComponentUpdate(nextProps, nextState) {

        return this.state.results && this.state.results.Detail !== undefined;
    }
 
    render() {
        const { results,totalItemCount } = this.state;
        
        const columns = [
            {
                Header: 'Benefit Year',
                accessor: 'BenefitYear',
            },
            {
                Header: 'HCC Type',
                accessor: 'HCCType',
            },
            {
                Header: 'HCC',
                accessor: 'HCC',
            },
            {
                Header: 'HCC Description',
                accessor: 'HCCDescription',
                width: 350
            },
            {
                Header: 'Risk Factor',
                id: 'RiskFactor',
                Cell: ( row ) => renderDecimal( row.original.RiskFactor, 3 ),
            },
			{
                Header: 'Gap Source',
				accessor: 'GapSource',
			},
			{
                Header: 'Last ICD Code',
				accessor: 'LastICDCode',
			},
			{
                Header: 'Last Through DOS',
				accessor: 'LastThroughDOS',
			},
			{
				Header: 'Last Provider ID',
				accessor: 'LastProviderID',
			},
			{
				Header: 'Last Provider Last Name',
				accessor: 'LastProviderLastName',
			},
			{
                Header: 'Last Provider First Name',
				accessor: 'LastProviderFirstName',
            },
                        
        ];


        return (
            <div className="col-sm-12">
            <ReactTable
                className='ts-react-table -striped -highlight'
                columns = { columns } 
                data = { results.Detail }
                page = { results.PageNumber - 1 }
                pages = { results.PageCount }
                defaultPageSize = { 10 }
                pageSizeOptions = { [ 5, 10, 25, 50, 100 ] }
                sortable={ true }
                defaultSorted={ [ {
                    id : 'HCC',
                    desc : false
                 } ] }
                multiSort = { false }
                manual

                onPageChange = { this.onPageChange }
                onPageSizeChange = { this.onPageSizeChange }
                onSortedChange = { this.onSortedChange }
                noDataText={(<span>No Results Found</span>)}
            />
            	<div><strong>Total Number of Records: {totalItemCount}</strong></div>
            </div>
        );
    }
}

