import React, { Component } from "react";
import axios from "axios";
import toastr from "toastr";
import Autosuggest from "react-autosuggest";
import ReactTable from "react-table";
import { connect } from "react-redux";
import {
    mapMemberProfileDispatchToProps,
    mapMemberProfileToProps
} from "../../../redux/actions";
import {
	renderDetail,
    renderDate,
    renderInteger,
    renderDecimal
} from "../../../utils/CommonReactTable";
import { Content } from "../../PageLayout";
import { download } from "../../../utils/File";
import _ from "lodash";
import { Redirect } from 'react-router-dom'


class MemberProfileReport extends Component {
    constructor(props) {
        super(props);

        const { search } = props.location;

        let filter = {
                year: new Date().getFullYear(),
                hiosId: "All",
                market: "All",
                status: "Active",
                columnFilter: [],
                columnSorting: [
                    { id: "MemberLastName", desc: false },
                    { id: "MemberFirstName", desc: false }
                ]
            },
            searchKey = "",
            memberMasterId = 0;

        if (search) {
            let temp = search.split("&");
            temp.forEach(x => {
                let keyPair = x.split("=");
                if (keyPair[0].indexOf("benefitYr") > -1) {
                    filter.year = parseInt(keyPair[1]);
                } else if (keyPair[0].indexOf("status") > -1) {
                    filter.status = keyPair[1];
                } else if (keyPair[0].indexOf("keyword") > -1) {
                    searchKey = keyPair[1];
                } else if (keyPair[0].indexOf("memberMasterId") > -1) {
                    memberMasterId = keyPair[1];
                }
            });
        }

        this.state = {
            years: [],
            markets: [],
            hiosIds: [],
            status: [],
            filter,
            hiosIdYearPair: [],
            selectedMemberMasterId: memberMasterId,
            suggestions: [],
            search: searchKey,
            pageNumber: 1,
            pageSize: 10,
            pageCount: 0,
            data: [],
            loading: false,
            timeId: null,
            selectedYear: filter.year,
            totalItemCount : 0,
            redirect:false,
            detailUrl:null
        };

        this.onPageChange = this.onPageChange.bind(this);
        this.onPageSizeChange = this.onPageSizeChange.bind(this);
        this.onPaging = this.onPaging.bind(this);
    }

    componentDidMount() {
        axios
            .get("/MemberProfile/Options")
            .then(response => {
                const { data } = response;

                const years = data.map(x => x.Year);
                const hiosIdYearPair = this.getHiosIdOptions(data);
                const markets = this.getMarketOptions(data);
                const status = data.map(x => x.Status)[0];

                this.setState(
                    { data, years, markets, status, hiosIdYearPair },
                    this.checkAndSetFromState
                );
            })
            .catch(error => {
                toastr.error("An error occurred retrieving filter options.");
            })
            .finally(this.getMemberProfileData);
    }

    checkAndSetFromState = () => {
        const {filter, pagination} = this.props.memberProfile;
        
        // If benefitYear in redux equals 0, which means we have defaultMemberProfile for now
        if (parseInt(filter.benefitYear) === 0) {
            
            this.props.resetMemberProfile();
        } 
        /* 
        If benefitYear in redux is not 0, which means we have the state which is NOT defaultMemberProfile 
        We can get stored states from props, and set them to current state.
        */  
        else {
            this.setState({ filter: filter,
                pageNumber: pagination.pageNumber,
                pageSize: pagination.pageSize
             });
        }
    };

    getHiosIdOptions = data => {
        const hiosIdOptions = data.map(({ Year, HiosIds }) => ({
            Year,
            HiosIds
        }));
        
        return hiosIdOptions;
    };

    getMarketOptions = data => {
        const marketOptions = data.map(x => x.Markets)[0];


        return marketOptions;
    };

    handleFilterChange = key => event => {
        const { filter } = this.state;
        this.setState({ filter: { ...filter, [key]: event.target.value } });
    };

    searchByFilter = event => {
        event.preventDefault();
        this.setState(
            { search: "", selectedMemberMasterId: 0, pageNumber: 1 },
            this.getMemberProfileData
        );
    };

    getMemberProfileData = () => {
        const {
            filter,
            selectedMemberMasterId,
            pageNumber,
            pageSize,
            search,
            totalItemCount
        } = this.state;

        let currentYr = new Date().getFullYear();
        if (parseInt(filter.year) !== currentYr) {
            filter.status = "Active";
        }

        const params = {
            ...filter,
            pageNumber,
            pageSize,
            memberMasterId: selectedMemberMasterId,
            search
        };

        axios
            .get("/MemberProfile", { params })
            .then(response => {
                this.setState({
                    loading: false,
                    pageCount: response.data.PageCount,
                    data: response.data.Detail,
                    totalItemCount : response.data.TotalItemCount
                });
            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false, data: [] });
                toastr.error(
                    "An error occurred retrieving member profile data."
                );
            });
    };

    getSuggessions = value => {
        const { year } = this.state.filter;
        const params = { search: value, benefitYr: year };

        axios
            .get("/MemberProfile/MemberAutofill", { params })
            .then(response => {
                this.setState({ suggestions: response.data });
            })
            .catch(error => {
                console.log(error);
                toastr.error(
                    "An error occurred retrieving member data for autofill."
                );
            });
    };

    onSuggestionsFetchRequested = ({ value, reason }) => {
        if (reason === "input-changed" && value.length >= 3) {
            var filter = this.state.filter;
            filter.status = "search";
            this.setState({ filter }, this.getSuggessions(value));
        } else if (reason === "input-focused") {
            this.getSuggessions(value);
        }
    };

    onSuggestionsClearRequested = () => {
        this.setState({ suggestions: [] });
    };

    getSuggestionValue = suggestion => {
        this.setState(
            {
                selectedMemberMasterId: Object.values(suggestion)[0],
                pageNumber: 1,
                pageSize: this.state.pageSize,
                loading: true
            },
            this.getMemberProfileData
        );
    };

    renderSuggestion = (suggestion, { query, isHighlighted }) => {
        return <span>{suggestion.Label}</span>;
    };

    memberSearchChange = (event, { newValue, method }) => {
        event.preventDefault();
        if (method === "type") {
            this.setState({ search: newValue });
            if (newValue === null || newValue.length === 0) {
                this.setState({
                    selectedMemberMasterId: 0
                });
            }
        }
    };

    handleExportClick = e => {
        e.preventDefault();
        const { filter, selectedMemberMasterId } = this.state;

        let currentYr = new Date().getFullYear();
        if (parseInt(filter.year) !== currentYr) {
            filter.status = "Active";
        }

        const params = {
            ...filter,
            memberMasterId: selectedMemberMasterId
        };

        axios
            .get("/MemberProfile/Export", { params })
            .then(response => {
                const fileName =
                    response.headers["x-suggested-filename"] ||
                    "ResultsExtract_" + new Date().getTime() + ".txt";
                download(response.data, fileName);
            })
            .catch(error => {
                console.log(error);
                toastr.error(
                    "An error occurred retrieving member profile data export file."
                );
            });
    };

    onPaging = (pageSize, pageIndex) => {
        const { filter } = this.state;

        if (pageSize < 1) pageSize = this.state.pageSize;
        else pageIndex = 0;

        let pageNumber = pageIndex + 1;

        this.setState(
            { filter, pageSize, pageNumber },
            this.getMemberProfileData
        );
    };

    onPageChange(pageIndex) {
        this.onPaging(-1, pageIndex);
    }

    onPageSizeChange(pageSize, pageIndex) {
        this.onPaging(pageSize, pageIndex);
    }

    generateLink = MemberMasterID => {
        const { year, status } = this.state.filter;
        const { search } = this.state;
        var link = "/Reporting/MemberProfile/" + MemberMasterID;
        link += "?benefitYr=" + year;
        if (status !== "Active" && status !== "search") {
            link += "&status=" + status;
        }
        link += "&keyword=" + search;
        return link;
    };

    setRedirect = (memberMasterId) => {
        var url = this.generateLink(memberMasterId);
        this.setState({
          redirect: true,
          detailUrl: url
        }, ()=>this.setupMemberProfile())
      }

    columnFilterhandler = filtered => {
        const { filter, timeId } = this.state;
        if (timeId != null) {
            clearTimeout(timeId);
        }
        var currenttimeId = setTimeout(() => {
            this.setState(
                {
                    pageNumber: 1,
                    filter: { ...filter, columnFilter: filtered }
                },
                this.getMemberProfileData
            );
        }, 1000);
        this.setState({ timeId: currenttimeId });
    };

    columnSortinghandler = sorted => {
        const { filter } = this.state;
        this.setState(
            { pageNumber: 1, filter: { ...filter, columnSorting: sorted } },
            this.getMemberProfileData
        );
    };

    //TODO: Add more states if it's necessary to let Redux instore for carrying them through the app
    /*
    Setup memberProfile globally here because when you are leaving the summary component
    the Redux will update the new states for member profile component.
    When you drill down to one member's detail then back to the summary,
	it will re-render the summary report within previous filter information.
	*/
    setupMemberProfile = () => {
		const { filter, pageNumber, pageSize, search } = this.state;
        const memberProfile = {
            filter: filter,
            pagination: {
            	pageNumber: pageNumber,
            	pageSize:pageSize
              },
            search: search
        };

        this.props.setMemberProfile(memberProfile);
    };

    render() {
        const {
            years,
            status,
            markets,
            hiosIdYearPair,
            search,
            suggestions,
            filter,
            data,
            pageCount,
            pageNumber,
            pageSize,
            loading,
            totalItemCount,
            redirect,
            detailUrl
        } = this.state;
        const {
            handleFilterChange,
            searchByFilter,
            onSuggestionsFetchRequested,
            onSuggestionsClearRequested,
            getSuggestionValue,
            renderSuggestion,
            memberSearchChange,
            onPageSizeChange,
            onPageChange,
            handleExportClick,
            columnFilterhandler,
            columnSortinghandler,
            setupMemberProfile,
            generateLink,
            setRedirect
        } = this;

        const currentYr = new Date().getFullYear();

        const inputProps = {
            value: search,
            onChange: memberSearchChange,
            placeholder: "Search...."
        };

        const columns = [
            {
                Header: "Detail",
                id: "MemberMasterID",
                width: 50,
                filterable: false,
                sortable: false,
                //TODO: In the future, if we want to retrieve filtered member detail, we might need to update the detail page reuqest link
                Cell: row =>
                    renderDetail(generateLink(row.original.MemberMasterID), setupMemberProfile)
            },
            {
                Header: "Benefit Year",
                id: "BenefitYear",
                width: 100,
                Cell: row => renderInteger(row.original.BenefitYear)
            },
            {
                Header: "Member ID",
                accessor: "MemberID",
                width: 100
            },
            {
                Header: "Status",
                accessor: "Status",
                width: 100
            },
            {
                Header: "HIOS ID",
                accessor: "HiosID",
                width: 100
            },
            {
                Header: "Market",
                accessor: "Market",
                width: 100
            },
            {
                Header: "Last Name",
                accessor: "MemberLastName",
                width: 200
            },
            {
                Header: "First Name",
                accessor: "MemberFirstName",
                width: 150
            },
            {
                Header: "MI",
                accessor: "MI",
                width: 50
            },
            {
                Header: "Sex",
                accessor: "Gender",
                width: 50,
                filterable: false
            },
            {
                Header: "DOB",
                id: "DOB",
                width: 100,
                filterable: false,
                Cell: row => renderDate(row.original.DOB)
            },
            {
                Header: "Age Last",
                id: "AgeLast",
                width: 50,
                filterable: false,
                Cell: row => renderInteger(row.original.AgeLast)
            },
            {
                Header: "Average CMS RARSD Score",
                id: "AvgCMSRARSDScore",
                width: 125 /* 125 minimum for Footer */,
                filterable: false,
                Cell: row => renderDecimal(row.original.AvgCMSRARSDScore, 3),

                Footer: (
                    <span>
                        <strong>Average:</strong>{" "}
                        {_.round(
                            _.mean(_.map(data, d => d.AvgCMSRARSDScore)),
                            3
                        )}
                    </span>
                )
            },
            {
                Header: "Age Band",
                accessor: "AgeBand",
                width: 75
            },
            {
                Header: "# of Plans",
                id: "NumberOfPlans",
                width: 75,
                filterable: false,
                Cell: row => renderInteger(row.original.NumberOfPlans)
            },
            {
                Header: "Open Gaps",
                id: "OpenGaps",
                width: 75,
                filterable: false,
                Cell: row => renderInteger(row.original.OpenGaps),

                Footer: (
                    <span>
                        <strong>Total:</strong>{" "}
                        {_.sumBy(data, function(d) {
                            return d.OpenGaps;
                        })}
                    </span>
                )
            }
        ];

        let hiosIdList = hiosIdYearPair.filter(x => {
            return x.Year === parseInt(filter.year);
        });

        if (redirect) {
            return <Redirect to = {detailUrl} />
          }

        return (
            <Content>
                <Content.Header>Member Profile Report</Content.Header>
                <Content.Body>
                    <Content.Filter>
                        {years.length > 0 &&
                            status.length > 0 &&
                            markets.length > 0 && (
                                <div className="row">
                                    <div className="col-sm-12">
                                        <form className="form-inline">
                                            <div className="form-group">
                                                <label className="control-label">
                                                    Benefit Year:
                                                </label>
                                                <select
                                                    className="form-control"
                                                    value={filter.year}
                                                    onChange={handleFilterChange(
                                                        "year"
                                                    )}
                                                >
                                                    <option value={0} disabled>
                                                        Select
                                                    </option>
                                                    {years.map(x => (
                                                        <option
                                                            key={x}
                                                            value={x}
                                                        >
                                                            {x}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label className="control-label">
                                                    HIOS ID:
                                                </label>
                                                <select
                                                    className="form-control"
                                                    value={filter.hiosId}
                                                    onChange={handleFilterChange(
                                                        "hiosId"
                                                    )}
                                                >
                                                    <option value="" disabled>
                                                        Select
                                                    </option>
                                                    {hiosIdList[0].HiosIds.map(
                                                        x => (
                                                            <option
                                                                key={x}
                                                                value={x}
                                                            >
                                                                {x}
                                                            </option>
                                                        )
                                                    )}
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label className="control-label">
                                                    Market:
                                                </label>
                                                <select
                                                    className="form-control"
                                                    value={filter.market}
                                                    onChange={handleFilterChange(
                                                        "market"
                                                    )}
                                                >
                                                    <option value={0} disabled>
                                                        Select
                                                    </option>
                                                    {markets.map(x => (
                                                        <option
                                                            key={x}
                                                            value={x}
                                                        >
                                                            {x}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label className="control-label">
                                                    Status:
                                                </label>
                                                <select
                                                    className="form-control"
                                                    value={filter.status}
                                                    onChange={handleFilterChange(
                                                        "status"
                                                    )}
                                                    disabled={
                                                        parseInt(
                                                            filter.year
                                                        ) !== currentYr
                                                    }
                                                >
                                                    {parseInt(filter.year) ===
                                                    currentYr ? (
                                                        status.map(x => (
                                                            <option
                                                                key={x}
                                                                value={x}
                                                            >
                                                                {x}
                                                            </option>
                                                        ))
                                                    ) : (
                                                        <option
                                                            key="Active"
                                                            value="Active"
                                                        >
                                                            Active
                                                        </option>
                                                    )}
                                                </select>
                                            </div>
                                            <button
                                                className="btn btn-tessellate"
                                                style={{ marginRight: "15px" }}
                                                onClick={searchByFilter}
                                                disabled={
                                                    filter.status.length ===
                                                        0 || filter.year === 0
                                                }
                                            >
                                                Submit
                                            </button>
                                        </form>
                                    </div>
                                    <div className="col-sm-12">
                                        <form className="form-inline">
                                            <div className="form-group">
                                                <label className="control-label">
                                                    Search by Member (Member ID,
                                                    Last Name):
                                                </label>
                                                <Autosuggest
                                                    className="form-control"
                                                    suggestions={suggestions}
                                                    onSuggestionsFetchRequested={
                                                        onSuggestionsFetchRequested
                                                    }
                                                    onSuggestionsClearRequested={
                                                        onSuggestionsClearRequested
                                                    }
                                                    getSuggestionValue={
                                                        getSuggestionValue
                                                    }
                                                    renderSuggestion={
                                                        renderSuggestion
                                                    }
                                                    inputProps={inputProps}
                                                    
                                                />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            )}
                    </Content.Filter>
                    <div className="row export-spacing">
                        <button
                            className="btn btn-tessellate pull-right"
                            onClick={handleExportClick}
                        >
                            Export
                        </button>
                    </div>
                    <Content.Result>
                        <div className="row">
                            <div className="col-sm-12">
                                <ReactTable
                                    filterable={true}
                                    sortable={true}
                                    className="ts-react-table table-row-cursor -striped -highlight"
                                    columns={columns}
                                    data={data}
                                    manual
                                    loading={loading}
                                    onPageSizeChange={onPageSizeChange}
                                    onPageChange={onPageChange}
                                    defaultPageSize={pageSize}
                                    page={pageNumber - 1}
                                    pages={pageCount}
                                    pageSizeOptions={[10, 25, 50, 100]}
                                    noDataText={(<span>No Results Found</span>)}
                                    onFilteredChange={filtered =>
                                        columnFilterhandler(filtered)
                                    }
                                    onSortedChange={sorted =>
                                        columnSortinghandler(sorted)
                                    }
                                    getTrProps={(state, rowInfo, column) => {
                                          return {
                                            onClick: (event)=>setRedirect(rowInfo.row._original.MemberMasterID)}
                                            }                                    
                                    }
                                
                                />
                                 <div><strong>Total Number of Records: {totalItemCount}</strong></div>
                            </div>
                        </div>
                    </Content.Result>
                </Content.Body>
            </Content>
        );
    }
}

export default connect(
    mapMemberProfileToProps,
    mapMemberProfileDispatchToProps
)(MemberProfileReport);
