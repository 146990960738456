import React, { Component } from 'react';

export default class Footer extends Component {
    constructor (props) {
        super(props);

        this.state = {
            client: '',
            environment: '',
            version: ''
        };
    }

    componentDidMount() {
        this.setState({
            client:  (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_CLIENT_NAME_DEV : process.env.REACT_APP_CLIENT_NAME_PROD) || '',
            environment:  process.env.NODE_ENV || '',
            version:  process.env.REACT_APP_VERSION || ''
        });
    }

    render() {
        return (
            <footer className="main-footer">
                <div className="pull-right">
                    {this.state.client} | {this.state.version}
                </div>
            </footer>
        );
    }
}