import React from 'react';
import Axios from 'axios';
import { Col, Glyphicon, Panel, Row, Table } from 'react-bootstrap';
import { clone } from 'lodash';
import { defaultGapListResultColumns, defaultChaseListResultColumns } from './DefaultColumns';
import GapChaseLoadingStatus from './GapChaseLoadingStatus';
import { Error } from './Messages';
import { Records, Sort } from './TableComponents';

export default class ListResults extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listResults: null,
            paginationStats: {
                CountPerPage: 10,
                Page: 0,
                RecordCount: 0
            },
            sortOrder: [],
            error: this.props.showError ? this.props.showError : false
        };
        this.columns = this.props.listType === 'Gap' ? defaultGapListResultColumns() : defaultChaseListResultColumns();

        const CancelToken = Axios.CancelToken;
        this.source = CancelToken.source();
    }

    componentDidMount() {
        //load results on create
        if (this.props.listDefinition && this.props.listDefinition.loadingStatus === "Complete") {
            this.loadListResults(this.props);
        }
    }

    componentWillUpdate(nextProps) {
        //load results on update
        if (nextProps.listDefinition && this.props.listDefinition && nextProps.listDefinition.loadingStatus !== this.props.listDefinition.loadingStatus) {
            this.loadListResults(nextProps.listDefinition.id);
        }

        if (nextProps.showError === true && this.props.showError !== nextProps.showError) {
            this.setState({ error: true });
        }
    }

    componentWillUnmount() {
        this.source.cancel();
    }

    handleSort = (sortData, e) => {
        this.columns.forEach((item, index) => {
            if (item.fieldName === sortData.fieldName) {
                this.columns[index].sortOrder = 1;
                this.columns[index].sortDirection = sortData.sortDirection;
            }
            else {
                if (item.sortOrder === undefined) {
                    item.sortOrder = 0;
                }
                this.columns[index].sortOrder = 0;
                this.columns[index].sortDirection = "";
            }
        });
        this.pageChange(0);
    }

    pageChange = (idx) => {
        this.loadListResults(this.props, idx, this.state.paginationStats.CountPerPage);
    }

    pageCountChange = (countPerPage) => {
        this.loadListResults(this.props, 0, countPerPage);
    }

    loadListResults = (props, page = this.state.paginationStats.Page, pageSize = this.state.paginationStats.CountPerPage) => {
        this.getListResults(props.listDefinition.id, page, pageSize)
            .then((response) => {
                if (response.status === 200) {
                    if (props.listType == "Chase")
                        response.data.results.map(x => x.ProviderAddress1 = x.ProviderAddress1.Location);
                    this.setState({
                        listResults: response.data,
                        paginationStats: {
                            CountPerPage: pageSize,
                            Page: response.data.pageNumber - 1,
                            RecordCount: response.data.totalCount
                        }
                    });
                }
            })
            .catch((error) => {
                if (!Axios.isCancel(error)) {
                    // handle error
                    this.setState({ error: true });
                }
            });
    }

    //gets paginated results
    getListResults = (listID, page = this.state.paginationStats.Page, pageSize = this.state.paginationStats.CountPerPage) => {
        const sortOrder = [];
        let localColumns = clone(this.columns);
        localColumns.sort((a, b) => { return a.sortOrder > b.sortOrder }).forEach((item) => {
            if (item.sortDirection !== undefined && item.sortDirection.length && item.sortOrder > 0) {
                sortOrder.push(item.fieldName + " " + item.sortDirection);
            }
        });

        return Axios.get(`/v1/Lists/${this.props.listType}/${listID}/Results`, {
            params: {
                listType: this.props.listType,
                page: page + 1,
                pageSize: pageSize,
                sortOrder: sortOrder.join(',')
            },
            cancelToken: this.source.token
        });
    }

    //remove row properties that shouldn't be displayed from the row obj
    removeNonDisplayedProps = (row, nonDisplayed) => {
        return Object.entries(row).reduce((acc, cur) => {
            if (!nonDisplayed.includes(cur[0])) {
                acc[cur[0]] = cur[1];
            }
            return acc;
        }, {});
    }

    render() {
        let contents, panelTitle, header, rows, table;

        if (this.props.listDefinition) {
            const loadingStatusDisplay = this.props.listDefinition.loadingStatus && this.props.listDefinition.loadingStatus !== "Complete" ?
                <div style={{ display: "inline-block", float: "right" }}>&nbsp;/ {GapChaseLoadingStatus[this.props.listDefinition.loadingStatus]}</div> : null;

            panelTitle = (<h4>
                <div style={{ display: "inline-block" }}>{this.props.listDefinition.name}</div>
                <div style={{ display: "inline-block", float: "right" }}>{this.props.listDefinition.status} {loadingStatusDisplay}</div>
            </h4>);

            if (this.props.listDefinition.loadingStatus === "Complete" && this.state.listResults) {
                //list loaded, results available
                header = this.columns.map((item, index) => {
                    if ((localStorage.getItem("Lob") == "Medicare" && item.fieldName != "MetalLevel") || (localStorage.getItem("Lob") == "Commercial" && item.fieldName != "RaStatus"))
                    {
                        return (
                            <th key={index} className={item.className} style={{ height: "1px" }}>
                                <Sort onSort={this.handleSort} fieldName={item.fieldName} sortDirection={item.sortDirection}
                                    text={item.displayName} sortable={item.sortable} />
                            </th>
                        );
                    }
                    else return null;

                });

                let nonDisplayed = [];
                if (this.props.listType === "Gap") {
                    //nonDisplayed = ['EvidenceDate', 'EvidenceType', 'EvidenceValue', 'EvidenceDescription'];
                    if (localStorage.getItem("Lob") == "Medicare") nonDisplayed = ['MetalLevel'];
                    else nonDisplayed = ['RaStatus'];
                }
                else {
                    nonDisplayed = ['CreatedDateTime'];
                }

                rows = this.state.listResults.results.map((item, index) => {
                    item = this.removeNonDisplayedProps(item, nonDisplayed);
                    if (this.props.listType === "Gap") {
                        item.IsChronic = (item.IsChronic == null) ? "" : item.IsChronic ? "Chronic" : "Acute";
                    }

                    return (
                        <tr className="text-left" key={index}>
                            {
                                Object.values(item).map((value, idx) => {
                                    if (typeof (value) === 'boolean') {
                                        value = value.toString();
                                    }
                                    return (<td key={idx}>{value}</td>);
                                })
                            }
                        </tr>
                    );
                });

                table = <div style={{ minHeight: "298px" }}>
                    <Table hover bordered condensed striped responsive>
                        <thead>
                            <tr>
                                {header}
                            </tr>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                    </Table>
                </div>

                contents = (
                    <Col lg={12}>
                        {table}
                        <Records stats={this.state.paginationStats} onPageChangeSize={this.pageCountChange} onPage={this.pageChange} />
                    </Col>
                );
            }

            else if (this.props.listDefinition.loadingStatus === "Failed") {
                //Error on generation
                table = (
                    <div style={{ textAlign: "center", marginTop: "13rem", marginBottom: "8rem" }}>
                        <Glyphicon glyph="remove-circle" style={{ fontSize: "48px", lineHeight: "14px", color: "#6c716c" }} />
                        <h3 style={{ textAlign: "center", marginTop: "2rem" }}>There was an error generating your list.</h3>
                    </div>
                );
                contents = <Col lg={12}>{table}</Col>;
            }

            else if (this.props.listDefinition.loadingStatus !== "Complete" && this.props.listDefinition.loadingStatus !== "Failed") {
                //list loaded, results unavailable
                table = (
                    <div style={{ textAlign: "center", marginTop: "13rem", marginBottom: "8rem" }}>
                        <Glyphicon className="spinning" glyph="cog" style={{ fontSize: "48px", lineHeight: "14px", color: "#6c716c" }} />
                        <h3 style={{ textAlign: "center", marginTop: "2rem" }}>List generation in progress</h3>
                    </div>
                );
                contents = <Col lg={12}>{table}</Col>;
            }
            return (
                <div>
                    <section>
                        <div className="panel-content" style={{ marginBottom: "3rem", paddingLeft: "0rem", paddingRight: "0rem" }}>
                            <Panel>
                                <Panel.Heading>
                                    <Panel.Title>
                                        {panelTitle}
                                    </Panel.Title>
                                </Panel.Heading>
                                <Panel.Body style={{ minHeight: '383px' }}>
                                    <Row>
                                        {contents}
                                    </Row>
                                </Panel.Body>
                            </Panel>
                        </div>
                    </section>
                </div>
            );
        }
        else if (this.state.error) {
            return (
                <div className="panel-body" style={{ minHeight: '383px' }}>
                    <Error />
                </div>
            );
        }
        else {
            return null;
        }
    }
}