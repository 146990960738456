import React from 'react';
import MultiSelect from './MultiSelect';
import Range from './Range';
import { UniqueID } from '../../utils/GapChaseUtils';
import { isNil } from 'lodash';

/*
    FilterInput is a controlled component for simple input types (select, checkbox, text fields),
    and serves as wrapper for more complex components (MultiSelect, Range). Refs are used here to provide access
    to MultiSelect (which manages its own state) and for input validation (ex. number / text).
    On change, filter selections are passed up the component chain to FilterSidebar.
*/
var cpm = null; var Tier1Chase=null; var Tier2Chase=null; 
var PcpChaseType = null; var SpecialistChase = null; var FacilityChase = null;var ddlSpecialist = null;
var ddlFacility = null;

export default class FilterInput extends React.Component {    
    constructor(props) {
        super(props);
        this.state = {
            id: UniqueID.getUniqueID(),
            value: this.props.defaultValue ? this.props.defaultValue : '',
        };

        this.inputElement = null;
        this.mounted = false;       
    }

    componentDidMount() {
        this.mounted = true;

        //clear input if providing a default that should not be set on initial render (ex. HCC filters after hiding/displaying the panel)
        if (!this.props.setDefault) {
            this.clear();
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    getDefaultValue = () => {
        return this.props.defaultValue ? this.props.defaultValue : '';
    }

    getInputRef = (input) => {
        return this.inputElement = input;
    }

    trimDecimal = (input) => {
        return input.length > 0 && (!isNaN(parseInt(input))) > 0 ? Math.trunc(Math.abs(input)) : '';
    }
    


    handleOnChange = (event) => {
        if (this.props.inputType === 'Select') {
            this.props.onChange(this.props.filter, 'Eq', event.target.value, this);
            this.setState({ value: event.target.value });
            ////if ALL Chases then disable the Chases Per Member
            //if(this.props.filter.id === 40)
            //{
            //    this.disableChasesPerMmeber(event.target.value);               
            //}
        }
        else if (this.props.inputType === 'Multiselect') {
            this.props.onChange(this.props.filter, 'Eq', event, this);
        }
        else if (this.props.inputType === 'Range') {
            if (event) {
                if (event[0] && event[1]) {
                    this.props.onChange(this.props.filter, 'Between', event, this);
                }
                else if (event[0]) {
                    this.props.onChange(this.props.filter, 'Gte', event, this);
                }
                else if (event[1]) {
                    this.props.onChange(this.props.filter, 'Lte', event, this);
                }
                else {
                    this.props.onChange(this.props.filter, 'Between', null, this);
                }
            }
        }
        else {
            if (this.props.inputType === 'Checkbox') {
                this.props.onChange(this.props.filter, 'Eq', event.target.checked, this);
                this.setState({ value: event.target.checked })
                //if (this.props.filter.id === 41) {
                //    //Disabling PCP RA Gain
                //    this.disableRangeControl(event.target.checked, PcpChaseType)
                //}
                //else if(this.props.filter.id === 43 || this.props.filter.id === 45) //Disable Tier1,Tier2 RA GAIN 
                //{                     
                //    this.disableRangeControl(event.target.checked, (this.props.filter.id === 43 ? Tier1Chase : Tier2Chase));               
                //}
                //else if (this.props.filter.id === 53 || this.props.filter.id === 54)
                //{
                //    this.disableRangeControl(event.target.checked, (this.props.filter.id === 53 ? SpecialistChase : FacilityChase));
                //    //this.hideOrShowDropdown(event.target.checked, (this.props.filter.id === 53 ? ddlSpecialist : ddlFacility));
                //}
            }
            else {                
                // this.props.onChange(this.props.filter, 'Eq', event.target.value, this);
                // //Allow whole number to Chases Per Member Textbox
                // if (this.props.filter.id === 24)
                // {
                //     let cnt =this.countDecimals(event.target.value);                                                        
                //     if(cnt <= 0 && event.target.value >= 0)
                //         this.setState({ value: event.target.value })
                // }else
                // this.setState({ value: event.target.value })

                //Allow whole positive number in "Chases Per Member" Textbox
                let input = event.target.value;
                if (this.props.filter.id === 24)
                {
                    input = this.trimDecimal(input);
                    this.setState({ value: input })
                }
                else
                  this.setState({ value: input })

                this.props.onChange(this.props.filter, 'Eq', input, this);
            }
        }
    }
    //Disable the chases Per member based on All Chases
    disableChasesPerMmeber(val)
    {
        var e= document.getElementById(cpm.id);
        if(val === "2" || val === "3")                
           {                                         
            e.disabled = false;
           }else  
           {               
            cpm.value = "";           
           e.disabled = true; 
        }
    }


    disableRangeControl(val, rangeControl) {
        if (rangeControl != null) {
            //Disabling Range control based on checkbox
            var min = document.getElementById(rangeControl.props.filter.id + '-1');
            var max = document.getElementById(rangeControl.props.filter.id + '-2');
            min.disabled = !val;
            max.disabled = !val;
    
            if (val === false)
                rangeControl.clear();  
        }
    }

    //Hide/ Display the dropdown based check box selection
    hideOrShowDropdown(val, ddl) {
        if (ddl != null) {                                  
           var div = document.getElementById(ddl.props.filter.id);            
            if (val === false && div != null){
                ddl.clear();  
                div.style.display="none";
            }else div.style.display="block";
        }
    }
    

    //Verify the Chaes Per Member having decimals or not
    countDecimals(value)
    {               
        return value.toString().split(".").length > 1 ? value.toString().split(".")[1].length : 0;
    }

    //set input to default
    reset = () => {
        if (this.mounted) {
            if (this.props.inputType === 'Multiselect' || this.props.inputType === 'Range') {
                if (this.inputElement) {
                    this.inputElement.setValue(this.getDefaultValue());
                }
            }
            this.setState({ value: this.getDefaultValue() });
        }
    }

    //set input to empty
    clear = () => {
        if (this.mounted) {
            if (this.props.inputType === 'Multiselect' || this.props.inputType === 'Range') {
                if (this.inputElement) {
                    this.inputElement.setValue('');
                }
            }
            this.setState({ value: '' });
        }
    }

    renderRequiredLabel = () => {
        return this.props.isRequired ? <span style={{ color: 'red', paddingRight: '0.2rem' }}>*</span> : null;
    }

    render() {
        switch (this.props.inputType) {
            case 'Text':
                var value = (!this.state.value || this.state.value === "") ? this.props.defaultValue : this.state.value;
                if (this.props.dataType === 'Number') {
                    if(this.props.filter.id === 24)
                    {
                        cpm = this.state;

                        return ( // ChasesPerMember
                            <div style={{ paddingBottom: "10px" }}>
                                <span>
                                    {this.renderRequiredLabel()}
                                    {this.props.filter.name}
                                </span>
                                <input className="form-control" id={this.state.id} type="number" min="0" step="any" disabled = {this.props.filter.isHidden}
                                    placeholder={this.props.filter.name} value={value} onChange={this.handleOnChange} ref={this.getInputRef} />
                            </div>
                        );
                    }
                    if (isNil(this.props.filter.minvalue) || isNil(this.props.filter.maxvalue) || isNil(this.props.filter.step)) {                       
                        return (                           
                            <div style={{ paddingBottom: "10px" }}>
                                <span>
                                    {this.renderRequiredLabel()}
                                    {this.props.filter.name}
                                </span>
                                <input className="form-control" id={this.state.id} type="number" min="0" step="any" disabled = {cpm != null}
                                    placeholder={this.props.filter.name} value={value} onChange={this.handleOnChange} ref={this.getInputRef} />
                            </div>
                        );
                       
                    }
                    else
                    {                        
                        return (
                            <div style={{ paddingBottom: "10px" }}>
                                <span>
                                    {this.renderRequiredLabel()}
                                    {this.props.filter.name}
                                </span>
                                <input className="form-control" id={this.state.id} type="number" min={this.props.filter.minvalue} 
                                    max={this.props.filter.maxvalue} step={this.props.filter.step} placeholder={this.props.filter.placeholder}
                                    value={value} onChange={this.handleOnChange} ref={this.getInputRef} />
                            </div>
                        );
                    }        
                }
                else {
                    return (
                        <div style={{ paddingBottom: "10px" }}>
                        <span>
                            {this.renderRequiredLabel()}
                            {this.props.filter.name}
                        </span>
                            <input className="form-control" id={this.state.id} type="text" placeholder={this.props.filter.name}
                                value={value} onChange={this.handleOnChange} />
                        </div>
                    );
                }
            case 'Checkbox':
                var CheckBoxValue = ( this.state.value === '') ? this.props.defaultValue  : this.state.value  ;
                return (
                    <div style={{ paddingBottom: "10px" }}>
                        <table><tbody><tr><td>                         
                        {
                            this.props.filter.values.map((value, index) =>
                                <span key={index} style={{ marginRight: "4px" }}>
                                    <input id={this.state.id} style={{ margin: "0px" }} type="checkbox" checked={Boolean(CheckBoxValue)} onChange={this.handleOnChange} />
                                    {value.label}
                                </span>
                            )
                        }                                                
                        {this.renderRequiredLabel()}
                        {this.props.filter.name}
                        </td></tr></tbody></table>
                    </div>
                );
            case 'Select':
                const className = (!this.state.value || this.state.value === "") ? "select-placeholder form-control" : "form-control";
                var value = (!this.state.value || this.state.value === "") ? this.props.defaultValue : this.state.value;
                return this.props.filter.values && this.props.filter.values.length > 0 ? (
                    <div style={{ paddingBottom: "10px" }}>
                        <span>
                            {this.renderRequiredLabel()}
                            {this.props.filter.name}
                        </span>
                        <select className={className} id={this.state.id} value={value} onChange={this.handleOnChange}
                            disabled={this.props.filter.id === 18 && this.props.showUpdate}>
                            <option value="">Select</option>
                            {this.props.filter.values.map((value, index) =>
                            <option key={index} value={value.value} >{value.label}</option>)}
                        </select>
                    </div>
                ) : null;
            case 'Multiselect':
                if (this.props.filter.values != undefined && this.props.filter.values.length > 0) {
                    let i = 1;
                    this.props.filter.values.forEach(x => {
                        if (x.key == undefined || x.key == null)
                            x.key = this.props.filter.filterName + "-" + i;
                        i++;
                    })
                }
                if (this.props.filter.id === 29) {
                    ddlSpecialist = this;
                }
                else if (this.props.filter.id === 33) {
                    ddlFacility = this;
                }
                return this.props.filter.values != undefined && this.props.filter.values.length > 0 || this.props.filter.id === 7 || this.props.filter.id === 2  ? (
                    <div style={{ paddingBottom: "10px" }} id={this.props.filter.id}>
                        <span>
                            {this.renderRequiredLabel()}
                            {this.props.filter.name}
                        </span>
                        <MultiSelect id={this.state.id} key={this.props.filter.filterName} options={this.props.filter.values} defaultValue={this.props.defaultValue}
                            handleOnChange={this.handleOnChange} ref={this.getInputRef}/>
                    </div>
                ) : null;
            case 'Range':
                if (this.props.filter.id === 26) {
                    PcpChaseType =  this;
                }
                else if(this.props.filter.id === 44)                 
                    Tier1Chase = this;
                else if(this.props.filter.id === 46)                    
                    Tier2Chase = this;
                else if (this.props.filter.id === 28)
                    SpecialistChase = this;
                else if (this.props.filter.id === 32)
                    FacilityChase = this;
                return (
                    <div style={{ paddingBottom: "10px" }}>
                        <span>
                            {this.renderRequiredLabel()}
                            {this.props.filter.name}
                        </span>
                        <Range id={this.state.id} filter={this.props.filter} defaultValue={this.props.defaultValue} handleOnChange={this.handleOnChange}
                            ref={this.getInputRef} />
                    </div>
                );
            default:
                return (
                    <div style={{ paddingBottom: "10px" }}>
                        <span>
                            {this.renderRequiredLabel()}
                            {this.props.filter.name}
                        </span>
                        <input className="form-control" id={this.state.id} type="text" placeholder={this.props.filter.name}
                            value={this.state.value} onChange={this.handleOnChange} />
                    </div>
                );
        }
    }
}