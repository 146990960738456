import { GapChaseFilterBase, filterType, dataType } from '../GapChaseFilterBase';

export default class AllOrTopChasesFilter extends GapChaseFilterBase {
    constructor() {
        super();
        this.id = 40;
        this.name = "All Or Top Chases";
        this.filterName = "AllOrTopChases";
        this.isHidden = false;
        this.filterType = filterType.select;
        this.values = [
                        {value: '1', label: 'All Chases'},
                        {value: '2', label: 'Top Chases Per Member'},
                        {value: '3', label: 'Top Chases Per Member by Type'}
                      ];

        this.dataType = dataType.number;
        this.filterCategory = "Chase Level";
        this.filterGroupId = 3;
        this.placeholder = "All Or Top Chases";
    }
}
