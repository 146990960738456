import { GapChaseFilterBase, filterType, dataType } from '../../GapChaseFilterBase';
import Axios from 'axios';

export default class Tier1RaGainFilter extends GapChaseFilterBase {
    constructor() {
        super();
        this.id = 44;
        this.name = "Tier 1 RA Gain";
        this.filterName = "Tier1RaGain";
        //this.isHidden = false;
        this.filterType = filterType.range;
        this.values = [];

        this.dataType = dataType.number;
        this.filterCategory = "Provider Level";
        this.filterGroupId = 4;
        this.minValue = -999.99;
        this.maxValue = 999.99;
        this.step = 0.01;
        this.placeholder = "Min RA Gain";

    }

}
