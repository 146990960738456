//todo: replace this -- it's bad practice to mutate the DOM directly (i.e. not through React)
export const toggleClass = (id, clazz) => {
    let regex = new RegExp('(?:^|\\s)' + clazz + '(?!\\S)', 'g');

    if (document.getElementById(id).className.match(regex)) {
        document.getElementById(id).className = document.getElementById(id).className.replace(regex, '');
    } else {
        document.getElementById(id).className += ' ' + clazz;
    }
};

export const addLoader = () => {
    let loader = document.getElementById('loader');
    if (loader) {
        loader.className = 'loader';
    }
};

export const removeLoader = () => {
    let loader = document.getElementById('loader');
    if (loader) {
        loader.className = 'loader hidden';
    }
};

export const setHeader = (header) => {
    if (header) {
        document.title = 'Advantasure&#0153; ACA Platform - ' + header;
    } else {
        document.title = 'Advantasure&#0153; ACA Platform';
    }
};

export const addNoOverflowClass = () => {
    document.getElementsByClassName('panel-body')[0].className = 'panel-body no-overflow';
};

export const removeNoOverflowClass = () => {
    document.getElementsByClassName('panel-body')[0].className = 'panel-body';
};
