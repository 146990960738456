import { GapChaseFilterBase, filterType, dataType } from '../GapChaseFilterBase';

export default class ChaseExclusionListsFilter extends GapChaseFilterBase {
    constructor(chaseFilterData) {
        super();
        this.id = 22;
        this.name = "Chase Exclusion List";
        this.filterName = "ChaseExclusionLists";
        // this.isHidden = false;
        this.filterType = filterType.multiSelect;
        this.values = [];
        if (chaseFilterData.ChaseIncExNames !== null && chaseFilterData.ChaseIncExNames !== "undefined")
            this.values = chaseFilterData.ChaseIncExNames;

        this.dataType = dataType.number;
        this.filterCategory = "Chase Level";
        this.filterGroupId = 3;
        this.placeholder = "Chase Exclusion List";
    }
}
