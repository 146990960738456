import React, { Component } from 'react';
import ReactTable from 'react-table';
import axios from 'axios';
import toastr from 'toastr';
import { Link } from 'react-router-dom';
import { Content } from '../../PageLayout';
import { renderDecimal } from '../../../utils/CommonReactTable';
import { download } from '../../../utils/File';


export default class FinancialProfileDetail extends Component {

	constructor(props) {
		super(props);

        const { search } = props.location;
        console.log( 'FPD props = ' + JSON.stringify( props ) );

        let filter = {
            benefitYear : 0,
            hiosId: 0,
            market: '',
            planId: ''
        };

        let pagination = {
            pageCount : 0,
            pageNumber : 1,
            pageSize : 10,
            sortKey : '',
            sortDirection : ''
        };

        var urlQuery = '';

		if (search) {

            // skip ?
			let temp = search.substring(1).split('&');
			temp.forEach((x) => {
// console.log( 'FPD kp = ' + x);

				let keyPair = x.split('=');

                if (keyPair[0].indexOf('benefitYear') > -1) {
					filter.benefitYear = parseInt(keyPair[1]);
				} else if (keyPair[0].indexOf('hiosId') > -1) {
					filter.hiosId = keyPair[1];
				} else if (keyPair[0].indexOf('market') > -1) {
                    filter.market = keyPair[1];

                } else if (keyPair[0].indexOf('pageSize') > -1) {
                    pagination.pageSize = parseInt( keyPair[1] );
                } else if (keyPair[0].indexOf('sortKey') > -1) {
                    pagination.sortKey = keyPair[1];
                } else if (keyPair[0].indexOf('sortDirection') > -1){
                    pagination.sortDirection = keyPair[1];
                } 
            });

var obj = props.match.params;
Object.keys(obj).forEach(key => {
    const value = obj[ key ];
// console.log( 'FPD object keys value key = ' + value + ' ' + key);

    if ( key === 'year' ) 
        filter.benefitYear = value;
    else if ( key === 'hiosId' )
        filter.hiosId = value;
    else if ( key === 'market' )
        filter.market = value;
    else if ( key === 'planId' )
        filter.planId = value;

  });

  urlQuery = search + '&benefitYear=' + filter.benefitYear + '&hiosId=' + filter.hiosId ; 
  
        }
        
		const { year, hiosId, market, planId } = props.match.params;

        filter.benefitYear = year;
        filter.hiosId = hiosId;
        filter.market = market;
        filter.planId = planId;

		this.state = {
            data: null,
            filter,
            pagination,
            totalItemCount : 0,
            urlQuery
        };
        
    }

    componentDidMount() {
        
        const pagination = this.state.pagination;
        const pageSize = pagination.pageSize, pageIndex = 0;

        this.onPaging( pageSize, pageIndex );

    }

    onPaging = ( pageSize, pageIndex, sortKey = '', sortDirection = '' ) => {

        const { planId, hiosId, market, benefitYear } = this.state.filter;
        const { pagination } = this.state;

        var pageNumber = pageIndex + 1;

        if ( pageSize < 0 )
            pageSize = pagination.pageSize;

        if ( sortKey === '')
            sortKey = pagination.sortKey;

        if ( sortDirection === '')
            sortDirection = pagination.sortDirection;

        pagination.pageNumber = pageNumber;
        pagination.pageSize = pageSize;
        pagination.sortKey = sortKey;
        pagination.sortDirection = sortDirection;

        axios.get( '/FinancialProfile/' + planId, { params: { hiosId, market, benefitYear, pageNumber, pageSize, sortKey, sortDirection } } ).then( ( response ) => {
        //  console.log ( ' *** FinancialProfileDetail onPaging response = ' + JSON.stringify( response ) );

            pagination.pageCount = response.PageCount;
            this.setState( { data: response, pagination, totalItemCount : response.data.TotalItemCount });

		}).catch((error) => {
			toastr.error('Failed to load financial detail.');
			console.log(error);
        })

    }

    onPageChange = ( pageIndex ) => {
        
        this.onPaging( -1, pageIndex );
    }

    onPageSizeChange = ( pageSize, pageIndex ) => {
        
        this.onPaging( pageSize, 0 );
    }

    onSortedChange = ( newSorted, column, shiftKey ) => {
        
        const sortKey = newSorted[0].id;
        const sortDirection = newSorted[0].desc ? 'DESC' : 'ASC';

        this.onPaging( -1, 0, sortKey, sortDirection );
    }

	handleExportDetail = (event) => {

        event.preventDefault();
        const { planId, hiosId, market, benefitYear } = this.state.filter;
        var sortKey = this.state.sortKey;
        var sortDirection = this.state.sortDirection;

        axios.get( '/FinancialProfile/' + planId + '/Export', { params: { hiosId, market, benefitYear, sortKey, sortDirection } } ).then( ( response ) => {

			const fileName = response.headers['x-suggested-filename'] || 'ResultsExtract_' + new Date().getTime() + '.txt';
			download( response.data, fileName );
            
		}).catch((error) => {
			toastr.error('Failed to export financial detail.');
			console.log(error);
        })
        
	}

    render() {
        var results = null;

        if (this.state.data != null) {
            results = this.state.data.data;
        }
        
        const { urlQuery, filter, pagination, totalItemCount } = this.state


        const columns = [
			{
				Header: 'Plan ID',
                accessor: 'PlanID',
                minWidth: 140
			},
			{
				Header: 'HIOS ID',
                accessor: 'HiosID'
			},
			{
				Header: 'Market',
                accessor: 'Market'
            },
			{
				Header: 'Benefit Year',
                accessor: 'BenefitYear',
                maxWidth: 80
            },
			{
				Header: 'As Of Month/Year',
                accessor: 'AsOfMonYr',
                sortable: true,
			},
			{
				Header: 'Total Members',
                accessor: 'TotalMembers',
                sortable: true,
            },
			{
				Header: 'Member Months',
                accessor: 'MemberMonths',
                Cell: (row) => renderDecimal(row.original.MemberMonths, 3),
                sortable: true,
			},
			{
				Header: 'Age Band',
                accessor: 'AgeBand',
                sortable: true,
            },
			{
				Header: 'Metal Level',
                accessor: 'MetalLevel',
                sortable: true,
            },
			{ 
				Header: 'Medical Claims',
				id: 'MedicalClaims', 
                Cell: (row) => renderDecimal(row.original.MedicalClaims, 2, "$") ,
                sortable: true,
			},
			{
				Header: 'Pharmacy Claims',
				id: 'PharmacyClaims',
                Cell: (row) => renderDecimal(row.original.PharmacyClaims, 2, "$") ,
                sortable: true,
            },
            {
                Header: 'Avg PMPM Expense',
                id: 'AvgPmpmExpense',
                Cell: (row) => renderDecimal(row.original.AvgPmpmExpense, 2, "$") ,
                sortable: true,
            }


        ];

		return (
			<Content>
				<Content.Header>Financial Profile Detail</Content.Header>
				<Content.Body>
					
                        <React.Fragment>
					{	filter.year > 0 &&
						<Content.Filter>
                            {
                                <div className="header-details">
                                    <div className="row">

                                        <div className="col-sm-12">
                                                <label>Plan ID: </label>
                                                <span>{filter.planId}</span>
                                        </div>
                                        
                                    </div> 

                                    <div className="row">

                                        <div className="col-sm-2">
                                                <label>HIOS ID: </label>
                                                <span>{filter.hiosId}</span>
                                        </div> 

                                        <div className="col-sm-2">
                                                <label>Market: </label>
                                                <span>{filter.market}</span>
                                        </div>

                                    </div>

    
                                </div>

                                }
		
                        </Content.Filter>
                    }
					
                    <div className="row export-spacing">
						<div className="col-sm-12">
                            <Link to={"/Reporting/FinancialProfile" + urlQuery}>
                                <button className="btn btn-default">Back</button>
                            </Link>
							<button className="btn btn-tessellate pull-right" onClick={this.handleExportDetail}>
								Export
							</button>
						</div>
					</div>

                    {
                        results &&
                        <Content.Result>
                        {/* property page is zero based. results.PageNumber is one based. */}
                        <ReactTable
                            className='ts-react-table -striped -highlight'
                            columns={columns}
                            data={results.Detail}
                            defaultPageSize={pagination.pageSize}
                            page={pagination.pageNumber - 1 }
                            pages={pagination.pageCount}
                            pageSizeOptions={[1, 2, 3, 5, 10, 25, 50, 100]}
                            sortable={false}
                            // defaultSorted={[{
                            //     id: 'MemberID',
                            //     desc: false
                            // }]}
                            // multiSort={false}
                            manual

                            onPageChange={this.onPageChange}
                            onPageSizeChange={this.onPageSizeChange}
                            onSortedChange={this.onSortedChange}
                            noDataText={(<span>No Results Found</span>)}

                        />
                          <div><strong>Total Number of Records: {totalItemCount}</strong></div>
                        </Content.Result>
                    }
                        </React.Fragment>
					
				</Content.Body>
			</Content>
		);
    }
    
}

