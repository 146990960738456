// import React from 'react';
import AgeFilter from '../filters/gap/AgeFilter';
import GendersFilter from '../filters/gap/GendersFilter';
import PlanIdsFilter from '../filters/gap/PlanIdsFilter';
import RaStatusFilter from '../filters/gap/RaStatusFilter';
import ORECListFilter from '../filters/gap/ORECListFilter';
import MetalLevelIdsFilter from '../filters/gap/MetalLevelIdsFilter';
import MarketsFilter from '../filters/gap/MarketsFilter';
import StatesFilter from '../filters/gap/StatesFilter';
import CountyFilter from '../filters/gap/CountyFilter';
import MemberPopulationFilter from '../filters/gap/MemberPopulationFilter';
import RAFFilter from '../filters/gap/RAFFilter';
import RAFOpportunityIncreaseFilter from '../filters/gap/RAFOpportunityIncreaseFilter';
import PotentialRafGainFilter from '../filters/gap/PotentialRafGainFilter';
import RarsdScoreFilter from '../filters/gap/RarsdScoreFilter';
import MemberInclusionListIdsFilter from '../filters/gap/MemberInclusionListIdsFilter';
import MemberExclusionListIdsFilter from '../filters/gap/MemberExclusionListIdsFilter';

export default class MemberLevelFilterGroup {
    constructor(memberLevelData) {
        // super(props);
        this.categoryName = "Member Level";
        this.isHidden = false;

        this.filters = [];
        let ageFilter = new AgeFilter();
        let gendersFilter = new GendersFilter();
        let planIdsFilter = new PlanIdsFilter();
        let raStatusFilter = new RaStatusFilter();
        let orecFilter = new ORECListFilter();
        let metalLevelIdsFilter = new MetalLevelIdsFilter(memberLevelData.MetalLevels);
        let marketsFilter = new MarketsFilter();
        let statesFilter = new StatesFilter();
        let countyFilter = new CountyFilter();
        let memberPopulationFilter = new MemberPopulationFilter();
        let rafFilter = new RAFFilter();
        let rafOpportunityIncreaseFilter = new RAFOpportunityIncreaseFilter();
        let potentialRafGainFilter = new PotentialRafGainFilter();
        let rarsdScoreFilter = new RarsdScoreFilter();
        let memberInclusionListIdsFilter = new MemberInclusionListIdsFilter(memberLevelData.IncExNames);
        let memberExclusionListIdsFilter = new MemberExclusionListIdsFilter(memberLevelData.IncExNames);

        this.filters.push(
            ageFilter, gendersFilter, planIdsFilter, raStatusFilter, orecFilter, metalLevelIdsFilter,
            marketsFilter, statesFilter, countyFilter, memberPopulationFilter, rafFilter,
            rafOpportunityIncreaseFilter, potentialRafGainFilter, rarsdScoreFilter,
            memberInclusionListIdsFilter, memberExclusionListIdsFilter
        );

    }
}