import { GapChaseFilterBase, filterType, dataType } from '../../GapChaseFilterBase';
import Axios from 'axios';

export default class OneFacilityVisitFilter extends GapChaseFilterBase {
    constructor() {
        super();
        this.id = 34;
        this.name = "At Least One Inpatient Facility Visit Per Member";
        this.filterName = "AtLeastOneInpatientFacilityVisitPerMember";
        this.isHidden = true;
        this.filterType = filterType.checkbox;
        this.values = [
            {
                "label": "",
                "value": "OneFacilityVisit"
            }
        ];

        this.dataType = dataType.boolean;
        this.filterCategory = "Provider Level";
        this.filterGroupId = 4;
        this.placeholder = "At Least One Inpatient Facility Visit Per Member";

    }

}
