import { GapChaseFilterBase, filterType, dataType } from '../GapChaseFilterBase';

export default class ChasesPerMemberFilter extends GapChaseFilterBase {
    constructor() {
        super();
        this.id = 24;
        this.name = "Chases Per Member";
        this.filterName = "ChasesPerMember";
        this.isHidden = true;
        this.filterType = filterType.text;
        this.values = [];

        this.dataType = dataType.number;
        this.filterCategory = "Chase Level";
        this.filterGroupId = 3;
        this.maxValue = 1000;
        this.placeholder = "Chases Per Member";
    }
}
