import React from 'react';
import Axios from 'axios';
import { Glyphicon, Table } from 'react-bootstrap';
import { Col, Collapse, Panel, Row } from 'react-bootstrap';
import { Sort } from './TableComponents';
import {
    defaultGapChaseListResultStatsMemberCountByMetalLevelColumns,
    defaultGapChaseListResultStatsMemberCountByGenderColumns,
    defaultGapChaseListResultStatsMemberCountByPlanIDColumns,
    defaultGapChaseListResultStatsChaseCountByProviderTypeColumns
} from './DefaultColumns.js';
import { Error } from './Messages';

export default class ListResultStats extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listResultStats: null,
            sortOrder: [],
            error: this.props.showError ? this.props.showError : false
        };

        if (this.props.listType === 'Chase') {
            this.columnsChaseCountByProviderType = defaultGapChaseListResultStatsChaseCountByProviderTypeColumns();
        }
        this.columnsMemberCountByMetalLevel = defaultGapChaseListResultStatsMemberCountByMetalLevelColumns();
        this.columnsMemberCountByGender = defaultGapChaseListResultStatsMemberCountByGenderColumns();
        this.columnsMemberCountByPlanID = defaultGapChaseListResultStatsMemberCountByPlanIDColumns();

        const CancelToken = Axios.CancelToken;
        this.source = CancelToken.source();
    }

    componentDidMount() {
        //load stats on create
        if (this.props.listDefinition && this.props.listDefinition.loadingStatus === "Complete") {
            this.loadListResultStats(this.props.listDefinition.id);
        }
    }

    componentWillUpdate(nextProps, nextState) {
        //load stats on update
        if (nextProps.listDefinition && this.props.listDefinition && nextProps.listDefinition.loadingStatus !== this.props.listDefinition.loadingStatus) {
            this.loadListResultStats(nextProps.listDefinition.id);
        }

        if (nextProps.showError === true && this.props.showError !== nextProps.showError) {
            this.setState({ error: true });
        }
    }

    componentWillUnmount() {
        this.source.cancel();
    }

    loadListResultStats = (listID) => {
        this.getListResultStats(listID)
        .then((response) => {
            if (response.status === 200) {
                this.setState({
                    listResultStats: response.data,
                    expanded: true
                });
            }
        })
        .catch((error) => {
            if (!Axios.isCancel(error)) {
                // handle error
                this.setState({ error: true });
            }
        });
    }

    getListResultStats = (listID) => {
        return Axios.get(`/GapChaseLists/${listID}/Stats`, {
            cancelToken: this.source.token
        });
    }

    expandPanel = () => {
        this.setState({ expanded: !this.state.expanded });
    }

    gapListMemberStats = (totalMemberCount, memberCountWithGaps, memberCountWithoutGaps, memberCountWithHistoricalHCC, memberCountWithSuspectHCC, avgGapsPerMember) => {
        return (
            <div style={{ paddingLeft: "15px" }}>
                <Table hover bordered condensed striped responsive>
                    <thead style={{ verticalAlign: "top" }}>
                        <tr>
                            <th>Distinct Members</th>
                            <th>Members W/ Gaps</th>
                            <th>Members W/out Gaps</th>
                            <th>Members W/ Historical HCC</th>
                            <th>Members W/ Suspect HCC</th>
                            <th>Avg Gaps per Member</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="text-left">
                            <td>{totalMemberCount}</td>
                            <td>{memberCountWithGaps}</td>
                            <td>{memberCountWithoutGaps}</td>
                            <td>{memberCountWithHistoricalHCC}</td>
                            <td>{memberCountWithSuspectHCC}</td>
                            <td>{avgGapsPerMember.toFixed(2)}</td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        );
    }

    chaseListMemberStats = (totalMemberCount, memberCountWithGaps, memberCountWithoutGaps) => {
        return (
            <div style={{ paddingLeft: "15px" }}>
                <Table hover bordered condensed striped responsive>
                    <thead style={{ verticalAlign: "top" }}>
                        <tr>
                            <th>Distinct Members</th>
                            <th>Members W/ Gaps</th>
                            <th>Members W/out Gaps</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="text-left">
                            <td>{totalMemberCount}</td>
                            <td>{memberCountWithGaps}</td>
                            <td>{memberCountWithoutGaps}</td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        );
    }

    gapListGapStats = (historicalGapCount, suspectGapCount, totalGapCount) => {
        return (
            <div style={{ paddingLeft: "15px" }}>
                <Table hover bordered condensed striped responsive>
                    <thead style={{ verticalAlign: "top" }}>
                        <tr>
                            <th>Historical Gaps</th>
                            <th>Suspect Gaps</th>
                            <th>Total Gaps</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="text-left">
                            <td>{historicalGapCount}</td>
                            <td>{suspectGapCount}</td>
                            <td>{totalGapCount}</td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        );
    }

    chaseListChaseStats = (totalChaseCount, minRAGain, maxRAGain, avgRAGain) => {
        return (
            <div style={{ paddingLeft: "15px" }}>
                <Table hover bordered condensed striped responsive>
                    <thead style={{ verticalAlign: "top" }}>
                        <tr>
                            <th>Total Chases</th>
                            <th>Min RAGain</th>
                            <th>Max RAGain</th>
                            <th>Avg RAGain</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="text-left">
                            <td>{totalChaseCount}</td>
                            <td>{minRAGain.toFixed(2)}</td>
                            <td>{maxRAGain.toFixed(2)}</td>
                            <td>{avgRAGain.toFixed(2)}</td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        );
    }

    listStatsGroupByTable = (columns, count, paddingRight = '0px', paddingLeft = '0px') => {
        let header, rows;
        if (count.length > 0) {
            header = columns.map((item, index) => {
                return (
                    <th key={index} className={item.className} style={{ height: "1px" }}>
                        <Sort fieldName={item.fieldName} text={item.displayName} onSort={this.handleSort}
                            sortDirection={item.sortDirection} sortable={item.sortable} />
                    </th>
                );
            });
            rows = count.map((item, index) => {
                return (
                    <tr className="text-left" key={index}>
                        {
                            Object.values(item).map((value, idx) => {
                                return (<td key={idx}>{value}</td>);
                            })
                        }
                    </tr>
                );
            });
            return (
                <div style={{ verticalAlign: "top", maxHeight: '220px', overflowY: 'auto', paddingRight: paddingRight, paddingLeft: paddingLeft }}>
                    <Table hover bordered condensed striped responsive>
                        <thead>
                            <tr>
                                {header}
                            </tr>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                    </Table>
                </div> 
            );
        }
    }

    renderGapStats = (tableMemberCountByGender, tableMemberCountByMetalLevel, tableMemberCountByPlanID) => {
        return (
            <div>
                <Row>
                    <h4 style={{ marginLeft: '1.4rem' }}>Member</h4>
                    <hr style={{ marginTop: 0, marginRight: '1rem', marginLeft: '1rem' }} />
                    <Row>
                        <Col lg={6}>
                            {
                                this.gapListMemberStats(this.state.listResultStats.TotalMemberCount,
                                            this.state.listResultStats.MemberCountWithGaps,
                                            this.state.listResultStats.MemberCountWithoutGaps,
                                            this.state.listResultStats.MemberCountWithHistoricalHCC,
                                            this.state.listResultStats.MemberCountWithSuspectHCC,
                                            this.state.listResultStats.AvgGapsPerMember)
                            }
                        </Col>
                        <Col lg={6}>
                            {tableMemberCountByGender}
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6}>
                            {tableMemberCountByMetalLevel}
                        </Col>
                        <Col lg={6}>
                            {tableMemberCountByPlanID}
                        </Col>
                    </Row>
                </Row>
                <Row>
                    <h4 style={{ marginLeft: '1.4rem' }}>{this.props.listType}</h4>
                    <hr style={{ marginTop: 0, marginRight: '1rem', marginLeft: '1rem' }} />
                    <Col lg={6}>
                        <Row>
                            {
                                this.gapListGapStats(this.state.listResultStats.HistoricalGapCount,
                                                this.state.listResultStats.SuspectGapCount,
                                                this.state.listResultStats.TotalGapCount)
                            }
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }

    renderChaseStats = (tableMemberCountByGender, tableMemberCountByMetalLevel, tableMemberCountByPlanID, tableChaseCountByProviderType) => {
        return (
            <div>
                <Row>
                    <h4 style={{ marginLeft: '1.4rem' }}>Member</h4>
                    <hr style={{ marginTop: 0, marginRight: '1rem', marginLeft: '1rem' }} />
                    <Row>
                        <Col lg={6}>
                            {
                                this.chaseListMemberStats(this.state.listResultStats.TotalMemberCount,
                                                this.state.listResultStats.MemberCountWithGaps,
                                                this.state.listResultStats.MemberCountWithoutGaps)
                            }
                        </Col>
                        <Col lg={6}>
                                {tableMemberCountByGender}
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6}>
                            {tableMemberCountByMetalLevel}
                        </Col>
                        <Col lg={6}>
                            {tableMemberCountByPlanID}
                        </Col>
                    </Row>
                </Row>
                <Row>
                    <h4 style={{ marginLeft: '1.4rem' }}>{this.props.listType}</h4>
                    <hr style={{ marginTop: 0, marginRight: '1rem', marginLeft: '1rem' }} />
                    <Col lg={6}>
                        <Row>
                            {
                                this.chaseListChaseStats(this.state.listResultStats.TotalChaseCount,
                                                this.state.listResultStats.MinRAGain,
                                                this.state.listResultStats.MaxRAGain,
                                                this.state.listResultStats.AvgRAGain)
                            }
                        </Row>
                    </Col>
                    <Col lg={6}>
                        {tableChaseCountByProviderType}
                    </Col>
                </Row>
            </div>
        );
    }

    render() {
        let contents, panelTitle, table, tableMemberCountByGender, tableMemberCountByMetalLevel, tableMemberCountByPlanID, tableChaseCountByProviderType;

        if (this.props.listDefinition) {
            let listName = this.props.listDefinition.name + " Summary Statistics";
            panelTitle = <div style={{ display: "inline-block" }}>{listName}</div>;

            if (this.props.listDefinition.loadingStatus === "Complete" && this.state.listResultStats) {
                tableMemberCountByGender = this.listStatsGroupByTable(
                    this.columnsMemberCountByGender,
                    this.state.listResultStats.MemberCountByGender, '15px');
                tableMemberCountByMetalLevel = this.listStatsGroupByTable(
                    this.columnsMemberCountByMetalLevel,
                    this.state.listResultStats.MemberCountByMetalLevel, '0px', '15px');
                tableMemberCountByPlanID = this.listStatsGroupByTable(
                    this.columnsMemberCountByPlanID,
                    this.state.listResultStats.MemberCountByPlanID, '15px', '0px');

                if (this.props.listType === 'Chase') {
                    tableChaseCountByProviderType = this.listStatsGroupByTable(
                        this.columnsChaseCountByProviderType,
                        this.state.listResultStats.ChaseCountByProviderType);
                }

                if (this.props.listType === "Gap") {
                    contents = this.renderGapStats(tableMemberCountByGender, tableMemberCountByMetalLevel, tableMemberCountByPlanID);
                }
                else {
                    contents = this.renderChaseStats(tableMemberCountByGender, tableMemberCountByMetalLevel, tableMemberCountByPlanID,
                        tableChaseCountByProviderType);
                }
            }
            
            else if (this.props.listDefinition.loadingStatus === "Error" ) {
                //Error on generation
                table = (
                    <div style={{ textAlign: "center", marginTop: "13rem", marginBottom: "8rem" }}>
                        <Glyphicon  glyph="remove-circle" style={{ fontSize: "48px", lineHeight: "14px", color: "#6c716c" }} />
                        <h3 style={{ textAlign: "center", marginTop: "2rem" }}>An error has occurred during result generation.</h3>
                    </div>
                );
                contents = <Col lg={12}>{table}</Col>;
            }

            else {
                //list loaded, results unavailable
                table = (
                    <div style={{ textAlign: "center", marginTop: "13rem", marginBottom: "8rem" }}>
                        <Glyphicon className="spinning" glyph="cog" style={{ fontSize: "48px", lineHeight: "14px", color: "#6c716c" }} />
                        <h3 style={{ textAlign: "center", marginTop: "2rem" }}>List generation in progress</h3>
                    </div>
                );
                contents = (<div>{table}</div>)
            }
        }

        const title = (
            <div onClick={this.expandPanel} style={{ cursor: 'pointer' }}>
                <h4>
                    {panelTitle}
                    <Glyphicon glyph="menu-down" style={{ color: "#6c716c", float: "right", paddingRight: "0.4rem" }} />
                </h4>
            </div>
        );

        if (this.state.error) {
            return (
                <div className="panel-body" style={{ minHeight: '183px' }}>
                    <Error />
                </div>
            );
        }
        else {
            return (
                 <Panel>
                    <Panel.Heading>
                        <Panel.Title>
                            {title}
                        </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body>
                        <Collapse in={this.state.expanded}>
                            {contents}
                        </Collapse>
                    </Panel.Body>
                </Panel>
             );
        }
    }
}