import React, { Component } from 'react';
import moment from 'moment';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import toastr from 'toastr';
import ReactTable from 'react-table';
import DatePicker from 'react-datepicker';
import Multiselect from 'react-bootstrap-multiselect';
import { Content } from '../../PageLayout';
import { download } from '../../../utils/File';

export default class UserActivityReport extends Component {
	constructor(props) {
		super(props);

		this.state = {
			filter: {
				duration: 0,
				dateRange: {
					startDate: moment(),
					endDate: moment()
				},
				reports: []
			},
			page: 0,
			pageSize: 10,
			numOfPages: 0,
			loading: false,
			results: [],
			searched: false,
			reports: [],
			duration: [{ label: 'Last 7 Days', value: 1 }, { label: 'Last 30 Days', value: 2 }, { label: 'Custom Date Range', value: 3 }],
			datePickerOpen: false,
			columnSorting:[
				{ id: "AccessedDate", desc: true },
				{ id: "LastName", desc: false },
				{ id: "FirstName", desc: false }
			],
			totalItemCount : 0
		}
	}

	componentDidMount() {
		axios.get('/UserActivity/Options').then((response) => {
			const reports = response.data.Reports.map(x => { return { label: x, value: x } });
			this.setState({ reports });
		}).catch((error) => {
			console.error(error);
			toastr.error('An error loading filter data');
		});
	}

	selectDuration = (event) => {
		const { value } = event.target;
		const { filter } = this.state;
		this.setState({ filter: { ...filter, duration: parseInt(value) } });
	}

	selectDate = (position) => (date) => {
		const { filter } = this.state;
		this.setState({ filter: { ...filter, dateRange: { ...filter.dateRange, [position]: date } } });
	}

	openDatePicker = () =>{
		this.setState({datePickerOpen:true});
	}
	
	closeDatePicker = () =>{
		this.setState({datePickerOpen:false});
	}

	selectReport = (options, selected) => {
		const { value } = options[0];
		const { filter, reports } = this.state;

		reports.forEach((x) => {
			if (x.value === value) { x.selected = selected; }
		});

		filter.reports = reports.map((x) => {
			if (x.selected === true) {
				return x.value;
			}
		});

		this.setState({ reports, filter });
	}

	toggleSelectAllReports = () => {
		const { reports, filter } = this.state;
		const isAllSelected = reports.filter(x => x.selected).length === reports.length;

		if (isAllSelected) {
			filter.reports = [];
			reports.forEach(x => x.selected = false);
			this.setState({ reports, filter });
		} else {
			filter.reports = reports.map(x => x.value);
			reports.forEach(x => x.selected = true);
			this.setState({ reports, filter });
		}
	}

	clearSearchFilter = (event) => {
		event.preventDefault();
		const reports = this.state.reports.map(x => { x.selected = false; return x; })
		const filter = {
			duration: 0,
			dateRange: {
				startDate: moment(),
				endDate: moment()
			},
			reports: []
		};
		const searched = false;
		this.setState({ filter, searched, reports });
	}

	isSearchDisabled = () => {
		const { duration, reports } = this.state.filter;

		if (reports.length === 0 || duration === 0) {
			return true;
		}

		return false;
	}

	getSearchResults = (event) => {
		if (event) {
			event.preventDefault();
		}
		const { duration, dateRange, reports } = this.state.filter;
		const { page, pageSize,columnSorting } = this.state;
		let range = {};

		switch (duration) {
			case 1:
				range = { startDate: moment().subtract(7, 'd').format('YYYY-MM-DD'), endDate: moment().format('YYYY-MM-DD') };
				break;
			case 2:
				range = { startDate: moment().subtract(30, 'd').format('YYYY-MM-DD'), endDate: moment().format('YYYY-MM-DD') };
				break;
			case 3:
				if (dateRange.endDate.diff(dateRange.startDate,'years',true)>1) {
					toastr.error('The maximum date span for the use activity report is one year.');
					return;
				} else if (dateRange.startDate.diff(dateRange.endDate) > 0) {
					toastr.error('The start date must occur before the end date.');
					return;
				} else {
					range = { startDate: dateRange.startDate.format('YYYY-MM-DD'), endDate: dateRange.endDate.format('YYYY-MM-DD') };
				}
				break;
			default:
				toastr.error('Must select a period of time.');
				return;
		}

		let query = {
			Take: pageSize,
			Skip: page * pageSize,
			Start: range.startDate,
			End: range.endDate,
			Reports: reports,
			columnSorting
		};

		axios.get('/UserActivity', { params: query }).then((response) => {
			const { Results, Count } = response.data;
			const numOfPages = Math.ceil(Count / query.Take);
			this.setState({ results: Results, searched: true, loading: false, numOfPages, totalItemCount: response.data.Count });
		}).catch((error) => {
			this.setState({ loading: false });
			console.error(error);
			toastr.error('An error occurred retrieving user activity data.');
		});
	}

	exportData = (event) => {
		if (event) {
			event.preventDefault();
		}
		const { duration, dateRange, reports } = this.state.filter;
		let range = {};

		switch (duration) {
			case 1:
				range = { startDate: moment().subtract(7, 'd').format('YYYY-MM-DD'), endDate: moment().format('YYYY-MM-DD') };
				break;
			case 2:
				range = { startDate: moment().subtract(30, 'd').format('YYYY-MM-DD'), endDate: moment().format('YYYY-MM-DD') };
				break;
			case 3:
				if (dateRange.endDate.diff(dateRange.startDate,'years',true)>1) {
					toastr.error('The maximum date span for the use activity report is one year.');
					return;
				} else if (dateRange.startDate.diff(dateRange.endDate) > 0) {
					toastr.error('The start date must occur before the end date.');
					return;
				} else {
					range = { startDate: dateRange.startDate.format('YYYY-MM-DD'), endDate: dateRange.endDate.format('YYYY-MM-DD') };
				}
				break;
			default:
				toastr.error('Must select a period of time.');
				return;
		}

		let query = {
			Start: range.startDate,
			End: range.endDate,
			Reports: reports
		};

		axios.get('/UserActivity/Export', { params: query, responseType: 'blob' }).then((response) => {
			const fileName = response.headers['x-suggested-filename'] || 'ResultsExtract_' + new Date().getTime() + '.txt';
			download(response.data, fileName)
		}).catch((error) => {
			console.error(error);
			toastr.error('Failed to export data.');
		});
	}

	dateFormatter = (input) => {
		if (input === null) return '';
		return moment(input).format('llll');
	}

	serverPagination = (state, instance) => {
		const { page, pageSize } = instance.state;

		if (page !== this.state.page || pageSize !== this.state.pageSize) {
			this.setState({ loading: true, page, pageSize }, this.getSearchResults);
		}
	}

	columnSortinghandler = sorted => {

        var page = this.state.page;
        page = 0;

        this.setState( { page, columnSorting: sorted }, this.getSearchResults );
    };

	render() {
		const { filter, results, reports, duration, searched, page, pageSize, loading, numOfPages,datePickerOpen, totalItemCount } = this.state;
		const { selectDuration, selectDate, openDatePicker, closeDatePicker,getSearchResults, clearSearchFilter, exportData,
			isSearchDisabled, selectReport, toggleSelectAllReports, dateFormatter,
			serverPagination,columnSortinghandler } = this;

		const columns = [
			{
				Header: 'Organization',
				accessor: 'Organization'
			},
			{
				Header: 'User First Name',
				accessor: 'FirstName'
			},
			{
				Header: 'User Last Name',
				accessor: 'LastName'
			},
			{
				Header: 'User Email Address',
				accessor: 'EmailAddress'
			},
			{
				Header: 'User Status',
				accessor: 'Status'
			},
			{
				Header: 'Page Accessed',
				accessor: 'ReportName'
			},
			{
				Header: 'Date Accessed',
				id: 'AccessedDate',
				width: 200,
				accessor: r => { return dateFormatter(r.AccessedDate) }
			},
			{
				Header: 'IP Address',
				accessor: 'IpAddress'
			},
			{
				Header: 'Last Login Date',
				id: 'LastLoginDate',
				width: 200,
				accessor: r => { return dateFormatter(r.LastLoginDate) }
			},
			{
				Header: 'Run Date',
				id: 'RunDate',
				width: 200,
				accessor: r => { return dateFormatter(r.RunDate) }
			}
		];

		return (
			<Content>
				<Content.Header>User Activity Report</Content.Header>
				<Content.Body>
					{
						reports.length > 0 &&
						<Content.Filter>
							<div className="row">
								<div className="col-sm-12">
									<form className="form-inline">
										<div className="form-group">
											<label className="control-label">Page: </label>
											<div className="ts-multiselect">
												<Multiselect
													buttonClass="btn btn-default ts-multi-select"
													style={{ padding: '12px 6px' }}
													multiple
													data={reports}
													includeSelectAllOption="true"
													onChange={selectReport}
													onSelectAll={toggleSelectAllReports}
													onDeselectAll={toggleSelectAllReports} />
											</div>
										</div>
									</form>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-12">
									<form className="form-inline">
										<div className="form-group">
											<label className="control-label">Period: </label>
											<select className="form-control" value={filter.duration} onChange={selectDuration}>
												<option value="0" disabled>Select a Duration</option>
												{
													duration.map((x, index) => <option value={x.value} key={'duration-' + index}>{x.label}</option>)
												}
											</select>
										</div>
									</form>
								</div>
							</div>
							{
								filter.duration === 3 &&
								<div className="row">
									<div className="col-sm-12">
										<form className="form-inline">
											<div className="form-group">
												<label className="control-label">Date From:</label>
												<div className="inline-flex-container">
													<div className="ts-date-picker">
														<DatePicker
															className="form-control"
															selected={filter.dateRange.startDate}														
															onChange={selectDate('startDate')} 
															onInputClick={openDatePicker}
															onSelect={closeDatePicker}
														    onClickOutside={closeDatePicker}/>
													</div>
													<div className="date-picker-container">
														<FontAwesomeIcon icon="calendar" className="date-picker-icon" />
													</div>
												</div>
											</div>
											<div className="form-group">
												<label className="control-label">Date To:</label>
												<div className="form-group">
													<div className="col-sm-4 col-md-3 col-lg-2 inline-flex-container">
														<div className="ts-date-picker">
															<DatePicker
																className="form-control"
																selected={filter.dateRange.endDate}																
																onChange={selectDate('endDate')} 
																onInputClick={openDatePicker}
																onSelect={closeDatePicker}
																onClickOutside={closeDatePicker}/>
														</div>
														<div className="date-picker-container">
															<FontAwesomeIcon icon="calendar" className="date-picker-icon" />
														</div>
													</div>
												</div>
											</div>
										</form>
									</div>
								</div>
							}
							<div className="row">
								<div className="col-sm-12">
									<form className="form-inline">
										<button className="btn btn-tessellate"
											onClick={getSearchResults}
											disabled={isSearchDisabled()}>Submit</button>
										<span style={{ padding: '0 10px' }} />
										<button className="btn btn-default"
											onClick={clearSearchFilter}>Clear</button>
									</form>
								</div>
							</div>
						</Content.Filter>
					}
					{
						searched === true &&
						<span>
							<div className="row export-spacing">
								<div className="col-sm-12">
									<div className="pull-right" style={{ paddingRight: '10px' }}>
										<button className="btn btn-tessellate" onClick={exportData}>Export</button>
									</div>
								</div>
							</div>
							<Content.Result>
								<ReactTable
									className="ts-react-table"
									data={results}
									columns={columns}
									page={page}
									loading={loading}
									pages={numOfPages}
									pageSize={pageSize}
									pageSizeOptions={[10, 25, 50, 100]}
									sortable={true}
									multiSort={true}
									showPaginationBottom={true}
									manual="true"
									onFetchData={serverPagination}
									resizable={!datePickerOpen}
									onSortedChange={sorted => columnSortinghandler(sorted)}
									noDataText={(<span>No Results Found</span>)}
								/>
							<div><strong>Total Number of Records: {totalItemCount}</strong></div>
							</Content.Result>
						</span>
					}
				</Content.Body>
			</Content>
		);
	}
}