import ChaseExclusionListsFilter from '../filters/chase/ChaseExclusionListsFilter';
import ChaseInclusionListsFilter from '../filters/chase/ChaseInclusionListsFilter';
import ChaseRankFilter from '../filters/chase/ChaseRankFilter';
import ChasesPerMemberFilter from '../filters/chase/ChasesPerMemberFilter';
import GapListIdFilter from '../filters/chase/GapListIdFilter';
import RaGainFilter from '../filters/chase/RaGainFilter';
import AllOrTopChasesFilter from '../filters/chase/AllOrTopChasesFilter';
import MrrProjectFilter from '../filters/chase/MrrProjectFilter';

export default class ChaseLevelFilterGroup {
    constructor(filterData) {
        // super(props);
        this.categoryName = "Chase Level";
        this.isHidden = false;

        this.filters = [];
        let chaseExclusionListsFilter = new ChaseExclusionListsFilter(filterData);
        let chaseInclusionListsFilter = new ChaseInclusionListsFilter(filterData);
        let chaseRankFilter = new ChaseRankFilter();
        let chasesPerMemberFilter = new ChasesPerMemberFilter();
        let gapListIdFilter = new GapListIdFilter(filterData);
        let raGainFilter = new RaGainFilter();
        let allOrTopChasesFilter = new AllOrTopChasesFilter();
        let mrrProjectFilter = new MrrProjectFilter();


        this.filters.push(
            gapListIdFilter, raGainFilter, chaseRankFilter, allOrTopChasesFilter, chasesPerMemberFilter,
            chaseInclusionListsFilter, chaseExclusionListsFilter, mrrProjectFilter
        );

    }
}