//Action needs a type property for describing how the state should change, 
//and payload is the component we want to add to Redux
export const setMemberProfile = memberProfile => ({
    type: "SET_MEMBER_PROFILE",
    memberProfile
});

export const resetMemberProfile = memberProfile => ({
    type: "RESET_MEMBER_PROFILE",
    memberProfile
});


//MemberProfile component receives the prop which is a copy of that prop in Redux state And it's from reducer.
export const  mapMemberProfileToProps = state => {
    const memberProfileProp = state.memberProfileStates.find(x => x.memberProfile)
    return memberProfileProp ? memberProfileProp : {};

};
//map[componentName]DispatchToProps connects Redux actions to React props
export const mapMemberProfileDispatchToProps = dispatch => ({
    setMemberProfile: memberProfile => dispatch(setMemberProfile(memberProfile)),
    resetMemberProfile: memberProfile => dispatch(resetMemberProfile(memberProfile))
});